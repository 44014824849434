const prod_baseurl = "https://onboardapi.cannyhr.com";

const production = {
  domain: "cannyhr",
  graphql_endpoint: {
    onboardBE: prod_baseurl + "/graphql",
  }
};

let configuration;
if (process.env.NODE_ENV === "production") {
  configuration = production;
}

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...configuration
};
 