import Vue from "vue";
import "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VeeValidate from "vee-validate";
import VueApollo from "vue-apollo";
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

import config from "./config";
import "./registerServiceWorker";

Vue.use(PerfectScrollbar);
Vue.use(VeeValidate);
Vue.use(VueApollo);

const httpLink = new HttpLink({
  uri: config.graphql_endpoint.onboardBE,
});

const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $loadingKey: "loading",
  },
});

Vue.config.productionTip = false;

new Vue({
  $_veeValidate: {
    validator: "new",
  },
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
