<template>
  <v-app>
    <Navbar />
    <img src="../assets/ComingSoon.png" alt="..." className="img" />
  </v-app>
</template>

<script>
import Navbar from "../components/Navbar.vue";
export default {
  components: {
    Navbar,
  },
};
</script>

<style scoped>
img {
  max-width: 70%;
  margin: auto;
}
</style>
