<template>
  <v-container>
    <!-- alerts -->
    <v-snackbar v-model="snackbar" :color="color" multi-line :timeout="3000">
      {{ ResponseMessage }}
      <v-btn dark flat @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-flex id="scrolling-techniques" ref="scroll"></v-flex>
    <v-layout justify-center nowrap>
      <!-- heading -->
      <p class="mt-4 mb-4 title">Personal Information</p>
    </v-layout>
    <!-- card tags to navigate anthoer section -->
    <div id="app">
      <v-container grid-list-md>
        <v-layout nowrap>
          <v-flex v-for="section in sections" :key="section.name" md3>
            <v-card class="pointerClass">
              <v-card-text
                :id="section.name"
                :class="{
                  active: sectionName === section.name,
                  notactive: sectionName !== section.name,
                }"
                dark
                @click="proceedNextSection(section.name)"
              >
                <v-layout align-center justify-center column>
                  <v-flex md12>
                    <v-icon
                      :class="{
                        activeIcon: sectionName === section.name,
                        notactiveIcon: sectionName !== section.name,
                      }"
                      >{{ section.icon }}</v-icon
                    >
                  </v-flex>
                  <v-flex md12 class="hidden-sm-and-down">
                    <h3
                      style="font-size: 0.9em"
                      :class="{ textClassActive: sectionName === section.name }"
                    >
                      {{ section.name }}
                    </h3>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </div>

    <!-- Section 1 -->
    <v-form v-show="sectionName === 'Section 1'" ref="section1" lazy-validation>
      <v-container grid-list-md text-xs-left>
        <v-layout row wrap justify-center>
          <!-- line 1 -->
          <v-flex xs12 sm5>
            <!-- Salutation -->
            <v-select
              :id="'Salutation'"
              v-model="Salutation"
              background-color="#e0e0e0"
              outline
              prepend-icon="supervisor_account"
              color="#3cd1c2"
              :items="SalutationList"
              label="Salutation*"
              :rules="[(v) => !!v || 'This field is required']"
              required
              @change="salutationListChange"
            ></v-select>
          </v-flex>
          <v-flex sm1></v-flex>
          <v-flex xs12 sm5>
            <!-- First name -->
            <v-text-field
              :id="'First_Name'"
              v-model="First_Name"
              prepend-icon="person"
              background-color="#e0e0e0"
              color="#3cd1c2"
              outline
              label="First Name*"
              :counter="50"
              :maxlength="50"
              :rules="$store.state.alSpDotRules"
              required
            ></v-text-field>
          </v-flex>
          <!-- line 2 -->
          <v-flex xs12 sm5>
            <!-- middle name -->
            <v-text-field
              :id="'Middle_Name'"
              v-model="Middle_Name"
              background-color="#e0e0e0"
              color="#3cd1c2"
              outline
              :counter="50"
              :maxlength="50"
              label="Middle Name"
              prepend-icon="perm_identity"
              :rules="Middle_Name ? $store.state.midLastRules : [true]"
              required
            ></v-text-field>
          </v-flex>
          <v-flex sm1></v-flex>
          <v-flex xs12 sm5>
            <!-- last name -->
            <v-text-field
              :id="'Last_Name'"
              v-model="Last_Name"
              background-color="#e0e0e0"
              color="#3cd1c2"
              outline
              label="Last Name*"
              :counter="50"
              :maxlength="50"
              prepend-icon="person"
              :rules="$store.state.midLastRules"
              required
            ></v-text-field>
          </v-flex>
          <!-- line 3 -->
          <v-flex xs12 sm5>
            <!-- nick name -->
            <v-text-field
              :id="'Nick_Name'"
              v-model="Nick_Name"
              background-color="#e0e0e0"
              color="#3cd1c2"
              outline
              :counter="50"
              :maxlength="50"
              label="Known as"
              prepend-icon="child_care"
              :rules="Nick_Name ? $store.state.onlyLetterSpaceRules : [true]"
              required
            ></v-text-field>
          </v-flex>
          <v-flex sm1></v-flex>
          <v-flex xs12 sm5>
            <!-- gender -->
            <v-select
              :id="'Gender'"
              v-model="Gender"
              background-color="#e0e0e0"
              outline
              prepend-icon="wc"
              color="#3cd1c2"
              :items="Gender_List"
              label="Gender*"
              :rules="[(v) => !!v || 'This field is required']"
              required
            ></v-select>
          </v-flex>
          <!-- line 4 -->
          <v-flex xs12 sm5>
            <!-- date of birth -->
            <v-dialog v-model="dobModal" persistent width="290" lazy full-width>
              <template #activator="{ on }">
                <v-text-field
                  :id="'DateofBirth'"
                  v-model="DateofBirth"
                  outline
                  label="DOB*"
                  background-color="#e0e0e0"
                  color="#3cd1c2"
                  prepend-icon="cake"
                  readonly
                  :rules="[(v) => !!v || 'This field is required']"
                  required
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="DateofBirth"
                :max="MaxDob"
                color="#3cd1c2"
                scrollable
                actions
                @change="saveDob(DateofBirth)"
              >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="dobModal = false"
                    >OK</v-btn
                  >
                </v-card-actions>
              </v-date-picker>
            </v-dialog>
          </v-flex>
          <v-flex sm1></v-flex>
          <v-flex xs12 sm5>
            <!-- marital statue -->
            <v-select
              :id="'MaritalStatus'"
              v-model="MaritalStatus"
              outline
              background-color="#e0e0e0"
              prepend-icon="wc"
              color="#3cd1c2"
              :items="maritalStatusList"
              item-text="Marital_Status"
              item-value="Marital_Status_Id"
              label="Marital Status*"
              :rules="[(v) => !!v || 'This field is required']"
              required
              @change="maritalStatusChange"
            ></v-select>
          </v-flex>
          <!-- line 5 -->
          <v-flex xs12 sm5>
            <!-- place of birth -->
            <v-text-field
              :id="'PlaceofBirth'"
              v-model="PlaceofBirth"
              background-color="#e0e0e0"
              color="#3cd1c2"
              outline
              :counter="50"
              :maxlength="50"
              label="Place Of Birth"
              prepend-icon="place"
              :rules="PlaceofBirth ? $store.state.onlyLetterSpaceRules : [true]"
              required
            ></v-text-field>
          </v-flex>
          <v-flex sm1></v-flex>
          <v-flex xs12 sm5>
            <!-- pan number -->
            <v-text-field
              :id="'PanNumber'"
              v-model="PanNumber"
              background-color="#e0e0e0"
              color="#3cd1c2"
              outline
              :counter="10"
              :maxlength="10"
              :label="getDomain === 'upshot' ? 'UTR Number' : 'PAN Number'"
              prepend-icon="folder_shared"
              :rules="PanNumber ? $store.state.panRules : [true]"
              required
            ></v-text-field>
          </v-flex>
          <!-- line 6 -->
          <v-flex xs12 sm5>
            <!-- Aadhaar number -->
            <v-text-field
              :id="'AadhaarNumber'"
              v-model="AadhaarNumber"
              background-color="#e0e0e0"
              color="#3cd1c2"
              outline
              :counter="25"
              :maxlength="25"
              :label="
                getDomain === 'upshot'
                  ? 'National Insurance Number'
                  : 'Aadhaar Number'
              "
              prepend-icon="folder_shared"
              :rules="AadhaarNumber ? $store.state.aadhaarRules : [true]"
              required
            ></v-text-field>
          </v-flex>
          <v-flex sm1></v-flex>
          <v-flex xs12 sm5></v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <!-- Section 2 -->
    <v-form v-show="sectionName === 'Section 2'" ref="section2" lazy-validation>
      <v-container grid-list-md text-xs-left>
        <v-layout row wrap justify-center>
          <!-- line 1 -->
          <v-flex xs12 sm5>
            <!-- blood group -->
            <v-select
              :id="'BloodGroup'"
              v-model="BloodGroup"
              outline
              background-color="#e0e0e0"
              prepend-icon="invert_colors"
              color="#3cd1c2"
              :items="BloodGroupList"
              label="Blood Group*"
              :rules="[(v) => !!v || 'This field is required']"
              required
            ></v-select>
          </v-flex>
          <v-flex sm1></v-flex>
          <v-flex xs12 sm5>
            <!-- languages known -->
            <v-autocomplete
              :id="'Languages'"
              v-model="Languages"
              outline
              background-color="#e0e0e0"
              prepend-icon="language"
              color="#3cd1c2"
              :items="laguagesList"
              item-text="Language_Name"
              item-value="Lang_Id"
              label="Languages Known"
              multiple
              autocomplete
            ></v-autocomplete>
          </v-flex>
          <!-- line 2 -->
          <v-flex xs12 sm5>
            <!-- hobbies -->
            <v-text-field
              :id="'Hobbies'"
              v-model="Hobbies"
              outline
              background-color="#e0e0e0"
              prepend-icon="videogame_asset"
              :counter="100"
              :maxlength="100"
              label="Hobbies"
              color="#3cd1c2"
              :rules="Hobbies ? $store.state.hobbyRules : [true]"
              required
            />
          </v-flex>
          <v-flex sm1></v-flex>
          <v-flex xs12 sm5>
            <!-- nationality -->
            <v-text-field
              :id="'Nationality'"
              v-model="Nationality"
              outline
              background-color="#e0e0e0"
              prepend-icon="flag"
              :counter="50"
              :maxlength="50"
              label="Nationality*"
              color="#3cd1c2"
              :rules="$store.state.onlyLetterSpaceRules"
              required
            />
          </v-flex>
          <!-- line 3 -->
          <v-flex xs12 sm5>
            <!-- Ethnic race -->
            <v-text-field
              :id="'EthnicRace'"
              v-model="EthnicRace"
              label="Ethnic Race"
              color="#3cd1c2"
              outline
              background-color="#e0e0e0"
              prepend-icon="supervised_user_circle"
              :counter="50"
              :maxlength="50"
              :rules="EthnicRace ? $store.state.onlyLetterSpaceRules : [true]"
              required
            />
          </v-flex>
          <v-flex sm1></v-flex>
          <v-flex xs12 sm5>
            <!-- Caste -->
            <v-text-field
              :id="'Caste'"
              v-model="Caste"
              label="Caste"
              color="#3cd1c2"
              outline
              background-color="#e0e0e0"
              prepend-icon="supervised_user_circle"
              :counter="50"
              :maxlength="50"
              :rules="Caste ? $store.state.onlyLetterSpaceRules : [true]"
              required
            />
          </v-flex>
          <!-- line 4 -->
          <v-flex xs12 sm5>
            <!-- Religion -->
            <v-text-field
              :id="'Religion'"
              v-model="Religion"
              label="Religion"
              color="#3cd1c2"
              outline
              background-color="#e0e0e0"
              prepend-icon="supervised_user_circle"
              :counter="50"
              :maxlength="50"
              :rules="Religion ? $store.state.onlyLetterSpaceRules : [true]"
              required
            />
          </v-flex>
          <v-flex sm1></v-flex>
          <v-flex xs12 sm5>
            <!-- personal email -->
            <v-text-field
              :id="'PersonalEmail'"
              v-model="PersonalEmail"
              label="Personal Email"
              color="#3cd1c2"
              outline
              :counter="100"
              :maxlength="100"
              background-color="#e0e0e0"
              prepend-icon="mail_outline"
              :rules="PersonalEmail ? $store.state.emailRules : [true]"
              required
            />
          </v-flex>
          <v-flex xs12 sm5>
            <v-switch
              :id="'PhysicallyChallanged'"
              v-model="PhysicallyChallanged"
              label="Do you have a disability?"
            ></v-switch>
          </v-flex>
          <v-flex sm1></v-flex>
          <v-flex xs12 sm5>
            <v-switch
              :id="'MilitaryServices'"
              v-model="MilitaryServices"
              label="Military Service"
            ></v-switch>
          </v-flex>
          <v-flex xs12 sm5>
            <v-switch :id="'Smoker'" v-model="Smoker" label="Smoker"></v-switch>
          </v-flex>
          <v-flex sm1></v-flex>
          <v-flex xs12 sm5>
            <v-dialog
              v-model="smokerasofModal"
              persistent
              width="290"
              lazy
              full-width
            >
              <template #activator="{ on }">
                <v-text-field
                  v-show="Smoker"
                  :id="'DateofBirth'"
                  v-model="SmokerAsOf"
                  outline
                  label="Somekr As Of*"
                  background-color="#e0e0e0"
                  color="#3cd1c2"
                  prepend-icon="smoking_rooms"
                  readonly
                  :rules="
                    Smoker ? [(v) => !!v || 'This field is required'] : [true]
                  "
                  :required="Smoker ? true : false"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="SmokerAsOf"
                :max="maxYear"
                color="#3cd1c2"
                scrollable
                actions
              >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="smokerasofModal = false"
                    >OK</v-btn
                  >
                </v-card-actions>
              </v-date-picker>
            </v-dialog>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <!-- Section 3 -->
    <v-form v-show="sectionName === 'Section 3'" ref="section3" lazy-validation>
      <v-container grid-list-md text-xs-center>
        <!-- car amimation -->
        <div class="car">
          <div class="strike"></div>
          <div class="strike strike2"></div>
          <div class="strike strike3"></div>
          <div class="strike strike4"></div>
          <div class="strike strike5"></div>
          <div class="car-detail spoiler"></div>
          <div class="car-detail back"></div>
          <div class="car-detail center"></div>
          <div class="car-detail center1"></div>
          <div class="car-detail front"></div>
          <div class="car-detail wheel"></div>
          <div class="car-detail wheel wheel2"></div>
        </div>
        <v-layout row wrap>
          <v-flex md12>
            <!-- heading -->
            <v-layout>
              <v-flex md12>
                <p class="mt-4 headline grey--text">Driving Licence Details</p>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 sm6 md12>
            <v-layout row wrap>
              <!-- line 1 -->
              <v-flex xs12 sm5>
                <!-- licence nnumber -->
                <v-text-field
                  :id="'LicenceNumber'"
                  v-model="LicenceNumber"
                  label="Driving Licence No"
                  color="#3cd1c2"
                  outline
                  background-color="#e0e0e0"
                  prepend-icon="motorcycle"
                  :counter="30"
                  :maxlength="30"
                  :rules="
                    validLicence ? $store.state.alphaNumSpHyRules : [true]
                  "
                  required
                  @change="validLicenceCheck(LicenceNumber)"
                />
              </v-flex>
              <v-flex sm1></v-flex>
              <v-flex xs12 sm5>
                <!-- licence issue date -->
                <v-dialog
                  v-model="licIsuModal"
                  persistent
                  width="290"
                  lazy
                  full-width
                >
                  <template #activator="{ on }">
                    <v-text-field
                      :id="'LicenceIssueDate'"
                      v-model="LicenceIssueDate"
                      outline
                      background-color="#e0e0e0"
                      :value="LicenceIssueDate"
                      color="#3cd1c2"
                      label="Issue Date"
                      prepend-icon="date_range"
                      :rules="
                        validLicence
                          ? [(v) => !!v || 'This field is required']
                          : [true]
                      "
                      required
                      v-on="on"
                      @change="validLicenceCheck(LicenceIssueDate)"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="LicenceIssueDate"
                    color="#3cd1c2"
                    :max="MaxExpDate"
                    scrollable
                    actions
                  >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn flat color="primary" @click="licIsuModal = false"
                        >OK</v-btn
                      >
                    </v-card-actions>
                  </v-date-picker>
                </v-dialog>
              </v-flex>
              <!-- line 2 -->
              <v-flex xs12 sm5>
                <!-- licence expiry date -->
                <v-dialog
                  v-model="licExpModal"
                  persistent
                  width="290"
                  lazy
                  full-width
                >
                  <template #activator="{ on }">
                    <v-text-field
                      :id="'LicenceExpiryDate'"
                      v-model="LicenceExpiryDate"
                      :value="LicenceExpiryDate"
                      color="#3cd1c2"
                      label="Expiry Date"
                      prepend-icon="date_range"
                      outline
                      background-color="#e0e0e0"
                      :rules="
                        validLicence
                          ? [(v) => !!v || 'This field is required']
                          : [true]
                      "
                      required
                      v-on="on"
                      @change="validLicenceCheck(LicenceExpiryDate)"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="LicenceExpiryDate"
                    color="#3cd1c2"
                    :min="MinExpDate"
                    scrollable
                    actions
                  >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn flat color="primary" @click="licExpModal = false"
                        >OK</v-btn
                      >
                    </v-card-actions>
                  </v-date-picker>
                </v-dialog>
              </v-flex>
              <v-flex sm1></v-flex>
              <v-flex xs12 sm5>
                <!-- licence issued authority -->
                <v-text-field
                  :id="'LicenceIssuingAuthority'"
                  v-model="LicenceIssuingAuthority"
                  outline
                  background-color="#e0e0e0"
                  prepend-icon="person"
                  :counter="50"
                  :maxlength="50"
                  label="Issuing Authority"
                  color="#3cd1c2"
                  :rules="
                    validLicence ? $store.state.onlyLetterSpaceRules : [true]
                  "
                  required
                  @change="validLicenceCheck(LicenceIssuingAuthority)"
                />
              </v-flex>

              <v-flex xs12 sm5>
                <!-- licence issuing country -->
                <v-autocomplete
                  :id="'LicenceIssueCountry'"
                  v-model="LicenceIssueCountry"
                  color="#3cd1c2"
                  :items="CountryLists"
                  item-text="Country_Name"
                  item-value="Country_Code"
                  label="Issuing Country"
                  outline
                  background-color="#e0e0e0"
                  prepend-icon="flag"
                  :rules="
                    validLicence
                      ? [(v) => !!v || 'This field is required']
                      : [true]
                  "
                  required
                  autocomplete
                  @change="validLicenceCheck(LicenceIssueCountry)"
                ></v-autocomplete>
              </v-flex>
              <v-flex sm1></v-flex>
              <v-flex xs12 sm5>
                <!-- vehicle type -->
                <v-text-field
                  :id="'VehicleType'"
                  v-model="VehicleType"
                  outline
                  background-color="#e0e0e0"
                  prepend-icon="directions_car"
                  :counter="30"
                  :maxlength="30"
                  label="Vehicle Type"
                  color="#3cd1c2"
                  :rules="validLicence ? $store.state.vehicleRules : [true]"
                  required
                  @change="validLicenceCheck(VehicleType)"
                />
              </v-flex>
              <v-flex xs12 sm5>
                <!-- licence issue state -->
                <v-text-field
                  :id="'IssuingState'"
                  v-model="IssuingState"
                  outline
                  background-color="#e0e0e0"
                  prepend-icon="location_on"
                  :counter="30"
                  :maxlength="30"
                  label="Issuing State"
                  color="#3cd1c2"
                  :rules="
                    validLicence ? $store.state.onlyLetterSpaceRules : [true]
                  "
                  required
                  @change="validLicenceCheck(IssuingState)"
                />
              </v-flex>
              <v-flex sm1></v-flex>
              <v-flex xs12 sm5></v-flex>
            </v-layout>
          </v-flex>
          <!-- flight animations -->
          <div class="animation">
            <svg
              id="master-artboard"
              class="animation__cloud--back"
              viewBox="0 0 45 18"
              version="1.1"
              x="0px"
              y="0px"
              width="45px"
              height="18px"
            >
              <path
                d="M 58.88372802734375 825.155517578125 C 16.61685562133789 826.1433715820312 57.925209045410156 781.6769409179688 58.883731842041016 781.3507080078125 C 106.25779724121094 731.152099609375 169.17739868164062 692.9862670898438 226.64694213867188 694.6730346679688 C 227.2968292236328 557.091552734375 389.74322509765625 563.0558471679688 425.166748046875 635.9559326171875 C 534.7359619140625 431.2034912109375 793.6226196289062 599.6361694335938 715.956298828125 741.27392578125 C 820.5570068359375 803.94287109375 789.773193359375 826.9736328125 767.21728515625 825.1555786132812 C 394.85125732421875 825.5911865234375 359.5561218261719 823.805908203125 58.88372802734375 825.155517578125 Z"
                transform="matrix(0.0573558509349823, 0, 0, 0.056244850158691406, -1.3596858978271484, -29.666284561157227)"
              ></path>
            </svg>
            <svg
              id="master-artboard"
              class="animation__cloud--middle"
              viewBox="0 0 45 18"
              version="1.1"
              x="0px"
              y="0px"
              width="45px"
              height="18px"
            >
              <path
                d="M 58.88372802734375 825.155517578125 C 16.61685562133789 826.1433715820312 57.925209045410156 781.6769409179688 58.883731842041016 781.3507080078125 C 106.25779724121094 731.152099609375 169.17739868164062 692.9862670898438 226.64694213867188 694.6730346679688 C 227.2968292236328 557.091552734375 389.74322509765625 563.0558471679688 425.166748046875 635.9559326171875 C 534.7359619140625 431.2034912109375 793.6226196289062 599.6361694335938 715.956298828125 741.27392578125 C 820.5570068359375 803.94287109375 789.773193359375 826.9736328125 767.21728515625 825.1555786132812 C 394.85125732421875 825.5911865234375 359.5561218261719 823.805908203125 58.88372802734375 825.155517578125 Z"
                transform="matrix(0.0573558509349823, 0, 0, 0.056244850158691406, -1.3596858978271484, -29.666284561157227)"
              ></path>
            </svg>
            <div class="animation__plane--shadow"></div>
            <svg
              class="animation__plane"
              xmlns:cc="https://creativecommons.org/ns#"
              xmlns:dc="https://purl.org/dc/elements/1.1/"
              xmlns:rdf="https://www.w3.org/1999/02/22-rdf-syntax-ns#"
              xmlns:svg="https://www.w3.org/2000/svg"
              data-name="svgi-plane"
              viewBox="0 0 135.67 49.55"
            >
              <path
                fill="#fff"
                stroke="#464646"
                stroke-linejoin="round"
                d="M74.663 45.572h-9.106z"
                class="svgi-plane--stripe3"
              ></path>
              <path
                fill="#fff"
                stroke="#464646"
                stroke-linejoin="round"
                stroke-width="1"
                d="M.75 26.719h23.309z"
                class="svgi-plane--stripe1"
              ></path>
              <path
                fill="#fff"
                stroke="#464646"
                stroke-linejoin="round"
                stroke-width="1"
                d="M11.23 31.82h22.654z"
                class="svgi-plane--stripe2"
              ></path>
              <path
                fill="#fff"
                stroke="#464646"
                stroke-linejoin="round"
                stroke-width="1"
                d="m 53.47597,24.263013 h 68.97869 c 6.17785,0 12.47074,6.758518 12.40872,8.67006 -0.05,1.537903 -5.43763,7.036166 -11.72452,7.056809 l -59.599872,0.201269 c -9.092727,0.03097 -23.597077,-5.992662 -22.178652,-11.794378 1.160348,-4.74789 7.862358,-4.13376 12.115634,-4.13376 z"
              ></path>
              <path
                fill="#fff"
                stroke="#464646"
                stroke-linejoin="round"
                stroke-width="1"
                d="M 45.243501,24.351777 37.946312,0.952937 h 7.185155 c 15.37061,20.184725 28.402518,23.28324 28.402518,23.28324 0,0 -27.106129,-0.178562 -28.290484,0.1156 z"
              ></path>
              <path
                fill="#fff"
                stroke="#464646"
                stroke-linejoin="round"
                stroke-width="1"
                d="m 42.699738,18.984597 h 10.627187 c 5.753726,0 5.364609,7.799958 0,7.799958 H 42.998828 c -4.96749,0 -5.574672,-7.799958 -0.29909,-7.799958 z m 33.139939,16.164502 h 29.656893 c 6.62199,0 6.49395,6.577892 0,6.577892 H 75.940707 c -8.658596,0 -8.499549,-6.35598 -0.10103,-6.577892 z m 9.693907,6.664592 h 8.86866 c 4.439332,0 4.309293,7.066099 0,7.066099 h -8.756626 z"
              ></path>
              <path
                fill="#fff"
                stroke="#464646"
                stroke-linejoin="round"
                stroke-width="1"
                d="m 85.55159,42.447431 c 0,0 -5.282585,0.456211 -6.372912,3.263659 1.335401,2.378073 6.397919,2.528767 6.397919,2.528767 z"
              ></path>
              <path
                fill="#fff"
                stroke="#464646"
                stroke-linejoin="round"
                stroke-width="1"
                d="m 133.68903,31.07417 h -7.01411 c -1.26938,0 -2.89286,-1.005314 -3.21496,-3.216179 h 7.50225 z"
              ></path>
              <ellipse
                cx="113.564"
                cy="29.448534"
                fill="#fff"
                stroke="#464646"
                stroke-linecap="square"
                stroke-linejoin="round"
                stroke-width="1"
                rx="1.3354006"
                ry="1.6400863"
              ></ellipse>
              <ellipse
                cx="107.56219"
                cy="29.448534"
                fill="#fff"
                stroke="#464646"
                stroke-linecap="square"
                stroke-linejoin="round"
                stroke-width="1"
                rx="1.3354006"
                ry="1.6400863"
              ></ellipse>
              <ellipse
                cx="101.56039"
                cy="29.448534"
                fill="#fff"
                stroke="#464646"
                stroke-linecap="square"
                stroke-linejoin="round"
                stroke-width="1"
                rx="1.3354006"
                ry="1.6400863"
              ></ellipse>
              <ellipse
                cx="95.558594"
                cy="29.448534"
                fill="#fff"
                stroke="#464646"
                stroke-linecap="square"
                stroke-linejoin="round"
                stroke-width="1"
                rx="1.3354006"
                ry="1.6400863"
              ></ellipse>
              <ellipse
                cx="89.556793"
                cy="29.448534"
                fill="#fff"
                stroke="#464646"
                stroke-linecap="square"
                stroke-linejoin="round"
                stroke-width="1"
                rx="1.3354006"
                ry="1.6400863"
              ></ellipse>
              <ellipse
                cx="83.554993"
                cy="29.448534"
                fill="#fff"
                stroke="#464646"
                stroke-linecap="square"
                stroke-linejoin="round"
                stroke-width="1"
                rx="1.3354006"
                ry="1.6400863"
              ></ellipse>
              <ellipse
                cx="77.553192"
                cy="29.448534"
                fill="#fff"
                stroke="#464646"
                stroke-linecap="square"
                stroke-linejoin="round"
                stroke-width="1"
                rx="1.3354006"
                ry="1.6400863"
              ></ellipse>
              <ellipse
                cx="71.551392"
                cy="29.448534"
                fill="#fff"
                stroke="#464646"
                stroke-linecap="square"
                stroke-linejoin="round"
                stroke-width="1"
                rx="1.3354006"
                ry="1.6400863"
              ></ellipse>
              <ellipse
                cx="65.549591"
                cy="29.448534"
                fill="#fff"
                stroke="#464646"
                stroke-linecap="square"
                stroke-linejoin="round"
                stroke-width="1"
                rx="1.3354006"
                ry="1.6400863"
              ></ellipse>
            </svg>
            <svg
              id="master-artboard"
              class="animation__cloud--front"
              viewBox="0 0 45 18"
              version="1.1"
              x="0px"
              y="0px"
              width="45px"
              height="18px"
            >
              <path
                d="M 58.88372802734375 825.155517578125 C 16.61685562133789 826.1433715820312 57.925209045410156 781.6769409179688 58.883731842041016 781.3507080078125 C 106.25779724121094 731.152099609375 169.17739868164062 692.9862670898438 226.64694213867188 694.6730346679688 C 227.2968292236328 557.091552734375 389.74322509765625 563.0558471679688 425.166748046875 635.9559326171875 C 534.7359619140625 431.2034912109375 793.6226196289062 599.6361694335938 715.956298828125 741.27392578125 C 820.5570068359375 803.94287109375 789.773193359375 826.9736328125 767.21728515625 825.1555786132812 C 394.85125732421875 825.5911865234375 359.5561218261719 823.805908203125 58.88372802734375 825.155517578125 Z"
                transform="matrix(0.0573558509349823, 0, 0, 0.056244850158691406, -1.3596858978271484, -29.666284561157227)"
              ></path>
            </svg>
            <div class="animation__loader"></div>
          </div>
          <v-flex md12>
            <!-- heading -->
            <v-layout>
              <v-flex md12>
                <p class="mt-4 headline grey--text">Passport Details</p>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 sm6 md12>
            <v-layout row wrap>
              <!-- line 1 -->
              <v-flex xs12 sm5>
                <!-- passport number -->
                <v-text-field
                  :id="'PassportNumber'"
                  v-model="PassportNumber"
                  outline
                  background-color="#e0e0e0"
                  prepend-icon="flight"
                  :counter="50"
                  :maxlength="50"
                  label="Passport Number"
                  color="#3cd1c2"
                  :rules="
                    validPassport ? $store.state.onlyLetterNumberRules : [true]
                  "
                  required
                  @change="validPassportCheck"
                />
              </v-flex>
              <v-flex sm1></v-flex>
              <v-flex xs12 sm5>
                <!-- passport issue date -->
                <v-dialog
                  v-model="passIsuModal"
                  persistent
                  width="290"
                  lazy
                  full-width
                >
                  <template #activator="{ on }">
                    <v-text-field
                      :id="'PassportIssueDate'"
                      v-model="PassportIssueDate"
                      outline
                      background-color="#e0e0e0"
                      :value="PassportIssueDate"
                      color="#3cd1c2"
                      label="Issue Date"
                      prepend-icon="date_range"
                      :rules="
                        validPassport
                          ? [(v) => !!v || 'This field is required']
                          : [true]
                      "
                      required
                      v-on="on"
                      @change="validPassportCheck"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="PassportIssueDate"
                    color="#3cd1c2"
                    :max="MaxExpDate"
                    scrollable
                    actions
                  >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn flat color="primary" @click="passIsuModal = false"
                        >OK</v-btn
                      >
                    </v-card-actions>
                  </v-date-picker>
                </v-dialog>
              </v-flex>
              <v-flex xs12 sm5>
                <!-- passport expiry date -->
                <v-dialog
                  v-model="passExpmodal"
                  persistent
                  width="290"
                  lazy
                  full-width
                >
                  <template #activator="{ on }">
                    <v-text-field
                      :id="'PassportExpiryDate'"
                      v-model="PassportExpiryDate"
                      outline
                      background-color="#e0e0e0"
                      :value="PassportExpiryDate"
                      color="#3cd1c2"
                      label="Expiry Date"
                      prepend-icon="date_range"
                      :rules="
                        validPassport
                          ? [(v) => !!v || 'This field is required']
                          : [true]
                      "
                      required
                      v-on="on"
                      @change="validPassportCheck"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="PassportExpiryDate"
                    color="#3cd1c2"
                    :min="MinExpDate"
                    scrollable
                    actions
                  >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn flat color="primary" @click="passExpmodal = false"
                        >OK</v-btn
                      >
                    </v-card-actions>
                  </v-date-picker>
                </v-dialog>
              </v-flex>
              <v-flex sm1></v-flex>
              <v-flex xs12 sm5></v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <!-- Section 4 -->
    <v-form v-show="sectionName === 'Section 4'" ref="section4">
      <v-container grid-list-md text-xs-center>
        <p class="headline grey--text mt-4 mb-4">Permanent Address</p>
        <v-layout row wrap>
          <!-- line -1 -->
          <v-flex xs12 sm5>
            <!-- permanent appartment name -->
            <v-text-field
              :id="'PermanentAppartment'"
              v-model="PermanentAppartment"
              outline
              background-color="#e0e0e0"
              prepend-icon="domain"
              :counter="100"
              color="#3cd1c2"
              label="Apartment Name*"
              :rules="$store.state.alphaNumSpCDotHySlashRules"
              required
            />
          </v-flex>
          <v-flex sm1></v-flex>
          <v-flex xs12 sm5>
            <!-- permanent street name -->
            <v-text-field
              :id="'PermanentStreet'"
              v-model="PermanentStreet"
              outline
              background-color="#e0e0e0"
              prepend-icon="streetview"
              :counter="100"
              label="Street*"
              color="#3cd1c2"
              :rules="$store.state.alphaNumSpCDotHySlashRules"
              required
            />
          </v-flex>
          <v-flex xs12 sm5>
            <!-- permanent city name -->
            <v-text-field
              :id="'PermanentCity'"
              v-model="PermanentCity"
              outline
              background-color="#e0e0e0"
              prepend-icon="location_city"
              :counter="50"
              color="#3cd1c2"
              label="City*"
              :rules="$store.state.cityRules"
              required
            />
          </v-flex>
          <v-flex sm1></v-flex>
          <v-flex xs12 sm5>
            <!-- permanent state name -->
            <v-text-field
              :id="'PermanentState'"
              v-model="PermanentState"
              outline
              background-color="#e0e0e0"
              prepend-icon="location_on"
              :counter="100"
              label="State*"
              color="#3cd1c2"
              :rules="$store.state.city_state_Rules"
              required
            />
          </v-flex>
          <v-flex xs12 sm5>
            <!-- permanent country name -->
            <v-autocomplete
              :id="'PermanentCountry'"
              v-model="PermanentCountry"
              outline
              background-color="#e0e0e0"
              prepend-icon="flag"
              color="#3cd1c2"
              :items="CountryLists"
              item-text="Country_Name"
              item-value="Country_Code"
              label="Country*"
              :rules="[(v) => !!v || 'This field is required']"
              required
              autocomplete
            ></v-autocomplete>
          </v-flex>
          <v-flex sm1></v-flex>
          <v-flex xs12 sm5>
            <!-- permanent pincode name -->
            <v-text-field
              :id="'PermanentPin'"
              v-model="PermanentPin"
              outline
              background-color="#e0e0e0"
              prepend-icon="fiber_pin"
              :counter="100"
              color="#3cd1c2"
              label="Postcode*"
              :rules="$store.state.alphaNumSpHyRules"
              required
            />
          </v-flex>
        </v-layout>

        <p class="headline grey--text mt-4 mb-4">Current Address</p>
        <v-switch
          v-model="sameAddress"
          label="Same as permanent address"
        ></v-switch>
        <v-layout row wrap>
          <v-flex xs12 sm5>
            <!-- current appartment name -->
            <v-text-field
              :id="'CurrentAppartment'"
              v-model="CurrentAppartment"
              outline
              background-color="#e0e0e0"
              prepend-icon="domain"
              :counter="100"
              :disabled="sameAddress"
              color="#3cd1c2"
              label="Apartment Name*"
              :rules="$store.state.alphaNumSpCDotHySlashRules"
              required
            />
          </v-flex>
          <v-flex sm1></v-flex>
          <v-flex xs12 sm5>
            <!-- current street name -->
            <v-text-field
              :id="'CurrentStreet'"
              v-model="CurrentStreet"
              outline
              background-color="#e0e0e0"
              prepend-icon="streetview"
              :counter="100"
              :disabled="sameAddress"
              label="Street*"
              color="#3cd1c2"
              :rules="$store.state.alphaNumSpCDotHySlashRules"
              required
            />
          </v-flex>
          <v-flex xs12 sm5>
            <!-- current city name -->
            <v-text-field
              :id="'CurrentCity'"
              v-model="CurrentCity"
              outline
              background-color="#e0e0e0"
              prepend-icon="location_city"
              :counter="50"
              :disabled="sameAddress"
              color="#3cd1c2"
              label="City*"
              :rules="$store.state.cityRules"
              required
            />
          </v-flex>
          <v-flex sm1></v-flex>
          <v-flex xs12 sm5>
            <!-- current state name -->
            <v-text-field
              :id="'CurrentState'"
              v-model="CurrentState"
              outline
              background-color="#e0e0e0"
              prepend-icon="location_on"
              :counter="100"
              :disabled="sameAddress"
              label="State*"
              color="#3cd1c2"
              :rules="$store.state.city_state_Rules"
              required
            />
          </v-flex>
          <v-flex xs12 sm5>
            <!-- currrent country name -->
            <v-autocomplete
              :id="'CurrentCountry'"
              v-model="CurrentCountry"
              outline
              background-color="#e0e0e0"
              prepend-icon="flag"
              :disabled="sameAddress"
              color="#3cd1c2"
              :items="CountryLists"
              item-text="Country_Name"
              item-value="Country_Code"
              label="Country*"
              :rules="[(v) => !!v || 'This field is required']"
              required
              autocomplete
            ></v-autocomplete>
          </v-flex>
          <v-flex sm1></v-flex>
          <v-flex xs12 sm5>
            <!-- current pincode  -->
            <v-text-field
              :id="'CurrentPin'"
              v-model="CurrentPin"
              outline
              background-color="#e0e0e0"
              prepend-icon="fiber_pin"
              :counter="100"
              :disabled="sameAddress"
              color="#3cd1c2"
              label="Postcode*"
              :rules="$store.state.alphaNumSpHyRules"
              required
            />
          </v-flex>
        </v-layout>

        <p class="headline grey--text mt-4 mb-4">Contact Information</p>
        <v-layout row wrap>
          <v-flex xs12 sm5>
            <!-- mobile number -->
            <div class="ml-4 pl-2">
              <VueTelInput
                v-model="MobileNumber"
                class="pa-2 mt-1"
                style="width: 100%"
                :preferred-countries="['IN', 'US', 'AU']"
                :error="!!mobileNumberValidation"
                error-color="#E53935"
                valid-color="#9E9E9E"
                :input-options="mobileNumberTranslation"
                :default-country="getCountryCode(MobileNumberCountryCode)"
                :valid-characters-only="true"
                @input="updateMobileNumber"
              ></VueTelInput>
              <span
                :class="
                  mobileNumberValidation
                    ? 'red--text caption mt-1'
                    : 'green--text caption mt-1'
                "
                >{{ mobileNumberValidation }}</span
              >
            </div>
          </v-flex>
          <v-flex sm1></v-flex>
          <v-flex xs12 sm5>
            <!-- fax number -->
            <v-text-field
              :id="'EmergencyContactNo'"
              v-model="EmergencyContactNo"
              outline
              background-color="#e0e0e0"
              prepend-icon="phone_android"
              :counter="15"
              color="#3cd1c2"
              label="Emergency Contact No"
              :rules="EmergencyContactNo ? $store.state.phoneRules : [true]"
              :required="EmergencyContactNo ? true : false"
            />
          </v-flex>
          <v-flex sm1></v-flex>
          <v-flex xs12 sm5>
            <!-- landline number -->
            <v-text-field
              :id="'LandlineNumber'"
              v-model="LandlineNumber"
              outline
              background-color="#e0e0e0"
              prepend-icon="contact_phone"
              :counter="15"
              color="#3cd1c2"
              label="Land Line Number"
              :rules="LandlineNumber ? $store.state.phoneRules : [true]"
              :required="LandlineNumber ? true : false"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <!-- Dependent details -->
    <dependent-details
      v-show="sectionName === 'Section 5'"
      ref="childComponent"
      progress="Dependent"
      @dependentProgress="dependentPrpgressMethod($event)"
    />
    <v-btn
      v-show="sectionName !== 'Section 5'"
      :id="'SavePersonalDetails'"
      class="success center"
      @click.native="SaveDetails()"
      >Save</v-btn
    >
    <v-btn
      v-show="sectionName !== 'Section 5'"
      :id="'ProceedToNextSection'"
      class="right success"
      @click="proceedNextSection()"
      >Next</v-btn
    >
  </v-container>
</template>

<script>
import {
  LIST_LANGUAGE,
  LIST_COUNTRY,
  LIST_MARITAL_STATUS,
  LIST_RELATIONSHIP,
  UPDATE_PERSONAL_INFO,
  GET_PERSONAL_INFO,
} from "../graphql.js";
import DependentDetails from "./DepentDetails.vue";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

export default {
  components: {
    // Loading,
    "dependent-details": DependentDetails,
    VueTelInput,
  },

  data() {
    return {
      dialogLoading: false,
      sectionName: "Section 1",
      sections: [
        {
          name: "Section 1",
          icon: "account_box",
        },
        {
          name: "Section 2",
          icon: "group",
        },
        {
          name: "Section 3",
          icon: "folder_shared",
        },
        {
          name: "Section 4",
          icon: "contact_phone",
        },
        {
          name: "Section 5",
          icon: "group_add",
        },
      ],
      // section 1 datas
      Salutation: "",
      SalutationList: ["Mr", "Miss", "Mrs", "Dr", "Prof"],
      First_Name: "",
      Last_Name: "",
      Middle_Name: "",
      Nick_Name: "",
      DateofBirth: "",
      PlaceofBirth: "",
      AadhaarNumber: "",
      PanNumber: "",
      Gender: "",
      Gender_List: ["Female", "Male"],
      MaritalStatus: "",
      maritalStatusList: [],
      // section 2 datss
      BloodGroup: "",
      Languages: [],
      Hobbies: "",
      MilitaryServices: false,
      SmokerAsOf: null,
      smokerasofModal: false,
      EthnicRace: "",
      Nationality: "",
      Religion: "",
      Caste: "",
      Manager: false,
      PhysicallyChallanged: false,
      Smoker: false,
      PersonalEmail: "",

      // Section 3 datas
      relationShip_index: 1,
      LicenceIssueDate: "",
      LicenceExpiryDate: "",
      LicenceNumber: "",
      LicenceIssuingAuthority: "",
      IssuingState: "",
      VehicleType: "",
      LicenceIssueCountry: "",
      PassportIssueDate: "",
      PassportExpiryDate: "",
      PassportNumber: "",
      licence: false,
      passport: false,

      // section 4 datas
      PermanentAppartment: "",
      PermanentCity: "",
      PermanentStreet: "",
      PermanentState: "",
      PermanentCountry: "",
      PermanentPin: "",
      CurrentAppartment: "",
      CurrentCity: "",
      CurrentStreet: "",
      CurrentState: "",
      CurrentCountry: "",
      CurrentPin: "",
      sameAddress: false,
      MobileNumber: "",
      MobileNumberCountryCode: "",
      LandlineNumber: "",
      EmergencyContactNo: "",
      CountryLists: [],
      // queries fields
      listLanguage: [],
      languages_id: [],
      listCountry: [],
      listMartialStatus: [],
      laguagesList: [],
      //others
      tagValue: "tab-1",
      validDependent: false,
      validLicence: false,
      validPassport: false,
      MaxDob: new Date().getFullYear() - 15 + "-12-31",
      MaxExpDate: new Date().getFullYear() + "-12-31",
      MinExpDate: new Date().getFullYear() + "-01-01",
      snackbar: false,
      color: "success",
      ResponseMessage: "",
      showAddExp: [true],
      ExpInex: 0,
      Photo_Path: "",
      section1Progress: false,
      section2Progress: false,
      section3Progress: false,
      section4Progress: false,
      dobModal: false,
      licIsuModal: false,
      licExpModal: false,
      passIsuModal: false,
      passExpmodal: false,
      personalDetails: "",
      maxYear: "",
      mobileNumberTranslation: {
        placeholder: "Mobile Number*",
      },
      mobileNumberProps: {},
    };
  },

  computed: {
    BloodGroupList() {
      return this.$store.state.bloodGroup;
    },
    getDomain() {
      return this.$store.state.domainName;
    },
    mobileNumberValidation() {
      if (!this.MobileNumber || !this.MobileNumberCountryCode) {
        return "Mobile number is required / Please provide a valid mobile number";
      } else if (this.MobileNumber) {
        return this.mobileNumberProps.valid ||
          this.mobileNumberProps.valid === undefined
          ? ""
          : "Please provide a valid mobile number";
      }
      return "";
    },
  },
  watch: {
    sameAddress(val) {
      if (val) {
        this.CurrentAppartment = this.PermanentAppartment;
        this.CurrentStreet = this.PermanentStreet;
        this.CurrentCity = this.PermanentCity;
        this.CurrentState = this.PermanentState;
        this.CurrentCountry = this.PermanentCountry;
        this.CurrentPin = this.PermanentPin;
      } else {
        this.CurrentAppartment = "";
        this.CurrentStreet = "";
        this.CurrentCity = "";
        this.CurrentState = "";
        this.CurrentCountry = "";
        this.CurrentPin = "";
      }
    },
  },
  created: function () {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    this.maxYear = yyyy + "-" + mm + "-" + dd;

    this.$store.commit("Loader", true);

    this.$apollo
      .query({
        query: LIST_LANGUAGE,
        variables: {
          Org_Code: localStorage.getItem("org_code"),
          Url_Hash: localStorage.getItem("url_hash"),
        },
      })
      .then((res) => {
        if (res.data.listLanguage.languages) {
          this.laguagesList = res.data.listLanguage.languages;
        }

        this.$apollo
          .query({
            query: LIST_MARITAL_STATUS,
            variables: {
              Org_Code: localStorage.getItem("org_code"),
              Url_Hash: localStorage.getItem("url_hash"),
            },
          })
          .then((res) => {
            if (res.data.listMartialStatus) {
              this.maritalStatusList =
                res.data.listMartialStatus.marital_status;
            }
            this.$apollo
              .query({
                query: LIST_COUNTRY,
                variables: {
                  Org_Code: localStorage.getItem("org_code"),
                  Url_Hash: localStorage.getItem("url_hash"),
                },
              })
              .then((res) => {
                if (res.data.listCountry) {
                  this.CountryLists = res.data.listCountry.country
                    ? res.data.listCountry.country
                    : [];
                }
                this.$apollo
                  .query({
                    query: GET_PERSONAL_INFO,
                    variables: {
                      Org_Code: localStorage.getItem("org_code"),
                      Url_Hash: localStorage.getItem("url_hash"),
                    },
                    fetchPolicy: "no-cache",
                  })
                  .then((res) => {
                    var personal = res.data.getPersonalInfo;
                    this.personalDetails = res.data.getPersonalInfo;

                    this.Languages = personal.Lang_Known.languages.map(
                      function (obj) {
                        return obj.Lang_Id;
                      }
                    );
                    this.Salutation = !personal.Salutation
                      ? ""
                      : personal.Salutation;
                    this.First_Name = !personal.Emp_First_Name
                      ? ""
                      : personal.Emp_First_Name;
                    this.Middle_Name = personal.Emp_Middle_Name;
                    this.Last_Name = !personal.Emp_Last_Name
                      ? ""
                      : personal.Emp_Last_Name;
                    this.Nick_Name = personal.Nick_Name;
                    this.Gender = !personal.Gender ? "" : personal.Gender;
                    this.DateofBirth = !personal.DOB ? "" : personal.DOB;
                    this.PlaceofBirth = personal.Place_Of_Birth;
                    this.MaritalStatus = !personal.Marital_Status
                      ? ""
                      : personal.Marital_Status;
                    this.AadhaarNumber = !personal.Aadhaar_Card_Number
                      ? ""
                      : personal.Aadhaar_Card_Number;
                    this.PanNumber = personal.PAN;
                    this.BloodGroup = !personal.Blood_Group
                      ? ""
                      : personal.Blood_Group;
                    this.Hobbies = personal.hobbies;
                    this.Nationality = !personal.Nationality
                      ? ""
                      : personal.Nationality;
                    this.Religion = personal.Religion;
                    this.Caste = personal.Caste;
                    this.EthnicRace = personal.Ethnic_Race;
                    this.PersonalEmail = personal.Personal_Email;
                    this.PhysicallyChallanged = personal.Physically_Challenged;
                    this.Smoker = personal.Smoker;
                    this.SmokerAsOf = personal.Smokerasof;
                    this.MilitaryServices = personal.Military_Service;
                    this.LicenceNumber = personal.Driving_License_No;
                    this.LicenceIssueDate = personal.License_Issue_Date;
                    this.LicenceExpiryDate = personal.License_Expiry_Date;
                    this.LicenceIssuingAuthority = personal.Issuing_Authority;
                    this.LicenceIssueCountry = personal.Issuing_Country
                      ? personal.Issuing_Country
                      : "";
                    this.IssuingState = personal.Issuing_State;
                    this.VehicleType = personal.Vehicle_Type;
                    this.PassportNumber = personal.Passport_No;
                    this.PassportIssueDate = personal.Issue_Date;
                    this.PassportExpiryDate = personal.Expiry_Date;
                    this.PermanentAppartment = !personal.pApartment_Name
                      ? ""
                      : personal.pApartment_Name;
                    this.PermanentCity = !personal.pCity ? "" : personal.pCity;
                    this.PermanentStreet = !personal.pStreet_Name
                      ? ""
                      : personal.pStreet_Name;
                    this.PermanentState = !personal.pState
                      ? ""
                      : personal.pState;
                    this.PermanentCountry = !personal.pCountry
                      ? ""
                      : personal.pCountry;
                    this.PermanentPin = !personal.pPincode
                      ? ""
                      : personal.pPincode;
                    this.sameAddress =
                      personal.Use_Location_Address == 1 ? true : false;
                    this.CurrentAppartment = !personal.cApartment_Name
                      ? ""
                      : personal.cApartment_Name;
                    this.CurrentCity = !personal.cCity ? "" : personal.cCity;
                    this.CurrentStreet = !personal.cStreet_Name
                      ? ""
                      : personal.cStreet_Name;
                    this.CurrentState = !personal.cState ? "" : personal.cState;
                    this.CurrentCountry = !personal.cCountry
                      ? ""
                      : personal.cCountry;
                    this.CurrentPin = !personal.cPincode
                      ? ""
                      : personal.cPincode;
                    this.LandlineNumber = personal.Land_Line_No;
                    this.MobileNumber = !personal.Mobile_No
                      ? ""
                      : personal.Mobile_No;
                    this.MobileNumberCountryCode =
                      !personal.Mobile_No_Country_Code
                        ? ""
                        : personal.Mobile_No_Country_Code;
                    this.EmergencyContactNo = personal.Fax_No;
                    this.Photo_Path = !personal.Photo_Path
                      ? ""
                      : personal.Photo_Path;
                    this.section1Progress = personal.Section1_Progress;
                    this.section2Progress = personal.Section2_Progress;
                    this.section3Progress = personal.Section3_Progress;
                    this.section4Progress = personal.Section4_Progress;
                    this.$emit("PhotoPath", personal.Photo_Path);
                    if (personal.Section1_Progress)
                      this.$emit("personalProcessChanged", "section1");
                    if (personal.Section2_Progress)
                      this.$emit("personalProcessChanged", "section2");
                    if (personal.Section3_Progress)
                      this.$emit("personalProcessChanged", "section3");
                    if (personal.Section4_Progress)
                      this.$emit("personalProcessChanged", "section4");
                    this.$store.commit("Loader", false);
                    // to get list of relationships
                    this.maritalStatusChange();
                  })
                  .catch((err) => {
                    this.snackbar = true;
                    this.ResponseMessage =
                      err.graphQLErrors.length !== 0
                        ? err.graphQLErrors[0].message
                        : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
                    this.color = "error";
                  });
              })
              .catch((err) => {
                this.snackbar = true;
                this.ResponseMessage =
                  err.graphQLErrors.length !== 0
                    ? err.graphQLErrors[0].message
                    : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
                this.color = "error";
              });
          })
          .catch((err) => {
            this.snackbar = true;
            this.ResponseMessage =
              err.graphQLErrors.length !== 0
                ? err.graphQLErrors[0].message
                : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
            this.color = "error";
          });
      })
      .catch((err) => {
        this.snackbar = true;
        this.ResponseMessage =
          err.graphQLErrors.length !== 0
            ? err.graphQLErrors[0].message
            : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
        this.color = "error";
      });
  },
  methods: {
    getCountryCode(MobileNumberCountryCode) {
      if (MobileNumberCountryCode) {
        let countryCode = parseInt(MobileNumberCountryCode);
        return countryCode;
      } else {
        return "";
      }
    },
    updateMobileNumber(payload, payload2) {
      this.mobileNumberProps = payload2;
      if (payload2 && Object.keys(payload2).length > 0) {
        this.MobileNumber = payload2.nationalNumber;
        this.MobileNumberCountryCode = "+" + payload2.countryCallingCode;
      }
    },
    validPassportCheck() {
      if (
        !this.PassportIssueDate &&
        !this.PassportExpiryDate &&
        !this.PassportNumber
      ) {
        this.validPassport = false;
      } else if (
        this.PassportIssueDate ||
        this.PassportExpiryDate ||
        this.PassportNumber
      ) {
        this.validPassport = true;
      } else {
        this.validPassport = false;
      }
    },
    validLicenceCheck() {
      if (
        !this.LicenceIssueDate &&
        !this.LicenceExpiryDate &&
        !this.LicenceNumber &&
        !this.LicenceIssuingAuthority &&
        !this.IssuingState &&
        !this.VehicleType &&
        !this.LicenceIssueCountry
      ) {
        this.validLicence = false;
      } else if (
        this.LicenceIssueDate ||
        this.LicenceExpiryDate ||
        this.LicenceNumber ||
        this.LicenceIssuingAuthority ||
        this.IssuingState ||
        this.VehicleType ||
        this.LicenceIssueCountry
      ) {
        this.validLicence = true;
      } else {
        this.validLicence = false;
      }
    },
    maritalStatusChange() {
      this.$apollo
        .query({
          query: LIST_RELATIONSHIP,
          variables: {
            Org_Code: localStorage.getItem("org_code"),
            Url_Hash: localStorage.getItem("url_hash"),
            Marital_Status_Id: !this.MaritalStatus
              ? 1
              : parseInt(this.MaritalStatus, 10),
          },
        })
        .then((res) => {
          if (res.data.listMartialStatusRelation.marital_status_relation) {
            this.$refs.childComponent.setRelation(
              res.data.listMartialStatusRelation.marital_status_relation
            );
          }
        })
        .catch((err) => {
          this.snackbar = true;
          this.ResponseMessage =
            err.graphQLErrors.length !== 0
              ? err.graphQLErrors[0].message
              : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
          this.color = "error";
        });
    },
    salutationListChange() {
      this.Gender =
        this.Salutation === "Mr"
          ? "Male"
          : this.Salutation === "Miss"
          ? "Female"
          : "";
    },

    SaveDetails() {
      var scope =
        this.sectionName === "Section 1"
          ? "section1"
          : this.sectionName === "Section 2"
          ? "section2"
          : this.sectionName === "Section 3"
          ? "section3"
          : "section4";

      if (this.$refs[scope].validate()) {
        if (this.sectionName !== "Section 5") {
          this.SubmitForm(name, "save");
        } else {
          if (name) {
            this.sectionName = name;
          } else {
            this.sectionName = "Section " + ++this.sectionName.split(" ")[1];
          }
        }
      }
    },
    proceedNextSection(name) {
      var scope =
        this.sectionName === "Section 1"
          ? "section1"
          : this.sectionName === "Section 2"
          ? "section2"
          : this.sectionName === "Section 3"
          ? "section3"
          : "section4";

      if (this.$refs[scope].validate()) {
        if (this.sectionName !== "Section 5") {
          this.SubmitForm(name, "next");
        } else {
          if (name) {
            this.sectionName = name;
          } else {
            this.sectionName = "Section " + ++this.sectionName.split(" ")[1];
          }
        }
      }
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    saveDob(value) {
      localStorage.setItem("DOB", value);
    },
    SubmitForm(name, type) {
      this.$apollo
        .mutate({
          mutation: UPDATE_PERSONAL_INFO,
          variables: {
            Org_Code: localStorage.getItem("org_code"),
            Url_Hash: localStorage.getItem("url_hash"),
            Salutation: this.Salutation,
            Emp_First_Name: this.First_Name,
            Emp_Middle_Name: this.Middle_Name,
            Emp_Last_Name: this.Last_Name,
            Nick_Name: this.Nick_Name,
            Gender: this.Gender,
            DOB: this.DateofBirth,
            Place_Of_Birth: this.PlaceofBirth,
            Marital_Status: this.MaritalStatus,
            Blood_Group: this.BloodGroup,
            Section1_Progress: this.section1Progress
              ? true
              : this.sectionName === "Section 1" ||
                this.sectionName === "Section 2" ||
                this.sectionName === "Section 3" ||
                this.sectionName === "Section 4",
            Section2_Progress: this.section2Progress
              ? true
              : this.sectionName === "Section 2" ||
                this.sectionName === "Section 3" ||
                this.sectionName === "Section 4",
            Section3_Progress: this.section3Progress
              ? true
              : this.sectionName === "Section 3" ||
                this.sectionName === "Section 4",
            Section4_Progress: this.section4Progress
              ? true
              : this.sectionName === "Section 4",
            Photo_Path: this.Photo_Path,
            Lang_Known: this.Languages,
            hobbies: this.Hobbies,
            Nationality: this.Nationality,
            Religion: this.Religion,
            Caste: this.Caste,
            Is_Manager: this.Manager,
            Personal_Email: this.PersonalEmail,
            Physically_Challenged: this.PhysicallyChallanged,
            Smoker: this.Smoker,
            Smokerasof: this.SmokerAsOf,
            PAN: this.PanNumber,
            Aadhaar_Card_Number: this.AadhaarNumber,
            Military_Service: this.MilitaryServices,
            Driving_License_No: this.LicenceNumber,
            License_Issue_Date: this.LicenceIssueDate,
            License_Expiry_Date: this.LicenceExpiryDate,
            Issuing_Authority: this.LicenceIssuingAuthority,
            Issuing_Country: this.LicenceIssueCountry,
            Issuing_State: this.IssuingState,
            Vehicle_Type: this.VehicleType,
            Passport_No: this.PassportNumber,
            Issue_Date: this.PassportIssueDate,
            Expiry_Date: this.PassportExpiryDate,
            pApartment_Name: this.PermanentAppartment,
            pStreet_Name: this.PermanentStreet,
            pCity: this.PermanentCity,
            pState: this.PermanentState,
            pCountry: this.PermanentCountry,
            pPincode: this.PermanentPin,
            cApartment_Name: this.sameAddress
              ? this.PermanentAppartment
              : this.CurrentAppartment,
            cStreet_Name: this.sameAddress
              ? this.PermanentStreet
              : this.CurrentStreet,
            cCity: this.sameAddress ? this.PermanentCity : this.CurrentCity,
            cState: this.sameAddress ? this.PermanentState : this.CurrentState,
            cCountry: this.sameAddress
              ? this.PermanentCountry
              : this.CurrentCountry,
            cPincode: this.sameAddress ? this.PermanentPin : this.CurrentPin,
            Use_Location_Address: this.sameAddress ? 1 : 0,
            Land_Line_No: this.LandlineNumber,
            Mobile_No: this.MobileNumber,
            Mobile_No_Country_Code: this.MobileNumberCountryCode,
            Emergency_Contact_No: this.EmergencyContactNo,
            Ethnic_Race: this.EthnicRace,
          },
        })
        .then(() => {
          if (!this.section1Progress && this.sectionName === "Section 1") {
            this.$emit("personalProcessChanged", "section1");
            this.section1Progress = true;
          } else if (
            !this.section2Progress &&
            this.sectionName === "Section 2"
          ) {
            this.$emit("personalProcessChanged", "section2");
            this.section2Progress = true;
          } else if (
            !this.section3Progress &&
            this.sectionName === "Section 3"
          ) {
            this.$emit("personalProcessChanged", "section3");
            this.section3Progress = true;
          } else if (
            !this.section4Progress &&
            this.sectionName === "Section 4"
          ) {
            this.$emit("personalProcessChanged", "section4");
            this.section4Progress = true;
          } else {
            null;
          }
          if (type === "next") {
            if (name) {
              this.sectionName = name;
            } else {
              this.sectionName = "Section " + ++this.sectionName.split(" ")[1];
            }
          }
          this.ResponseMessage = "Auto Save Success";
          this.color = "success";
          this.snackbar = true;
        })
        .catch((err) => {
          this.snackbar = true;
          this.ResponseMessage =
            err.graphQLErrors.length !== 0
              ? err.graphQLErrors[0].message
              : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
          this.color = "error";
        });
    },
    dependentPrpgressMethod(val) {
      if (val === 20) {
        this.$emit("personalProcessChanged", "dependent");
      } else {
        this.$emit("personalProcessChanged", "sec5del");
      }
    },
  },
};
</script>

<style scoped>
.pointerClass {
  cursor: pointer;
}
@keyframes spin {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -3px) rotate(36deg);
  }
  20% {
    transform: translate(-2px, 0px) rotate(72deg);
  }
  30% {
    transform: translate(1px, 2px) rotate(108deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(144deg);
  }
  50% {
    transform: translate(-1px, 3px) rotate(180deg);
  }
  60% {
    transform: translate(-1px, 1px) rotate(216deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(252deg);
  }
  80% {
    transform: translate(-2px, -1px) rotate(288deg);
  }
  90% {
    transform: translate(2px, 1px) rotate(324deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(360deg);
  }
}
@keyframes speed {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -3px) rotate(-1deg);
  }
  20% {
    transform: translate(-2px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 3px) rotate(-1deg);
  }
  60% {
    transform: translate(-1px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-2px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
@keyframes strikes {
  from {
    left: 25px;
  }
  to {
    left: -80px;
    opacity: 0;
  }
}
@keyframes dots {
  from {
    width: 0px;
  }
  to {
    width: 15px;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
body {
  background: #333;
  font-family: "Lato", "Lucida Grande", "Lucida Sans Unicode", Tahoma,
    Sans-Serif;
}

.fadeIn,
.loading-window {
  animation: fadeIn 0.4s both;
}

.loading-window {
  background: rgb(255, 255, 255);
  color: #ffe4e1;
  height: 200px;
  left: 50%;
  margin-left: -150px;
  margin-top: -100px;
  position: fixed;
  top: 50%;
  width: 100px;
  z-index: 99;
}
.loading-window .text {
  font-size: 16px;
  position: absolute;
  width: auto;
  top: 75%;
  left: 38%;
  margin: 0 auto;
}
.loading-window .dots {
  display: inline-block;
  width: 5px;
  overflow: hidden;
  vertical-align: bottom;
  animation: dots 1.5s linear infinite;
  transition: 1;
}

.car {
  position: relative;
  width: 117px;
  height: 42px;
  top: 4px;
  margin: auto;
}
.car .strike {
  position: absolute;
  width: 11px;
  height: 1px;
  background: black;
  animation: strikes 0.2s linear infinite;
}
.car .strike2 {
  top: 11px;
  animation-delay: 0.05s;
}
.car .strike3 {
  top: 22px;
  animation-delay: 0.1s;
}
.car .strike4 {
  top: 33px;
  animation-delay: 0.15s;
}
.car .strike5 {
  top: 44px;
  animation-delay: 0.2s;
}
.car-detail {
  position: absolute;
  display: block;
  background: red;
  animation: speed 0.5s linear infinite;
}
.car-detail.spoiler {
  width: 0;
  height: 0;
  top: 7px;
  background: none;
  border: 20px solid transparent;
  border-bottom: 8px solid red;
  border-left: 20px solid red;
}
.car-detail.back {
  height: 20px;
  width: 92px;
  top: 15px;
  left: 0px;
}
.car-detail.center {
  height: 35px;
  width: 75px;
  left: 12px;
  border-top-left-radius: 30px;
  border-top-right-radius: 45px 40px;
  border: 4px solid red;
  background: none;
  box-sizing: border-box;
}
.car-detail.center1 {
  height: 35px;
  width: 35px;
  left: 12px;
  border-top-left-radius: 30px;
}
.car-detail.front {
  height: 20px;
  width: 50px;
  top: 15px;
  left: 67px;
  border-top-right-radius: 50px 40px;
  border-bottom-right-radius: 10px;
}
.car-detail.wheel {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  top: 20px;
  left: 12px;
  border: 3px solid #333;
  background: linear-gradient(
      45deg,
      transparent 45%,
      #ffe4e1 46%,
      #ffe4e1 54%,
      transparent 55%
    ),
    linear-gradient(
      -45deg,
      transparent 45%,
      #ffe4e1 46%,
      #ffe4e1 54%,
      transparent 55%
    ),
    linear-gradient(
      90deg,
      transparent 45%,
      #ffe4e1 46%,
      #ffe4e1 54%,
      transparent 55%
    ),
    linear-gradient(
      0deg,
      transparent 45%,
      #ffe4e1 46%,
      #ffe4e1 54%,
      transparent 55%
    ),
    radial-gradient(#ffe4e1 29%, transparent 30%, transparent 50%, #ffe4e1 51%),
    #333;
  animation-name: spin;
}
.car-detail.wheel2 {
  left: 82px;
}

@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:100,300,400,600,700");
@import url("https://fonts.googleapis.com/css?family=Indie+Flower");

body {
  box-shadow: 0 0 10rem 0 #000000 inset;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;
  background-color: #1d1f20;
  color: #ffffff;
  font-family: "Josefin Sans", sans-serif;
  font-size: 10px;
  font-weight: 100;
}

a {
  text-decoration: none;
  color: #ffa500;
}
a:hover {
  text-decoration: underline;
}

.animation {
  margin: auto;
  position: relative;
  overflow: hidden;
  width: 17ex;
  height: 7rem;
  max-width: 25rem;
  background-color: #ffffff;
  border-radius: 10rem;
  animation: 24s linear infinite sky;
}
.animation:after {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  box-shadow: 0 7rem 30rem -4rem #5aacdc inset;
}
.animation [class^="animation__"],
.animation [class*=" animation__"] {
  position: absolute;
}
.animation__plane {
  position: absolute;
  z-index: 1;
  top: calc(40% - (62px / 2));
  left: calc(50% - (170px / 2));
  width: 10rem;
  height: 5.2rem;
  animation: 2s ease-in-out takeOff, 5s ease-in-out infinite alternate flight 2s;
}
.animation__plane--shadow {
  bottom: 1rem;
  left: calc(54% - (8rem / 2));
  width: 8rem;
  height: 1rem;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.15);
  animation: 2s ease-in-out takeOffShadow,
    5s ease-in-out infinite alternate flightShadow 2s;
}
.animation__cloud--front {
  z-index: 2;
  top: 50%;
  left: 20%;
  width: 8.5rem;
  height: 3rem;
  fill: #cee4e7;
  animation: 4s linear infinite cloudFront;
}
.animation__cloud--middle {
  top: 22%;
  left: 75%;
  width: 6rem;
  height: 3rem;
  fill: #cee4e7;
  animation: 5s linear infinite cloudMiddle;
}
.animation__cloud--back {
  top: 6%;
  left: 34%;
  fill: #cee4e7;
  animation: 8s linear infinite cloudBack;
}
.animation .animation__loader {
  position: absolute;
  left: 0;
  bottom: 3rem;
  width: 100%;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.15);
}
.animation .animation__loader:after {
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #cee4e7;
  overflow: hidden;
  animation: 1.5s ease-in-out infinite loader;
}

@keyframes loader {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
@keyframes takeOff {
  0% {
    transform: translate(-220%, 110%);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes takeOffShadow {
  0% {
    transform: translate(-440%, 110%);
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 0.8;
  }
}
@keyframes flight {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, 10%);
  }
  75% {
    transform: translate(0, -10%);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes flightShadow {
  0% {
    transform: scale(0.8);
    opacity: 0.8;
  }
  25% {
    transform: scale(0.9);
    opacity: 1;
  }
  75% {
    transform: scale(1.1);
    opacity: 0.6;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.8;
  }
}
@keyframes cloudFront {
  0% {
    transform: translate(520%, 0);
  }
  100% {
    transform: translate(-600%, 0);
  }
}
@keyframes cloudMiddle {
  0% {
    transform: translate(230%, 0);
  }
  100% {
    transform: translate(-900%, 0);
  }
}
@keyframes cloudBack {
  0% {
    transform: translate(910%, 0);
  }
  100% {
    transform: translate(-1000%, 0);
  }
}
@keyframes sky {
  0% {
    background-color: #ffffff;
  }
  20% {
    background-color: #ffffff;
  }
  35% {
    background-color: #ffc8bd;
  }
  50% {
    background-color: transparent;
  }
  70% {
    background-color: transparent;
  }
  85% {
    background-color: #ffc8bd;
  }
  100% {
    background-color: #ffffff;
  }
}

.v-menu--inline {
  display: block;
}
.lableclass {
  margin-top: 10px;
}
.shadowBox {
  box-shadow: 1px 1px 3px #888888;
}
.inputColor {
  border: 1px solid #bccaca !important;
}
.active {
  border-bottom: 4px solid;
  border-image-source: linear-gradient(45deg, #38d3c4, #1362a3);
  border-image-slice: 1;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
  color: #3cd1c2;
}
.notactive {
  color: darkgray;
}
.activeIcon {
  background: linear-gradient(to right, #38d3c4, #1362a3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.notactiveIcon {
  color: #888888;
}
.textClassActive {
  background: linear-gradient(to right, #38d3c4, #1362a3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.v-card__text {
  padding: 0.5em !important;
  width: 100%;
}
</style>
