<template>
  <v-app>
    <Navbar />
    <Project />
  </v-app>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Project from "./Project.vue";

export default {
  components: {
    Navbar,
    Project,
  },
};
</script>

<style></style>
