<template>
  <v-app class="appClass lighten-4">
    <v-content :class="{ contentClass: $store.state.drawer }">
      <router-view></router-view>
      <loader></loader>
    </v-content>
  </v-app>
</template>

<script>
import config from "./config.js";
import Loader from "./views/Loader.vue";
export default {
  name: "App",
  components: {
    Loader,
  },
  data() {
    return {};
  },
  created() {
    let domain = config.domain;
    this.$store.commit("UPDATE_DOMAIN_NAME", domain);
  },
};
</script>

<style>
.appClass {
  background: #fafafa !important;
}
.contentClass {
  padding-left: 16% !important;
}
</style>
