<template>
  <v-container>
    <!-- alert -->
    <v-snackbar v-model="snackbar" :color="color" multi-line :timeout="3000">
      {{ ResponseMessage }}
      <v-btn dark flat @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <!-- piggy animation -->
    <div class="CIT-loader">
      <svg
        class="CIT-loader__asset"
        width="150px"
        height="150px"
        viewBox="0 0 512 512"
      >
        <path
          class="CIT-loader__coin"
          d="m256 120c33.089844 0 60-26.910156 60-60s-26.910156-60-60-60-60 26.910156-60 60 26.910156 60 60 60zm0 0"
        ></path>
        <path
          class="CIT-loader__pig"
          d="m497 241h-33.988281c-7.265625-14.253906-16.523438-27.289062-27.625-38.890625l15.1875-62.5625c1.085937-4.484375.058593-9.199219-2.78125-12.820313-2.84375-3.617187-7.191407-5.726562-11.792969-5.726562h-45c-23.714844 0-45.921875 11.308594-59.867188 30h-105.132812c-74.996094 0-138.347656 50.339844-158.335938 118.96875-10.828124 2.242188-19.105468-1.191406-23.335937-4.023438-8.347656-5.59375-14.328125-14.925781-14.328125-24.945312v-15c0-8.289062-6.710938-15-15-15s-15 6.710938-15 15v15c0 20.054688 10.945312 38.6875 27.617188 49.863281 10.046874 6.738281 21.519531 10.152344 33.292968 10.152344.285156 0 .570313-.082031.851563-.085937-.449219 4.96875-.761719 9.984374-.761719 15.070312 0 18.75 3.136719 37.179688 9.316406 54.757812 9.988282 28.429688 27.4375 54.363282 50.683594 73.539063v52.703125c0 8.289062 6.710938 15 15 15h75c8.289062 0 15-6.710938 15-15v-15h90v15c0 8.289062 6.710938 15 15 15h75c8.289062 0 15-6.710938 15-15v-52.472656l5.917969-5.359375c16.332031-14.855469 29.296875-33.417969 38.585937-53.265625l40.152344-27.65625c3.972656-2.796875 6.34375-7.367188 6.34375-12.246094v-90c0-8.289062-6.710938-15-15-15zm-196 0h-90c-8.289062 0-15-6.710938-15-15s6.710938-15 15-15h90c8.289062 0 15 6.710938 15 15s-6.710938 15-15 15zm75 45c-8.285156 0-15-6.714844-15-15s6.714844-15 15-15 15 6.714844 15 15-6.714844 15-15 15zm0 0"
        ></path>
      </svg>
    </div>
    <!-- heading -->
    <v-layout justify-center nowrap>
      <p class="mt-2 mb-2 title">Bank Information</p>
    </v-layout>
    <!-- form -->
    <v-form ref="bankform" v-model="valid" lazy-validation>
      <v-container grid-list-md text-xs-center>
        <v-layout row wrap xs12 md12>
          <v-flex>
            <v-layout row wrap>
              <!-- line 1 -->
              <v-flex xs12 md5>
                <!-- ifsc -->
                <v-text-field
                  :id="'IFSC'"
                  v-model="IFSC"
                  :maxlength="50"
                  background-color="#e0e0e0"
                  color="#3cd1c2"
                  outline
                  :counter="50"
                  label="IFSC Code*"
                  prepend-icon="account_box"
                  :rules="$store.state.onlyLetterNumberRules"
                  required
                  @change="ifscCall"
                ></v-text-field>
              </v-flex>
              <v-flex md1 />
              <v-flex xs12 md5>
                <!-- bank name -->
                <v-autocomplete
                  :id="'Bankname'"
                  v-model="BankName"
                  background-color="#e0e0e0"
                  outline
                  prepend-icon="account_balance"
                  :rules="[(v) => !!v || 'This field is required']"
                  required
                  :items="banknameList"
                  color="#3cd1c2"
                  label="BankName*"
                  autocomplete
                  :error-messages="bankerrors"
                  @blur="bankNameBlur"
                ></v-autocomplete>
              </v-flex>
              <!-- line 2 -->
              <v-flex xs12 md5>
                <!-- branch name -->
                <v-text-field
                  :id="'Branchname'"
                  v-model="BranchName"
                  background-color="#e0e0e0"
                  color="#3cd1c2"
                  outline
                  :counter="50"
                  :maxlength="50"
                  label="Branch Name*"
                  prepend-icon="domain"
                  :rules="$store.state.branchName_street_Rules"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex md1 />
              <v-flex xs12 md5>
                <!-- street -->
                <v-text-field
                  :id="'BankStreet'"
                  v-model="Street"
                  :maxlength="100"
                  background-color="#e0e0e0"
                  color="#3cd1c2"
                  outline
                  :counter="100"
                  label="Street"
                  prepend-icon="streetview"
                  :rules="
                    Street ? $store.state.branchName_street_Rules : [true]
                  "
                ></v-text-field>
              </v-flex>
              <!-- line 3 -->
              <v-flex xs12 md5>
                <!-- city -->
                <v-text-field
                  :id="'BankCity'"
                  v-model="City"
                  :maxlength="50"
                  background-color="#e0e0e0"
                  color="#3cd1c2"
                  outline
                  :counter="50"
                  label="City"
                  prepend-icon="location_city"
                  :rules="City ? $store.state.city_state_Rules : [true]"
                ></v-text-field>
              </v-flex>
              <v-flex md1 />
              <v-flex xs12 md5>
                <!-- state -->
                <v-text-field
                  :id="'BankState'"
                  v-model="State"
                  :maxlength="50"
                  background-color="#e0e0e0"
                  color="#3cd1c2"
                  outline
                  :counter="50"
                  label="State"
                  prepend-icon="location_on"
                  :rules="State ? $store.state.city_state_Rules : [true]"
                ></v-text-field>
              </v-flex>
              <!-- line 4 -->
              <v-flex xs12 md5>
                <!-- pincode -->
                <v-text-field
                  :id="'BankPincode'"
                  v-model="PinCode"
                  :maxlength="15"
                  background-color="#e0e0e0"
                  color="#3cd1c2"
                  outline
                  :counter="15"
                  label="PostCode"
                  prepend-icon="fiber_pin"
                  :rules="PinCode ? $store.state.pinCodeRules : [true]"
                ></v-text-field>
              </v-flex>
              <v-flex md1 />
              <v-flex xs12 md5>
                <!-- account number -->
                <v-text-field
                  :id="'AccountNumber'"
                  v-model="AccountNumber"
                  background-color="#e0e0e0"
                  color="#3cd1c2"
                  outline
                  :counter="50"
                  :maxlength="50"
                  label="Account Number*"
                  prepend-icon="credit_card"
                  :rules="$store.state.onlyLetterNumberRules"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md5>
                <!-- account type -->
                <v-select
                  :id="'AccountType'"
                  v-model="AccountType"
                  background-color="#e0e0e0"
                  outline
                  prepend-icon="folder_shared"
                  :items="AccountTypeList"
                  item-text="Account_Type"
                  item-value="Account_Type_Id"
                  color="#3cd1c2"
                  label="Account Type*"
                  :rules="[(v) => !!v || 'This field is required']"
                  required
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs12 md12>
                <!-- bank details submit  -->
                <v-btn
                  :id="'BankDetailsSubmition'"
                  class="success"
                  style="margin: auto; bottom: 0px"
                  @click="SaveBankDetails"
                  >Save</v-btn
                >
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import axios from "axios";
import {
  GET_BANK_DETAILS,
  LIST_ACCOUNT_TYPE,
  LIST_BANK,
  UPDATE_BANK,
  ADD_BANK,
} from "../graphql.js";

export default {
  data() {
    return {
      // form details
      AccountNumber: "",
      City: "",
      State: "",
      Street: "",
      BankName: "",
      Status: "",
      PinCode: "",
      AccountType: "",
      IFSC: "",
      BranchName: "",
      StatusList: ["Active", "InActive"],
      listAccountType: [],
      banknameList: [],
      //others
      snackbar: false,
      ResponseMessage: "",
      color: "success",
      // validations
      valid: true,
      bankerrors: "",
    };
  },
  computed: {
    // formatting account type
    AccountTypeList() {
      return this.listAccountType.accountType;
    },
  },

  beforeCreate: function () {
    //  for retrieving bank details
    this.$apollo
      .query({
        query: LIST_BANK,
        variables: {
          Org_Code: localStorage.getItem("org_code"),
          Url_Hash: localStorage.getItem("url_hash"),
        },
      })
      .then((res) => {
        var bank_arr = [];
        var bank_name_res = res.data.listBankName.bankName;
        for (var i = 0; i < bank_name_res.length; i++) {
          bank_arr.push(bank_name_res[i].Bank_Name);
        }
        this.banknameList = bank_arr;
      })
      .catch((err) => {
        this.snackbar = true;
        this.ResponseMessage =
          err.graphQLErrors.length !== 0
            ? err.graphQLErrors[0].message
            : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
        this.color = "error";
      });
    this.$apollo
      .query({
        query: GET_BANK_DETAILS,
        variables: {
          Org_Code: localStorage.getItem("org_code"),
          Url_Hash: localStorage.getItem("url_hash"),
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        var bank_res = res.data.getBankDetails;
        if (bank_res) {
          this.$emit("bankProcessChanged", 100);
          this.AccountType = bank_res.Account_Type;
          this.AccountNumber = bank_res.Bank_Account_Number;
          this.BankName = bank_res.Bank_Name.toUpperCase();
          this.BranchName = bank_res.Branch_Name;
          this.City = bank_res.City;
          this.Street = bank_res.Street;
          this.State = bank_res.State;
          this.PinCode = bank_res.Zip;
          this.IFSC = bank_res.IFSC_Code;
          this.Update = true;
        } else {
          this.Update = false;
        }
      })
      .catch((err) => {
        this.snackbar = true;
        this.ResponseMessage =
          err.graphQLErrors.length !== 0
            ? err.graphQLErrors[0].message
            : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
        this.color = "error";
      });
  },
  apollo: {
    // listing account type
    listAccountType: {
      query: LIST_ACCOUNT_TYPE,
      variables() {
        return {
          Org_Code: localStorage.getItem("org_code"),
          Url_Hash: localStorage.getItem("url_hash"),
        };
      },
      loading: true,
    },
  },
  methods: {
    bankNameBlur() {
      this.bankerrors = !this.BankName ? "This field is required" : "";
    },
    //  submit bank details method
    SaveBankDetails() {
      this.bankerrors = !this.BankName === 0 ? "This field is required" : "";
      if (this.$refs.bankform.validate() && !this.bankerrorss) {
        if (this.Update) {
          this.$apollo
            .mutate({
              mutation: UPDATE_BANK,
              variables: {
                Org_Code: localStorage.getItem("org_code"),
                Url_Hash: localStorage.getItem("url_hash"),
                Bank_Account_Number: this.AccountNumber,
                Bank_Name: this.BankName,
                Branch_Name: this.BranchName,
                IFSC_Code: this.IFSC,
                Street: this.Street,
                City: this.City,
                State: this.State,
                Zip: this.PinCode,
                Emp_Bank_Id: this.banknameList.indexOf(this.BankName) + 1,
                Account_Type_Id: this.AccountType,
                BSB_Code: "",
                isAustralian: 0,
              },
            })
            .then(() => {
              this.snackbar = true;
              this.ResponseMessage = "Updated Successfully";
              this.color = "success";
            })
            .catch((err) => {
              this.snackbar = true;
              this.ResponseMessage =
                err.graphQLErrors.length !== 0
                  ? err.graphQLErrors[0].message
                  : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
              this.color = "error";
            });
        } else {
          this.$apollo
            .mutate({
              mutation: ADD_BANK,
              variables: {
                Org_Code: localStorage.getItem("org_code"),
                Url_Hash: localStorage.getItem("url_hash"),
                Bank_Account_Number: this.AccountNumber,
                Bank_Name: this.BankName,
                Branch_Name: this.BranchName,
                IFSC_Code: this.IFSC,
                Street: this.Street,
                City: this.City,
                State: this.State,
                Zip: this.PinCode,
                Emp_Bank_Id: this.banknameList.indexOf(this.BankName) + 1,
                Account_Type_Id: this.AccountType,
                BSB_Code: "",
                isAustralian: 0,
              },
            })
            .then(() => {
              this.$emit("bankProcessChanged", 100);
              this.Update = true;
              this.snackbar = true;
              this.ResponseMessage = "Added Successfully";
              this.color = "success";
            })
            .catch((err) => {
              this.snackbar = true;
              this.ResponseMessage =
                err.graphQLErrors.length !== 0
                  ? err.graphQLErrors[0].message
                  : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
              this.color = "error";
            });
        }
      }
    },
    //  calling api to prefil other details through giving ifsc
    ifscCall() {
      const headers = {
        "DY-X-Authorization": "001cf6dba3b7d1b91cef85e9587058fa704f7af3",
      };
      axios
        .get(
          "https://ifsc.razorpay.com/" + this.IFSC,
          { crossdomain: true },
          {
            headers: headers,
          }
        )
        .then((res) => {
          this.BankName =
            this.banknameList.indexOf(res.data.BANK.toUpperCase()) != -1
              ? res.data.BANK.toUpperCase()
              : "";
          this.City = res.data.CITY;
          this.Street = res.data.ADDRESS.split("-")[0];
          this.State = res.data.STATE;
          this.BranchName = res.data.BRANCH;
          this.PinCode = res.data.ADDRESS.split("-")[1];
        })
        .catch(() => {
          this.snackbar = true;
          this.ResponseMessage = "Invalid IFSC";
          this.color = "error";
        });
    },
  },
};
</script>

<style scoped>
.ps {
  height: 98ex;
}
body {
  margin: 0;
}

svg {
  margin: 0 auto;
  display: block;
}

.CIT-loader {
  padding: 0px;
  background: #fff;
}
.CIT-loader__asset {
  overflow: visible;
}
.CIT-loader__pig {
  fill: #00b3be;
  animation: fat-pig 1.6s ease-in-out infinite;
}
.CIT-loader__coin {
  fill: #ffcb4f;
  animation: coin-drop 1.6s ease-in-out infinite;
  z-index: 1000;
}

@keyframes coin-drop {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  60% {
    opacity: 1;
  }
  70% {
    transform: translateY(250px);
  }
  100% {
    transform: translateY(250px);
  }
}
@keyframes fat-pig {
  0% {
    transform: scale(1, 1) translateX(0) translateY(0);
  }
  60% {
    transform: scale(1, 1) translateX(0) translateY(0);
  }
  70% {
    transform: scale(1.05, 1.05) translateX(-16px) translateY(-16px);
  }
  80% {
    transform: scale(1, 1) translateX(0) translateY(0);
  }
  90% {
    transform: scale(1.05, 1.05) translateX(-16px) translateY(-16px);
  }
  100% {
    transform: scale(1, 1) translateX(0) translateY(0);
  }
}
</style>
