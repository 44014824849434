<template>
  <v-app>
    <v-container fluid fill-height class="my-2">
      <!-- alerts -->
      <v-snackbar v-model="snackbar" :color="color" multi-line :timeout="3000">
        {{ ResponseMessage }}
        <v-btn dark flat @click="snackbar = false">Close</v-btn>
      </v-snackbar>
      <v-layout justify-center>
        <v-flex md11 xs12>
          <!-- for desktop view -->
          <v-card class="mt-4 hidden-sm-and-down">
            <v-layout row wrap>
              <v-flex xs12 sm4 md3 class="heading">
                <!-- profile picture -->
                <v-avatar
                  class="mx-auto d-block mt-3 avatarPad"
                  style="position: relative"
                  size="130"
                >
                  <v-img :src="imgDataUrl" aspect-ratio="2.75"></v-img>
                  <!-- for floating button -->
                  <v-speed-dial
                    v-model="fab"
                    absolute
                    bottom
                    right
                    small
                    direction="bottom"
                    :open-on-hover="true"
                    transition="slide-y-reverse-transition"
                  >
                    <template #activator>
                      <v-btn v-model="fab" color="success darken-1" dark fab>
                        <v-icon>camera_alt</v-icon>
                        <v-icon>close</v-icon>
                      </v-btn>
                    </template>
                    <!-- add profile picture -->
                    <v-btn
                      v-show="showUpload"
                      fab
                      dark
                      small
                      color="success"
                      @click="toggleShow"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                    <!-- edit profile picture -->
                    <v-layout row>
                      <v-flex xs6 md6>
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <v-btn
                              v-show="!showUpload"
                              small
                              fab
                              dark
                              color="success"
                              v-on="on"
                              @click="toggleShow"
                            >
                              <v-icon dark>edit</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit Profile</span>
                        </v-tooltip>
                      </v-flex>
                      <!-- delete profile picture -->
                      <v-flex xs6 md6>
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <v-btn
                              v-show="!showUpload"
                              small
                              fab
                              dark
                              color="red"
                              v-on="on"
                              @click="deleteProfile"
                            >
                              <v-icon dark>delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete Profile</span>
                        </v-tooltip>
                      </v-flex>
                    </v-layout>
                  </v-speed-dial>
                </v-avatar>
                <div>
                  <!-- crop and upload profile picture -->
                  <my-upload
                    v-model="show"
                    field="img"
                    lang-type="en"
                    :no-rotate="false"
                    img-format="png"
                    @crop-success="cropSuccess"
                    @src-file-set="srcFileSet"
                  ></my-upload>
                </div>
                <!-- side navbar cards -->
                <v-card
                  v-for="(heading, index) in headings"
                  :key="index"
                  class="mt-3 mb-1 pointerClass"
                  flat
                >
                  <v-card-text
                    :id="heading.name"
                    dark
                    :class="{
                      active: cardName === heading.name,
                      notactive: cardName !== heading.name,
                    }"
                    @click="proceedNext(heading.name)"
                  >
                    <v-layout row>
                      <v-flex md3 lg2>
                        <v-avatar size="50" class="mr-2 mt-2 avatarClass">
                          <v-img
                            :src="heading.avatar"
                            aspect-ratio="2.75"
                          ></v-img>
                        </v-avatar>
                      </v-flex>
                      <v-flex md6 lg7>
                        <h6 class="text-xs-left mt-2 ml-4 cardHeading">
                          {{ heading.name }}
                        </h6>
                        <h6 class="text-xs-left ml-4 cardsubHeading grey--text">
                          {{ heading.content }}
                        </h6>
                      </v-flex>
                      <v-flex md3 lg3>
                        <v-avatar size="50" class="mt-1 ml-2 avatarClass">
                          <!-- progress bar to show completion -->
                          <v-progress-circular
                            :rotate="360"
                            :size="40"
                            :width="5"
                            :value="progress[index]"
                            color="teal"
                            >{{ progress[index] }}</v-progress-circular
                          >
                        </v-avatar>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 sm8 md9 justify-center>
                <v-form>
                  <perfect-scrollbar>
                    <transition name="fade">
                      <!-- personal information details -->
                      <personal-card
                        v-if="cardName === 'Personal Details'"
                        :progress="progress[0]"
                        @personalProcessChanged="personalProgressMethod($event)"
                        @PhotoPath="ProfileRetrieveMethod($event)"
                      />
                    </transition>
                    <transition name="fade">
                      <!-- experience details -->
                      <exp-card
                        v-if="cardName === 'Experience'"
                        :progress="progress[1]"
                        @expProcessChanged="expProgressMethod($event)"
                      />
                    </transition>
                    <transition name="fade">
                      <!-- certification deatils -->
                      <certi-card
                        v-if="cardName === 'Certification'"
                        :progress="progress[2]"
                        @cerProcessChanged="cerProgreeMethod($event)"
                      />
                    </transition>
                    <transition name="fade">
                      <!-- other document upload details -->
                      <doc-card
                        v-if="cardName === 'Documents'"
                        :progress="progress[3]"
                        @docProcessChanged="docProcessMethod($event)"
                      />
                    </transition>
                    <transition name="fade">
                      <!-- bank details -->
                      <bank-card
                        v-if="cardName === 'Bank Account'"
                        :progress="progress[4]"
                        @bankProcessChanged="bankProgreeMethod($event)"
                      />
                    </transition>
                  </perfect-scrollbar>
                </v-form>
              </v-flex>
            </v-layout>
            <v-layout justify-center style="border-top: 2px solid #f8f8f8">
              <v-flex md8 xs12>
                <v-layout
                  align-center
                  justify-center
                  column
                  style="padding: 20px"
                >
                  <v-flex md12 class="hidden-sm-and-down">
                    <!-- Final submission button -->
                    <v-btn
                      :id="'FinishSubmission'"
                      :disabled="!completeProgress"
                      color="success"
                      @click="SubmitFrom('desk')"
                      >Finish</v-btn
                    >
                  </v-flex>
                  <v-flex md10>
                    <p class="text-xs-center">
                      Please fill the required data(Personal Details: 80%,
                      Experience: 100%, Certification: 30%, Bank Account: 100%)
                      to submit the process
                    </p>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- mobile view alternative design -->
    <div class="hidden-md-and-up">
      <v-layout row wrap justify-center>
        <!-- profile picture -->
        <v-avatar
          class="mx-auto d-block mt-3 avatarPad"
          style="position: relative"
          size="130"
        >
          <v-img :src="imgDataUrl" aspect-ratio="2.75"></v-img>
          <!-- floating button -->
          <v-speed-dial
            v-model="fab"
            absolute
            bottom
            right
            small
            direction="bottom"
            :open-on-hover="false"
            transition="slide-y-reverse-transition"
          >
            <template #activator>
              <v-btn v-model="fab" color="success darken-1" dark fab>
                <v-icon>camera_alt</v-icon>
                <v-icon>close</v-icon>
              </v-btn>
            </template>
            <!-- add profile picture -->
            <div @click="launchFilePicker()">
              <v-btn v-show="showUpload" fab dark small color="success">
                <v-icon>add</v-icon>
              </v-btn>
            </div>
            <v-layout row>
              <!-- edit profile picture -->
              <v-flex xs6 md6>
                <div @click="launchFilePicker()">
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-btn
                        v-show="!showUpload"
                        small
                        fab
                        dark
                        color="success"
                        v-on="on"
                      >
                        <v-icon dark>edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Profile</span>
                  </v-tooltip>
                </div>
                <!-- for file upload hidden field -->
                <input
                  ref="file"
                  type="file"
                  :name="uploadFieldName"
                  style="display: none"
                  @change="
                    onFileChange($event.target.name, $event.target.files)
                  "
                />
              </v-flex>
              <!-- delete profile picture -->
              <v-flex xs6 md6>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn
                      v-show="!showUpload"
                      small
                      fab
                      dark
                      color="red"
                      v-on="on"
                      @click="deleteProfile"
                    >
                      <v-icon dark>delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Profile</span>
                </v-tooltip>
              </v-flex>
            </v-layout>
          </v-speed-dial>
        </v-avatar>
      </v-layout>
      <!-- tabs for mobile view,swipeable -->
      <v-tabs
        v-model="model"
        centered
        dark
        color="success"
        slider-color="yellow"
        show-arrows
        class="sticketTab"
      >
        <!-- tab name -->
        <v-tab
          v-for="index in 5"
          :id="headings[index - 1].name"
          :key="index"
          :href="'#tab-' + index"
          >{{ headings[index - 1].name }}</v-tab
        >
      </v-tabs>
      <!-- tab items for relevant tab -->
      <v-tabs-items v-model="model">
        <!-- personal details -->
        <v-tab-item :value="'tab-1'">
          <v-card class="stickyClass">
            <transition name="fade">
              <personal-card
                :progress="progress[0]"
                @personalProcessChanged="personalProgressMethod($event)"
                @PhotoPath="ProfileRetrieveMethod($event)"
              />
            </transition>
          </v-card>
        </v-tab-item>
        <!-- experience details -->
        <v-tab-item :value="'tab-2'">
          <v-card class="stickyClass">
            <transition name="fade">
              <exp-card
                :progress="progress[1]"
                @expProcessChanged="expProgressMethod($event)"
              />
            </transition>
          </v-card>
        </v-tab-item>
        <!-- certification details -->
        <v-tab-item :value="'tab-3'">
          <v-card class="stickyClass">
            <transition name="fade">
              <certi-card
                :progress="progress[2]"
                @cerProcessChanged="cerProgreeMethod($event)"
              />
            </transition>
          </v-card>
        </v-tab-item>
        <!-- docuemnt details -->
        <v-tab-item :value="'tab-' + 4">
          <v-card class="stickyClass">
            <transition name="fade">
              <doc-card
                :progress="progress[3]"
                @docProcessChanged="docProcessMethod($event)"
              />
            </transition>
          </v-card>
        </v-tab-item>
        <!-- bank details -->
        <v-tab-item :value="'tab-' + 5">
          <v-card class="stickyClass">
            <transition name="fade">
              <bank-card
                :progress="progress[4]"
                @bankProcessChanged="bankProgreeMethod($event)"
              />
            </transition>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <!-- bottom navigation to next or previous steps -->
      <v-bottom-nav :active.sync="bottomNav" color="success" :value="true" dark>
        <!-- pewvious -->
        <v-btn
          :id="'PreviousStep'"
          dark
          :disabled="model === 'tab-1'"
          @click="previoueTab"
        >
          <span>Previos</span>
          <v-icon>keyboard_arrow_left</v-icon>
        </v-btn>
        <!-- final submission button -->
        <v-btn
          :id="'FinalSubmissionMobileView'"
          dark
          @click="SubmitFrom('mobile')"
        >
          <span>Finish</span>
          <v-icon>send</v-icon>
        </v-btn>

        <v-btn
          :id="'NextStep'"
          dark
          :disabled="model === 'tab-5'"
          @click="changeTab"
        >
          <span>Next</span>
          <v-icon>keyboard_arrow_right</v-icon>
        </v-btn>
      </v-bottom-nav>
    </div>
    <!-- Confirmation Modal -->
    <v-dialog v-model="dialog" max-width="600">
      <v-card style="border-radius: 20px; padding: 30px">
        <v-card-text class="text-xs-center">
          <h3 class="font-weight-medium display-2 mt-3" style="padding: 10px">
            This is your last step
          </h3>
          <p class="mt-4 headline">
            By clicking Submit your details will be submitted to
            <span class="font-weight-medium">{{ comName }}</span
            >. Click cancel if you wanted to change any details.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :id="'CancelConfirmation'" @click="dialog = false"
            >Cancel</v-btn
          >
          <v-btn :id="'AcceptConfirmation'" color="success" @click="FinalSubmit"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Succes Modal when details are submitted -->
    <v-dialog v-model="finalDialog" persistent max-width="390">
      <v-card class="text-xs-center" style="border-radius: 20px">
        <v-card-text>
          <h3 class="font-weight-medium display-1 mt-3 mb-3">ThankYou!!</h3>
          <div class="wrapper mb-4">
            <svg class="plane" viewBox="0 0 139 139">
              <defs>
                <linearGradient
                  id="a"
                  x1="147.152%"
                  x2="0%"
                  y1="-47.397%"
                  y2="100%"
                >
                  <stop offset="0%" stop-color="#FCCF31"></stop>
                  <stop offset="100%" stop-color="#F55555"></stop>
                </linearGradient>
              </defs>
              <path
                fill="#FFF"
                stroke="url(#a)"
                stroke-width="2"
                d="M171.1455 37.3577l-131.69 71.251a2.7136 2.7136 0 0 0-1.4535 2.5092 2.7138 2.7138 0 0 0 1.6436 2.3892l22.4823 8.4671a5.2937 5.2937 0 0 1 2.9597 2.7138l17.4183 35.9715c.3259 1.5876 3.2583 2.0083 3.299.3935l-2.7152-29.7026c.157-2.2004.997-4.297 2.403-5.9975L153.795 58.471a1.6162 1.6162 0 0 1 1.4798-.4207c.526.1172.9531.4993 1.1277 1.0088a1.5462 1.5462 0 0 1-.2724 1.488l-58.8803 71.9973a13.1443 13.1443 0 0 0-2.308 5.9297l-3.5298 25.659c.2986 2.239 2.05 3.0938 3.462 1.0855l12.6802-14.926c.8703-1.1836 2.525-1.4602 3.7334-.624l32.6917 23.8136a2.635 2.635 0 0 0 2.5231.3387 2.6328 2.6328 0 0 0 1.6313-1.9534l26.8131-131.6197a2.713 2.713 0 0 0-1.004-2.6831 2.7164 2.7164 0 0 0-2.8516-.2886l.0543.0815z"
                transform="translate(-37 -36)"
              ></path>
            </svg>
          </div>
          <p class="mt-4">Your Details has been Submitted Successfully</p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import myUpload from "vue-image-crop-upload";
import Personalcard from "./PesonalDetails.vue";
import ExpCard from "./Experience.vue";
import CertiCard from "./Certification.vue";
import DocCard from "./Documents.vue";
import BankCard from "./BankAccount.vue";
import {
  UPLOAD_DOCUMENT,
  RETRIEVE_DOCUMENT,
  UPDATA_PHOTO_PATH,
  EDIT_DOCUMENT,
  DELETE_DOCUMENT,
  SUBMIT_DETAILS,
  URL_EXPIRATION,
} from "../graphql.js";
import DefaultAvatar from "../assets/defaultavatar.png";
import Man from "../assets/man.png";
import ExpImg from "../assets/technical-support.png";
import CertiImg from "../assets/certificate.png";
import DocImg from "../assets/test.png";
import BankImg from "../assets/bank.png";

export default {
  components: {
    "my-upload": myUpload,
    "personal-card": Personalcard,
    "exp-card": ExpCard,
    "certi-card": CertiCard,
    "doc-card": DocCard,
    "bank-card": BankCard,
  },
  data() {
    return {
      i: 0, // set i to track the tab index
      model: "tab-1",
      bottomNav: 3,
      cardName: "Personal Details",
      headings: [
        {
          avatar: Man,
          name: "Personal Details",
          content: "Let us know about more you",
        },
        {
          avatar: ExpImg,
          name: "Experience",
          content: "Let us know about your experience ",
        },
        {
          avatar: CertiImg,
          name: "Certification",
          content: "Let us know about your academics",
        },
        {
          avatar: DocImg,
          name: "Documents",
          content: "Upload your idntity documents",
        },
        {
          avatar: BankImg,
          name: "Bank Account",
          content: "This is ware your salary will be credited",
        },
      ],
      fab: false,
      progress: [0, 0, 0, 0, 0],
      certificationprocess: 0,
      personalprogress: 0,
      show: false,
      imgDataUrl: DefaultAvatar,
      fileName: "",
      File_Name: "",
      showUpload: true,
      certiProVal: 0,
      perProVal: "section",
      dialog: false,
      finalDialog: false,
      uploadFieldName: "file",
      maxSize: 1024,
      // alerts
      snackbar: false,
      ResponseMessage: "",
      color: "success",
      comName: localStorage.getItem("companyName"),
      personal_details_type: [],
    };
  },
  computed: {
    completeProgress() {
      if (
        this.progress[0] >= 80 &&
        this.progress[1] === 100 &&
        this.progress[2] >= 30 &&
        this.progress[4] === 100
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  beforeCreate() {
    this.$store.commit("Drawer", false);
    if (!localStorage.getItem("url_hash")) {
      window.location.href = "/";
    }
  },
  created() {
    this.$apollo
      .query({
        query: URL_EXPIRATION,
        variables: {
          Org_Code: localStorage.getItem("org_code"),
          Url_Hash: localStorage.getItem("url_hash"),
        },
      })
      .then(() => {})
      .catch((err) => {
        if (
          err.graphQLErrors.length !== 0 &&
          err.graphQLErrors[0].extensions.code
        ) {
          this.Signout();
        }
      });
  },
  methods: {
    uploadImage() {
      this.saving = true;
      setTimeout(() => this.savedAvatar(), 1000);
    },
    savedAvatar() {
      this.saving = false;
      this.saved = true;
    },
    launchFilePicker() {
      this.$refs.file.click();
    },
    onFileChange(fieldName, file) {
      const { maxSize } = this;
      let imageFile = file[0];
      //check if user actually selected a file
      if (file.length > 0) {
        let size = imageFile.size / maxSize / maxSize;

        if (!imageFile.type.match("image.*")) {
          // check whether the upload is an image
          this.snackbar = true;
          this.ResponseMessage = "Please choose an image file";
          this.color = "error";
        } else if (size > 1) {
          // check whether the size is greater than the size limit
          this.snackbar = true;
          this.ResponseMessage =
            "Your file is too big! Please select an image under 1MB";
          this.color = "error";
        } else {
          const fr = new FileReader();
          fr.readAsDataURL(file[0]);
          fr.addEventListener("load", () => {
            var imageURL = fr.result;
            this.fileName = imageFile.name;
            this.cropSuccess(imageURL);
          });
        }
      }
    },
    previoueTab() {
      this.i = this.i - 1;
      this.model = `tab-${this.i + 1}`;
    },
    changeTab() {
      this.i = (this.i + 1) % 5;
      this.model = `tab-${this.i + 1}`;
    },
    ProfileRetrieveMethod(val) {
      if (!val) {
        this.imgDataUrl = DefaultAvatar;
      } else {
        this.showUpload = false;
        this.File_Name = val;
        this.$apollo
          .query({
            query: RETRIEVE_DOCUMENT,
            variables: {
              File_Name: val,
              Org_Code: localStorage.getItem("org_code"),
              Url_Hash: localStorage.getItem("url_hash"),
              Category: "profileImage",
            },
          })
          .then((res) => {
            if (res.data.fileRetrieve) {
              this.imgDataUrl =
                "data:image/png;base64," + res.data.fileRetrieve.fileContent;
            }
          })
          .catch((err) => {
            this.snackbar = true;
            this.ResponseMessage =
              err.graphQLErrors.length !== 0
                ? err.graphQLErrors[0].message
                : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
            this.color = "error";
          });
      }
    },
    expProgressMethod(val) {
      this.$set(this.progress, 1, val);
    },
    bankProgreeMethod(val) {
      this.$set(this.progress, 4, val);
    },
    docProcessMethod(val) {
      this.$set(this.progress, 3, val);
    },
    personalProgressMethod(val) {
      this.personal_details_type += val;
      if (
        this.personal_details_type.indexOf("dependent") === -1 ||
        val === "sec5del"
      ) {
        if (val === "section1") {
          this.$set(this.progress, 0, 20);
        } else if (val === "section2") {
          this.$set(this.progress, 0, 40);
        } else if (val === "section3") {
          this.$set(this.progress, 0, 60);
        } else if (val === "section4") {
          this.$set(this.progress, 0, 80);
        } else {
          this.$set(this.progress, 0, 80);
        }
      } else {
        this.$set(this.progress, 0, 100);
      }
    },
    cerProgreeMethod(val) {
      if (val !== this.certiProVal) {
        this.certiProVal = val;
        this.certificationprocess += val;
        this.$set(this.progress, 2, this.certificationprocess);
      }
    },
    proceedNext(name) {
      this.cardName = name;
    },
    toggleShow() {
      this.show = !this.show;
    },
    srcFileSet(fileName) {
      this.fileName = fileName;
    },
    SubmitFrom(type) {
      if (type === "mobile") {
        if (this.completeProgress) {
          this.dialog = true;
        } else {
          this.snackbar = true;
          this.ResponseMessage =
            "Please fill Your required data to submit the form.";
          this.color = "error";
        }
      } else {
        this.dialog = true;
      }
    },
    FinalSubmit() {
      this.$apollo
        .mutate({
          mutation: SUBMIT_DETAILS,
          variables: {
            Org_Code: localStorage.getItem("org_code"),
            Url_Hash: localStorage.getItem("url_hash"),
            Status: "Finished",
          },
        })
        .then(() => {
          this.dialog = false;
          this.finalDialog = true;
        })
        .catch((err) => {
          this.snackbar = true;
          this.ResponseMessage =
            err.graphQLErrors.length !== 0
              ? err.graphQLErrors[0].message
              : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
          this.color = "error";
        });
    },
    Signout() {
      window.location.href = "/" + localStorage.getItem("url_param");
      localStorage.clear();
    },
    cropSuccess(imgDataUrl) {
      if (this.showUpload) {
        this.$apollo
          .query({
            query: UPLOAD_DOCUMENT,
            variables: {
              File_Name: this.fileName,
              Category: "profileImage",
              File_Content: imgDataUrl.split(",")[1],
              Org_Code: localStorage.getItem("org_code"),
              Url_Hash: localStorage.getItem("url_hash"),
            },
          })
          .then((res) => {
            this.File_Name = res.data.fileUpload.fileName;
            this.imgDataUrl = imgDataUrl;
            this.snackbar = true;
            this.ResponseMessage = "Updated Successfully";
            this.color = "success";
            this.showUpload = false;
            this.$apollo
              .mutate({
                mutation: UPDATA_PHOTO_PATH,
                variables: {
                  Photo_Path: res.data.fileUpload.fileName,
                  Org_Code: localStorage.getItem("org_code"),
                  Url_Hash: localStorage.getItem("url_hash"),
                },
              })
              .then(() => {});
          })
          .catch((err) => {
            this.snackbar = true;
            this.ResponseMessage =
              err.graphQLErrors.length !== 0
                ? err.graphQLErrors[0].message
                : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
            this.color = "error";
          });
      } else {
        this.$apollo
          .query({
            query: EDIT_DOCUMENT,
            variables: {
              New_File_Name: this.fileName,
              Category: "profileImage",
              New_File_Content: imgDataUrl.split(",")[1],
              Org_Code: localStorage.getItem("org_code"),
              Url_Hash: localStorage.getItem("url_hash"),
              File_Name: this.File_Name,
            },
          })
          .then((res) => {
            this.File_Name = res.data.fileReplace.fileName;
            this.imgDataUrl = imgDataUrl;
            this.snackbar = true;
            this.ResponseMessage = "Updated Successfully";
            this.color = "success";
            this.$apollo
              .mutate({
                mutation: UPDATA_PHOTO_PATH,
                variables: {
                  Photo_Path: res.data.fileReplace.fileName,
                  Org_Code: localStorage.getItem("org_code"),
                  Url_Hash: localStorage.getItem("url_hash"),
                },
              })
              .then(() => {});
          })
          .catch((err) => {
            this.snackbar = true;
            this.ResponseMessage =
              err.graphQLErrors.length !== 0
                ? err.graphQLErrors[0].message
                : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
            this.color = "error";
          });
      }
    },
    deleteProfile() {
      var content = this.imgDataUrl;
      this.$apollo
        .query({
          query: DELETE_DOCUMENT,
          variables: {
            Org_Code: localStorage.getItem("org_code"),
            Url_Hash: localStorage.getItem("url_hash"),
            File_Name: this.File_Name,
            Category: "profileImage",
          },
        })
        .then(() => {
          this.imgDataUrl = DefaultAvatar;
          this.showUpload = true;
          this.$apollo
            .mutate({
              mutation: UPDATA_PHOTO_PATH,
              variables: {
                Photo_Path: "",
                Org_Code: localStorage.getItem("org_code"),
                Url_Hash: localStorage.getItem("url_hash"),
              },
            })
            .then(() => {
              this.snackbar = true;
              this.ResponseMessage = "Deleted Successfully";
              this.color = "success";
              window.location.reload();
            })
            .catch((err) => {
              this.imgDataUrl = content;
              this.showUpload = false;
              this.snackbar = true;
              this.ResponseMessage =
                err.graphQLErrors.length !== 0
                  ? err.graphQLErrors[0].message
                  : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
              this.color = "error";
            });
        })
        .catch((err) => {
          this.snackbar = true;
          this.ResponseMessage =
            err.graphQLErrors.length !== 0
              ? err.graphQLErrors[0].message
              : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
          this.color = "error";
        });
    },
  },
};
</script>

<style scoped>
.v-content {
  padding-left: 17% !important;
}
.heading {
  border-right: 3px solid #f6f4f4 !important;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.cardHeading {
  font-size: 0.9em;
}
.cardsubHeading {
  font-size: 0.6em;
}
.p {
  margin-bottom: 0px !important;
}
.pointerClass {
  cursor: pointer;
}
.v-speed-dial--bottom.v-speed-dial--absolute {
  bottom: 10% !important;
}
.v-speed-dial--right {
  right: 0px !important;
}
.active {
  border-bottom: 3px solid #3cd1c2;
  color: black;
  box-shadow: 0px 3px 3px darkgray;
}
.notactive {
  color: darkgray;
}
.ps {
  height: 93ex;
}
.avatarClass {
  width: 6ex !important;
  height: 6ex !important;
}
.v-card__text {
  padding: 10px !important;
  width: 100%;
}
.success.darken-1 {
  background-color: #575c5bc2 !important;
  border-color: #f8f8f8 !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.caption {
  font-size: 0.8em !important;
  font-weight: 300;
}

.v-chip.complete {
  background: #3cd1c2;
}
.v-chip.ongoing {
  background: #ffaa2c;
}
.v-chip.overdue {
  background: #f83e70;
}
.avatarPad {
  margin-bottom: 5ex;
}
.v-speed-dial--absolute {
  z-index: 1 !important;
}
.stickyClass {
  height: 450px;
  overflow-y: auto;
}
.sticketTab {
  position: -webkit-sticky;
  position: sticky;
}
/* rocket */
.wrapper {
  display: flex;
  width: 100%;
  height: 100%;

  justify-content: center;
  align-items: center;
}

.screen {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  width: 375px;
  height: 667px;

  border: 4px solid #7a7a7a;
  border-radius: 32px;
  background-color: #f5f5f5;
  box-shadow: 2px 8px 32px rgba(0, 0, 0, 0.5);
}

.clouds {
  position: relative;
  bottom: -155px;
  width: 243px;
  height: 172px;
  opacity: 0;
  animation: clouds 1s 0.8s ease-in forwards;
}

.plane {
  position: relative;
  width: 139px;
  height: 139px;
  opacity: 0;
  animation: plane-start 0.8s 0.8s ease-out,
    plane-loop 1.6s 1.6s linear infinite;
}

.message {
  margin-top: 72px;
  font-family: Rubik;
  font-size: 16px;
  color: #292929;
  letter-spacing: 1px;
  animation: message 0.3s 1.6s ease-in-out backwards;
}

.button {
  margin: 80px;
  padding: 16px 80px;

  background: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  border-radius: 24px;
  font-family: Rubik-Medium;
  font-size: 16px;
  color: #7a7a7a;
  letter-spacing: -0.05px;
  text-align: center;
}

@keyframes plane-start {
  0% {
    transform: translate(-80px, 130px);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0px, 0);
    opacity: 1;
  }
}

@keyframes plane-loop {
  0% {
    transform: translate(0px, 0);
    opacity: 1;
  }
  50% {
    transform: translate(-20px, 30px);
    opacity: 1;
  }
  100% {
    transform: translate(0px, 0);
    opacity: 1;
  }
}

@keyframes clouds {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes message {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
