<template>
  <v-container>
    <!-- alerts -->
    <v-snackbar v-model="snackbar" :color="color" multi-line :timeout="3000">
      {{ ResponseMessage }}
      <v-btn dark flat @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <!-- training -->
    <v-form
      v-for="(addexp, index) in addTraining"
      :key="index + 'form3'"
      :ref="'trainingform' + index"
    >
      <v-container grid-list-md text-xs-center>
        <v-layout row wrap>
          <v-flex xs12 md10>
            <v-card>
              <v-card-text>
                <v-layout row wrap>
                  <v-flex md12>
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          v-show="!showTrain[index]"
                          :id="'DeleteTrainingDetails'"
                          small
                          fab
                          dark
                          color="red"
                          class="right"
                          v-on="on"
                          @click.native.prevent="
                            deleteTraining(index, addTraining, showTrain)
                          "
                        >
                          <v-icon dark>delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete Training Details</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          v-show="!showTrain[index]"
                          :id="'EditTrainingDetails'"
                          small
                          fab
                          dark
                          color="warning"
                          class="right"
                          v-on="on"
                          @click="editTraining(index)"
                        >
                          <v-icon dark>edit</v-icon>
                        </v-btn>
                      </template>
                      <span>Change Training Details</span>
                    </v-tooltip>
                  </v-flex>
                  <!-- line 1 -->
                  <!-- line2 -->
                  <v-flex xs12 md6>
                    <!-- training name -->
                    <v-text-field
                      :id="'TrainingName'"
                      v-model="TrainingName[index]"
                      background-color="#e0e0e0"
                      color="#3cd1c2"
                      outline
                      :counter="30"
                      :maxlength="30"
                      :disabled="!showTrain[index]"
                      label="Training Name*"
                      prepend-icon="phonelink"
                      :rules="$store.state.alphaNumSpHyRules"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <!-- start date -->
                    <v-dialog
                      v-model="modal_train"
                      persistent
                      width="290"
                      lazy
                      full-width
                    >
                      <template #activator="{ on }">
                        <v-text-field
                          :id="'TrainingStratDate'"
                          v-model="StartDate[index]"
                          :disabled="!showTrain[index]"
                          background-color="#e0e0e0"
                          color="#3cd1c2"
                          outline
                          readonly
                          label="Start Date*"
                          prepend-icon="date_range"
                          :rules="[(v) => !!v || 'This field is required']"
                          required
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="StartDate[index]"
                        :min="minJoinDate"
                        :max="maxYear"
                        color="#3cd1c2"
                        scrollable
                        actions
                        @change="emptyEnd(index)"
                      >
                        <v-btn
                          flat
                          color="primary"
                          class="right"
                          @click="modal_train = false"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                  <!-- line 2 -->
                  <v-flex xs12 md6>
                    <!-- end date -->
                    <v-dialog
                      v-model="modal_train1"
                      persistent
                      width="290"
                      lazy
                      full-width
                    >
                      <template #activator="{ on }">
                        <v-text-field
                          :id="'TrainingEndDate'"
                          v-model="EndDate[index]"
                          :disabled="!showTrain[index]"
                          readonly
                          background-color="#e0e0e0"
                          color="#3cd1c2"
                          outline
                          label="End Date*"
                          prepend-icon="date_range"
                          :value="EndDate[index]"
                          :rules="[(v) => !!v || 'This field is required']"
                          required
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="EndDate[index]"
                        :min="StartDate[index]"
                        :max="maxYear"
                        color="#3cd1c2"
                        scrollable
                        actions
                      >
                        <v-btn
                          flat
                          color="primary"
                          class="right"
                          @click="modal_train1 = false"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                  <v-flex xs12 md6>
                    <!-- center -->
                    <v-text-field
                      :id="'TrainingCenter'"
                      v-model="Center[index]"
                      :maxlength="30"
                      background-color="#e0e0e0"
                      color="#3cd1c2"
                      outline
                      :counter="30"
                      :disabled="!showTrain[index]"
                      label="Training Center*"
                      prepend-icon="location_on"
                      :rules="$store.state.alphaNumSpHyRules"
                      required
                    ></v-text-field>
                  </v-flex>
                  <!-- line 3 -->
                  <v-flex xs12 md6>
                    <!-- trainer -->
                    <v-text-field
                      :id="'TrainerName'"
                      v-model="Trainer[index]"
                      background-color="#e0e0e0"
                      color="#3cd1c2"
                      outline
                      :counter="30"
                      :maxlength="30"
                      :disabled="!showTrain[index]"
                      label="Trainer*"
                      prepend-icon="person"
                      :rules="$store.state.alphaNumSpHyRules"
                      required
                    ></v-text-field>
                  </v-flex>
                  <!-- file upload -->
                  <v-flex xs12 md6>
                    <v-list v-show="fileNameTrain[index]">
                      <v-list-tile>
                        <v-list-tile-action>
                          <v-icon :disabled="!showTrain[index]"
                            >cloud_upload</v-icon
                          >
                        </v-list-tile-action>
                        <v-list-tile-content>
                          <v-list-tile-title class="grey--text">{{
                            fileNameTrain[index]
                          }}</v-list-tile-title>
                        </v-list-tile-content>
                        <v-list-tile-action>
                          <v-tooltip bottom>
                            <template #activator="{ on }">
                              <v-btn
                                :id="'EditDocumentTraining'"
                                fab
                                small
                                :disabled="!showTrain[index]"
                                class="ml-4"
                                color="warning"
                                v-on="on"
                                @click="pickFile(index, 'train')"
                              >
                                <v-icon
                                  class="right"
                                  :disabled="!showTrain[index]"
                                  >edit</v-icon
                                >
                              </v-btn>
                              <input
                                ref="image"
                                type="file"
                                style="display: none"
                                accept=".png,.jpg,.jpeg,.pdf"
                                @change="onFilePicked"
                              />
                            </template>
                            <span>Change Training Details</span>
                          </v-tooltip>
                        </v-list-tile-action>
                        <v-btn
                          :id="'DeleteDocumentsTraining'"
                          fab
                          small
                          :disabled="!showTrain[index]"
                          color="red"
                        >
                          <v-icon
                            class="right white--text"
                            dark
                            :disabled="!showTrain[index]"
                            @click="deleteFile(index, 'train')"
                            >delete</v-icon
                          >
                        </v-btn>
                      </v-list-tile>
                    </v-list>
                    <v-list v-show="!fileNameTrain[index]">
                      <v-list-tile>
                        <v-list-tile-action>
                          <v-icon :disabled="!showTrain[index]"
                            >cloud_upload</v-icon
                          >
                        </v-list-tile-action>
                        <v-list-tile-content>
                          <v-list-tile-title class="grey--text"
                            >No File Chosen</v-list-tile-title
                          >
                        </v-list-tile-content>
                        <v-list-tile-action>
                          <v-btn
                            :id="'AddDocumentsTraining'"
                            fab
                            small
                            :disabled="!showTrain[index]"
                            class="success ml-4"
                            @click="pickFile(index, 'train')"
                          >
                            <v-icon class="right" :disabled="!showTrain[index]"
                              >add</v-icon
                            >
                          </v-btn>
                        </v-list-tile-action>
                      </v-list-tile>
                    </v-list>
                  </v-flex>
                  <!-- file upload end -->
                  <v-flex xs12 md6>
                    <v-select
                      v-show="fileNameTrain[index]"
                      :id="'Training_Sub_Document_Type'"
                      v-model="TrainingSubType[index]"
                      background-color="#e0e0e0"
                      outline
                      :disabled="!showTrain[index]"
                      prepend-icon="list_alt"
                      :items="TrainingSubTypeList"
                      item-text="Document_Sub_Type"
                      item-value="Document_Sub_Type_Id"
                      color="#3cd1c2"
                      label="Training Sub Type*"
                      :rules="
                        fileNameTrain[index]
                          ? [(v) => !!v || 'This field is required']
                          : [true]
                      "
                      :required="fileNameTrain[index] ? true : false"
                      @change="SelectTrainingSubType(index)"
                    ></v-select>
                  </v-flex>
                  <!-- document name-->
                  <v-flex xs12 md6>
                    <v-text-field
                      v-show="fileNameTrain[index]"
                      :id="'Train_document_name'"
                      v-model="TrainDocName[index]"
                      background-color="#e0e0e0"
                      color="#3cd1c2"
                      outline
                      :counter="50"
                      :maxlength="50"
                      :disabled="!showTrain[index]"
                      label="Document Name"
                      prepend-icon="folder_shared"
                      :rules="
                        fileNameTrain[index]
                          ? $store.state.docNameRules
                          : [true]
                      "
                      :required="fileNameTrain[index] ? true : false"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs12 md12>
                    <!-- submit training details -->
                    <v-btn
                      v-show="showTrain[index]"
                      :id="'SaveTraining'"
                      class="success"
                      style="margin: auto"
                      :disabled="isUploadingDocument"
                      @click="saveTraining(index)"
                      >Save</v-btn
                    >
                    <!-- cancel training -->
                    <v-btn
                      v-show="showTrain[index]"
                      :id="'CancelTraining'"
                      class="ml-2"
                      style="margin: auto"
                      @click="
                        cancelTraining(showTrain, index, addTraining, showTrain)
                      "
                      >Cancel</v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 md2>
            <!-- add more taining details  -->
            <v-btn
              v-show="
                addTraining.length === index + 1 || addTraining.length === 0
              "
              :id="'AddMoreTraining'"
              fab
              small
              class="success"
              dark
              @click="AddTraining(index, addTraining, showTrain)"
            >
              <v-icon class="taxt-xs-center">add</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import {
  GET_TRAINING,
  UPDATE_TRAINING,
  ADD_TRAINING,
  DELETE_TRAINING,
  UPLOAD_DOCUMENT,
  EDIT_DOCUMENT,
  DELETE_DOCUMENT,
  LIST_SUB_DOC_TYPE,
} from "../graphql.js";

export default {
  data() {
    return {
      //Training Details
      TrainingName: [],
      TrainingSubType: [],
      TrainingSubTypeList: [],
      StartDate: [],
      EndDate: [],
      Trainer: [],
      Center: [],
      TrainingId: [],
      TrainDocName: [],
      // copy for backup
      TrainNameCopy: [],
      StratCopy: [],
      EndCopy: [],
      CenterCopy: [],
      TrainerCopy: [],
      fileNameCopy: [],
      SubDocCopy: [],
      DocNameCopy: [],
      // File Details
      FileFormatTraning: [],
      FileType: "",
      CurrentIndex: "",
      fileNameTrain: [],
      isUploadingDocument: false,
      // form count
      addTraining: [0],
      // display add edit
      showTrain: [true],
      // alert properties
      snackbar: false,
      ResponseMessage: "",
      color: "success",
      // enable edit and avoid add
      enableTrainEdit: [false],
      avoidAddTrain: false,
      // minimum date
      minJoinDate: localStorage.getItem("DOB"),
      maxYear: "",
      // list of courses
      modal_train: false,
      modal_train1: false,
    };
  },

  created: function () {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    this.maxYear = yyyy + "-" + mm + "-" + dd;

    // retrieve training sub types
    this.$apollo
      .query({
        query: LIST_SUB_DOC_TYPE,
        variables: {
          Org_Code: localStorage.getItem("org_code"),
          Url_Hash: localStorage.getItem("url_hash"),
          type_id: 6,
        },
      })
      .then((res) => {
        if (res.data.listDocumentSubType.documentSubType) {
          this.TrainingSubTypeList =
            res.data.listDocumentSubType.documentSubType;
        }
      })
      .catch((err) => {
        this.snackbar = true;
        this.ResponseMessage =
          err.graphQLErrors.length !== 0
            ? err.graphQLErrors[0].message
            : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
        this.color = "error";
      });

    // retrieve training details
    this.$apollo
      .query({
        query: GET_TRAINING,
        variables: {
          Org_Code: localStorage.getItem("org_code"),
          Url_Hash: localStorage.getItem("url_hash"),
        },
        fetchPolicy: "no-cache",
      })
      .then((res2) => {
        this.addTraining = [];
        var train = res2.data.getTraining.training;
        if (!train) {
          this.addTraining = [0];
        } else {
          this.$emit("trainingProgress", 33);
          for (var i = 0; i < train.length; i++) {
            this.avoidAddTrain = true;
            this.addTraining.push(i);
            this.$set(this.showTrain, i, false);
            this.$set(this.TrainingName, i, train[i].Training_Name);
            this.$set(this.StartDate, i, train[i].Training_Start_Date);
            this.$set(this.EndDate, i, train[i].Training_End_Date);
            this.$set(this.Center, i, train[i].Center);
            this.$set(this.Trainer, i, train[i].Trainer);
            this.$set(this.TrainingId, i, train[i].Training_Id);
            this.$set(
              this.fileNameTrain,
              i,
              !train[i].File_Name ? "" : train[i].File_Name.split("?")[3]
            );
            this.$set(this.FileFormatTraning, i, train[i].File_Name);
            this.$set(this.TrainingSubType, i, train[i].Sub_Type_Id);
            this.$set(this.TrainDocName, i, train[i].Document_Name);
          }
        }
      })
      .catch((err) => {
        this.snackbar = true;
        this.ResponseMessage =
          err.graphQLErrors.length !== 0
            ? err.graphQLErrors[0].message
            : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
        this.color = "error";
      });
  },

  methods: {
    emptyEnd(index) {
      this.$set(this.EndDate, index, "");
    },
    // enable taining details to edit
    editTraining(index) {
      this.avoidAddTrain = false;
      this.$set(this.enableTrainEdit, index, true);
      this.$set(this.showTrain, index, true);
      this.$set(this.TrainNameCopy, index, this.TrainingName[index]);
      this.$set(this.StratCopy, index, this.StartDate[index]);
      this.$set(this.EndCopy, index, this.EndDate[index]);
      this.$set(this.CenterCopy, index, this.Center[index]);
      this.$set(this.TrainerCopy, index, this.Trainer[index]);
      this.$set(this.fileNameCopy, index, this.fileNameTrain[index]);
      this.$set(this.SubDocCopy, index, this.TrainingSubType[index]);
      this.$set(this.DocNameCopy, index, this.TrainDocName[index]);
    },
    // cancel training details
    cancelTraining(val, index, remove_el, remove_val) {
      if (this.enableTrainEdit[index]) {
        this.avoidAddTrain = true;
        this.$set(val, index, false);
        this.$set(this.TrainingName, index, this.TrainNameCopy[index]);
        this.$set(this.StartDate, index, this.StratCopy[index]);
        this.$set(this.EndDate, index, this.EndCopy[index]);
        this.$set(this.Center, index, this.CenterCopy[index]);
        this.$set(this.Trainer, index, this.TrainerCopy[index]);
        this.$set(this.fileNameTrain, index, this.fileNameCopy[index]);
        this.$set(this.TrainingSubType, index, this.SubDocCopy[index]);
        this.$set(this.TrainDocName, index, this.DocNameCopy[index]);
      } else {
        if (this.addTraining.length !== 1) {
          this.avoidAddTrain = true;
          remove_el.splice(index, 1);
          remove_val.splice(index, 1);
          this.$set(this.TrainingName, index, this.TrainingName[index + 1]);
          this.$set(this.StartDate, index, this.StartDate[index + 1]);
          this.$set(this.EndDate, index, this.EndDate[index + 1]);
          this.$set(this.Trainer, index, this.Trainer[index + 1]);
          this.$set(this.Center, index, this.Center[index + 1]);
          this.$set(this.fileNameTrain, index, this.fileNameTrain[index + 1]);
        } else {
          this.$emit("trainingProgress", -33);
          this.$set(this.TrainingName, index, "");
          this.$set(this.StartDate, index, "");
          this.$set(this.EndDate, index, "");
          this.$set(this.Trainer, index, "");
          this.$set(this.Center, index, "");
          this.$set(this.showTrain, index, true);
          this.$set(this.fileNameTrain, index, "");
          this.$refs["trainingform" + index][0].reset();
          remove_val[index] = true;
        }
      }
    },
    // add more tanining details
    AddTraining(index, remove_el, remove_val) {
      var index_val = remove_val.length;

      if (
        this.$refs["trainingform" + index][0].validate() &&
        this.avoidAddTrain
      ) {
        this.avoidAddTrain = false;
        this.$set(this.showTrain, index_val, true);
        this.$set(this.TrainingName, index_val, "");
        this.$set(this.StartDate, index_val, "");
        this.$set(this.EndDate, index_val, "");
        this.$set(this.Trainer, index_val, "");
        this.$set(this.Center, index_val, "");
        remove_el.push(index_val);
        remove_val[index_val] = true;
      } else {
        this.snackbar = true;
        this.ResponseMessage = "First save your details";
        this.color = "error";
      }
    },
    // submit tarining details
    saveTraining(index) {
      if (this.$refs["trainingform" + index][0].validate()) {
        if (!this.enableTrainEdit[index]) {
          this.$apollo
            .mutate({
              mutation: ADD_TRAINING,
              variables: {
                Org_Code: localStorage.getItem("org_code"),
                Url_Hash: localStorage.getItem("url_hash"),
                Training_Name: this.TrainingName[index],
                Training_Start_Date: this.StartDate[index],
                Training_End_Date: this.EndDate[index],
                Trainer: this.Trainer[index],
                Center: this.Center[index],
                File_Name: this.FileFormatTraning[index],
                Sub_Type_Id: this.TrainingSubType[index],
                Document_Name: this.TrainDocName[index],
              },
            })
            .then((res) => {
              if (this.addTraining.length === 1) {
                this.$emit("trainingProgress", 33);
              }
              this.TrainingId = [];
              for (var i = 0; i < res.data.addTraining.training.length; i++) {
                this.$set(
                  this.TrainingId,
                  i,
                  res.data.addTraining.training[i].Training_Id
                );
              }
              this.avoidAddTrain = true;
              this.$set(this.showTrain, index, false);
              this.snackbar = true;
              this.ResponseMessage = "Added Successfully";
              this.color = "success";
            })
            .catch((err) => {
              this.snackbar = true;
              this.ResponseMessage =
                err.graphQLErrors.length !== 0
                  ? err.graphQLErrors[0].message
                  : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
              this.color = "error";
            });
        } else {
          this.$apollo
            .mutate({
              mutation: UPDATE_TRAINING,
              variables: {
                Org_Code: localStorage.getItem("org_code"),
                Url_Hash: localStorage.getItem("url_hash"),
                Training_Id: this.TrainingId[index],
                Training_Name: this.TrainingName[index],
                Training_Start_Date: this.StartDate[index],
                Training_End_Date: this.EndDate[index],
                Trainer: this.Trainer[index],
                Center: this.Center[index],
                File_Name: this.FileFormatTraning[index],
                Sub_Type_Id: this.TrainingSubType[index],
                Document_Name: this.TrainDocName[index],
              },
            })
            .then(() => {
              this.snackbar = true;
              this.$set(this.enableTrainEdit, index, false);
              this.ResponseMessage = "Updated Successfully";
              this.color = "success";
              this.avoidAddTrain = true;
              this.$set(this.showTrain, index, false);
            })
            .catch((err) => {
              this.snackbar = true;
              this.ResponseMessage =
                err.graphQLErrors.length !== 0
                  ? err.graphQLErrors[0].message
                  : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
              this.color = "error";
            });
        }
      }
    },
    // delete training details
    deleteTraining(index, remove_el, remove_val) {
      this.$apollo
        .mutate({
          mutation: DELETE_TRAINING,
          variables: {
            Org_Code: localStorage.getItem("org_code"),
            Url_Hash: localStorage.getItem("url_hash"),
            Training_Id: this.TrainingId[index],
          },
        })
        .then(() => {
          this.snackbar = true;
          this.ResponseMessage = "Deleted Successfully";
          this.color = "success";
          this.TrainingId.splice(index, 1);
          if (this.addTraining.length !== 1) {
            remove_el.splice(index, 1);
            remove_val.splice(index, 1);
            this.$set(this.TrainingName, index, this.TrainingName[index + 1]);
            this.$set(this.StartDate, index, this.StartDate[index + 1]);
            this.$set(this.EndDate, index, this.EndDate[index + 1]);
            this.$set(this.Trainer, index, this.Trainer[index + 1]);
            this.$set(this.Center, index, this.Center[index + 1]);
            this.$set(this.fileNameTrain, index, this.fileNameTrain[index + 1]);
            this.$set(this.TrainDocName, index, this.fileNameTrain[index + 1]);
            this.$set(
              this.TrainingSubType,
              index,
              this.TrainingSubType[index + 1]
            );
          } else {
            this.$emit("trainingProgress", -33);
            this.$set(this.TrainingName, index, "");
            this.$set(this.StartDate, index, "");
            this.$set(this.EndDate, index, "");
            this.$set(this.Trainer, index, "");
            this.$set(this.Center, index, "");
            this.$set(this.showTrain, index, true);
            this.$set(this.fileNameTrain, index, "");
            this.$set(this.TrainDocName, index, "");
            this.$set(this.TrainingSubType, index, "");
            this.$refs["trainingform" + index][0].reset();
            remove_val[index] = true;
          }
        })
        .catch((err) => {
          this.snackbar = true;
          this.ResponseMessage =
            err.graphQLErrors.length !== 0
              ? err.graphQLErrors[0].message
              : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
          this.color = "error";
        });
    },
    //  enable file picker
    pickFile(index, type) {
      this.CurrentIndex = index;
      this.FileType = type;
      this.$refs.image[index].click();
    },
    // to fetch file details
    onFilePicked(e) {
      const files = e.target.files;
      var fileContent;
      // reader.readAsDataURL(files);
      if (files[0] !== undefined) {
        // this.fileName[this.CurrentIndex] = files[0].name;
        if (files[0].name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          fileContent = fr.result.split(",");
          if (this.FileType === "train") {
            this.updateTrain(files[0].name, fileContent);
          }
        });
      } else {
        this.fileNameTrain[this.CurrentIndex] = "";
      }
    },
    // upload documents for taining
    updateTrain(filename, fileContent) {
      if (!this.fileNameTrain[this.CurrentIndex]) {
        this.isUploadingDocument = true;
        this.$apollo
          .query({
            query: UPLOAD_DOCUMENT,
            variables: {
              File_Name: filename,
              Category: "document",
              File_Content: fileContent[1],
              Org_Code: localStorage.getItem("org_code"),
              Url_Hash: localStorage.getItem("url_hash"),
            },
          })
          .then((response) => {
            this.snackbar = true;
            this.ResponseMessage = "File Uploaded";
            this.color = "success";
            this.$set(this.fileNameTrain, this.CurrentIndex, filename);
            this.FileFormatTraning[this.CurrentIndex] =
              response.data.fileUpload.fileName;
            this.isUploadingDocument = false;
          })
          .catch((err) => {
            this.snackbar = true;
            this.ResponseMessage =
              err.graphQLErrors.length !== 0
                ? err.graphQLErrors[0].message
                : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
            this.color = "error";
            this.isUploadingDocument = false;
          });
      } else {
        this.isUploadingDocument = true;
        this.$apollo
          .query({
            query: EDIT_DOCUMENT,
            variables: {
              New_File_Name: filename,
              Category: "document",
              File_Name: this.FileFormatTraning[this.CurrentIndex],
              New_File_Content: fileContent[1],
              Org_Code: localStorage.getItem("org_code"),
              Url_Hash: localStorage.getItem("url_hash"),
            },
          })
          .then((response) => {
            this.snackbar = true;
            this.ResponseMessage = "File Uploaded";
            this.color = "success";
            this.$set(this.fileNameTrain, this.CurrentIndex, filename);
            this.FileFormatTraning[this.CurrentIndex] =
              response.data.fileReplace.fileName;
            this.isUploadingDocument = false;
          })
          .catch((err) => {
            this.snackbar = true;
            this.ResponseMessage =
              err.graphQLErrors.length !== 0
                ? err.graphQLErrors[0].message
                : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
            this.color = "error";
            this.isUploadingDocument = false;
          });
      }
    },
    // delete documents for all details
    deleteFile(index, FileType) {
      if (FileType === "train") {
        this.isUploadingDocument = true;
        this.$apollo
          .query({
            query: DELETE_DOCUMENT,
            variables: {
              Org_Code: localStorage.getItem("org_code"),
              Url_Hash: localStorage.getItem("url_hash"),
              File_Name: this.FileFormatTraning[index],
              Category: "document",
            },
          })
          .then(() => {
            this.snackbar = true;
            this.ResponseMessage = "File Deleted";
            this.color = "success";
            this.$set(this.fileNameTrain, index, "");
            this.$set(this.TrainingSubType, index, "");
            this.$set(this.TrainDocName, index, "");
            this.isUploadingDocument = false;
          })
          .catch((err) => {
            this.snackbar = true;
            this.ResponseMessage =
              err.graphQLErrors.length !== 0
                ? err.graphQLErrors[0].message
                : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
            this.color = "error";
            this.isUploadingDocument = false;
          });
      }
    },
  },
};
</script>
