<template>
  <div>
    <nav class="hidden-sm-and-down">
      <v-toolbar flat app :class="{ toolbarClass: $store.state.drawer }">
        <!-- <v-toolbar-side-icon @click="hideSidebar" class="grey--text"></v-toolbar-side-icon> -->
        <v-toolbar-title class="text-uppercase grey--text">
          <span class="font-weight-light">Self</span>
          <span>Onboarding</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat color="grey" @click="Signout">
          <span>Sign Out</span>
          <v-icon right>exit_to_app</v-icon>
        </v-btn>
      </v-toolbar>

      <v-navigation-drawer
        v-model="$store.state.drawer"
        app
        :class="{ navbarClass: true }"
      >
        <v-layout column align-center style="padding-top: 10%">
          <img
            style="width: 75%"
            alt="logo"
            class="imgClass"
            src="whitelogo.png"
          />
        </v-layout>
        <v-list>
          <v-list-tile router :to="'/onboarding'" class="mt-4">
            <v-list-tile-action>
              <v-icon class="white--text">dashboard</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title class="white--text"
                >Onboarding</v-list-tile-title
              >
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile router :to="'/help'" class="bottomClass">
            <v-list-tile-action>
              <v-icon class="white--text">help_outline</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title class="white--text">Support</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile router :to="'/faq'" class="bottomClass1">
            <v-list-tile-action>
              <v-icon class="white--text">announcement</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title class="white--text">FAQ</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-navigation-drawer>
    </nav>
    <nav class="hidden-md-and-up">
      <v-toolbar flat app :class="{ toolbarClass: $store.state.drawer }">
        <v-toolbar-side-icon
          class="grey--text"
          @click="hideSidebar"
        ></v-toolbar-side-icon>
        <v-toolbar-title class="text-uppercase grey--text">
          <span class="font-weight-light">Self</span>
          <span>Onboarding</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat color="grey" @click="Signout">
          <span>Sign Out</span>
          <v-icon right>exit_to_app</v-icon>
        </v-btn>
      </v-toolbar>

      <v-navigation-drawer
        v-model="$store.state.drawer"
        app
        :class="{ navbarClass: true }"
      >
        <v-layout column align-center>
          <img style="height: 5em" alt="logo" src="whitelogo.png" />
        </v-layout>
        <v-list>
          <v-list-tile router :to="'/onboarding'" class="mt-4">
            <v-list-tile-action>
              <v-icon class="white--text">dashboard</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title class="white--text"
                >Onboarding</v-list-tile-title
              >
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile router :to="'/help'" class="bottomClass">
            <v-list-tile-action>
              <v-icon class="white--text">help_outline</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title class="white--text">Support</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile router :to="'/faq'" class="bottomClass1">
            <v-list-tile-action>
              <v-icon class="white--text">announcement</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title class="white--text">FAQ</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-navigation-drawer>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      links: [
        { icon: "dashboard", text: "Onboarding", route: "/onboarding" },
        { icon: "help_outline", text: "Support", route: "/help" },
        { icon: "announcement", text: "FAQ", route: "/faq" },
      ],
      navwidth: false,
    };
  },
  beforeCreate() {
    this.navwidth = true;
    if (
      this.$vuetify.breakpoint.md &&
      this.$vuetify.breakpoint.lg &&
      this.$vuetify.breakpoint.xl
    ) {
      this.navwidth = true;
    } else {
      this.navwidth = false;
    }
  },
  methods: {
    Signout() {
      window.location.href = "/" + localStorage.getItem("url_param");
      localStorage.clear();
    },
    hideSidebar() {
      this.$store.commit("Drawer", !this.$store.state.drawer);
    },
  },
};
</script>

<style scoped>
.navbarClass {
  background-color: #352650 !important;
  border-color: #352650 !important;
}
.navwidth {
  width: 16% !important;
}
.toolbarClass {
  padding-left: 16% !important;
}
.bottomClass {
  position: absolute;
  bottom: 10px;
}
.topClass {
  top: 10%;
}
.bottomClass1 {
  position: absolute;
  bottom: 8%;
}
.imgClass {
  height: auto;
  padding-right: 10%;
}
</style>
