import gql from "graphql-tag";

export const LIST_LANGUAGE = gql`
  query languageQuery($Org_Code: String!, $Url_Hash: String!) {
    listLanguage(Org_Code: $Org_Code, Url_Hash: $Url_Hash) {
      languages {
        Lang_Id
        Language_Name
        __typename
      }
    }
    __typename
  }
`;

export const LIST_COUNTRY = gql`
  query CountryQuery($Org_Code: String!, $Url_Hash: String!) {
    listCountry(Org_Code: $Org_Code, Url_Hash: $Url_Hash) {
      country {
        Country_Code
        Country_Name
        __typename
      }
    }
    __typename
  }
`;

export const LIST_MARITAL_STATUS = gql`
  query MartialStatusQuery($Org_Code: String!, $Url_Hash: String!) {
    listMartialStatus(Org_Code: $Org_Code, Url_Hash: $Url_Hash) {
      marital_status {
        Marital_Status_Id
        Marital_Status
        __typename
      }
    }
    __typename
  }
`;

export const LIST_RELATIONSHIP = gql`
  query MartialStatusRelationQuery(
    $Org_Code: String!
    $Url_Hash: String!
    $Marital_Status_Id: Int
  ) {
    listMartialStatusRelation(
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
      Marital_Status_Id: $Marital_Status_Id
    ) {
      marital_status_relation {
        Marital_Status_Id
        Dependent_Relationship
        __typename
      }
    }
    __typename
  }
`;

export const UPDATE_PERSONAL_INFO = gql`
  mutation updatePersonalInfo(
    $Org_Code: String!
    $Url_Hash: String!
    $Salutation: String
    $Emp_First_Name: String
    $Emp_Middle_Name: String
    $Emp_Last_Name: String
    $Nick_Name: String
    $Gender: String
    $DOB: String
    $Place_Of_Birth: String
    $Marital_Status: Int
    $Blood_Group: String
    $Lang_Known: [Int]
    $hobbies: String
    $Nationality: String
    $Religion: String
    $Caste: String
    $Is_Manager: Boolean
    $Photo_Path: String
    $Personal_Email: String
    $Physically_Challenged: Boolean
    $Smoker: Boolean
    $Smokerasof: String
    $PAN: String
    $Aadhaar_Card_Number: String
    $Military_Service: Boolean
    $Driving_License_No: String
    $License_Issue_Date: String
    $License_Expiry_Date: String
    $Issuing_Authority: String
    $Issuing_Country: String
    $Issuing_State: String
    $Vehicle_Type: String
    $Passport_No: String
    $Issue_Date: String
    $Expiry_Date: String
    $pApartment_Name: String
    $pStreet_Name: String
    $pCity: String
    $pState: String
    $pCountry: String
    $pPincode: String
    $cApartment_Name: String
    $cStreet_Name: String
    $cCity: String
    $cState: String
    $cCountry: String
    $cPincode: String
    $Use_Location_Address: Int
    $Land_Line_No: String
    $Mobile_No: String
    $Mobile_No_Country_Code: String
    $Emergency_Contact_No: String
    $Section1_Progress: Boolean
    $Section2_Progress: Boolean
    $Section3_Progress: Boolean
    $Section4_Progress: Boolean
    $Ethnic_Race: String
  ) {
    updatePersonalInfo(
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
      Salutation: $Salutation
      Emp_First_Name: $Emp_First_Name
      Emp_Middle_Name: $Emp_Middle_Name
      Emp_Last_Name: $Emp_Last_Name
      Nick_Name: $Nick_Name
      Gender: $Gender
      DOB: $DOB
      Place_Of_Birth: $Place_Of_Birth
      Marital_Status: $Marital_Status
      Blood_Group: $Blood_Group
      Lang_Known: $Lang_Known
      hobbies: $hobbies
      Nationality: $Nationality
      Religion: $Religion
      Caste: $Caste
      Is_Manager: $Is_Manager
      Photo_Path: $Photo_Path
      Personal_Email: $Personal_Email
      Physically_Challenged: $Physically_Challenged
      Smoker: $Smoker
      Smokerasof: $Smokerasof
      PAN: $PAN
      Aadhaar_Card_Number: $Aadhaar_Card_Number
      Military_Service: $Military_Service
      Driving_License_No: $Driving_License_No
      License_Issue_Date: $License_Issue_Date
      License_Expiry_Date: $License_Expiry_Date
      Issuing_Authority: $Issuing_Authority
      Issuing_Country: $Issuing_Country
      Issuing_State: $Issuing_State
      Vehicle_Type: $Vehicle_Type
      Passport_No: $Passport_No
      Issue_Date: $Issue_Date
      Expiry_Date: $Expiry_Date
      pApartment_Name: $pApartment_Name
      pStreet_Name: $pStreet_Name
      pCity: $pCity
      pState: $pState
      pCountry: $pCountry
      pPincode: $pPincode
      cApartment_Name: $cApartment_Name
      cStreet_Name: $cStreet_Name
      cCity: $cCity
      cState: $cState
      cCountry: $cCountry
      cPincode: $cPincode
      Use_Location_Address: $Use_Location_Address
      Land_Line_No: $Land_Line_No
      Mobile_No: $Mobile_No
      Mobile_No_Country_Code: $Mobile_No_Country_Code
      Emergency_Contact_No: $Emergency_Contact_No
      Section1_Progress: $Section1_Progress
      Section2_Progress: $Section2_Progress
      Section3_Progress: $Section3_Progress
      Section4_Progress: $Section4_Progress
      Ethnic_Race: $Ethnic_Race
    ) {
      message
    }
  }
`;

export const GET_PERSONAL_INFO = gql`
  query personalInfoQuery($Org_Code: String!, $Url_Hash: String!) {
    getPersonalInfo(Org_Code: $Org_Code, Url_Hash: $Url_Hash) {
      Salutation
      Emp_First_Name
      Emp_Middle_Name
      Emp_Last_Name
      Nick_Name
      Gender
      DOB
      Place_Of_Birth
      Photo_Path
      Marital_Status
      Blood_Group
      hobbies
      Nationality
      Religion
      Caste
      Is_Manager
      Personal_Email
      Physically_Challenged
      Smoker
      Smokerasof
      PAN
      Aadhaar_Card_Number
      Military_Service
      Driving_License_No
      License_Issue_Date
      License_Expiry_Date
      Issuing_Authority
      Issuing_Country
      Issuing_State
      Vehicle_Type
      Passport_No
      Issue_Date
      Expiry_Date
      pApartment_Name
      pStreet_Name
      pCity
      pState
      pCountry
      pPincode
      cApartment_Name
      cStreet_Name
      cCity
      cState
      cCountry
      cPincode
      Use_Location_Address
      Land_Line_No
      Mobile_No
      Mobile_No_Country_Code
      Fax_No
      Section1_Progress
      Ethnic_Race
      Section2_Progress
      Section3_Progress
      Section4_Progress
      Lang_Known {
        languages {
          Lang_Id
          Language_Name
        }
      }
    }
  }
`;

export const GET_EXPERIENCE = gql`
  query experienceQuery($Org_Code: String!, $Url_Hash: String!) {
    getExperience(Org_Code: $Org_Code, Url_Hash: $Url_Hash) {
      experience {
        Experience_Id
        Candidate_Id
        Prev_Company_Name
        Prev_Company_Location
        Designation
        Start_Date
        End_Date
        Duration
        File_Name
      }
      previousExperienceMonth
      previousExperienceYear
    }
  }
`;

export const ADD_EXPERIENCE = gql`
  mutation experienceQuery(
    $Org_Code: String!
    $Url_Hash: String!
    $Prev_Company_Name: String!
    $Prev_Company_Location: String!
    $Designation: String!
    $Start_Date: String
    $End_Date: String
    $File_Name: String
  ) {
    addExperience(
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
      Prev_Company_Name: $Prev_Company_Name
      Prev_Company_Location: $Prev_Company_Location
      Designation: $Designation
      Start_Date: $Start_Date
      End_Date: $End_Date
      File_Name: $File_Name
    ) {
      experience {
        Experience_Id
        Candidate_Id
        Prev_Company_Name
        Prev_Company_Location
        Designation
        Start_Date
        End_Date
        Duration
        File_Name
      }
    }
  }
`;

export const UPDATE_EXPERIENCE = gql`
  mutation experienceQuery(
    $Org_Code: String!
    $Url_Hash: String!
    $Experience_Id: Int!
    $Prev_Company_Name: String!
    $Prev_Company_Location: String!
    $Designation: String!
    $Start_Date: String
    $End_Date: String
    $File_Name: String
  ) {
    updateExperience(
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
      Experience_Id: $Experience_Id
      Prev_Company_Name: $Prev_Company_Name
      Prev_Company_Location: $Prev_Company_Location
      Designation: $Designation
      Start_Date: $Start_Date
      End_Date: $End_Date
      File_Name: $File_Name
    ) {
      message
    }
  }
`;

export const DELETE_EXPERIENCE = gql`
  mutation experienceQuery(
    $Experience_Id: Int!
    $Org_Code: String!
    $Url_Hash: String!
  ) {
    deleteExperience(
      Experience_Id: $Experience_Id
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
    ) {
      experience {
        Experience_Id
        Candidate_Id
        Prev_Company_Name
        Prev_Company_Location
        Designation
        Start_Date
        End_Date
        Duration
      }
    }
  }
`;

export const UPDATE_TOTAL_EXPERIENCE = gql`
  mutation updateEmployeePreviousExperience(
    $Org_Code: String!
    $Url_Hash: String!
    $Previous_Experience_Month: Int!
    $Previous_Experience_Year: Int!
  ) {
    updateEmployeePreviousExperience(
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
      Previous_Experience_Month: $Previous_Experience_Month
      Previous_Experience_Year: $Previous_Experience_Year
    ) {
      message
    }
  }
`;

export const ADD_DEPENDENT = gql`
  mutation dependentQuery(
    $Org_Code: String!
    $Url_Hash: String!
    $Dependent_First_Name: String!
    $Dependent_Last_Name: String!
    $Gender: String!
    $Relationship: String!
    $Dependent_DOB: String!
  ) {
    addDependent(
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
      Dependent_First_Name: $Dependent_First_Name
      Dependent_Last_Name: $Dependent_Last_Name
      Gender: $Gender
      Relationship: $Relationship
      Dependent_DOB: $Dependent_DOB
    ) {
      dependent {
        Dependent_Id
        Dependent_First_Name
        Dependent_Last_Name
        Gender
        Relationship
        Dependent_DOB
      }
    }
  }
`;

export const GET_DEPENDENT = gql`
  query experienceQuery($Org_Code: String!, $Url_Hash: String!) {
    getDependent(Org_Code: $Org_Code, Url_Hash: $Url_Hash) {
      dependent {
        Dependent_Id
        Dependent_First_Name
        Dependent_Last_Name
        Gender
        Relationship
        Dependent_DOB
      }
    }
  }
`;

export const UPDATE_DEPENDENT = gql`
  mutation dependentQuery(
    $Org_Code: String!
    $Url_Hash: String!
    $Dependent_Id: Int!
    $Dependent_First_Name: String!
    $Dependent_Last_Name: String!
    $Gender: String!
    $Relationship: String!
    $Dependent_DOB: String!
  ) {
    updateDependent(
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
      Dependent_Id: $Dependent_Id
      Dependent_First_Name: $Dependent_First_Name
      Dependent_Last_Name: $Dependent_Last_Name
      Gender: $Gender
      Relationship: $Relationship
      Dependent_DOB: $Dependent_DOB
    ) {
      message
    }
  }
`;

export const DELETE_DEPENDENT = gql`
  mutation dependentQuery(
    $Dependent_Id: Int!
    $Org_Code: String!
    $Url_Hash: String!
  ) {
    deleteDependent(
      Dependent_Id: $Dependent_Id
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
    ) {
      dependent {
        Dependent_Id
        Dependent_First_Name
        Dependent_Last_Name
        Gender
        Relationship
        Dependent_DOB
      }
    }
  }
`;

export const LIST_COURSE = gql`
  query CourseQuery($Org_Code: String!, $Url_Hash: String!) {
    listCourseDetails(Org_Code: $Org_Code, Url_Hash: $Url_Hash) {
      courseDetails {
        Course_Id
        Course_Name
        __typename
      }
    }
    __typename
  }
`;

export const ADD_EDUCATION = gql`
  mutation educationQuery(
    $Org_Code: String!
    $Url_Hash: String!
    $Education_Type: Int!
    $Specialisation: String!
    $Institute_Name: String!
    $University: String!
    $Year_Of_Passing: String!
    $Percentage: String
    $Grade: String
    $File_Name: String
    $Document_Name: String
    $Sub_Type_Id: Int
  ) {
    addEducation(
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
      Education_Type: $Education_Type
      Specialisation: $Specialisation
      Institute_Name: $Institute_Name
      University: $University
      Year_Of_Passing: $Year_Of_Passing
      Percentage: $Percentage
      Grade: $Grade
      File_Name: $File_Name
      Document_Name: $Document_Name
      Sub_Type_Id: $Sub_Type_Id
    ) {
      education {
        Education_Id
        Education_Type
        Education_Type_Name
        Specialisation
        Institute_Name
        University
        Year_Of_Passing
        Percentage
        Grade
        File_Name
        Document_Name
        Sub_Type_Id
      }
    }
  }
`;

export const UPDATE_EDUCATION = gql`
  mutation educationQuery(
    $Org_Code: String!
    $Url_Hash: String!
    $Education_Id: Int!
    $Education_Type: Int!
    $Specialisation: String!
    $Institute_Name: String!
    $University: String!
    $Year_Of_Passing: String!
    $Percentage: String
    $Grade: String
    $File_Name: String
    $Document_Name: String
    $Sub_Type_Id: Int
  ) {
    updateEducation(
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
      Education_Id: $Education_Id
      Education_Type: $Education_Type
      Specialisation: $Specialisation
      Institute_Name: $Institute_Name
      University: $University
      Year_Of_Passing: $Year_Of_Passing
      Percentage: $Percentage
      Grade: $Grade
      File_Name: $File_Name
      Document_Name: $Document_Name
      Sub_Type_Id: $Sub_Type_Id
    ) {
      message
    }
  }
`;

export const GET_EDUCATION = gql`
  query educationQuery($Org_Code: String!, $Url_Hash: String!) {
    getEducation(Org_Code: $Org_Code, Url_Hash: $Url_Hash) {
      education {
        Education_Id
        Education_Type
        Education_Type_Name
        Specialisation
        Institute_Name
        University
        Year_Of_Passing
        Percentage
        Grade
        File_Name
        Document_Name
        Sub_Type_Id
        Document_Sub_Type
      }
    }
  }
`;

export const DELETE_EDUCATION = gql`
  mutation educationQuery(
    $Education_Id: Int!
    $Org_Code: String!
    $Url_Hash: String!
  ) {
    deleteEducation(
      Education_Id: $Education_Id
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
    ) {
      education {
        Education_Id
        Education_Type
        Education_Type_Name
        Specialisation
        Institute_Name
        University
        Year_Of_Passing
        Percentage
        Grade
        File_Name
        Document_Name
        Sub_Type_Id
      }
    }
  }
`;

export const ADD_CERTIFICATE = gql`
  mutation certificationQuery(
    $Org_Code: String!
    $Url_Hash: String!
    $Certification_Name: String!
    $Received_Date: String!
    $Certificate_Received_From: String!
    $File_Name: String
    $Document_Name: String
    $Sub_Type_Id: Int
  ) {
    addCertification(
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
      Certification_Name: $Certification_Name
      Received_Date: $Received_Date
      Certificate_Received_From: $Certificate_Received_From
      File_Name: $File_Name
      Document_Name: $Document_Name
      Sub_Type_Id: $Sub_Type_Id
    ) {
      certificates {
        Certification_Id
        Certification_Name
        Received_Date
        Certificate_Received_From
        File_Name
        Document_Name
        Sub_Type_Id
      }
    }
  }
`;

export const UPDATE_CERTIFICATE = gql`
  mutation certificationQuery(
    $Org_Code: String!
    $Url_Hash: String!
    $Certification_Id: Int!
    $Certification_Name: String!
    $Received_Date: String!
    $Certificate_Received_From: String!
    $File_Name: String
    $Document_Name: String
    $Sub_Type_Id: Int
  ) {
    updateCertification(
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
      Certification_Id: $Certification_Id
      Certification_Name: $Certification_Name
      Received_Date: $Received_Date
      Certificate_Received_From: $Certificate_Received_From
      File_Name: $File_Name
      Document_Name: $Document_Name
      Sub_Type_Id: $Sub_Type_Id
    ) {
      message
    }
  }
`;

export const GET_CERTIFICATE = gql`
  query experienceQuery($Org_Code: String!, $Url_Hash: String!) {
    getCertification(Org_Code: $Org_Code, Url_Hash: $Url_Hash) {
      certificates {
        Certification_Id
        Certification_Name
        Received_Date
        Certificate_Received_From
        File_Name
        Document_Name
        Sub_Type_Id
        Document_Sub_Type
      }
    }
  }
`;

export const DELETE_CERTIFICATE = gql`
  mutation certificationQuery(
    $Certification_Id: Int!
    $Org_Code: String!
    $Url_Hash: String!
  ) {
    deleteCertification(
      Certification_Id: $Certification_Id
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
    ) {
      certificates {
        Certification_Id
        Certification_Name
        Received_Date
        Certificate_Received_From
        File_Name
        Document_Name
        Sub_Type_Id
      }
    }
  }
`;

export const GET_TRAINING = gql`
  query experienceQuery($Org_Code: String!, $Url_Hash: String!) {
    getTraining(Org_Code: $Org_Code, Url_Hash: $Url_Hash) {
      training {
        Training_Id
        Training_Name
        Training_Start_Date
        Training_End_Date
        Trainer
        Center
        File_Name
        Document_Name
        Sub_Type_Id
        Document_Sub_Type
      }
    }
  }
`;
export const UPDATE_TRAINING = gql`
  mutation trainingQuery(
    $Org_Code: String!
    $Url_Hash: String!
    $Training_Id: Int!
    $Training_Name: String!
    $Training_Start_Date: String
    $Training_End_Date: String
    $Trainer: String!
    $Center: String!
    $File_Name: String
    $Document_Name: String
    $Sub_Type_Id: Int
  ) {
    updateTraining(
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
      Training_Id: $Training_Id
      Training_Name: $Training_Name
      Training_Start_Date: $Training_Start_Date
      Training_End_Date: $Training_End_Date
      Trainer: $Trainer
      Center: $Center
      File_Name: $File_Name
      Document_Name: $Document_Name
      Sub_Type_Id: $Sub_Type_Id
    ) {
      message
    }
  }
`;
export const ADD_TRAINING = gql`
  mutation trainingQuery(
    $Org_Code: String!
    $Url_Hash: String!
    $Training_Name: String!
    $Training_Start_Date: String
    $Training_End_Date: String
    $Trainer: String!
    $Center: String!
    $File_Name: String
    $Document_Name: String
    $Sub_Type_Id: Int
  ) {
    addTraining(
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
      Training_Name: $Training_Name
      Training_Start_Date: $Training_Start_Date
      Training_End_Date: $Training_End_Date
      Trainer: $Trainer
      Center: $Center
      File_Name: $File_Name
      Document_Name: $Document_Name
      Sub_Type_Id: $Sub_Type_Id
    ) {
      training {
        Training_Id
        Training_Name
        Training_Start_Date
        Training_End_Date
        Trainer
        Center
        File_Name
        Document_Name
        Sub_Type_Id
      }
    }
  }
`;
export const DELETE_TRAINING = gql`
  mutation trainingQuery(
    $Training_Id: Int!
    $Org_Code: String!
    $Url_Hash: String!
  ) {
    deleteTraining(
      Training_Id: $Training_Id
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
    ) {
      training {
        Training_Id
        Training_Name
        Training_Start_Date
        Training_End_Date
        Trainer
        Center
        File_Name
        Document_Name
        Sub_Type_Id
      }
    }
  }
`;
export const GET_BANK_DETAILS = gql`
  query bankQuery($Org_Code: String!, $Url_Hash: String!) {
    getBankDetails(Org_Code: $Org_Code, Url_Hash: $Url_Hash) {
      Candidate_Id
      Bank_Account_Number
      Bank_Name
      Emp_Bank_Id
      Branch_Name
      IFSC_Code
      Street
      City
      State
      Zip
      Account_Type
      Credit_Account
      Beneficiary_Id
      Status
      BSB_Code
    }
  }
`;
export const LIST_ACCOUNT_TYPE = gql`
  query bankQuery($Org_Code: String!, $Url_Hash: String!) {
    listAccountType(Org_Code: $Org_Code, Url_Hash: $Url_Hash) {
      accountType {
        Account_Type_Id
        Account_Type
        __typename
      }
    }
    __typename
  }
`;
export const ADD_BANK = gql`
  mutation bankQuery(
    $Org_Code: String!
    $Url_Hash: String!
    $Bank_Account_Number: String!
    $Bank_Name: String!
    $Emp_Bank_Id: Int!
    $Branch_Name: String!
    $IFSC_Code: String
    $Street: String
    $City: String
    $State: String
    $Zip: String
    $Account_Type_Id: Int
    $BSB_Code: String
    $isAustralian: Int!
  ) {
    addBankDetails(
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
      Bank_Account_Number: $Bank_Account_Number
      Bank_Name: $Bank_Name
      Emp_Bank_Id: $Emp_Bank_Id
      Branch_Name: $Branch_Name
      IFSC_Code: $IFSC_Code
      Street: $Street
      City: $City
      State: $State
      Zip: $Zip
      Account_Type_Id: $Account_Type_Id
      BSB_Code: $BSB_Code
      isAustralian: $isAustralian
    ) {
      message
    }
  }
`;

export const UPDATE_BANK = gql`
  mutation bankQuery(
    $Org_Code: String!
    $Url_Hash: String!
    $Bank_Account_Number: String!
    $Emp_Bank_Id: Int!
    $Bank_Name: String!
    $Branch_Name: String!
    $IFSC_Code: String
    $Street: String
    $City: String
    $State: String
    $Zip: String
    $Account_Type_Id: Int
    $BSB_Code: String
    $isAustralian: Int!
  ) {
    updateBankDetails(
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
      Bank_Account_Number: $Bank_Account_Number
      Emp_Bank_Id: $Emp_Bank_Id
      Bank_Name: $Bank_Name
      Branch_Name: $Branch_Name
      IFSC_Code: $IFSC_Code
      Street: $Street
      City: $City
      State: $State
      Zip: $Zip
      Account_Type_Id: $Account_Type_Id
      BSB_Code: $BSB_Code
      isAustralian: $isAustralian
    ) {
      message
    }
  }
`;

export const SIGN_IN = gql`
  query pinAuth($Org_Code: String!, $Url_Hash: String!, $Pin: Int!) {
    pinAuth(Org_Code: $Org_Code, Url_Hash: $Url_Hash, Pin: $Pin) {
      message
      countryCode
      serviceProviderId
    }
  }
`;
export const UPDATA_PHOTO_PATH = gql`
  mutation profileQuery(
    $Org_Code: String!
    $Url_Hash: String!
    $Photo_Path: String!
  ) {
    updatePhotoPath(
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
      Photo_Path: $Photo_Path
    ) {
      message
    }
  }
`;
export const UPLOAD_DOCUMENT = gql`
  query profileImageQuery(
    $Org_Code: String!
    $Url_Hash: String!
    $File_Name: String!
    $File_Content: String!
    $Category: String!
  ) {
    fileUpload(
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
      File_Name: $File_Name
      File_Content: $File_Content
      Category: $Category
    ) {
      fileName
    }
  }
`;
export const RETRIEVE_DOCUMENT = gql`
  query profileImageQuery(
    $Org_Code: String!
    $Url_Hash: String!
    $File_Name: String!
    $Category: String!
  ) {
    fileRetrieve(
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
      File_Name: $File_Name
      Category: $Category
    ) {
      fileContent
    }
  }
`;
export const EDIT_DOCUMENT = gql`
  query profileImageQuery(
    $Org_Code: String!
    $Url_Hash: String!
    $File_Name: String!
    $New_File_Name: String!
    $New_File_Content: String!
    $Category: String!
  ) {
    fileReplace(
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
      File_Name: $File_Name
      New_File_Name: $New_File_Name
      New_File_Content: $New_File_Content
      Category: $Category
    ) {
      fileName
    }
  }
`;
export const DELETE_DOCUMENT = gql`
  query profileImageQuery(
    $Org_Code: String!
    $Url_Hash: String!
    $File_Name: String!
    $Category: String!
  ) {
    fileDelete(
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
      File_Name: $File_Name
      Category: $Category
    ) {
      message
    }
  }
`;
export const GET_ALL_DOCUMENTS = gql`
  query documentQuery($Org_Code: String!, $Url_Hash: String!) {
    getAllDocuments(Org_Code: $Org_Code, Url_Hash: $Url_Hash) {
      documents {
        Document_Id
        Candidate_Id
        Category_Id
        Category_Name
        Document_Type_Id
        Document_Type
        Document_Sub_Type_Id
        Document_Sub_Type
        Document_Name
        File_Name
      }
    }
  }
`;
export const ADD_DOCUMENT = gql`
  mutation educationQuery(
    $Org_Code: String!
    $Url_Hash: String!
    $File_Name: String!
    $Document_Name: String!
    $Category_Id: Int!
    $Document_Type_Id: Int!
    $Sub_Document_Type_Id: Int
  ) {
    addDocument(
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
      File_Name: $File_Name
      Document_Name: $Document_Name
      Category_Id: $Category_Id
      Document_Type_Id: $Document_Type_Id
      Sub_Document_Type_Id: $Sub_Document_Type_Id
    ) {
      documents {
        Document_Id
        Candidate_Id
        Category_Id
        Category_Name
        Document_Type_Id
        Document_Type
        Document_Sub_Type_Id
        Document_Sub_Type
        Document_Name
        File_Name
      }
    }
  }
`;
export const DELETE_DOC_DOCUMENT = gql`
  mutation documentQuery(
    $Document_Id: Int!
    $Org_Code: String!
    $Url_Hash: String!
  ) {
    deleteDocument(
      Document_Id: $Document_Id
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
    ) {
      message
    }
  }
`;

export const LIST_DOC_CATEGORY = gql`
  query listQuery($Org_Code: String!, $Url_Hash: String!) {
    listDocumentCategory(Org_Code: $Org_Code, Url_Hash: $Url_Hash) {
      category {
        Category_Id
        Category_Name
        __typename
      }
    }
    __typename
  }
`;
export const LIST_DOC_TYPE = gql`
  query listQuery($Org_Code: String!, $Url_Hash: String!, $Category_Id: Int!) {
    listDocumentType(
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
      Category_Id: $Category_Id
    ) {
      documentType {
        Document_Type_Id
        Document_Type
        __typename
      }
    }
    __typename
  }
`;
export const LIST_SUB_DOC_TYPE = gql`
  query listQuery($Org_Code: String!, $Url_Hash: String!, $type_id: Int!) {
    listDocumentSubType(
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
      type_id: $type_id
    ) {
      documentSubType {
        Document_Sub_Type_Id
        Document_Sub_Type
        __typename
      }
    }
    __typename
  }
`;
export const UPDATE_DOC_DOCUMENT = gql`
  mutation educationQuery(
    $Org_Code: String!
    $Url_Hash: String!
    $Document_Id: Int
    $File_Name: String!
    $Document_Name: String!
    $Category_Id: Int!
    $Document_Type_Id: Int!
    $Sub_Document_Type_Id: Int
  ) {
    updateDocument(
      Org_Code: $Org_Code
      Url_Hash: $Url_Hash
      Document_Id: $Document_Id
      File_Name: $File_Name
      Document_Name: $Document_Name
      Category_Id: $Category_Id
      Document_Type_Id: $Document_Type_Id
      Sub_Document_Type_Id: $Sub_Document_Type_Id
    ) {
      message
    }
  }
`;
export const LIST_BANK = gql`
  query CountryQuery($Org_Code: String!, $Url_Hash: String!) {
    listBankName(Org_Code: $Org_Code, Url_Hash: $Url_Hash) {
      bankName {
        Bank_Id
        Bank_Name
        __typename
      }
    }
    __typename
  }
`;
export const SUBMIT_DETAILS = gql`
  mutation urlQuery($Org_Code: String!, $Url_Hash: String!, $Status: String) {
    statusUpdate(Org_Code: $Org_Code, Url_Hash: $Url_Hash, Status: $Status) {
      message
    }
  }
`;
export const URL_EXPIRATION = gql`
  query urlStatus($Org_Code: String!, $Url_Hash: String!) {
    checkUrlStatus(Org_Code: $Org_Code, Url_Hash: $Url_Hash) {
      message
      __typename
    }
    __typename
  }
`;
