import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    domainName: "hrapp",
    bloodGroup: [
      "O+",
      "O-",
      "A+",
      "A-",
      "B+",
      "B-",
      "AB+",
      "AB-",
      "A1+",
      "A1B+",
      "Unknown",
    ],
    loader: false,
    drawer: false,
    // accountnumber, passportnumber, ifsc
    onlyLetterNumberRules: [
      (v) => !!v || "This Field is required",
      (v) =>
        /^[a-zA-Z0-9]*$/.test(v) || "Only alphanumeric characters are allowed.",
    ],
    // address city
    cityRules: [
      (v) => !!v || "This Field is required",
      (v) =>
        /^[\w\-\(\)\,\ ]*$/.test(v) ||
        "Only alphanumeric, spaces and symbols(- , () )  are allowed.",
    ],
    // apartment name & street
    alphaNumSpCDotHySlashRules: [
      (v) => !!v || "This Field is required",
      (v) =>
        /^[\w\.\,\#\+\&\/\-\(\)\:\'\ ]*$/.test(v) ||
        "Only alphanumeric, spaces and symbols(+,/ . # & : ()' - ) are allowed.",
    ],
    // pincodes, licencenumber, training, trainer, center
    alphaNumSpHyRules: [
      (v) => !!v || "This Field is required",
      (v) =>
        /^[\w\-\ ]*$/.test(v) ||
        "Only alphanumeric, spaces and symbol(-) are allowed.",
    ],
    pinCodeRules: [
      (v) =>
        /^[\w\-\ ]*$/.test(v) ||
        "Only alphanumeric, spaces and symbol(-) are allowed.",
    ],
    // mobile & landline
    phoneRules: [
      (v) => !!v || "This Field is required",
      (v) =>
        /^([\+][0-9]{1,3}[ \.\-])?([\(]{1}[0-9]{2,6}[\)])?([0-9 \+\.\-\/]{5,15})((x|ext|extension)[ ]?[0-9]{1,4})?$/.test(
          v
        ) || "Provide a valid phone number.",
    ],
    // city and State
    city_state_Rules: [
      (v) => !!v || "This Field is required.",
      (v) =>
        /^[\w\(\)\,\ ]*$/.test(v) ||
        "Only alphanumeric, spaces and symbols(, () )  are allowed.",
    ],
    // dependent first & last name, location, nickname, placeofbirth, nationality, ethnicrace, caste, religion, licState, licAuth,
    onlyLetterSpaceRules: [
      (v) => !!v || "This Field is required",
      (v) =>
        /^[a-zA-Z\ ]+$/.test(v) || "Only alphabets and spaces are allowed.",
    ],
    // branch_name and street
    branchName_street_Rules: [
      (v) => !!v || "This Field is required",
      (v) =>
        /^[a-zA-Z0-9\s&+,/#:()'.-]*$/.test(v) ||
        "Only alphanumeric, spaces and symbols(+,/ . # & : ()' - ) are allowed.",
    ],
    //  documentname
    docNameRules: [
      (v) => !!v || "This Field is required",
      (v) =>
        /^[a-zA-Z0-9\s.-]*$/.test(v) ||
        "Only alphanumeric, spaces and symbols(. - ) are allowed.",
    ],
    // company name
    companyNameRules: [
      (v) => !!v || "This Field is required",
      (v) =>
        /^[a-zA-Z\.\-\&\ ]+$/.test(v) ||
        "Only alphabets, spaces and symbols(. & - ) are allowed.",
      (v) =>
        (v && v.length >= 3) || "The value should be more than 3 characters.",
    ],
    // designation
    designationRules: [
      (v) => !!v || "This Field is required",
      (v) =>
        /^[\w\-\&\ ]*$/.test(v) ||
        "Only alphanumeric,spaces and symbols(& - ) are allowed",
      (v) =>
        (v && v.length >= 3) || "The value should be more than 3 characters.",
    ],
    // specialization
    specializationRules: [
      (v) =>
        /^[a-zA-Z\-\ \.\(\) ]*$/.test(v) ||
        "Only alphabets,spaces and symbols( - . () ) are allowed.",
      (v) =>
        (v && v.length >= 2) || "The value should be more than 2 characters.",
    ],
    //  university, institutename, receivedfrom, firstname, companyname
    alSpDotRules: [
      (v) => !!v || "This Field is required",
      (v) =>
        /^[a-zA-Z\.\ ]+$/.test(v) ||
        "Only alphabets, spaces and symbols(.)  are allowed.",
    ],
    // middname, lastname
    midLastRules: [
      (v) => !!v || "This Field is required",
      (v) =>
        /^[a-zA-Z\.\ ]+$/.test(v) ||
        "Only alphabets, spaces and symbols(.)  are allowed.",
    ],
    // yearof passig
    yearofpassRules: [
      (v) => !!v || "This Field is required",
      (v) => /^[0-9]+$/.test(v) || "Only numerics are allowed.",
      (v) =>
        v <= new Date().getFullYear() ||
        "Year of passing should be less than or equal to " +
          new Date().getFullYear(),
      (v) =>
        v > localStorage.getItem("DOB") ||
        "Year of passing should be greater than the date of birth.",
      (v) =>
        (v && v.length === 4) || "The value should be more than 4 characters.",
    ],
    // percentage
    percentageRules: [
      (v) => !!v || "This Field is required",
      (v) => /^[0-9\.]+$/.test(v) || "Only numerics are allowed.",
      (v) => v >= 1 || "The value should be greater than 0.",
      (v) => v <= 100 || "The value should be less than or equal to 100.",
    ],
    //  certificate name
    certificationRules: [
      (v) => !!v || "This Field is required",
      (v) =>
        /^[\w\.\+\#\-\/\ ]*$/.test(v) ||
        "Only alphanumeric, spaces and symbols(- . + / # )  are allowed.",
    ],
    // aadhaar number
    aadhaarRules: [
      (v) => !!v || "This Field is required",
      (v) =>
        /^[\w\.\,\/\-\(\)\:\ ]*$/.test(v) ||
        "Only alphanumeric, spaces and symbols(, / . : () - ) are allowed.",
      (v) =>
        (v && v.length <= 25) ||
        "The value should not more than 25 characters.",
    ],
    //  PAN number
    panRules: [
      (v) => !!v || "This Field is required",
      (v) =>
        /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/.test(v) ||
        "Please provide a valid PAN number. Ex:AFZPK7190K",
    ],
    //  hobbies
    hobbyRules: [
      (v) => !!v || "This Field is required",
      (v) =>
        /^[a-zA-Z\ \,\n\r]+$/.test(v) ||
        "Only alphabets, spaces and symbols(,)  are allowed.",
    ],
    // email
    emailRules: [
      (v) => !!v || "This Field is required",
      (v) =>
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) ||
        "Please provide a valid email address.",
    ],
    vehicleRules: [
      (v) => !!v || "This Field is required",
      (v) =>
        /^[\w\ \,\.\&\(\)\ ]+$/.test(v) ||
        "Only alphanumeric, spaces, and symbols(& , () .) are allowed",
    ],
    // total experience validation
    totalExpYearRules: [
      (v) => /^[0-9]+$/.test(v) || "Only numbers allowed",
      (v) => v >= 0 || "The value should be greater than or equal to 0.",
      (v) => v <= 99 || "The value should be less than or equal to 99.",
    ],
    totalExpMonthRules: [
      (v) => /^[0-9]+$/.test(v) || "Only numbers allowed",
      (v) => v >= 0 || "The value should be greater than or equal to 0.",
      (v) => v <= 11 || "The value should be less than or equal to 11.",
    ],
  },
  mutations: {
    Loader(state, payload) {
      state.loader = payload;
    },
    Drawer(state, payload) {
      state.drawer = payload;
    },
    UPDATE_DOMAIN_NAME(state, payload) {
      state.domainName = payload;
    },
  },
  actions: {},
});
