<template>
  <v-container>
    <!-- alerts -->
    <v-snackbar v-model="snackbar" :color="color" multi-line :timeout="3000">
      {{ ResponseMessage }}
      <v-btn dark flat @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <!-- educations details -->
    <v-form
      v-for="(addexp, index) in addEducation"
      :key="index + 'form1'"
      :ref="'eduform' + index"
    >
      <v-container grid-list-md text-xs-center>
        <v-layout row wrap>
          <v-flex xs12 md10>
            <v-card>
              <v-card-text>
                <v-layout row wrap>
                  <v-flex md12>
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          v-show="!showEdu[index]"
                          :id="'DeleteEducationDetails'"
                          small
                          fab
                          dark
                          color="red"
                          class="right"
                          v-on="on"
                          @click.native.prevent="
                            deleteEducation(index, addEducation, showEdu)
                          "
                        >
                          <v-icon dark>delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete Education Details</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          v-show="!showEdu[index]"
                          :id="'EditEducationDetails'"
                          small
                          fab
                          dark
                          color="warning"
                          class="right"
                          v-on="on"
                          @click="editEducation(index)"
                        >
                          <v-icon dark>edit</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit Education Details</span>
                    </v-tooltip>
                  </v-flex>
                  <!-- line 1 -->
                  <v-flex xs12 md6>
                    <!-- degree selection -->
                    <v-select
                      :id="'School/Dip/Clg'"
                      v-model="EducationCategory[index]"
                      background-color="#e0e0e0"
                      outline
                      :disabled="!showEdu[index]"
                      prepend-icon="school"
                      :items="courseList"
                      item-text="Course_Name"
                      item-value="Course_Id"
                      color="#3cd1c2"
                      label="School/Diploma/Deg*"
                      :rules="[(v) => !!v || 'This field is required']"
                      required
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 md6>
                    <!-- specialisation -->
                    <v-text-field
                      :id="'Specialisatoin'"
                      v-model="Specialisation[index]"
                      background-color="#e0e0e0"
                      color="#3cd1c2"
                      outline
                      :counter="30"
                      :maxlength="30"
                      :disabled="!showEdu[index]"
                      label="Specialisation"
                      prepend-icon="book"
                      :rules="
                        Specialisation[index]
                          ? $store.state.specializationRules
                          : [true]
                      "
                      required
                    ></v-text-field>
                  </v-flex>
                  <!-- line 2 -->
                  <v-flex xs12 md6>
                    <!-- institute name -->
                    <v-text-field
                      :id="'InstituteName'"
                      v-model="InstituName[index]"
                      background-color="#e0e0e0"
                      color="#3cd1c2"
                      outline
                      :counter="50"
                      :maxlength="50"
                      :disabled="!showEdu[index]"
                      label="Institute Name"
                      prepend-icon="domain"
                      :rules="
                        InstituName[index] ? $store.state.alSpDotRules : [true]
                      "
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <!-- university -->
                    <v-text-field
                      :id="'University'"
                      v-model="University[index]"
                      :maxlength="50"
                      background-color="#e0e0e0"
                      color="#3cd1c2"
                      outline
                      :counter="50"
                      :disabled="!showEdu[index]"
                      label="University"
                      prepend-icon="location_city"
                      :rules="
                        University[index] ? $store.state.alSpDotRules : [true]
                      "
                      required
                    ></v-text-field>
                  </v-flex>
                  <!-- line 3 -->
                  <v-flex xs12 md6>
                    <!-- year of passing -->
                    <v-text-field
                      :id="'YearofPassing'"
                      v-model="YearOfPassing[index]"
                      background-color="#e0e0e0"
                      color="#3cd1c2"
                      outline
                      :counter="4"
                      :maxlength="4"
                      :disabled="!showEdu[index]"
                      label="Year Of Passing*"
                      prepend-icon="date_range"
                      :rules="$store.state.yearofpassRules"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <!-- percentage -->
                    <v-text-field
                      :id="'Percentage'"
                      v-model="Percentage[index]"
                      background-color="#e0e0e0"
                      color="#3cd1c2"
                      outline
                      :disabled="!showEdu[index]"
                      label="Percentage"
                      prepend-icon="trending_up"
                      :rules="
                        Percentage[index]
                          ? $store.state.percentageRules
                          : [true]
                      "
                    ></v-text-field>
                  </v-flex>
                  <!-- line 4 -->
                  <v-flex xs12 md6>
                    <!-- grade -->
                    <v-text-field
                      :id="'Grade'"
                      v-model="Grade[index]"
                      background-color="#e0e0e0"
                      color="#3cd1c2"
                      outline
                      :counter="10"
                      :maxlength="10"
                      :disabled="!showEdu[index]"
                      label="Grade"
                      prepend-icon="bar_chart"
                      :rules="
                        Grade[index]
                          ? $store.state.onlyLetterNumberRules
                          : [true]
                      "
                    ></v-text-field>
                  </v-flex>
                  <!-- file upload -->
                  <v-flex xs12 md6>
                    <v-list v-show="fileName[index]">
                      <v-list-tile>
                        <v-list-tile-action>
                          <v-icon :disabled="!showEdu[index]"
                            >cloud_upload</v-icon
                          >
                        </v-list-tile-action>
                        <v-list-tile-content>
                          <v-list-tile-title class="grey--text">{{
                            fileName[index]
                          }}</v-list-tile-title>
                        </v-list-tile-content>
                        <v-list-tile-action>
                          <v-tooltip bottom>
                            <template #activator="{ on }">
                              <v-btn
                                :id="'ChageDocumentsEducation'"
                                fab
                                small
                                :disabled="!showEdu[index]"
                                class="ml-4"
                                color="warning"
                                v-on="on"
                                @click="pickFile(index, 'education')"
                              >
                                <v-icon
                                  class="right"
                                  :disabled="!showEdu[index]"
                                  >edit</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Change File</span>
                          </v-tooltip>
                        </v-list-tile-action>
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <v-btn
                              :id="'DeleteDocumentsEducation'"
                              fab
                              small
                              :disabled="!showEdu[index]"
                              color="red"
                              v-on="on"
                            >
                              <v-icon
                                class="right white--text"
                                dark
                                :disabled="!showEdu[index]"
                                @click="deleteFile(index, 'education')"
                                >delete</v-icon
                              >
                            </v-btn>
                          </template>
                          <span>Delete File</span>
                        </v-tooltip>
                      </v-list-tile>
                    </v-list>
                    <v-list v-show="!fileName[index]">
                      <v-list-tile>
                        <v-list-tile-action>
                          <v-icon :disabled="!showEdu[index]"
                            >cloud_upload</v-icon
                          >
                        </v-list-tile-action>
                        <v-list-tile-content>
                          <v-list-tile-title class="grey--text"
                            >No File Chosen</v-list-tile-title
                          >
                        </v-list-tile-content>
                        <v-list-tile-action>
                          <v-tooltip bottom>
                            <template #activator="{ on }">
                              <v-btn
                                :id="'AddDocumentsEducation'"
                                fab
                                small
                                :disabled="!showEdu[index]"
                                class="success ml-4"
                                v-on="on"
                                @click="pickFile(index, 'education')"
                              >
                                <v-icon
                                  class="right"
                                  :disabled="!showEdu[index]"
                                  >add</v-icon
                                >
                              </v-btn>
                              <input
                                ref="image"
                                type="file"
                                style="display: none"
                                accept=".png,.jpg,.jpeg,.pdf"
                                @change="onFilePicked"
                              />
                            </template>
                            <span>Add File</span>
                          </v-tooltip>
                        </v-list-tile-action>
                      </v-list-tile>
                    </v-list>
                  </v-flex>
                  <!-- file upload end -->
                  <v-flex xs12 md6>
                    <!-- degree selection -->
                    <v-select
                      v-show="fileName[index]"
                      :id="'Education_Sub_Document_Type'"
                      v-model="EducationSubType[index]"
                      background-color="#e0e0e0"
                      outline
                      :disabled="!showEdu[index]"
                      prepend-icon="list_alt"
                      :items="EducationSubTypeList"
                      item-text="Document_Sub_Type"
                      item-value="Document_Sub_Type_Id"
                      color="#3cd1c2"
                      label="Education Sub Type*"
                      :rules="
                        fileName[index]
                          ? [(v) => !!v || 'This field is required']
                          : [true]
                      "
                      :required="fileName[index] ? true : false"
                    ></v-select>
                  </v-flex>
                  <!-- document name-->
                  <v-flex xs12 md6>
                    <v-text-field
                      v-show="fileName[index]"
                      :id="'Education_document_name'"
                      v-model="EduDocName[index]"
                      background-color="#e0e0e0"
                      color="#3cd1c2"
                      outline
                      :counter="50"
                      :maxlength="50"
                      :disabled="!showEdu[index]"
                      label="Document Name"
                      prepend-icon="folder_shared"
                      :rules="
                        fileName[index] ? $store.state.docNameRules : [true]
                      "
                      :required="fileName[index] ? true : false"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs12 md12>
                    <!-- submit education etaisl -->
                    <v-btn
                      v-show="showEdu[index]"
                      :id="'SaveEducationDetails'"
                      class="success"
                      style="margin: auto"
                      :disabled="isUploadingDocument"
                      @click="saveEducation(index)"
                      >Save</v-btn
                    >
                    <!-- cancel when they don't need -->
                    <v-btn
                      v-show="showEdu[index]"
                      :id="'CancelEducationDetails'"
                      class="ml-2"
                      style="margin: auto"
                      @click="
                        cancelEducation(showEdu, index, addEducation, showEdu)
                      "
                      >Cancel</v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 md2>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <!-- add more educations -->
                <v-btn
                  v-show="
                    addEducation.length === index + 1 ||
                    addEducation.length === 0
                  "
                  :id="'AddEducationDetails'"
                  fab
                  small
                  class="success"
                  dark
                  v-on="on"
                  @click="AddEducation(index, addEducation, showEdu)"
                >
                  <v-icon class="taxt-xs-center">add</v-icon>
                </v-btn>
              </template>
              <span>Add Education Details</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import {
  LIST_COURSE,
  ADD_EDUCATION,
  GET_EDUCATION,
  UPDATE_EDUCATION,
  DELETE_EDUCATION,
  UPLOAD_DOCUMENT,
  EDIT_DOCUMENT,
  DELETE_DOCUMENT,
  LIST_SUB_DOC_TYPE,
} from "../graphql.js";

export default {
  data() {
    return {
      // Education details
      EducationCategory: [],
      EducationSubType: [],
      EducationSubTypeList: [],
      Specialisation: [],
      InstituName: [],
      University: [],
      YearOfPassing: [],
      Percentage: [],
      Grade: [],
      EducationId: [],
      EduDocName: [],
      listCourseDetails: [],
      // copy for backup
      EduCatCopy: [],
      SplCopy: [],
      InsCopy: [],
      UniCopy: [],
      YOPCopy: [],
      PerCopy: [],
      GradeCopy: [],
      fileNameCopy: [],
      DocNameCopy: [],
      SubTypeCopy: [],

      // File Details
      FileFormatEducation: [],
      FileType: "",
      CurrentIndex: "",
      fileName: [],
      isUploadingDocument: false,
      // form count
      addEducation: [0],
      // display add edit
      showEdu: [true],
      // alert properties
      snackbar: false,
      ResponseMessage: "",
      color: "success",
      // enable edit and avoid add
      enableEducationEdit: [false],
      avoidAddEducation: false,
      // minimum date

      // list of courses
      modal_edu: false,
      modal_certi: false,
      modal_train: false,
    };
  },
  computed: {
    courseList() {
      return this.listCourseDetails.courseDetails;
    },
  },

  created: function () {
    // retrieve education subtype list
    this.$apollo
      .query({
        query: LIST_SUB_DOC_TYPE,
        variables: {
          Org_Code: localStorage.getItem("org_code"),
          Url_Hash: localStorage.getItem("url_hash"),
          type_id: 3,
        },
      })
      .then((res) => {
        if (res.data.listDocumentSubType.documentSubType) {
          this.EducationSubTypeList =
            res.data.listDocumentSubType.documentSubType;
        }
      })
      .catch((err) => {
        this.snackbar = true;
        this.ResponseMessage =
          err.graphQLErrors.length !== 0
            ? err.graphQLErrors[0].message
            : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
        this.color = "error";
      });

    // retrieve education details
    this.$apollo
      .query({
        query: GET_EDUCATION,
        variables: {
          Org_Code: localStorage.getItem("org_code"),
          Url_Hash: localStorage.getItem("url_hash"),
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        var edu = res.data.getEducation.education;
        this.addEducation = [];
        if (!edu) {
          this.addEducation = [0];
        } else {
          this.$emit("educationProgress", 35);
          for (var i = 0; i < edu.length; i++) {
            this.avoidAddEducation = true;
            this.addEducation.push(i);
            this.$set(this.showEdu, i, false);
            this.$set(this.EducationCategory, i, edu[i].Education_Type);
            this.$set(
              this.fileName,
              i,
              !edu[i].File_Name ? "" : edu[i].File_Name.split("?")[3]
            );
            this.$set(this.FileFormatEducation, i, edu[i].File_Name);
            this.$set(this.Specialisation, i, edu[i].Specialisation);
            this.$set(this.InstituName, i, edu[i].Institute_Name);
            this.$set(this.University, i, edu[i].University);
            this.$set(this.YearOfPassing, i, edu[i].Year_Of_Passing);
            this.$set(this.Percentage, i, edu[i].Percentage);
            this.$set(this.Grade, i, edu[i].Grade);
            this.$set(this.EducationId, i, edu[i].Education_Id);
            this.$set(this.EducationSubType, i, edu[i].Sub_Type_Id);
            this.$set(this.EduDocName, i, edu[i].Document_Name);
          }
        }
      })
      .catch((err) => {
        this.snackbar = true;
        this.ResponseMessage =
          err.graphQLErrors.length !== 0
            ? err.graphQLErrors[0].message
            : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
        this.color = "error";
      });
  },
  apollo: {
    listCourseDetails: {
      query: LIST_COURSE,
      variables() {
        return {
          Org_Code: localStorage.getItem("org_code"),
          Url_Hash: localStorage.getItem("url_hash"),
        };
      },
      loading: true,
    },
  },
  methods: {
    // enable editing educatin details
    editEducation(index) {
      this.avoidAddEducation = false;
      this.$set(this.enableEducationEdit, index, true);
      this.$set(this.showEdu, index, true);
      this.$set(this.EduCatCopy, index, this.EducationCategory[index]);
      this.$set(this.SplCopy, index, this.Specialisation[index]);
      this.$set(this.InsCopy, index, this.InstituName[index]);
      this.$set(this.UniCopy, index, this.University[index]);
      this.$set(this.YOPCopy, index, this.YearOfPassing[index]);
      this.$set(this.PerCopy, index, this.Percentage[index]);
      this.$set(this.GradeCopy, index, this.Grade[index]);
      this.$set(this.fileNameCopy, index, this.fileName[index]);
      this.$set(this.SubTypeCopy, index, this.EducationSubType[index]);
      this.$set(this.DocNameCopy, index, this.EduDocName[index]);
    },
    //  add more education details
    AddEducation(index, remove_el, remove_val) {
      var index_val = remove_val.length;
      if (
        this.$refs["eduform" + index][0].validate() &&
        this.avoidAddEducation
      ) {
        this.avoidAddEducation = false;
        this.$set(this.showEdu, index_val, true);
        this.$set(this.Specialisation, index_val, "");
        this.$set(this.InstituName, index_val, "");
        this.$set(this.University, index_val, "");
        this.$set(this.YearOfPassing, index_val, "");
        this.$set(this.Percentage, index_val, "");
        this.$set(this.Grade, index_val, "");
        remove_el.push(index_val);
        remove_val[index_val] = true;
      } else {
        this.snackbar = true;
        this.ResponseMessage = "First save your details";
        this.color = "error";
      }
    },
    // cancel education details
    cancelEducation(val, index, remove_el, remove_val) {
      if (this.enableEducationEdit[index]) {
        this.avoidAddEducation = true;
        this.$set(val, index, false);
        this.$set(this.EducationCategory, index, this.EduCatCopy[index]);
        this.$set(this.Specialisation, index, this.SplCopy[index]);
        this.$set(this.InstituName, index, this.InsCopy[index]);
        this.$set(this.University, index, this.UniCopy[index]);
        this.$set(this.Percentage, index, this.PerCopy[index]);
        this.$set(this.YearOfPassing, index, this.YOPCopy[index]);
        this.$set(this.Grade, index, this.GradeCopy[index]);
        this.$set(this.fileName, index, this.fileNameCopy[index]);
        this.$set(this.EducationSubType, index, this.SubTypeCopy[index]);
        this.$set(this.EduDocName, index, this.DocNameCopy[index]);
      } else {
        if (this.addEducation.length !== 1) {
          this.avoidAddEducation = true;
          remove_el.splice(index, 1);
          remove_val.splice(index, 1);
          this.$set(this.Specialisation, index, this.Specialisation[index + 1]);
          this.$set(
            this.EducationCategory,
            index,
            this.EducationCategory[index + 1]
          );
          this.$set(this.InstituName, index, this.InstituName[index + 1]);
          this.$set(this.University, index, this.University[index + 1]);
          this.$set(this.YearOfPassing, index, this.YearOfPassing[index + 1]);
          this.$set(this.Percentage, index, this.Percentage[index + 1]);
          this.$set(this.Grade, index, this.Grade[index + 1]);
          this.$set(this.fileName, index, this.fileName[index + 1]);
        } else {
          this.$set(this.Specialisation, index, "");
          this.$set(this.EducationCategory, index, "");
          this.$set(this.InstituName, index, "");
          this.$set(this.University, index, "");
          this.$set(this.YearOfPassing, index, "");
          this.$set(this.Percentage, index, "");
          this.$set(this.Grade, index, "");
          this.$set(this.fileName, index, "");
          this.$set(this.showEdu, index, true);
          this.$refs["eduform" + index][0].reset();
          remove_val[index] = true;
        }
      }
    },
    // submit education details
    saveEducation(index) {
      if (this.$refs["eduform" + index][0].validate()) {
        if (!this.enableEducationEdit[index]) {
          this.$apollo
            .mutate({
              mutation: ADD_EDUCATION,
              variables: {
                Org_Code: localStorage.getItem("org_code"),
                Url_Hash: localStorage.getItem("url_hash"),
                Education_Type: this.EducationCategory[index],
                Specialisation: !this.Specialisation[index]
                  ? ""
                  : this.Specialisation[index],
                Institute_Name: !this.InstituName[index]
                  ? ""
                  : this.InstituName[index],
                University: !this.University[index]
                  ? ""
                  : this.University[index],
                Year_Of_Passing: this.YearOfPassing[index],
                Percentage: !this.Percentage[index]
                  ? ""
                  : this.Percentage[index],
                Grade: !this.Grade[index] ? "" : this.Grade[index],
                File_Name: this.FileFormatEducation[index],
                Document_Name: this.EduDocName[index],
                Sub_Type_Id: this.EducationSubType[index],
              },
            })
            .then((res) => {
              if (this.addEducation.length === 1) {
                this.$emit("educationProgress", 35);
              }
              this.EducationId = [];
              for (var i = 0; i < res.data.addEducation.education.length; i++) {
                this.$set(
                  this.EducationId,
                  i,
                  res.data.addEducation.education[i].Education_Id
                );
              }
              this.avoidAddEducation = true;
              this.$set(this.showEdu, index, false);
              this.snackbar = true;
              this.ResponseMessage = "Added Successfully";
              this.color = "success";
            })
            .catch((err) => {
              this.snackbar = true;
              this.ResponseMessage =
                err.graphQLErrors.length !== 0
                  ? err.graphQLErrors[0].message
                  : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
              this.color = "error";
            });
        } else {
          this.$apollo
            .mutate({
              mutation: UPDATE_EDUCATION,
              variables: {
                Org_Code: localStorage.getItem("org_code"),
                Url_Hash: localStorage.getItem("url_hash"),
                Education_Id: this.EducationId[index],
                Education_Type: this.EducationCategory[index],
                Specialisation: this.Specialisation[index],
                Institute_Name: this.InstituName[index],
                University: this.University[index],
                Year_Of_Passing: this.YearOfPassing[index],
                Percentage: this.Percentage[index],
                Grade: this.Grade[index],
                File_Name: this.FileFormatEducation[index],
                Sub_Type_Id: this.EducationSubType[index],
                Document_Name: this.EduDocName[index],
              },
            })
            .then(() => {
              this.snackbar = true;
              this.$set(this.enableEducationEdit, index, false);
              this.ResponseMessage = "Updated Successfully";
              this.color = "success";
              this.avoidAddEducation = true;
              this.$set(this.showEdu, index, false);
            })
            .catch((err) => {
              this.snackbar = true;
              this.ResponseMessage =
                err.graphQLErrors.length !== 0
                  ? err.graphQLErrors[0].message
                  : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
              this.color = "error";
            });
        }
      }
    },
    // delete education details
    deleteEducation(index, remove_el, remove_val) {
      this.$apollo
        .mutate({
          mutation: DELETE_EDUCATION,
          variables: {
            Org_Code: localStorage.getItem("org_code"),
            Url_Hash: localStorage.getItem("url_hash"),
            Education_Id: this.EducationId[index],
          },
        })
        .then(() => {
          this.snackbar = true;
          this.ResponseMessage = "Deleted Successfully";
          this.color = "success";
          this.EducationId.splice(index, 1);

          if (this.addEducation.length !== 1) {
            remove_el.splice(index, 1);
            remove_val.splice(index, 1);

            this.$set(
              this.Specialisation,
              index,
              this.Specialisation[index + 1]
            );
            this.$set(
              this.EducationCategory,
              index,
              this.EducationCategory[index + 1]
            );
            this.$set(this.InstituName, index, this.InstituName[index + 1]);
            this.$set(this.University, index, this.University[index + 1]);
            this.$set(this.YearOfPassing, index, this.YearOfPassing[index + 1]);
            this.$set(this.Percentage, index, this.Percentage[index + 1]);
            this.$set(this.Grade, index, this.Grade[index + 1]);
            this.$set(this.fileName, index, this.fileName[index + 1]);
            this.$set(this.EduDocName, index, this.EduDocName[index + 1]);
            this.$set(
              this.EducationSubType,
              index,
              this.EducationSubType[index + 1]
            );
          } else {
            this.$emit("educationProgress", -35);
            this.$set(this.Specialisation, index, "");
            this.$set(this.EducationCategory, index, "");
            this.$set(this.InstituName, index, "");
            this.$set(this.University, index, "");
            this.$set(this.YearOfPassing, index, "");
            this.$set(this.Percentage, index, "");
            this.$set(this.Grade, index, "");
            this.$set(this.fileName, index, "");
            this.$set(this.EduDocName, index, "");
            this.$set(this.EducationSubType, index, "");
            this.$set(this.showEdu, index, true);
            this.$refs["eduform" + index][0].reset();
            remove_val[index] = true;
          }
        })
        .catch((err) => {
          this.snackbar = true;
          this.ResponseMessage =
            err.graphQLErrors.length !== 0
              ? err.graphQLErrors[0].message
              : "Something went wrong.It could be a posibility due to Network Connectivity Error.";
          this.color = "error";
        });
    },
    //enable file picker
    pickFile(index, type) {
      this.CurrentIndex = index;
      this.FileType = type;
      this.$refs.image[index].click();
    },
    // to fetch file details
    onFilePicked(e) {
      const files = e.target.files;
      var fileContent;
      // reader.readAsDataURL(files);
      if (files[0] !== undefined) {
        // this.fileName[this.CurrentIndex] = files[0].name;
        if (files[0].name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          fileContent = fr.result.split(",");
          if (this.FileType === "education") {
            this.updateEdu(files[0].name, fileContent);
          }
        });
      } else {
        this.fileName[this.CurrentIndex] = "";
      }
    },
    // document upload for education
    updateEdu(filename, fileContent) {
      if (!this.fileName[this.CurrentIndex]) {
        this.isUploadingDocument = true;
        this.$apollo
          .query({
            query: UPLOAD_DOCUMENT,
            variables: {
              File_Name: filename,
              Category: "document",
              File_Content: fileContent[1],
              Org_Code: localStorage.getItem("org_code"),
              Url_Hash: localStorage.getItem("url_hash"),
            },
          })
          .then((response) => {
            this.snackbar = true;
            this.ResponseMessage = "File Uploaded";
            this.color = "success";
            this.$set(this.fileName, this.CurrentIndex, filename);
            this.FileFormatEducation[this.CurrentIndex] =
              response.data.fileUpload.fileName;
            this.isUploadingDocument = false;
          })
          .catch((err) => {
            this.snackbar = true;
            this.ResponseMessage =
              err.graphQLErrors.length !== 0
                ? err.graphQLErrors[0].message
                : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
            this.color = "error";
            this.isUploadingDocument = false;
          });
      } else {
        this.isUploadingDocument = true;
        this.$apollo
          .query({
            query: EDIT_DOCUMENT,
            variables: {
              New_File_Name: filename,
              Category: "document",
              File_Name: this.FileFormatEducation[this.CurrentIndex],
              New_File_Content: fileContent[1],
              Org_Code: localStorage.getItem("org_code"),
              Url_Hash: localStorage.getItem("url_hash"),
            },
          })
          .then((response) => {
            this.snackbar = true;
            this.ResponseMessage = "File Uploaded";
            this.color = "success";
            this.$set(this.fileName, this.CurrentIndex, filename);
            this.FileFormatEducation[this.CurrentIndex] =
              response.data.fileReplace.fileName;
            this.isUploadingDocument = false;
          })
          .catch((err) => {
            this.snackbar = true;
            this.ResponseMessage =
              err.graphQLErrors.length !== 0
                ? err.graphQLErrors[0].message
                : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
            this.color = "error";
            this.isUploadingDocument = false;
          });
      }
    },
    // delete documents for all details
    deleteFile(index, FileType) {
      if (FileType === "education") {
        this.isUploadingDocument = true;
        this.$apollo
          .query({
            query: DELETE_DOCUMENT,
            variables: {
              Org_Code: localStorage.getItem("org_code"),
              Url_Hash: localStorage.getItem("url_hash"),
              File_Name: this.FileFormatEducation[index],
              Category: "document",
            },
          })
          .then(() => {
            this.snackbar = true;
            this.ResponseMessage = "File Deleted";
            this.color = "success";
            this.$set(this.fileName, index, "");
            this.$set(this.EduDocName, index, "");
            this.$set(this.EducationSubType, index, "");
            this.isUploadingDocument = false;
          })
          .catch((err) => {
            this.snackbar = true;
            this.ResponseMessage =
              err.graphQLErrors.length !== 0
                ? err.graphQLErrors[0].message
                : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
            this.color = "error";
            this.isUploadingDocument = false;
          });
      }
    },
  },
};
</script>
