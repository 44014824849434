<template>
  <v-container>
    <!-- heading -->
    <v-layout justify-center nowrap>
      <p class="mt-4 mb-4 title">Certification Information</p>
    </v-layout>
    <!-- sections spliting for certification,training,education -->
    <div id="app">
      <v-container grid-list-md>
        <v-layout nowrap>
          <v-flex v-for="Portion in Portions" :key="Portion.name" md4>
            <v-card class="mb-1 pointerClass">
              <v-card-text
                :id="Portion.name"
                :class="{
                  active: portionName === Portion.name,
                  notactive: portionName !== Portion.name,
                }"
                dark
                @click="proceedNextPortion(Portion.name)"
              >
                <v-layout align-center justify-center column>
                  <v-flex md12>
                    <v-icon
                      :class="{
                        activeIcon: portionName === Portion.name,
                        notactiveIcon: portionName !== Portion.name,
                      }"
                      >{{ Portion.icon }}</v-icon
                    >
                  </v-flex>
                  <v-flex md12 class="hidden-sm-and-down">
                    <h3
                      style="font-size: 0.9em"
                      :class="{ textClassActive: portionName === Portion.name }"
                    >
                      {{ Portion.name }}
                    </h3>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <transition name="fade">
      <education-card
        v-show="portionName === 'Education'"
        @educationProgress="educationPrpgressMethod($event)"
      />
    </transition>
    <transition name="fade">
      <certificate-card
        v-show="portionName === 'Certification'"
        @certificateProgress="certificatePrpgressMethod($event)"
      />
    </transition>
    <transition name="fade">
      <training-card
        v-show="portionName === 'Training'"
        @trainingProgress="trainingPrpgressMethod($event)"
      />
    </transition>
  </v-container>
</template>

<script>
import EducationCard from "./Education.vue";
import CertificateCard from "./Certificate.vue";
import TrainingCard from "./Training.vue";

export default {
  components: {
    "education-card": EducationCard,
    "certificate-card": CertificateCard,
    "training-card": TrainingCard,
  },

  data() {
    return {
      //portions
      portionName: "Education",
      Portions: [
        {
          name: "Education",
          icon: "account_box",
        },
        {
          name: "Certification",
          icon: "group",
        },
        {
          name: "Training",
          icon: "folder_shared",
        },
      ],
      // alert properties
      snackbar: false,
      ResponseMessage: "",
      color: "success",
    };
  },
  methods: {
    // move to next card
    proceedNextPortion(portionname) {
      this.portionName = portionname;
    },
    educationPrpgressMethod(val) {
      this.$emit("cerProcessChanged", val);
    },
    certificatePrpgressMethod(val) {
      this.$emit("cerProcessChanged", val);
    },
    trainingPrpgressMethod(val) {
      this.$emit("cerProcessChanged", val);
    },
  },
};
</script>

<style scoped>
.ps {
  height: 72ex;
}
.active {
  border-bottom: 4px solid;
  border-image-source: linear-gradient(45deg, #38d3c4, #1362a3);
  border-image-slice: 1;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
}
.textClassActive {
  background: linear-gradient(to right, #38d3c4, #1362a3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.notactive {
  color: darkgray;
}
.activeIcon {
  background: linear-gradient(to right, #38d3c4, #1362a3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.notactiveIcon {
  color: #888888;
}
.pointerClass {
  cursor: pointer;
}
.v-card__text {
  padding: 0.7em !important;
  width: 100%;
}
</style>
