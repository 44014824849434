<template>
  <v-container fluid fill-height style="background: #312f5f">
    <!-- alerts -->
    <v-snackbar v-model="snackbar" :color="color" multi-line :timeout="3000">
      {{ ResponseMessage }}
      <v-btn dark flat @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-layout justify-center>
      <!-- animations -->
      <v-flex md6 class="hidden-sm-and-down">
        <!--FLOOR-->
        <div id="floor"></div>
        <!--END OF FLOOR-->
        <div id="scene">
          <!--CLOCK-->
          <div id="clock">
            <div id="clock-face">
              <div id="clock-marker-12-6" class="clock-marker"></div>
              <div id="clock-marker-1-7" class="clock-marker"></div>
              <div id="clock-marker-2-8" class="clock-marker"></div>
              <div id="clock-marker-3-9" class="clock-marker"></div>
              <div id="clock-marker-4-10" class="clock-marker"></div>
              <div id="clock-marker-5-11" class="clock-marker"></div>
              <div id="clock-inner"></div>
              <div class="hand hour"></div>
              <div class="hand minute"></div>
              <div class="hand second"></div>
              <div id="clock-dot"></div>
            </div>
          </div>
          <!--END OF CLOCK-->

          <!--BOOK STACK-->
          <div id="stack-book">
            <div id="stack-book-1"></div>
            <div class="stack-book-2"></div>
            <div class="stack-book-3"></div>
            <div class="stack-book-4"></div>
            <div id="stack-book-5"></div>
            <div class="stack-book-6"></div>
            <div class="stack-book-7"></div>
            <div id="stack-book-8"></div>
            <div class="stack-book-9"></div>
            <div id="stack-book-10"></div>
            <div id="stack-book-11"></div>
            <div id="stack-book-12"></div>
          </div>
          <!--END OF BOOK STACK-->

          <!--BINDER STACK-->
          <div id="stack-binder">
            <div id="stack-binder-1">
              <div id="stack-binder-1-bind" class="stack-binder-right-bind">
                <div class="stack-binder-right-top stack-binder-1-tb"></div>
                <div class="stack-binder-right-ring">
                  <div class="stack-binder-right-paper"></div>
                </div>
                <div class="stack-binder-right-bottom stack-binder-1-tb"></div>
              </div>
            </div>
            <div id="stack-binder-2">
              <div class="stack-binder-left-bind stack-binder-4-bind">
                <div class="stack-binder-left-top stack-binder-4-tb"></div>
                <div class="stack-binder-left-ring">
                  <div class="stack-binder-left-paper"></div>
                </div>
                <div class="stack-binder-left-bottom stack-binder-4-tb"></div>
              </div>
            </div>
            <div id="stack-binder-3">
              <div id="stack-binder-3-bind" class="stack-binder-right-bind">
                <div class="stack-binder-right-top stack-binder-3-tb"></div>
                <div class="stack-binder-right-ring">
                  <div class="stack-binder-right-paper"></div>
                </div>
                <div class="stack-binder-right-bottom stack-binder-3-tb"></div>
              </div>
            </div>
            <div id="stack-binder-4">
              <div class="stack-binder-left-bind stack-binder-4-bind">
                <div class="stack-binder-left-top stack-binder-4-tb"></div>
                <div class="stack-binder-left-ring">
                  <div class="stack-binder-left-paper"></div>
                </div>
                <div class="stack-binder-left-bottom stack-binder-4-tb"></div>
              </div>
            </div>
          </div>
          <!--END OF BINDER STACK-->

          <!--PENCIL HOLDER CONTENTS-->
          <div id="pencil-holder-contents">
            <div id="pencil-cup">
              <div id="heart"></div>
            </div>
            <div id="pencil-holder">
              <div id="ruler">
                <div id="ruler-inner"></div>
              </div>
              <div id="pencil-1">
                <div class="pencil-tip">
                  <div class="pencil-tip-top"></div>
                </div>
              </div>
              <div id="pencil-2">
                <div class="pencil-tip">
                  <div class="pencil-tip-top"></div>
                </div>
              </div>
            </div>
          </div>
          <!--END OF PENCIL HOLDER CONTENTS-->

          <!--COFFEE CUP-->
          <div id="coffee-cup">
            <div id="coffee-cup-top">
              <div id="coffee-cup-rim"></div>
            </div>
            <div id="coffee-cup-bottom">
              <div id="coffee-cup-holder">
                <div id="coffee-cup-logo"></div>
              </div>
              <p class="cup">Jackie</p>
            </div>
          </div>
          <!--END OF COFFEE CUP-->

          <!--BOOK STACK-->
          <div id="stack-book-small">
            <div class="stack-book-2"></div>
            <div class="stack-book-4"></div>
            <div class="stack-book-3"></div>
            <div class="stack-book-7"></div>
            <div class="stack-book-6"></div>
            <div class="stack-book-9"></div>
          </div>
          <!--END OF BOOK STACK-->

          <!--TAKE-OUT-->
          <div id="take-out">
            <div id="take-out-box">
              <div id="take-out-box-top"></div>
              <div id="take-out-box-bottom">
                <div id="take-out-box-handle">
                  <div id="take-out-box-handle-dot"></div>
                </div>
              </div>
              <div id="take-out-box-bottom-back">
                <p class="box">
                  Chinese
                  <br />Food
                </p>
              </div>
            </div>
            <div id="chopsticks">
              <div id="chopsticks-1"></div>
              <div id="chopsticks-2"></div>
            </div>
          </div>
          <!--END OF TAKE-OUT-->

          <!--COMPUTER-->
          <div id="computer">
            <div id="computer-side">
              <div id="computer-back">
                <div id="computer-logo"></div>
                <div id="computer-logo-bite"></div>
                <div id="computer-logo-stem"></div>
                <div id="computer-logo-leaf"></div>
              </div>
            </div>
            <div id="computer-stand">
              <div id="computer-stand-shadow"></div>
              <div id="computer-stand-hole"></div>
              <div id="computer-cable"></div>
            </div>
            <div id="computer-stand-bottom"></div>
            <div id="computer-stand-curve"></div>
          </div>
          <!--END OF COMPUTER-->

          <!--CHAIR-->
          <div id="chair">
            <div id="chair-seat">
              <div id="chair-arm-left"></div>
              <div id="chair-back"></div>
              <div id="chair-spine"></div>
              <div id="chair-pole">
                <div id="chair-rod"></div>
              </div>
              <div id="chair-arm-right"></div>
              <div id="chair-legs">
                <div id="chair-leg-left">
                  <div id="left-wheel"></div>
                </div>
                <div id="chair-leg-middle">
                  <div id="middle-wheel"></div>
                </div>
                <div id="chair-leg-right">
                  <div id="right-wheel"></div>
                </div>
              </div>
            </div>
          </div>
          <!--END OF CHAIR-->

          <!--SHELF 1-->
          <div id="shelf-area">
            <div id="shelf-1">
              <div id="shelf-1-book-1">
                <div id="shelf-1-book-1-line-1"></div>
                <div id="shelf-1-book-1-line-2"></div>
                <div id="shelf-1-book-1-line-3"></div>
              </div>
              <div id="shelf-1-book-2">
                <div id="shelf-1-book-2-line-1"></div>
                <div id="shelf-1-book-2-line-2"></div>
                <div id="shelf-1-book-2-line-3"></div>
              </div>
              <div id="shelf-1-book-3"></div>
              <div id="shelf-1-book-4"></div>
              <div id="plant">
                <div id="cactus-body">
                  <div id="cactus-arm-left">
                    <div id="cactus-arm-left-up"></div>
                  </div>
                  <div id="cactus-arm-right">
                    <div id="cactus-arm-right-up"></div>
                  </div>
                </div>
                <div id="plant-pot"></div>
              </div>
            </div>
          </div>
          <!--END OF SHELF 2-->

          <!--SHELF 2-->
          <div id="shelf-area-2">
            <div id="shelf-2">
              <div id="shelf-2-box">
                <div id="box-heart"></div>
              </div>
              <div id="shelf-2-book-1">
                <div id="shelf-2-book-1-line-1"></div>
                <div id="shelf-2-book-1-line-2"></div>
                <div id="shelf-2-book-1-line-3"></div>
              </div>
              <div id="shelf-2-book-2"></div>
              <div id="tape-dispenser">
                <div id="tape-roll"></div>
                <div id="tape-body">
                  <div id="tape-legs"></div>
                  <div id="tape-hole"></div>
                </div>
              </div>
            </div>
          </div>
          <!--END OF SHELF 2-->

          <!--PICTURES-->
          <div id="picture">
            <div id="picture-1"></div>
            <div id="picture-2"></div>
            <div id="picture-3"></div>
          </div>
          <!--END OF PICTURES-->

          <!--POST-ITS-->
          <div id="note-1" class="drag" draggable="true">
            <p class="note">BUY MILK</p>
          </div>
          <div id="note-2" class="drag" draggable="true">
            <p class="note">CALL MOM</p>
          </div>
          <div id="note-3" class="drag" draggable="true">
            <p class="note">FEED CATS</p>
          </div>
          <div id="note-4" class="drag" draggable="true">
            <p class="note">WATER CACTUS</p>
          </div>
          <!--END OF POST-ITS-->

          <!--PHOTOS-->
          <div id="photo-1" class="drag">
            <p class="photo">CAT LADY</p>
          </div>
          <!--END OF PHOTOS-->

          <!--DESK-->
          <div id="desk">
            <div id="desk-top-1"></div>
            <div id="desk-top-2"></div>
            <div id="desk-top-3">
              <div id="desk-top-3-left"></div>
              <div id="desk-top-3-right"></div>
            </div>
            <div id="desk-leg-left"></div>
            <div id="desk-leg-right"></div>
          </div>
          <!--END OF DESK-->

          <!--BIG BOX-->
          <div id="big-box">
            <div id="big-box-body">
              <div id="big-box-shadow"></div>
              <div id="big-box-hole"></div>
            </div>
            <div id="box-folder"></div>
            <div id="folder-paper">
              <p class="paper">
                Dear Jackie,
                <br />
                <br />Please bring home some chicken!
                <br />
                <br />
                <br />Love, <br />Your cats
              </p>
            </div>
            <div id="folder-paper-2">
              <p class="paper">
                Grocery List
                <br />
                <br />Eggs <br />Butter <br />Flour <br />Coffee <br />Bread
              </p>
            </div>
          </div>
          <!--END OF BIG BOX-->

          <!--WALL OUTLET-->
          <div id="outlet">
            <div class="outlet-face"></div>
            <div class="outlet-face"></div>
          </div>
          <!--END OF WALL OUTLET-->

          <!--BAG-->
          <div id="bag">
            <div id="bag-body">
              <div id="bag-logo"></div>
              <p class="bag">Groceries</p>
              <div id="bag-handle-front"></div>
              <div id="bag-handle-back"></div>
              <div id="bag-side"></div>
            </div>
          </div>
          <!--END OF BAG-->

          <!--FLOOR BINDER-->
          <div id="floor-binder">
            <div id="floor-binder-1"></div>
            <div id="floor-binder-2"></div>
          </div>
          <!--END OF FLOOR BINDER-->
        </div>
      </v-flex>
      <v-flex xs12 sm8 md6>
        <v-card flat class="cardheight">
          <v-card-text>
            <v-layout justify-center>
              <!-- logo -->
              <img src="logo.png" alt="logo" class="logoimage mb-4" />
            </v-layout>
            <h2 class="display-1 font-weight-bold fromTop mb-4">
              {{ companyName }}
            </h2>
            <p class="text-xs-center subheading mb-4 grey--text">
              User Onboarding
            </p>
            <v-layout justify-center>
              <v-flex md6 class="mt-3 mb-1">
                <!-- name field -->
                <v-text-field
                  :id="'SigninName'"
                  v-model="Name"
                  v-validate="{ max: 50, required: true, alpha_spaces: true }"
                  prepend-icon="person"
                  color="#3cd1c2"
                  background-color="#e0e0e0"
                  outline
                  label="Name"
                  :counter="50"
                  :maxlength="50"
                  :error-messages="errors.first('Name')"
                  data-vv-name="Name"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout justify-center>
              <v-flex md6 class="mb-2">
                <!-- pin field -->
                <v-text-field
                  :id="'PinPassword'"
                  v-model="Pin"
                  v-validate="{ max: 6, min: 6, required: true, numeric: true }"
                  color="#3cd1c2"
                  prepend-icon="lock"
                  name="password"
                  type="password"
                  label="Pin"
                  background-color="#e0e0e0"
                  outline
                  :counter="6"
                  :maxlength="6"
                  :error-messages="errors.first('Pin')"
                  data-vv-name="Pin"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-layout justify-center>
              <!-- submit buuton -->
              <v-btn
                :id="'Login'"
                color="#3cd1c2"
                dark
                :loading="loader"
                @click="signin"
                >LogIn</v-btn
              >
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- Dialog to show invalid url -->
    <v-dialog
      v-model="invaliddialog"
      persistent
      transition="dialog-bottom-transition"
      :width="dialodWidth"
    >
      <v-card class="text-xs-center" style="border-radius: 20px">
        <v-card-text>
          <h3 class="display-1 mt-3 font-weight-medium">
            {{ Popup_Title }}
          </h3>
          <div class="sea">
            <div class="circle-wrapper">
              <div class="bubble"></div>
              <div class="submarine-wrapper">
                <div class="submarine-body">
                  <div class="window"></div>
                  <div class="engine"></div>
                  <div class="light"></div>
                </div>
                <div class="helix"></div>
                <div class="hat">
                  <div class="leds-wrapper">
                    <div class="periscope"></div>
                    <div class="leds"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p class="mt-4">
            Please provide a valid URL, which is provided by your organization
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- dialog to show already submitted response -->
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      :width="findDisplay"
    >
      <v-card class="text-xs-center" style="border-radius: 20px">
        <v-card-text>
          <v-container class="hidden-md-and-up">
            <div class="success-checkmark">
              <div class="check-icon">
                <span class="icon-line line-tip"></span>
                <span class="icon-line line-long"></span>
                <div class="icon-circle"></div>
                <div class="icon-fix"></div>
              </div>
            </div>
          </v-container>
          <h3 class="font-weight-medium display-1">{{ Dialog_Message }}</h3>

          <v-container class="hidden-sm-and-down">
            <div class="birds">
              <div class="birds__hatdove">
                <div class="birds__hatdove-shadow"></div>
                <div class="birds__hatdove-head">
                  <div class="birds__hatdove-hat"></div>
                  <div class="birds__hatdove-forehead"></div>
                  <div class="birds__hatdove-eye"></div>
                  <div class="birds__hatdove-eye"></div>
                  <div class="birds__hatdove-beak"></div>
                </div>
                <div class="birds__hatdove-backwing"></div>
                <div class="birds__circles-1"></div>
                <div class="birds__hatdove-backleg">
                  <div class="birds__curly"></div>
                </div>
                <div class="birds__hatdove-body"></div>
                <div class="birds__hatdove-frontleg">
                  <div class="birds__curly"></div>
                </div>
                <div class="birds__hatdove-frontwing"></div>
                <div class="birds__circles-2"></div>
                <div class="birds__hatdove-frontwing-finger"></div>
                <div class="birds__hatdove-frontwing-finger"></div>
                <div class="birds__hatdove-frontwing-finger"></div>
              </div>
              <div class="birds__table">
                <div class="birds__table-shadow"></div>
              </div>
              <div class="birds__laptop"></div>
              <div class="birds__laptop">
                <div class="birds__monitor">
                  <div class="birds__code"></div>
                </div>
              </div>
              <div class="birds__coffee"></div>
              <div class="birds__feather"></div>
              <div class="birds__feather"></div>
              <div class="birds__rundove-shadow"></div>
              <div class="birds__rundove">
                <div class="birds__rundove-backwing">
                  <div class="birds__finger"></div>
                  <div class="birds__finger"></div>
                  <div class="birds__finger"></div>
                  <div class="birds__circles"></div>
                </div>
                <div class="birds__rundove-head">
                  <div class="birds__rundove-eye"></div>
                  <div class="birds__rundove-eye"></div>
                  <div class="birds__rundove-beak"></div>
                </div>
                <div class="birds__rundove-backleg">
                  <div class="birds__curly"></div>
                </div>
                <div class="birds__rundove-body"></div>
                <div class="birds__rundove-frontleg">
                  <div class="birds__curly"></div>
                </div>
                <div class="birds__rundove-frontwing">
                  <div class="birds__finger"></div>
                  <div class="birds__finger"></div>
                  <div class="birds__finger"></div>
                  <div class="birds__circles"></div>
                </div>
              </div>
            </div>
          </v-container>
          <div>Your submission is under review.</div>
          <div>
            Please contact
            <span class="font-weight-medium">{{ companyName }}</span> for more
            information
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { SIGN_IN, URL_EXPIRATION } from "../graphql.js";
import queryString from "querystring";

export default {
  data() {
    return {
      Name: "",
      Pin: "",
      color: "success",
      ResponseMessage: "",
      snackbar: false,
      dialog: false,
      loader: false,
      invaliddialog: false,
      Popup_Title: "",
      Dialog_Message: "",
      companyName: "",
      orgCode: "",
      urlHash: ""
    };
  },
  computed: {
    findDisplay() {
      var disp;
      if (this.$vuetify.breakpoint.md) {
        disp = "83%";
      } else if (this.$vuetify.breakpoint.lg) {
        disp = "63%";
      } else if (this.$vuetify.breakpoint.xs) {
        disp = "90%";
      } else {
        disp = "50%";
      }
      return disp;
    },
    dialodWidth() {
      var wid;
      if (this.$vuetify.breakpoint.xs) {
        wid = "100%";
      } else if (this.$vuetify.breakpoint.sm) {
        wid = "50%";
      } else if (this.$vuetify.breakpoint.md) {
        wid = "45%";
      } else {
        wid = "35%";
      }
      return wid;
    },
  },
  created() {
    this.$store.commit("Drawer", false);
    //  for check the url is valid or not
    var parsedValue = queryString.parse(window.location.search);
    console.log(window.location.search)
    let queryParams = Object.keys(parsedValue);
    if (queryParams.length > 0) {
      if(queryParams.length === 1) {
        let url = window.location.href;
        let index = url.indexOf("?");
        let decodeParam = url.substr(index + 1);
        let leftUrl = url.substr(0, index);
        let buff = Buffer.from(decodeParam, "base64");
        let decodedData = buff.toString("ascii");
        let url_string = leftUrl + "?" + decodedData;
        url_string = new URL(url_string);
        parsedValue = queryString.parse(url_string.search);
      }
      this.orgCode = parsedValue.code;
      this.urlHash = parsedValue.identifier;
      this.companyName = parsedValue["?companyName"];
      this.$apollo
        .query({
          query: URL_EXPIRATION,
          variables: {
            Org_Code: this.orgCode,
            Url_Hash: this.urlHash,
          },
        })
        .then(() => {})
        .catch((err) => {
          if (
            err.graphQLErrors.length !== 0 &&
            err.graphQLErrors[0].extensions.code
          ) {
            if (
              err.graphQLErrors[0].extensions.code === "REO0101" ||
              err.graphQLErrors[0].extensions.code === "REO0102" ||
              err.graphQLErrors[0].extensions.code === "REO0105" ||
              err.graphQLErrors[0].extensions.code === "REO0106"
            ) {
              this.invaliddialog = true;
              this.Popup_Title = err.graphQLErrors[0].message;
            } else if (
              err.graphQLErrors[0].extensions.code === "REO0103" ||
              err.graphQLErrors[0].extensions.code === "REO0104"
            ) {
              this.dialog = true;
              this.Dialog_Message = err.graphQLErrors[0].message;
            }
          } else {
            this.snackbar = true;
            this.ResponseMessage =
              err.graphQLErrors.length !== 0
                ? err.graphQLErrors[0].message
                : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
            this.color = "error";
          }
        });
    } else {
      this.invaliddialog = true;
      this.Popup_Title = "Invalid URL";
    }
  },
  methods: {
    // authentication function
    signin() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.loader = true;
          this.$apollo
            .query({
              query: SIGN_IN,
              variables: {
                Pin: parseInt(this.Pin, 10),
                Org_Code: this.orgCode,
                Url_Hash: this.urlHash,
              },
            })
            .then(() => {
              this.snackbar = true;
              this.ResponseMessage = "Login Success";
              this.color = "success";
              this.loader = false;

              localStorage.setItem("url_param", window.location.search);
              localStorage.setItem("Name", this.Name);
              localStorage.setItem("org_code", this.orgCode);
              localStorage.setItem("url_hash", this.urlHash);
              localStorage.setItem("companyName", this.companyName);
              this.$router.push({ name: "Project" });
            })
            .catch((err) => {
              this.loader = false;
              this.snackbar = true;
              this.ResponseMessage =
                err.graphQLErrors.length !== 0
                  ? err.graphQLErrors[0].message
                  : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
              this.color = "error";
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.logoimage {
  padding-top: 14%;
  width: 25%;
  height: 15%;
}
.fromTop {
  padding-top: 5%;
  color: #312f5f !important;
}
.imageback {
  width: 100%;
  height: 100%;
}
.cardheight {
  height: 100%;
}
.formwidth {
  width: 50%;
}
.container {
  padding: 0px !important;
}
/* success tick*/
/**
 * Extracted from: SweetAlert
 * Modified by: Istiak Tridip
 */
.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;
}
.success-checkmark .check-icon {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  border: 4px solid #4caf50;
}
.success-checkmark .check-icon::before {
  top: 3px;
  left: -2px;
  width: 30px;
  transform-origin: 100% 50%;
  border-radius: 100px 0 0 100px;
}
.success-checkmark .check-icon::after {
  top: 0;
  left: 30px;
  width: 60px;
  transform-origin: 0 50%;
  border-radius: 0 100px 100px 0;
  animation: rotate-circle 4.25s ease-in;
}
.success-checkmark .check-icon::before,
.success-checkmark .check-icon::after {
  content: "";
  height: 100px;
  position: absolute;
  background: #ffffff;
  transform: rotate(-45deg);
}
.success-checkmark .check-icon .icon-line {
  height: 5px;
  background-color: #4caf50;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
}
.success-checkmark .check-icon .icon-line.line-tip {
  top: 46px;
  left: 14px;
  width: 25px;
  transform: rotate(45deg);
  animation: icon-line-tip 0.75s;
}
.success-checkmark .check-icon .icon-line.line-long {
  top: 38px;
  right: 8px;
  width: 47px;
  transform: rotate(-45deg);
  animation: icon-line-long 0.75s;
}
.success-checkmark .check-icon .icon-circle {
  top: -4px;
  left: -4px;
  z-index: 10;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  border: 4px solid rgba(76, 175, 80, 0.5);
}
.success-checkmark .check-icon .icon-fix {
  top: 8px;
  width: 5px;
  left: 26px;
  z-index: 1;
  height: 85px;
  position: absolute;
  transform: rotate(-45deg);
  background-color: #ffffff;
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}
@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

#ajerez {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 9999;
}
#ajerez img {
  box-sizing: border-box;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 2px solid #7482a5;
  transition: border-radius 0.2s ease-in-out;
}
#ajerez img:hover {
  border-radius: 30%;
}

body {
  background-color: #306d85;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.sea {
  margin: 10px auto 0 auto;
  overflow: hidden;
}
.sea .bubble {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #6bafca;
  opacity: 0.9;
  -webkit-animation: bubble1-h-movement 1s ease-in infinite,
    bubble1-v-movement 300ms ease-in-out infinite alternate,
    bubble-scale-movement 300ms ease-in-out infinite alternate;
  animation: bubble1-h-movement 1s ease-in infinite,
    bubble1-v-movement 300ms ease-in-out infinite alternate,
    bubble-scale-movement 300ms ease-in-out infinite alternate;
}
.sea .bubble:after {
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #6bafca;
  opacity: 0.9;
}
.sea .bubble:after {
  top: -20;
  left: 100px;
  width: 9px;
  height: 9px;
}
.sea .circle-wrapper {
  position: relative;
  background: linear-gradient(#2c647a, #204858);
  width: 240px;
  height: 250px;
  margin: 10px auto 0 auto;
  overflow: hidden;
  z-index: 0;
  border-radius: 50%;
  padding: 0 50px 0 50px;
  border: 6px solid #3e8caa;
}
.sea .submarine-wrapper {
  height: 300px;
  width: 300px;
  padding: 30px 50px 30px 150px;
  margin: 0 auto 0 auto;
  -webkit-animation: diving 3s ease-in-out infinite,
    diving-rotate 3s ease-in-out infinite;
  animation: diving 3s ease-in-out infinite,
    diving-rotate 3s ease-in-out infinite;
}
.sea .submarine-wrapper .submarine-body {
  width: 150px;
  height: 80px;
  position: absolute;
  margin-top: 50px;
  left: 15px;
  background-color: #d93a54;
  border-radius: 40px;
  background: linear-gradient(#d93a54, #bc243d);
}
.sea .submarine-wrapper .submarine-body .light {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 40px 150px 40px;
  border-color: transparent transparent #377c98 transparent;
  -webkit-transform: rotate(-50deg);
  transform: rotate(-50deg);
  top: 40px;
  left: 99%;
}
.sea .submarine-wrapper .submarine-body .light:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 13px;
  border-radius: 5px;
  background-color: #d12844;
  margin-left: -10px;
}
.sea .submarine-wrapper .submarine-body .window {
  width: 37px;
  height: 37px;
  position: absolute;
  margin-top: 23px;
  right: 18px;
  background: linear-gradient(#1e4554, #183642);
  border-radius: 50%;
  border: 3px solid #d93a54;
}
.sea .submarine-wrapper .submarine-body .window:after {
  content: "";
  position: absolute;
  margin-top: 3px;
  margin-left: 3px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: transparent;
  opacity: 0.8;
  border-top: 3px solid white;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sea .submarine-wrapper .submarine-body .engine {
  width: 30px;
  height: 30px;
  position: absolute;
  margin-top: 32px;
  left: 53px;
  background-color: #bc243d;
  border-radius: 50%;
  border: 5px solid #d93a54;
}
.sea .submarine-wrapper .submarine-body .engine:after,
.sea .submarine-wrapper .submarine-body .engine:before {
  position: absolute;
  content: "";
  border-radius: 2px;
  background-color: white;
  -webkit-animation: spin 900ms linear infinite;
  animation: spin 900ms linear infinite;
  opacity: 0.8;
}
.sea .submarine-wrapper .submarine-body .engine:after {
  top: 8px;
  width: 20px;
  height: 4px;
}
.sea .submarine-wrapper .submarine-body .engine:before {
  left: 8px;
  width: 4px;
  height: 20px;
}
.sea .submarine-wrapper .helix {
  width: 30px;
  height: 70px;
  position: absolute;
  margin-top: 55px;
  left: -20px;
  background-color: #d93a54;
  border-radius: 7px;
  background: linear-gradient(#d93a54, #bc243d);
}
.sea .submarine-wrapper .helix:after {
  content: "";
  position: absolute;
  margin-top: 5px;
  margin-left: 7px;
  width: 17px;
  height: 60px;
  border-radius: 3px;
  background-color: transparent;
  opacity: 0.8;
  background: linear-gradient(
    to bottom,
    #d93a54,
    #d93a54 50%,
    #e57a8c 50%,
    #e57a8c
  );
  background-size: 100% 20px;
  -webkit-animation: helix-movement 110ms linear infinite;
  animation: helix-movement 110ms linear infinite;
}
.sea .submarine-wrapper .hat {
  width: 65px;
  height: 25px;
  position: absolute;
  margin-top: 26px;
  left: 70px;
  background-color: #d93a54;
  border-radius: 10px 10px 0 0;
  background: linear-gradient(#d93a54, #d72d49);
}
.sea .submarine-wrapper .hat .periscope {
  position: absolute;
  width: 7px;
  height: 20px;
  background-color: #d93a54;
  margin-top: -27px;
  margin-left: 32px;
  border-radius: 5px 5px 0 0;
}
.sea .submarine-wrapper .hat .periscope:after,
.sea .submarine-wrapper .hat .periscope:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 7px;
  border-radius: 5px;
  background-color: #d93a54;
}
.sea .submarine-wrapper .hat .leds-wrapper {
  width: 53px;
  height: 13px;
  position: relative;
  top: 7px;
  left: 7px;
  background-color: #d93a54;
  border-radius: 10px;
  background: linear-gradient(#b3233a, #a21f35);
}
.sea .submarine-wrapper .hat .leds-wrapper .leds {
  position: absolute;
  margin-top: 4px;
  margin-left: 7px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: white;
  -webkit-animation: leds-off 500ms linear infinite;
  animation: leds-off 500ms linear infinite;
}
.sea .submarine-wrapper .hat .leds-wrapper .leds:after,
.sea .submarine-wrapper .hat .leds-wrapper .leds:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: white;
}
.sea .submarine-wrapper .hat .leds-wrapper .leds:after {
  margin-top: 0px;
  margin-left: 17px;
}
.sea .submarine-wrapper .hat .leds-wrapper .leds:before {
  margin-top: 0px;
  margin-left: 34px;
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes leds-off {
  100% {
    opacity: 0.3;
  }
}
@keyframes leds-off {
  100% {
    opacity: 0.3;
  }
}
@-webkit-keyframes helix-movement {
  100% {
    background: linear-gradient(
      to bottom,
      #e57a8c 50%,
      #e57a8c,
      #d93a54,
      #d93a54 50%
    );
    background-size: 100% 20px;
  }
}
@keyframes helix-movement {
  100% {
    background: linear-gradient(
      to bottom,
      #e57a8c 50%,
      #e57a8c,
      #d93a54,
      #d93a54 50%
    );
    background-size: 100% 20px;
  }
}
@-webkit-keyframes diving {
  0% {
    margin-top: 5px;
  }
  50% {
    margin-top: 15px;
  }
  100% {
    margin-top: 5px;
  }
}
@keyframes diving {
  0% {
    margin-top: 5px;
  }
  50% {
    margin-top: 15px;
  }
  100% {
    margin-top: 5px;
  }
}
@-webkit-keyframes diving-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  75% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes diving-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  75% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-webkit-keyframes bubble1-h-movement {
  0% {
    margin-left: 80%;
  }
  100% {
    margin-left: -100%;
  }
}
@keyframes bubble1-h-movement {
  0% {
    margin-left: 80%;
  }
  100% {
    margin-left: -100%;
  }
}
@-webkit-keyframes bubble2-h-movement {
  0% {
    margin-left: 65%;
  }
  100% {
    margin-left: -5%;
  }
}
@keyframes bubble2-h-movement {
  0% {
    margin-left: 65%;
  }
  100% {
    margin-left: -5%;
  }
}
@-webkit-keyframes bubble1-v-movement {
  0% {
    margin-top: 115px;
  }
  100% {
    margin-top: 160px;
  }
}
@keyframes bubble1-v-movement {
  0% {
    margin-top: 115px;
  }
  100% {
    margin-top: 160px;
  }
}
@-webkit-keyframes bubble2-v-movement {
  0% {
    margin-top: 115px;
  }
  100% {
    margin-top: 90px;
  }
}
@keyframes bubble2-v-movement {
  0% {
    margin-top: 115px;
  }
  100% {
    margin-top: 90px;
  }
}
@-webkit-keyframes bubble-scale-movement {
  0% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
  }
  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
@keyframes bubble-scale-movement {
  0% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
  }
  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
@-webkit-keyframes light-movement {
  0% {
    -webkit-transform: rotate(-40deg);
    transform: rotate(-40deg);
  }
  50% {
    -webkit-transform: rotate(-70deg);
    transform: rotate(-70deg);
  }
  100% {
    -webkit-transform: rotate(-40deg);
    transform: rotate(-40deg);
  }
}
@keyframes light-movement {
  0% {
    -webkit-transform: rotate(-40deg);
    transform: rotate(-40deg);
  }
  50% {
    -webkit-transform: rotate(-70deg);
    transform: rotate(-70deg);
  }
  100% {
    -webkit-transform: rotate(-40deg);
    transform: rotate(-40deg);
  }
}

@use postcss-cssnext;
:root {
  --birds-dark: #878787;
  --birds-medium-dark: #afafaf;
  --birds-medium-light: #d3d3d3;
  --birds-light: #e2e2e2;
  --birds-bright: #ff5629;
  --birds-shadow: #dddddd;
}

.birds {
  position: relative;
  overflow: hidden;
  width: 800px;
  height: 450px;
  margin: auto;
}
.birds__hatdove {
  position: absolute;
  top: 70px;
  left: 450px;
  width: 255px;
  height: 400px;
}
.birds__hatdove-head {
  position: absolute;
  top: 0px;
  left: 80px;
  width: 80px;
  height: 190px;
  border-radius: 40px 40px 0 0;
  background: #878787;
  -webkit-animation: hatdove-head 2.75s linear infinite;
  animation: hatdove-head 2.75s linear infinite;
}
.birds__hatdove-head:before {
  content: "";
  position: absolute;
  top: 22px;
  left: -12px;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  background: #878787;
}
.birds__hatdove-hat {
  position: absolute;
  top: 10px;
  left: 5px;
  width: 84px;
  height: 22px;
  border-radius: 5px;
  background: #1d1d1b;
  -webkit-transform: rotate(20deg);
  transform: rotate(20deg);
}
.birds__hatdove-hat:after {
  content: "";
  position: absolute;
  top: -26px;
  left: 4px;
  width: 76px;
  height: 36px;
  border-radius: 34px 34px 0 0;
  background: #1d1d1b;
}
.birds__hatdove-hat:before {
  content: "";
  position: absolute;
  top: -44px;
  left: 29px;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #ff5629;
}
.birds__hatdove-forehead {
  position: absolute;
  top: 20px;
  left: 4px;
  width: 78px;
  height: 22px;
  border-radius: 100%;
  background: #878787;
  -webkit-transform: rotate(20deg);
  transform: rotate(20deg);
}
.birds__hatdove-eye {
  position: absolute;
  top: 26px;
  left: -8px;
  width: 34px;
  height: 34px;
  border-radius: 100%;
  background: white;
  overflow: hidden;
  -webkit-animation: hatdove-eye 2.75s linear infinite;
  animation: hatdove-eye 2.75s linear infinite;
}
.birds__hatdove-eye:after {
  content: "";
  position: absolute;
  left: 11px;
  top: 9px;
  width: 7px;
  height: 22px;
  border-radius: 100%;
  background: black;
  -webkit-animation: hatdove-pupil 2.75s linear infinite;
  animation: hatdove-pupil 2.75s linear infinite;
}
.birds__hatdove-eye:nth-child(4) {
  left: 20px;
}
.birds__hatdove-beak {
  position: absolute;
  top: 67px;
  left: 10px;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background: white;
}
.birds__hatdove-beak:after {
  content: "";
  position: absolute;
  top: 17px;
  left: -13px;
  width: 31px;
  height: 8px;
  border-radius: 1px 0 14px 70px / 1px 0 14px 20px;
  background: #ff5629;
}
.birds__hatdove-beak:before {
  content: "";
  position: absolute;
  top: 2px;
  left: -28px;
  width: 46px;
  height: 15px;
  border-radius: 30px 14px 0 1px / 14px 13px 0 1px;
  background: #ff5629;
}
.birds__hatdove-body {
  position: absolute;
  top: 174px;
  left: 69px;
  width: 91px;
  height: 110px;
  border-radius: 13px 0px 0px 40px / 70px 0px 0px 40px;
  background: #d3d3d3;
}
.birds__hatdove-body:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 91px;
  width: 0;
  height: 0;
  border-bottom: 110px solid #d3d3d3;
  border-right: 68px solid transparent;
  border-radius: 0 0 20px 0 / 0 0 40px 0;
}
.birds__hatdove-body:before {
  content: "";
  position: absolute;
  top: 40px;
  left: 91px;
  width: 0;
  height: 0;
  border-bottom: 70px solid #1d1d1b;
  border-right: 100px solid transparent;
  border-radius: 0 0 12px 0 / 0 0 70px 0;
}
.birds__hatdove-shadow {
  position: absolute;
  top: 363px;
  left: 55px;
  width: 154px;
  height: 12px;
  border-radius: 100%;
  background: #dddddd;
}
.birds__hatdove-backleg {
  position: absolute;
  top: 283px;
  left: 103px;
  width: 44px;
  border-style: solid;
  border-color: #afafaf transparent;
  border-width: 36px 12px 0;
}
.birds__hatdove-backleg:after {
  content: "";
  position: absolute;
  top: 45px;
  left: -15px;
  width: 36px;
  height: 6px;
  border-radius: 3px;
  background: #ff5629;
}
.birds__hatdove-backleg:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 2px;
  width: 15px;
  height: 46px;
  background: #ff5629;
}
.birds__hatdove-backleg .birds__curly {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 7px;
  height: 5px;
  border-radius: 0 0 7px 7px;
  background: #afafaf;
}
.birds__hatdove-backleg .birds__curly:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 6px;
  width: 7px;
  height: 5px;
  border-radius: 0 0 7px 7px;
  background: #afafaf;
}
.birds__hatdove-backleg .birds__curly:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 12px;
  width: 7px;
  height: 5px;
  border-radius: 0 0 7px 7px;
  background: #afafaf;
}
.birds__hatdove-frontleg {
  position: absolute;
  top: 283px;
  left: 130px;
  width: 44px;
  border-style: solid;
  border-color: #d3d3d3 transparent;
  border-width: 36px 12px 0;
}
.birds__hatdove-frontleg:after {
  content: "";
  position: absolute;
  top: 45px;
  left: -15px;
  width: 36px;
  height: 6px;
  border-radius: 3px;
  background: #ff5629;
}
.birds__hatdove-frontleg:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 2px;
  width: 15px;
  height: 46px;
  background: #ff5629;
}
.birds__hatdove-frontleg .birds__curly {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 7px;
  height: 5px;
  border-radius: 0 0 7px 7px;
  background: #d3d3d3;
}
.birds__hatdove-frontleg .birds__curly:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 6px;
  width: 7px;
  height: 5px;
  border-radius: 0 0 7px 7px;
  background: #d3d3d3;
}
.birds__hatdove-frontleg .birds__curly:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 12px;
  width: 7px;
  height: 5px;
  border-radius: 0 0 7px 7px;
  background: #d3d3d3;
}
.birds__hatdove-backwing {
  position: absolute;
  top: 186px;
  left: 20px;
  width: 100px;
  height: 90px;
  border-radius: 0 0 0 100%;
  background: #d3d3d3;
}
.birds__hatdove-backwing:after {
  content: "";
  position: absolute;
  top: -18px;
  left: 2px;
  width: 24px;
  height: 30px;
  border-radius: 100% 100% 0 0;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
  box-sizing: border-box;
  border-top: 8px solid #d3d3d3;
  border-right: 13px solid #d3d3d3;
  -webkit-animation: hatdove-finger 0.6s infinite;
  animation: hatdove-finger 0.6s infinite;
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}
.birds__hatdove-backwing:before {
  content: "";
  position: absolute;
  top: -18px;
  left: 16px;
  width: 24px;
  height: 30px;
  border-radius: 100% 100% 0 0;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
  box-sizing: border-box;
  border-top: 8px solid #d3d3d3;
  border-right: 13px solid #d3d3d3;
  -webkit-animation: hatdove-finger 0.6s infinite;
  animation: hatdove-finger 0.6s infinite;
}
.birds__hatdove-frontwing {
  position: absolute;
  top: 213px;
  left: 106px;
  width: 62px;
  height: 58px;
  border-radius: 100%;
  background: #e2e2e2;
}
.birds__hatdove-frontwing:after {
  content: "";
  position: absolute;
  top: -5px;
  left: -15px;
  width: 56px;
  height: 20px;
  border-radius: 4px;
  background: #e2e2e2;
  -webkit-transform: rotate(14deg);
  transform: rotate(14deg);
}
.birds__hatdove-frontwing:before {
  content: "";
  position: absolute;
  top: -73px;
  left: 9px;
  width: 45px;
  height: 110px;
  border-radius: 100%;
  background: #e2e2e2;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
  -webkit-transform: rotate(-50deg);
  transform: rotate(-50deg);
}
.birds__hatdove .birds__circles-2 {
  position: absolute;
  top: 121px;
  left: 116px;
  width: 58px;
  height: 110px;
  border-radius: 100%;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
  -webkit-transform: rotate(-60deg);
  transform: rotate(-60deg);
  overflow: hidden;
}
.birds__hatdove .birds__circles-2:after {
  content: "";
  position: absolute;
  top: -109px;
  left: -60px;
  width: 170px;
  height: 170px;
  border-radius: 100%;
  border: 8px solid #878787;
  box-shadow: inset 0 0 0 6px #e2e2e2, inset 0 0 0 14px #878787;
}
.birds__hatdove .birds__circles-1 {
  position: absolute;
  top: 135px;
  left: 66px;
  width: 58px;
  height: 110px;
  border-radius: 100%;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
  -webkit-transform: rotate(-43deg);
  transform: rotate(-43deg);
  overflow: hidden;
}
.birds__hatdove .birds__circles-1:after {
  content: "";
  position: absolute;
  top: -109px;
  left: -85px;
  width: 170px;
  height: 170px;
  border-radius: 100%;
  border: 8px solid #878787;
  box-shadow: inset 0 0 0 6px #d3d3d3, inset 0 0 0 14px #878787;
}
.birds__hatdove-frontwing-finger {
  position: absolute;
  top: 174px;
  width: 24px;
  height: 30px;
  border-radius: 100% 100% 0 0;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
  box-sizing: border-box;
  border-top: 8px solid #e2e2e2;
  border-right: 13px solid #e2e2e2;
  -webkit-animation: hatdove-finger 0.6s infinite;
  animation: hatdove-finger 0.6s infinite;
}
.birds__hatdove-frontwing-finger:nth-child(10) {
  left: 44px;
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.birds__hatdove-frontwing-finger:nth-child(11) {
  left: 56px;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.birds__hatdove-frontwing-finger:nth-child(12) {
  left: 68px;
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.birds__rundove {
  position: absolute;
  top: 70px;
  left: 90px;
  width: 255px;
  height: 400px;
  -webkit-animation: rundove 2.75s linear infinite;
  animation: rundove 2.75s linear infinite;
}
.birds__rundove-head {
  position: absolute;
  top: 0px;
  left: 99px;
  width: 80px;
  height: 190px;
  border-radius: 40px 40px 0 0;
  background: #878787;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
  -webkit-animation: rundove-head 2.75s linear infinite;
  animation: rundove-head 2.75s linear infinite;
}
.birds__rundove-head:before {
  content: "";
  position: absolute;
  top: 22px;
  left: 52px;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  background: #878787;
}
.birds__rundove-eye {
  position: absolute;
  top: 25px;
  left: 54px;
  width: 37px;
  height: 37px;
  border-radius: 100%;
  background: white;
  overflow: hidden;
  -webkit-animation: rundove-eye 2.75s linear infinite;
  animation: rundove-eye 2.75s linear infinite;
}
.birds__rundove-eye:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 19px;
  width: 7px;
  height: 22px;
  border-radius: 100%;
  background: black;
}
.birds__rundove-eye:after {
  content: "";
  position: absolute;
  top: -30px;
  left: -2px;
  width: 50px;
  height: 22px;
  border-radius: 100%;
  background: #878787;
  -webkit-animation: rundove-eyelid 2.75s linear infinite;
  animation: rundove-eyelid 2.75s linear infinite;
}
.birds__rundove-eye:nth-child(2) {
  left: 22px;
}
.birds__rundove-beak {
  position: absolute;
  top: 64px;
  left: 51px;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background: white;
}
.birds__rundove-beak:after {
  content: "";
  position: absolute;
  top: 17px;
  left: 1px;
  width: 31px;
  height: 8px;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  border-radius: 0 1px 70px 14px / 0 1px 20px 14px;
  background: #ff5629;
  -webkit-animation: rundove-beak 2.75s linear infinite;
  animation: rundove-beak 2.75s linear infinite;
}
.birds__rundove-beak:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 1px;
  width: 46px;
  height: 15px;
  border-radius: 14px 30px 1px 0 / 13px 14px 1px 0;
  background: #ff5629;
}
.birds__rundove-body {
  position: absolute;
  top: 174px;
  left: 98px;
  width: 91px;
  height: 110px;
  border-radius: 0px 13px 40px 0px / 0px 70px 40px 0px;
  background: #d3d3d3;
}
.birds__rundove-body:after {
  content: "";
  position: absolute;
  top: 0px;
  left: -68px;
  width: 0;
  height: 0;
  border-bottom: 110px solid #d3d3d3;
  border-left: 68px solid transparent;
  border-radius: 0 0 0 20px / 0 0 0 40px;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
  -webkit-transform: rotate(10deg);
  transform: rotate(10deg);
  -webkit-animation: rundove-tail 2.75s linear infinite;
  animation: rundove-tail 2.75s linear infinite;
}
.birds__rundove-body:before {
  content: "";
  position: absolute;
  top: 40px;
  left: -100px;
  width: 0;
  height: 0;
  border-bottom: 70px solid #1d1d1b;
  border-left: 100px solid transparent;
  border-radius: 0 0 0 12px / 0 0 0 70px;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
  -webkit-transform: rotate(10deg);
  transform: rotate(10deg);
  -webkit-animation: rundove-tail 2.75s linear infinite;
  animation: rundove-tail 2.75s linear infinite;
}
.birds__rundove-shadow {
  position: absolute;
  top: 433px;
  left: 43px;
  width: 162px;
  height: 12px;
  border-radius: 100%;
  background: #dddddd;
  -webkit-animation: rundove-shadow 2.75s linear infinite;
  animation: rundove-shadow 2.75s linear infinite;
}
.birds__rundove-backleg {
  position: absolute;
  top: 273px;
  left: 113px;
  width: 44px;
  -webkit-transform-origin: 9.5px 0;
  transform-origin: 9.5px 0;
  border-style: solid;
  border-color: #afafaf transparent;
  border-width: 46px 12px 0;
  -webkit-animation: rundove-back-knee 2.75s linear infinite;
  animation: rundove-back-knee 2.75s linear infinite;
}
.birds__rundove-backleg:after {
  content: "";
  position: absolute;
  top: 45px;
  left: -3px;
  width: 36px;
  height: 6px;
  -webkit-transform-origin: 10.5px -46px;
  transform-origin: 10.5px -46px;
  border-radius: 3px;
  background: #ff5629;
  -webkit-animation: rundove-back-foot 2.75s linear infinite;
  animation: rundove-back-foot 2.75s linear infinite;
}
.birds__rundove-backleg:before {
  content: "";
  position: absolute;
  top: -8px;
  left: 2px;
  width: 15px;
  height: 57px;
  border-radius: 7px 7px 3px 3px;
  -webkit-transform-origin: 7.5px 8px;
  transform-origin: 7.5px 8px;
  background: #ff5629;
  -webkit-animation: rundove-back-leg 2.75s linear infinite;
  animation: rundove-back-leg 2.75s linear infinite;
}
.birds__rundove-backleg .birds__curly {
  position: absolute;
  top: -10px;
  left: 0px;
  width: 7px;
  height: 15px;
  border-radius: 0 0 7px 7px;
  background: #afafaf;
}
.birds__rundove-backleg .birds__curly:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 6px;
  width: 7px;
  height: 15px;
  border-radius: 0 0 7px 7px;
  background: #afafaf;
}
.birds__rundove-backleg .birds__curly:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 12px;
  width: 7px;
  height: 15px;
  border-radius: 0 0 7px 7px;
  background: #afafaf;
}
.birds__rundove-frontleg {
  position: absolute;
  top: 273px;
  left: 87px;
  width: 44px;
  border-style: solid;
  border-color: #d3d3d3 transparent;
  -webkit-transform-origin: 9.5px 0;
  transform-origin: 9.5px 0;
  border-width: 46px 12px 0;
  -webkit-animation: rundove-front-knee 2.75s linear infinite;
  animation: rundove-front-knee 2.75s linear infinite;
}
.birds__rundove-frontleg:after {
  content: "";
  position: absolute;
  top: 45px;
  left: -3px;
  width: 36px;
  height: 6px;
  border-radius: 3px;
  -webkit-transform-origin: 10.5px -46px;
  transform-origin: 10.5px -46px;
  background: #ff5629;
  -webkit-animation: rundove-front-foot 2.75s linear infinite;
  animation: rundove-front-foot 2.75s linear infinite;
}
.birds__rundove-frontleg:before {
  content: "";
  position: absolute;
  top: -8px;
  left: 2px;
  width: 15px;
  height: 57px;
  border-radius: 7px 7px 3px 3px;
  -webkit-transform-origin: 7.5px 8px;
  transform-origin: 7.5px 8px;
  background: #ff5629;
  -webkit-animation: rundove-front-leg 2.75s linear infinite;
  animation: rundove-front-leg 2.75s linear infinite;
}
.birds__rundove-frontleg .birds__curly {
  position: absolute;
  top: -10px;
  left: 0px;
  width: 7px;
  height: 15px;
  border-radius: 0 0 7px 7px;
  background: #d3d3d3;
}
.birds__rundove-frontleg .birds__curly:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 6px;
  width: 7px;
  height: 15px;
  border-radius: 0 0 7px 7px;
  background: #d3d3d3;
}
.birds__rundove-frontleg .birds__curly:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 12px;
  width: 7px;
  height: 15px;
  border-radius: 0 0 7px 7px;
  background: #d3d3d3;
}
.birds__rundove-backwing {
  position: absolute;
  top: 150px;
  left: 84px;
  width: 85px;
  height: 85px;
  border-radius: 0 100% 40px 100%;
  background: #e2e2e2;
  -webkit-transform-origin: 80px 80px;
  transform-origin: 80px 80px;
  -webkit-transform: rotate(110deg);
  transform: rotate(110deg);
  -webkit-animation: rundove-back-wing 2.75s linear infinite;
  animation: rundove-back-wing 2.75s linear infinite;
}
.birds__rundove-backwing .birds__finger:nth-child(1) {
  position: absolute;
  top: -6px;
  left: 40px;
  width: 20px;
  height: 50px;
  -webkit-transform: rotate(95deg);
  transform: rotate(95deg);
  background: #878787;
  border-radius: 100% 0;
  -webkit-transform-origin: 5px 5px;
  transform-origin: 5px 5px;
}
.birds__rundove-backwing .birds__finger:nth-child(2) {
  position: absolute;
  top: 40px;
  left: 3px;
  width: 20px;
  height: 50px;
  -webkit-transform: rotate(165deg);
  transform: rotate(165deg);
  background: #878787;
  border-radius: 0 100%;
  -webkit-transform-origin: 5px 5px;
  transform-origin: 5px 5px;
  z-index: 22;
}
.birds__rundove-backwing .birds__finger:nth-child(3) {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 15px;
  height: 50px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  background: #878787;
  border-radius: 100%;
  -webkit-transform-origin: 5px 5px;
  transform-origin: 5px 5px;
}
.birds__rundove-backwing .birds__circles {
  position: absolute;
  width: 85px;
  height: 85px;
  border-radius: 0 100% 40px 100%;
  overflow: hidden;
}
.birds__rundove-backwing .birds__circles:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  border: 8px solid #878787;
  box-shadow: 0 0 0 7px #e2e2e2, 0 0 0 60px #878787;
}
.birds__rundove-frontwing {
  position: absolute;
  top: 132px;
  left: 67px;
  width: 85px;
  height: 85px;
  border-radius: 0 100% 40px 100%;
  background: #e2e2e2;
  -webkit-transform-origin: 80px 80px;
  transform-origin: 80px 80px;
  -webkit-transform: rotate(-68deg);
  transform: rotate(-68deg);
  -webkit-animation: rundove-front-wing 2.75s linear infinite;
  animation: rundove-front-wing 2.75s linear infinite;
}
.birds__rundove-frontwing .birds__circles {
  position: absolute;
  width: 85px;
  height: 85px;
  border-radius: 0 100% 40px 100%;
  overflow: hidden;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.birds__rundove-frontwing .birds__circles:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  border: 8px solid #878787;
  box-shadow: 0 0 0 7px #e2e2e2, 0 0 0 60px #878787;
}
.birds__rundove-frontwing .birds__finger:nth-child(1) {
  position: absolute;
  top: -6px;
  left: 40px;
  width: 20px;
  height: 50px;
  -webkit-transform: rotate(85deg);
  transform: rotate(85deg);
  background: #878787;
  border-radius: 100% 0;
  -webkit-transform-origin: 5px 5px;
  transform-origin: 5px 5px;
  -webkit-animation: rundove-front-finger-1 2.75s linear infinite;
  animation: rundove-front-finger-1 2.75s linear infinite;
}
.birds__rundove-frontwing .birds__finger:nth-child(2) {
  position: absolute;
  top: 40px;
  left: 3px;
  width: 20px;
  height: 50px;
  -webkit-transform: rotate(185deg);
  transform: rotate(185deg);
  background: #878787;
  border-radius: 0 100%;
  -webkit-transform-origin: 5px 5px;
  transform-origin: 5px 5px;
  -webkit-animation: rundove-front-finger-2 2.75s linear infinite;
  animation: rundove-front-finger-2 2.75s linear infinite;
}
.birds__rundove-frontwing .birds__finger:nth-child(3) {
  position: absolute;
  top: 28px;
  left: 28px;
  width: 20px;
  height: 50px;
  -webkit-transform: rotate(140deg);
  transform: rotate(140deg);
  background: #878787;
  border-radius: 100%;
  -webkit-transform-origin: 5px 5px;
  transform-origin: 5px 5px;
  -webkit-animation: rundove-front-finger-3 2.75s linear infinite;
  animation: rundove-front-finger-3 2.75s linear infinite;
}
.birds__table-shadow {
  position: absolute;
  top: 161px;
  left: 89px;
  width: 194px;
  height: 12px;
  border-radius: 100%;
  background: #dddddd;
}
.birds__table {
  position: absolute;
  top: 272px;
  left: 217px;
  width: 374px;
  height: 10px;
  border-radius: 5px;
  background: #1d1d1b;
}
.birds__table:before {
  content: "";
  position: absolute;
  top: 10px;
  left: 169px;
  width: 16px;
  height: 140px;
  background: #ff5629;
}
.birds__table:after {
  content: "";
  position: absolute;
  top: 137px;
  left: 123px;
  width: 14px;
  border-style: solid;
  border-color: #ff5629 transparent;
  border-width: 0 54px 30px;
}
.birds__laptop:nth-child(3) {
  position: absolute;
  top: 265px;
  left: 476px;
  height: 7px;
  width: 80px;
  border-radius: 4px;
  background: #f7f7f7;
}
.birds__laptop:nth-child(3):before {
  content: "";
  position: absolute;
  bottom: 0;
  left: -136px;
  height: 85px;
  width: 124px;
  background: #c6c6c6;
  border-radius: 8px;
  box-shadow: 7px 0 0 #e5e5e5;
  -webkit-transform: skew(20deg);
  transform: skew(20deg);
}
.birds__laptop:nth-child(4) {
  position: absolute;
  top: 265px;
  left: 256px;
  height: 7px;
  width: 186px;
  border-radius: 4px;
  background: #f7f7f7;
}
.birds__laptop:nth-child(4):before {
  content: "";
  position: absolute;
  bottom: 7px;
  left: 80px;
  height: 82px;
  width: 116px;
  background: #4c4c4c;
  border-radius: 8px;
  box-shadow: 5px 0 0 #e5e5e5;
  -webkit-transform: skew(-20deg);
  transform: skew(-20deg);
}
.birds__monitor {
  position: absolute;
  top: -76px;
  left: 88px;
  height: 70px;
  width: 100px;
  background: #1d1d1b;
  -webkit-transform: skew(-20deg);
  transform: skew(-20deg);
}
.birds__monitor:before {
  content: "";
  position: absolute;
  top: 16px;
  left: 10px;
  height: 4px;
  width: 54px;
  background: white;
  border-radius: 8px;
}
.birds__monitor:after {
  content: "";
  position: absolute;
  top: 24px;
  left: 10px;
  height: 4px;
  width: 26px;
  background: white;
  border-radius: 8px;
}
.birds__code {
  position: absolute;
  top: 32px;
  left: 10px;
  height: 4px;
  width: 38px;
  border-radius: 8px;
  background: white;
  -webkit-transform: skew(-20deg);
  transform: skew(-20deg);
}
.birds__code:before {
  content: "";
  position: absolute;
  top: 0;
  left: 42px;
  height: 4px;
  width: 11px;
  background: white;
  border-radius: 8px;
}
.birds__code:after {
  content: "";
  position: absolute;
  top: 9px;
  left: 2px;
  height: 4px;
  width: 30px;
  background: white;
  border-radius: 8px;
}
.birds__coffee {
  position: absolute;
  top: 229px;
  left: 335px;
  height: 43px;
  width: 35px;
  background: #ededed;
  -webkit-animation: birds-coffee 2.75s linear infinite;
  animation: birds-coffee 2.75s linear infinite;
  transition: 0.2s ease;
}
.birds__coffee:before {
  content: "";
  position: absolute;
  top: -6px;
  left: -3px;
  height: 6px;
  width: 40px;
  background: #e2e2e2;
}
.birds__coffee:after {
  content: "";
  position: absolute;
  top: -12px;
  left: 2px;
  height: 6px;
  width: 31px;
  background: #e2e2e2;
}
.birds__feather:nth-child(6) {
  position: absolute;
  top: 296px;
  left: 234px;
  width: 15px;
  height: 60px;
  border-radius: 100%;
  background: #d3d3d3;
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
  overflow: hidden;
  -webkit-animation: birds-feather-1 2.75s linear infinite;
  animation: birds-feather-1 2.75s linear infinite;
  transition: 0.2s ease;
}
.birds__feather:nth-child(6):before {
  content: "";
  position: absolute;
  top: 30px;
  left: -15px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 6px solid #878787;
  box-shadow: inset 0 0 0 4px #d3d3d3;
  background: #878787;
}
.birds__feather:nth-child(7) {
  position: absolute;
  top: 296px;
  left: 234px;
  width: 15px;
  height: 60px;
  border-radius: 100%;
  background: #d3d3d3;
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
  overflow: hidden;
  -webkit-animation: birds-feather-2 2.75s linear infinite;
  animation: birds-feather-2 2.75s linear infinite;
  transition: 0.2s ease;
}
.birds__feather:nth-child(7):before {
  content: "";
  position: absolute;
  top: 30px;
  left: -15px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 6px solid #878787;
  box-shadow: inset 0 0 0 4px #d3d3d3;
  background: #878787;
}

/* Анимации */
@-webkit-keyframes birds-feather-1 {
  0% {
    -webkit-transform: rotate(90deg) translate(-3px, -21px);
    transform: rotate(90deg) translate(-3px, -21px);
  }
  2.94% {
    -webkit-transform: rotate(100deg) translate(-8px, -23px);
    transform: rotate(100deg) translate(-8px, -23px);
  }
  13.23% {
    -webkit-transform: rotate(124deg) translate(-39px, 37px);
    transform: rotate(124deg) translate(-39px, 37px);
  }
  16.17% {
    -webkit-transform: rotate(130deg) translate(-48px, 41px);
    transform: rotate(130deg) translate(-48px, 41px);
    opacity: 1;
  }
  17% {
    opacity: 0;
  }
  69% {
    opacity: 0;
  }
  69.09% {
    -webkit-transform: rotate(120deg) translate(8px, -41px);
    transform: rotate(120deg) translate(8px, -41px);
    opacity: 1;
  }
  72.03% {
    -webkit-transform: rotate(90deg) translate(1px, -47px);
    transform: rotate(90deg) translate(1px, -47px);
  }
  80.85% {
    -webkit-transform: rotate(66deg) translate(-41px, -44px);
    transform: rotate(66deg) translate(-41px, -44px);
  }
  100% {
    -webkit-transform: rotate(90deg) translate(-3px, 21px);
    transform: rotate(90deg) translate(-3px, 21px);
  }
}
@keyframes birds-feather-1 {
  0% {
    -webkit-transform: rotate(90deg) translate(-3px, -21px);
    transform: rotate(90deg) translate(-3px, -21px);
  }
  2.94% {
    -webkit-transform: rotate(100deg) translate(-8px, -23px);
    transform: rotate(100deg) translate(-8px, -23px);
  }
  13.23% {
    -webkit-transform: rotate(124deg) translate(-39px, 37px);
    transform: rotate(124deg) translate(-39px, 37px);
  }
  16.17% {
    -webkit-transform: rotate(130deg) translate(-48px, 41px);
    transform: rotate(130deg) translate(-48px, 41px);
    opacity: 1;
  }
  17% {
    opacity: 0;
  }
  69% {
    opacity: 0;
  }
  69.09% {
    -webkit-transform: rotate(120deg) translate(8px, -41px);
    transform: rotate(120deg) translate(8px, -41px);
    opacity: 1;
  }
  72.03% {
    -webkit-transform: rotate(90deg) translate(1px, -47px);
    transform: rotate(90deg) translate(1px, -47px);
  }
  80.85% {
    -webkit-transform: rotate(66deg) translate(-41px, -44px);
    transform: rotate(66deg) translate(-41px, -44px);
  }
  100% {
    -webkit-transform: rotate(90deg) translate(-3px, 21px);
    transform: rotate(90deg) translate(-3px, 21px);
  }
}
@-webkit-keyframes birds-feather-2 {
  0% {
    -webkit-transform: rotate(-64deg) translate(53px, 39px);
    transform: rotate(-64deg) translate(53px, 39px);
  }
  2.94% {
    -webkit-transform: rotate(-68deg) translate(54px, 38px);
    transform: rotate(-68deg) translate(54px, 38px);
  }
  13.23% {
    -webkit-transform: rotate(-100deg) translate(48px, 20px);
    transform: rotate(-100deg) translate(48px, 20px);
    opacity: 1;
  }
  14% {
    opacity: 0;
  }
  69% {
    opacity: 0;
  }
  69.09% {
    -webkit-transform: rotate(-114deg) translate(-16px, 123px);
    transform: rotate(-114deg) translate(-16px, 123px);
    opacity: 1;
  }
  72.03% {
    -webkit-transform: rotate(-120deg) translate(-9px, 111px);
    transform: rotate(-120deg) translate(-9px, 111px);
  }
  80.85% {
    -webkit-transform: rotate(-100deg) translate(11px, 81px);
    transform: rotate(-100deg) translate(11px, 81px);
  }
  100% {
    -webkit-transform: rotate(-64deg) translate(53px, 39px);
    transform: rotate(-64deg) translate(53px, 39px);
  }
}
@keyframes birds-feather-2 {
  0% {
    -webkit-transform: rotate(-64deg) translate(53px, 39px);
    transform: rotate(-64deg) translate(53px, 39px);
  }
  2.94% {
    -webkit-transform: rotate(-68deg) translate(54px, 38px);
    transform: rotate(-68deg) translate(54px, 38px);
  }
  13.23% {
    -webkit-transform: rotate(-100deg) translate(48px, 20px);
    transform: rotate(-100deg) translate(48px, 20px);
    opacity: 1;
  }
  14% {
    opacity: 0;
  }
  69% {
    opacity: 0;
  }
  69.09% {
    -webkit-transform: rotate(-114deg) translate(-16px, 123px);
    transform: rotate(-114deg) translate(-16px, 123px);
    opacity: 1;
  }
  72.03% {
    -webkit-transform: rotate(-120deg) translate(-9px, 111px);
    transform: rotate(-120deg) translate(-9px, 111px);
  }
  80.85% {
    -webkit-transform: rotate(-100deg) translate(11px, 81px);
    transform: rotate(-100deg) translate(11px, 81px);
  }
  100% {
    -webkit-transform: rotate(-64deg) translate(53px, 39px);
    transform: rotate(-64deg) translate(53px, 39px);
  }
}
@-webkit-keyframes hatdove-head {
  14.70% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
  17.64% {
    -webkit-transform: translate(0px, 16px);
    transform: translate(0px, 16px);
  }
  22.05% {
    -webkit-transform: translate(0px, -5px);
    transform: translate(0px, -5px);
  }
  27.93% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}
@keyframes hatdove-head {
  14.70% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
  17.64% {
    -webkit-transform: translate(0px, 16px);
    transform: translate(0px, 16px);
  }
  22.05% {
    -webkit-transform: translate(0px, -5px);
    transform: translate(0px, -5px);
  }
  27.93% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}
@-webkit-keyframes hatdove-eye {
  17.60% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  17.64% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
  19.11% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
  22.05% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@keyframes hatdove-eye {
  17.60% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  17.64% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
  19.11% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
  22.05% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@-webkit-keyframes hatdove-pupil {
  17.64% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
  19.11% {
    -webkit-transform: translate(-5px, -3px);
    transform: translate(-5px, -3px);
  }
  52.92% {
    -webkit-transform: translate(-5px, -3px);
    transform: translate(-5px, -3px);
  }
  54.39% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}
@keyframes hatdove-pupil {
  17.64% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
  19.11% {
    -webkit-transform: translate(-5px, -3px);
    transform: translate(-5px, -3px);
  }
  52.92% {
    -webkit-transform: translate(-5px, -3px);
    transform: translate(-5px, -3px);
  }
  54.39% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}
@-webkit-keyframes hatdove-finger {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes hatdove-finger {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes rundove {
  0% {
    -webkit-transform: rotate(0deg) translate(-290px, -40px);
    transform: rotate(0deg) translate(-290px, -40px);
  }
  5.88% {
    -webkit-transform: rotate(-10deg) translate(-290px, -40px);
    transform: rotate(-10deg) translate(-290px, -40px);
  }
  17.64% {
    -webkit-transform: rotate(-10deg) translate(-40px, -10px);
    transform: rotate(-10deg) translate(-40px, -10px);
  }
  23.52% {
    -webkit-transform: rotate(0deg) translate(-1px, -1px);
    transform: rotate(0deg) translate(-1px, -1px);
  }
  52.92% {
    -webkit-transform: rotate(0deg) translate(-1px, -1px);
    transform: rotate(0deg) translate(-1px, -1px);
  }
  67.62% {
    -webkit-transform: rotate(18deg) translate(-16px, 27px);
    transform: rotate(18deg) translate(-16px, 27px);
  }
  72.03% {
    -webkit-transform: rotate(17deg) translate(214px, -50px);
    transform: rotate(17deg) translate(214px, -50px);
  }
  76.44% {
    -webkit-transform: rotate(8deg) translate(429px, -53px);
    transform: rotate(8deg) translate(429px, -53px);
  }
  86.79% {
    -webkit-transform: rotate(19deg) translate(710px, -250px);
    transform: rotate(19deg) translate(710px, -250px);
  }
  100% {
    -webkit-transform: rotate(0) translate(710px, -220px);
    transform: rotate(0) translate(710px, -220px);
  }
}
@keyframes rundove {
  0% {
    -webkit-transform: rotate(0deg) translate(-290px, -40px);
    transform: rotate(0deg) translate(-290px, -40px);
  }
  5.88% {
    -webkit-transform: rotate(-10deg) translate(-290px, -40px);
    transform: rotate(-10deg) translate(-290px, -40px);
  }
  17.64% {
    -webkit-transform: rotate(-10deg) translate(-40px, -10px);
    transform: rotate(-10deg) translate(-40px, -10px);
  }
  23.52% {
    -webkit-transform: rotate(0deg) translate(-1px, -1px);
    transform: rotate(0deg) translate(-1px, -1px);
  }
  52.92% {
    -webkit-transform: rotate(0deg) translate(-1px, -1px);
    transform: rotate(0deg) translate(-1px, -1px);
  }
  67.62% {
    -webkit-transform: rotate(18deg) translate(-16px, 27px);
    transform: rotate(18deg) translate(-16px, 27px);
  }
  72.03% {
    -webkit-transform: rotate(17deg) translate(214px, -50px);
    transform: rotate(17deg) translate(214px, -50px);
  }
  76.44% {
    -webkit-transform: rotate(8deg) translate(429px, -53px);
    transform: rotate(8deg) translate(429px, -53px);
  }
  86.79% {
    -webkit-transform: rotate(19deg) translate(710px, -250px);
    transform: rotate(19deg) translate(710px, -250px);
  }
  100% {
    -webkit-transform: rotate(0) translate(710px, -220px);
    transform: rotate(0) translate(710px, -220px);
  }
}
@-webkit-keyframes rundove-tail {
  05.88% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  17.64% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  23.52% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  52.92% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg);
  }
  67.62% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  72.03% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  76.44% {
    -webkit-transform: rotate(13deg);
    transform: rotate(13deg);
  }
  86.73% {
    -webkit-transform: rotate(13deg);
    transform: rotate(13deg);
  }
}
@keyframes rundove-tail {
  05.88% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  17.64% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  23.52% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  52.92% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg);
  }
  67.62% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  72.03% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  76.44% {
    -webkit-transform: rotate(13deg);
    transform: rotate(13deg);
  }
  86.73% {
    -webkit-transform: rotate(13deg);
    transform: rotate(13deg);
  }
}
@-webkit-keyframes rundove-head {
  05.88% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  17.64% {
    -webkit-transform: rotate(-9deg);
    transform: rotate(-9deg);
  }
  23.52% {
    -webkit-transform: rotate(9deg);
    transform: rotate(9deg);
  }
  28.12% {
    -webkit-transform: rotate(-9deg);
    transform: rotate(-9deg);
  }
  34.04% {
    -webkit-transform: rotate(9deg);
    transform: rotate(9deg);
  }
  52.92% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  67.62% {
    -webkit-transform: rotate(-11deg);
    transform: rotate(-11deg);
  }
  72.03% {
    -webkit-transform: rotate(-16deg);
    transform: rotate(-16deg);
  }
  76.44% {
    -webkit-transform: rotate(-13deg);
    transform: rotate(-13deg);
  }
  86.73% {
    -webkit-transform: rotate(-8deg);
    transform: rotate(-8deg);
  }
}
@keyframes rundove-head {
  05.88% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  17.64% {
    -webkit-transform: rotate(-9deg);
    transform: rotate(-9deg);
  }
  23.52% {
    -webkit-transform: rotate(9deg);
    transform: rotate(9deg);
  }
  28.12% {
    -webkit-transform: rotate(-9deg);
    transform: rotate(-9deg);
  }
  34.04% {
    -webkit-transform: rotate(9deg);
    transform: rotate(9deg);
  }
  52.92% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  67.62% {
    -webkit-transform: rotate(-11deg);
    transform: rotate(-11deg);
  }
  72.03% {
    -webkit-transform: rotate(-16deg);
    transform: rotate(-16deg);
  }
  76.44% {
    -webkit-transform: rotate(-13deg);
    transform: rotate(-13deg);
  }
  86.73% {
    -webkit-transform: rotate(-8deg);
    transform: rotate(-8deg);
  }
}
@-webkit-keyframes rundove-eye {
  54.39% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  56.24% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
  57.72% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
  60.27% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@keyframes rundove-eye {
  54.39% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  56.24% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
  57.72% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
  60.27% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@-webkit-keyframes rundove-eyelid {
  57.72% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  60.27% {
    -webkit-transform: translateY(19px);
    transform: translateY(19px);
  }
  100.0% {
    -webkit-transform: translateY(19px);
    transform: translateY(19px);
  }
}
@keyframes rundove-eyelid {
  57.72% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  60.27% {
    -webkit-transform: translateY(19px);
    transform: translateY(19px);
  }
  100.0% {
    -webkit-transform: translateY(19px);
    transform: translateY(19px);
  }
}
@-webkit-keyframes rundove-front-knee {
  53.28% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  57.72% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  68.08% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  72.52% {
    -webkit-transform: rotate(-59deg);
    transform: rotate(-59deg);
  }
  79.92% {
    -webkit-transform: rotate(9deg);
    transform: rotate(9deg);
  }
  100.0% {
    -webkit-transform: rotate(-59deg);
    transform: rotate(-59deg);
  }
}
@keyframes rundove-front-knee {
  53.28% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  57.72% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  68.08% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  72.52% {
    -webkit-transform: rotate(-59deg);
    transform: rotate(-59deg);
  }
  79.92% {
    -webkit-transform: rotate(9deg);
    transform: rotate(9deg);
  }
  100.0% {
    -webkit-transform: rotate(-59deg);
    transform: rotate(-59deg);
  }
}
@-webkit-keyframes rundove-front-leg {
  53.28% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  68.08% {
    -webkit-transform: rotate(113deg);
    transform: rotate(113deg);
  }
  72.52% {
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }
  79.92% {
    -webkit-transform: rotate(55deg);
    transform: rotate(55deg);
  }
  100.0% {
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }
}
@keyframes rundove-front-leg {
  53.28% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  68.08% {
    -webkit-transform: rotate(113deg);
    transform: rotate(113deg);
  }
  72.52% {
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }
  79.92% {
    -webkit-transform: rotate(55deg);
    transform: rotate(55deg);
  }
  100.0% {
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }
}
@-webkit-keyframes rundove-front-foot {
  17.64% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  23.52% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  53.28% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  67.62% {
    -webkit-transform: rotate(113deg);
    transform: rotate(113deg);
  }
  72.03% {
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }
  79.92% {
    -webkit-transform: rotate(55deg);
    transform: rotate(55deg);
  }
  100.0% {
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }
}
@keyframes rundove-front-foot {
  17.64% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  23.52% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  53.28% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  67.62% {
    -webkit-transform: rotate(113deg);
    transform: rotate(113deg);
  }
  72.03% {
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }
  79.92% {
    -webkit-transform: rotate(55deg);
    transform: rotate(55deg);
  }
  100.0% {
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }
}
@-webkit-keyframes rundove-back-knee {
  53.28% {
    -webkit-transform: rotate(0deg) translate(0, 0);
    transform: rotate(0deg) translate(0, 0);
  }
  62.16% {
    -webkit-transform: rotate(-60deg) translate(0, 0);
    transform: rotate(-60deg) translate(0, 0);
  }
  68.08% {
    -webkit-transform: rotate(-60deg) translate(0, 0);
    transform: rotate(-60deg) translate(0, 0);
  }
  72.52% {
    -webkit-transform: rotate(60deg) translate(10px, -20px);
    transform: rotate(60deg) translate(10px, -20px);
  }
  82.88% {
    -webkit-transform: rotate(-50deg) translate(0, 0);
    transform: rotate(-50deg) translate(0, 0);
  }
  100% {
    -webkit-transform: rotate(60deg) translate(10px, -20px);
    transform: rotate(60deg) translate(10px, -20px);
  }
}
@keyframes rundove-back-knee {
  53.28% {
    -webkit-transform: rotate(0deg) translate(0, 0);
    transform: rotate(0deg) translate(0, 0);
  }
  62.16% {
    -webkit-transform: rotate(-60deg) translate(0, 0);
    transform: rotate(-60deg) translate(0, 0);
  }
  68.08% {
    -webkit-transform: rotate(-60deg) translate(0, 0);
    transform: rotate(-60deg) translate(0, 0);
  }
  72.52% {
    -webkit-transform: rotate(60deg) translate(10px, -20px);
    transform: rotate(60deg) translate(10px, -20px);
  }
  82.88% {
    -webkit-transform: rotate(-50deg) translate(0, 0);
    transform: rotate(-50deg) translate(0, 0);
  }
  100% {
    -webkit-transform: rotate(60deg) translate(10px, -20px);
    transform: rotate(60deg) translate(10px, -20px);
  }
}
@-webkit-keyframes rundove-back-leg {
  53.28% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  62.16% {
    -webkit-transform: rotate(65deg);
    transform: rotate(65deg);
  }
  68.08% {
    -webkit-transform: rotate(65deg);
    transform: rotate(65deg);
  }
  72.52% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  82.88% {
    -webkit-transform: rotate(70deg);
    transform: rotate(70deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes rundove-back-leg {
  53.28% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  62.16% {
    -webkit-transform: rotate(65deg);
    transform: rotate(65deg);
  }
  68.08% {
    -webkit-transform: rotate(65deg);
    transform: rotate(65deg);
  }
  72.52% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  82.88% {
    -webkit-transform: rotate(70deg);
    transform: rotate(70deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-webkit-keyframes rundove-back-foot {
  17.76% {
    -webkit-transform: rotate(-10deg) translate(0, 0);
    transform: rotate(-10deg) translate(0, 0);
  }
  23.52% {
    -webkit-transform: rotate(0deg) translate(0, 0);
    transform: rotate(0deg) translate(0, 0);
  }
  53.28% {
    -webkit-transform: rotate(0deg) translate(0, 0);
    transform: rotate(0deg) translate(0, 0);
  }
  62.16% {
    -webkit-transform: rotate(42deg) translate(-9px, -14px);
    transform: rotate(42deg) translate(-9px, -14px);
  }
  68.08% {
    -webkit-transform: rotate(42deg) translate(-9px, -14px);
    transform: rotate(42deg) translate(-9px, -14px);
  }
  72.52% {
    -webkit-transform: rotate(15deg) translate(15px, 2px);
    transform: rotate(15deg) translate(15px, 2px);
  }
  82.88% {
    -webkit-transform: rotate(70deg) translate(0, 0);
    transform: rotate(70deg) translate(0, 0);
  }
  100% {
    -webkit-transform: rotate(15deg) translate(15px, 2px);
    transform: rotate(15deg) translate(15px, 2px);
  }
}
@keyframes rundove-back-foot {
  17.76% {
    -webkit-transform: rotate(-10deg) translate(0, 0);
    transform: rotate(-10deg) translate(0, 0);
  }
  23.52% {
    -webkit-transform: rotate(0deg) translate(0, 0);
    transform: rotate(0deg) translate(0, 0);
  }
  53.28% {
    -webkit-transform: rotate(0deg) translate(0, 0);
    transform: rotate(0deg) translate(0, 0);
  }
  62.16% {
    -webkit-transform: rotate(42deg) translate(-9px, -14px);
    transform: rotate(42deg) translate(-9px, -14px);
  }
  68.08% {
    -webkit-transform: rotate(42deg) translate(-9px, -14px);
    transform: rotate(42deg) translate(-9px, -14px);
  }
  72.52% {
    -webkit-transform: rotate(15deg) translate(15px, 2px);
    transform: rotate(15deg) translate(15px, 2px);
  }
  82.88% {
    -webkit-transform: rotate(70deg) translate(0, 0);
    transform: rotate(70deg) translate(0, 0);
  }
  100% {
    -webkit-transform: rotate(15deg) translate(15px, 2px);
    transform: rotate(15deg) translate(15px, 2px);
  }
}
@-webkit-keyframes rundove-front-wing {
  0% {
    -webkit-transform: rotate(-68deg) translate(0, 0);
    transform: rotate(-68deg) translate(0, 0);
  }
  57.33% {
    -webkit-transform: rotate(-68deg) translate(0, 0);
    transform: rotate(-68deg) translate(0, 0);
  }
  67.62% {
    -webkit-transform: rotate(-210deg) translate(-60px, 0);
    transform: rotate(-210deg) translate(-60px, 0);
  }
  72.03% {
    -webkit-transform: rotate(-70deg) translate(0, 10px);
    transform: rotate(-70deg) translate(0, 10px);
  }
  80.85% {
    -webkit-transform: rotate(-210deg) translate(-60px, 0);
    transform: rotate(-210deg) translate(-60px, 0);
  }
}
@keyframes rundove-front-wing {
  0% {
    -webkit-transform: rotate(-68deg) translate(0, 0);
    transform: rotate(-68deg) translate(0, 0);
  }
  57.33% {
    -webkit-transform: rotate(-68deg) translate(0, 0);
    transform: rotate(-68deg) translate(0, 0);
  }
  67.62% {
    -webkit-transform: rotate(-210deg) translate(-60px, 0);
    transform: rotate(-210deg) translate(-60px, 0);
  }
  72.03% {
    -webkit-transform: rotate(-70deg) translate(0, 10px);
    transform: rotate(-70deg) translate(0, 10px);
  }
  80.85% {
    -webkit-transform: rotate(-210deg) translate(-60px, 0);
    transform: rotate(-210deg) translate(-60px, 0);
  }
}
@-webkit-keyframes rundove-front-finger-1 {
  0% {
    -webkit-transform: rotate(85deg);
    transform: rotate(85deg);
  }
  55.86% {
    -webkit-transform: rotate(85deg);
    transform: rotate(85deg);
  }
  57.33% {
    -webkit-transform: rotate(110deg);
    transform: rotate(110deg);
  }
  67.62% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
@keyframes rundove-front-finger-1 {
  0% {
    -webkit-transform: rotate(85deg);
    transform: rotate(85deg);
  }
  55.86% {
    -webkit-transform: rotate(85deg);
    transform: rotate(85deg);
  }
  57.33% {
    -webkit-transform: rotate(110deg);
    transform: rotate(110deg);
  }
  67.62% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
@-webkit-keyframes rundove-front-finger-2 {
  0% {
    -webkit-transform: rotate(185deg);
    transform: rotate(185deg);
  }
  55.86% {
    -webkit-transform: rotate(185deg);
    transform: rotate(185deg);
  }
  57.33% {
    -webkit-transform: rotate(160deg);
    transform: rotate(160deg);
  }
  67.62% {
    -webkit-transform: rotate(100deg);
    transform: rotate(100deg);
  }
}
@keyframes rundove-front-finger-2 {
  0% {
    -webkit-transform: rotate(185deg);
    transform: rotate(185deg);
  }
  55.86% {
    -webkit-transform: rotate(185deg);
    transform: rotate(185deg);
  }
  57.33% {
    -webkit-transform: rotate(160deg);
    transform: rotate(160deg);
  }
  67.62% {
    -webkit-transform: rotate(100deg);
    transform: rotate(100deg);
  }
}
@-webkit-keyframes rundove-front-finger-3 {
  0% {
    -webkit-transform: rotate(140deg) translate(0, 0);
    transform: rotate(140deg) translate(0, 0);
  }
  55.86% {
    -webkit-transform: rotate(140deg) translate(0, 0);
    transform: rotate(140deg) translate(0, 0);
  }
  57.33% {
    -webkit-transform: rotate(132deg) translate(-8px, -8px);
    transform: rotate(132deg) translate(-8px, -8px);
  }
  67.62% {
    -webkit-transform: rotate(90deg) translate(-8px, -8px);
    transform: rotate(90deg) translate(-8px, -8px);
  }
}
@keyframes rundove-front-finger-3 {
  0% {
    -webkit-transform: rotate(140deg) translate(0, 0);
    transform: rotate(140deg) translate(0, 0);
  }
  55.86% {
    -webkit-transform: rotate(140deg) translate(0, 0);
    transform: rotate(140deg) translate(0, 0);
  }
  57.33% {
    -webkit-transform: rotate(132deg) translate(-8px, -8px);
    transform: rotate(132deg) translate(-8px, -8px);
  }
  67.62% {
    -webkit-transform: rotate(90deg) translate(-8px, -8px);
    transform: rotate(90deg) translate(-8px, -8px);
  }
}
@-webkit-keyframes rundove-back-wing {
  0% {
    -webkit-transform: rotate(110deg) translateY(0);
    transform: rotate(110deg) translateY(0);
  }
  29.4% {
    -webkit-transform: rotate(110deg) translateY(0);
    transform: rotate(110deg) translateY(0);
  }
  35.28% {
    -webkit-transform: rotate(70deg) translateY(-25px);
    transform: rotate(70deg) translateY(-25px);
  }
  49.98% {
    -webkit-transform: rotate(65deg) translateY(-25px);
    transform: rotate(65deg) translateY(-25px);
  }
  67.62% {
    -webkit-transform: rotate(335deg) translateY(0);
    transform: rotate(335deg) translateY(0);
  }
  72.03% {
    -webkit-transform: rotate(125deg) translateY(0);
    transform: rotate(125deg) translateY(0);
  }
  83.79% {
    -webkit-transform: rotate(335deg) translateY(0);
    transform: rotate(335deg) translateY(0);
  }
}
@keyframes rundove-back-wing {
  0% {
    -webkit-transform: rotate(110deg) translateY(0);
    transform: rotate(110deg) translateY(0);
  }
  29.4% {
    -webkit-transform: rotate(110deg) translateY(0);
    transform: rotate(110deg) translateY(0);
  }
  35.28% {
    -webkit-transform: rotate(70deg) translateY(-25px);
    transform: rotate(70deg) translateY(-25px);
  }
  49.98% {
    -webkit-transform: rotate(65deg) translateY(-25px);
    transform: rotate(65deg) translateY(-25px);
  }
  67.62% {
    -webkit-transform: rotate(335deg) translateY(0);
    transform: rotate(335deg) translateY(0);
  }
  72.03% {
    -webkit-transform: rotate(125deg) translateY(0);
    transform: rotate(125deg) translateY(0);
  }
  83.79% {
    -webkit-transform: rotate(335deg) translateY(0);
    transform: rotate(335deg) translateY(0);
  }
}
@-webkit-keyframes rundove-beak {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  32.4% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  33.81% {
    -webkit-transform: scaleX(1.5);
    transform: scaleX(1.5);
  }
  49.98% {
    -webkit-transform: scaleX(1.5);
    transform: scaleX(1.5);
  }
  50% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
@keyframes rundove-beak {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  32.4% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  33.81% {
    -webkit-transform: scaleX(1.5);
    transform: scaleX(1.5);
  }
  49.98% {
    -webkit-transform: scaleX(1.5);
    transform: scaleX(1.5);
  }
  50% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
@-webkit-keyframes rundove-shadow {
  0% {
    -webkit-transform: translateX(-243px);
    transform: translateX(-243px);
  }
  5.88% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
  17.64% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
  23.52% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px);
  }
  52.92% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px);
  }
  67.62% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px);
  }
  72.03% {
    -webkit-transform: translateX(280px);
    transform: translateX(280px);
  }
  76.44% {
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
  }
  86.79% {
    -webkit-transform: translateX(760px);
    transform: translateX(760px);
  }
  100% {
    -webkit-transform: translateX(760px);
    transform: translateX(760px);
  }
}
@keyframes rundove-shadow {
  0% {
    -webkit-transform: translateX(-243px);
    transform: translateX(-243px);
  }
  5.88% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
  17.64% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
  23.52% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px);
  }
  52.92% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px);
  }
  67.62% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px);
  }
  72.03% {
    -webkit-transform: translateX(280px);
    transform: translateX(280px);
  }
  76.44% {
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
  }
  86.79% {
    -webkit-transform: translateX(760px);
    transform: translateX(760px);
  }
  100% {
    -webkit-transform: translateX(760px);
    transform: translateX(760px);
  }
}
@-webkit-keyframes birds-coffee {
  10.29% {
    -webkit-transform: rotate(11deg) translate(4px, -3px);
    transform: rotate(11deg) translate(4px, -3px);
    z-index: 100;
  }
  24.99% {
    -webkit-transform: rotate(-5deg) translate(-2px, -1px);
    transform: rotate(-5deg) translate(-2px, -1px);
  }
  29.4% {
    -webkit-transform: rotate(-1deg) translate(0, -1px);
    transform: rotate(-1deg) translate(0, -1px);
  }
  33.81% {
    -webkit-transform: rotate(-24deg) translate(-20px, -59px);
    transform: rotate(-24deg) translate(-20px, -59px);
    z-index: 100;
  }
  49.98% {
    -webkit-transform: rotate(-44deg) translate(25px, -99px);
    transform: rotate(-44deg) translate(25px, -99px);
    z-index: 0;
  }
  54.39% {
    -webkit-transform: rotate(0deg) translate(-1px, 0);
    transform: rotate(0deg) translate(-1px, 0);
  }
  70.56% {
    -webkit-transform: rotate(0deg) translate(-1px, 0);
    transform: rotate(0deg) translate(-1px, 0);
  }
  79.38% {
    -webkit-transform: rotate(30deg) translate(15px, -6px);
    transform: rotate(30deg) translate(15px, -6px);
  }
  94.08% {
    -webkit-transform: rotate(-20deg) translate(-10px, -5px);
    transform: rotate(-20deg) translate(-10px, -5px);
    z-index: 0;
  }
}
@keyframes birds-coffee {
  10.29% {
    -webkit-transform: rotate(11deg) translate(4px, -3px);
    transform: rotate(11deg) translate(4px, -3px);
    z-index: 100;
  }
  24.99% {
    -webkit-transform: rotate(-5deg) translate(-2px, -1px);
    transform: rotate(-5deg) translate(-2px, -1px);
  }
  29.4% {
    -webkit-transform: rotate(-1deg) translate(0, -1px);
    transform: rotate(-1deg) translate(0, -1px);
  }
  33.81% {
    -webkit-transform: rotate(-24deg) translate(-20px, -59px);
    transform: rotate(-24deg) translate(-20px, -59px);
    z-index: 100;
  }
  49.98% {
    -webkit-transform: rotate(-44deg) translate(25px, -99px);
    transform: rotate(-44deg) translate(25px, -99px);
    z-index: 0;
  }
  54.39% {
    -webkit-transform: rotate(0deg) translate(-1px, 0);
    transform: rotate(0deg) translate(-1px, 0);
  }
  70.56% {
    -webkit-transform: rotate(0deg) translate(-1px, 0);
    transform: rotate(0deg) translate(-1px, 0);
  }
  79.38% {
    -webkit-transform: rotate(30deg) translate(15px, -6px);
    transform: rotate(30deg) translate(15px, -6px);
  }
  94.08% {
    -webkit-transform: rotate(-20deg) translate(-10px, -5px);
    transform: rotate(-20deg) translate(-10px, -5px);
    z-index: 0;
  }
}

@import url("https://fonts.googleapis.com/css?family=Merriweather|Pacifico|Permanent+Marker");

body {
  cursor: default;
  background-color: #3cd1c2;
  font-family: "Merriweather", serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}

@media only screen and (max-width: 950px) {
  #scene {
    display: none !important;
  }
  #floor {
    display: none;
  }
  p.mobile {
    display: block !important;
  }
  h1 {
    font-size: 4em !important;
  }
  h2 {
    font-size: 2em !important;
  }
}

p.mobile {
  color: #f46e65;
  font-size: 1em;
  text-align: center;
  margin-top: 80px;
  line-height: 40px;
  display: none;
}

#scene {
  /* width: 1000px; */
  height: 700px;
  padding: 10px;
  margin: 0 auto;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
}

h1 {
  color: #6686bf;
  text-align: center;
  font-size: 100px;
  line-height: 80px;
}

h2 {
  color: #6686bf;
  text-align: center;
  font-size: 30px;
  line-height: 5px;
}

/*CLOCK*/

#clock {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 10px solid #3e3f4b;
  background-color: #fff;
  position: absolute;
  cursor: pointer;
  margin-left: 400px;
  -webkit-box-shadow: inset 10px 10px 8px #3cd1c2;
  box-shadow: inset 10px 10px 8px #3cd1c2;
}

#clock-face {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background-color: #fff;
  margin: auto;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.clock-marker {
  background-color: #3cd1c2;
  height: 100%;
  width: 2px;
  position: absolute;
  z-index: 0;
  left: 50%;
  margin-left: -1px;
  top: 0;
}

#clock-marker-12-6 {
  background-color: #5fbabf;
}

#clock-marker-1-7 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

#clock-marker-2-8 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

#clock-marker-3-9 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  background-color: #5fbabf;
}

#clock-marker-4-10 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

#clock-marker-5-11 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

#clock-inner {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: #fff;
  margin: auto;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

#clock-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #3e3f4b;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -2.5px;
  margin-top: -2.5px;
}

.hand,
.hand.hour {
  position: absolute;
  width: 4px;
  height: 30%;
  top: 20%;
  left: 50%;
  margin-left: -2px;
  background-color: #f47164;
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  transform: rotate(35deg);
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-animation: spin 43200s infinite linear;
  animation: spin 43200s infinite linear;
}

.hand.minute {
  height: 45%;
  top: 5%;
  width: 3px;
  margin-left: -1.5px;
  -webkit-transform: rotate(50deg);
  -ms-transform: rotate(50deg);
  transform: rotate(50deg);
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-animation: spin 3600s infinite linear;
  animation: spin 3600s infinite linear;
}

.hand.second {
  height: 50%;
  width: 2px;
  margin-left: -1px;
  top: 0;
  background-color: #3e3f4b;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-animation: spin 60s infinite steps(60);
  animation: spin 60s infinite steps(60);
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin-minute {
  100% {
    -webkit-transform: rotate(410deg);
    transform: rotate(410deg);
  }
}

@keyframes spin-minute {
  100% {
    -webkit-transform: rotate(410deg);
    transform: rotate(410deg);
  }
}

@-webkit-keyframes spin-hour {
  100% {
    -webkit-transform: rotate(395deg);
    transform: rotate(395deg);
  }
}

@keyframes spin-hour {
  100% {
    -webkit-transform: rotate(395deg);
    transform: rotate(395deg);
  }
}

#clock:hover .hand.minute {
  -webkit-animation: spin-minute 2s infinite linear;
  animation: spin-minute 2s infinite linear;
}

#clock:hover .hand.hour {
  -webkit-animation: spin-hour 4s infinite linear;
  animation: spin-hour 4s infinite linear;
}

#clock:hover .hand.second {
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}

/*END OF CLOCK*/

/*DESK*/

#desk {
  bottom: 0;
  width: 800px;
  height: 280px;
  margin-left: 100px;
  position: absolute;
}

#desk-top-1,
#desk-top-2,
#desk-top-3 {
  background-color: #f47164;
  position: absolute;
  z-index: 100;
}

#desk-top-1 {
  width: 100%;
  height: 8px;
}

#desk-top-2 {
  width: 98%;
  height: 15px;
  margin-left: 8px;
  margin-top: 8px;
}

#desk-top-3 {
  width: 95%;
  height: 20px;
  margin-left: 18px;
  margin-top: 23px;
}

#desk-top-3-left,
#desk-top-3-right {
  background-color: #3cd1c2;
  width: 20px;
  height: 100%;
  z-index: 1;
  position: absolute;
  border-radius: 50%;
  margin-top: 8px;
}

#desk-top-3-left {
  margin-left: -8px;
  left: 0;
}

#desk-top-3-right {
  margin-right: -8px;
  right: 0;
}

#desk-leg-left,
#desk-leg-right {
  z-index: 5;
  position: absolute;
  border-left: solid 25px #f47164;
  height: 240px;
  margin-top: 43px;
}

#desk-leg-left {
  margin-left: 40px;
}

#desk-leg-right {
  margin-left: 730px;
}

/*END OF DESK*/

/*BOOK STACK*/

#stack-book {
  width: 135px;
  height: auto;
  display: inline-block;
  margin-top: 270px;
  margin-left: 150px;
  cursor: pointer;
  position: absolute;
  z-index: 100;
}

#stack-book-1,
.stack-book-2,
.stack-book-3,
.stack-book-4,
#stack-book-5,
.stack-book-6,
.stack-book-7,
#stack-book-8,
.stack-book-9,
#stack-book-10,
#stack-book-11,
#stack-book-12 {
  position: relative;
  -webkit-box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.2);
  top: 0;
}

#stack-book-1 {
  background-color: #fff;
  height: 3px;
  width: 100px;
  border: solid 3px #84a840;
  border-left: none;
}

.stack-book-2 {
  background-color: #fff;
  height: 5px;
  width: 100px;
  border: solid 3px #6787c0;
  border-left: none;
  margin-left: 5px;
  -webkit-transform: skew(15deg);
  -ms-transform: skew(15deg);
  transform: skew(15deg);
}

.stack-book-3 {
  background-color: #fff;
  height: 8px;
  width: 105px;
  border: solid 4px #6787c0;
  border-right: none;
  margin-left: 8px;
  -webkit-transform: skew(-10deg);
  -ms-transform: skew(-10deg);
  transform: skew(-10deg);
}

.stack-book-4 {
  background-color: #fff;
  height: 15px;
  width: 110px;
  border: solid 4px #81a748;
  border-right: none;
  border-left: none;
  margin-left: 0px;
}

#stack-book-5 {
  background-color: #fcd57e;
  height: 8px;
  width: 110px;
  border: solid 6px #eabbae;
  border-right: none;
  margin-left: 10px;
  -webkit-transform: skew(10deg);
  -ms-transform: skew(10deg);
  transform: skew(10deg);
}

.stack-book-6 {
  background-color: #fcd57e;
  height: 0px;
  width: 110px;
  border: solid 3px #698cc1;
  border-right: none;
  margin-left: 0px;
}

.stack-book-7 {
  background-color: #fff;
  height: 15px;
  width: 115px;
  border: solid 6px #f47164;
  border-left: none;
  border-right: none;
  margin-left: 4px;
  -webkit-transform: skew(-10deg);
  -ms-transform: skew(-10deg);
  transform: skew(-10deg);
}

#stack-book-8 {
  background-color: #fff;
  height: 0px;
  width: 115px;
  border: solid 3px #81a748;
  border-left: none;
  border-right: none;
  margin-left: 15px;
}

#stack-book-9 {
  background-color: #fcd57e;
  height: 6px;
  width: 115px;
  border: solid 4px #eabbae;
  border-left: none;
  border-right: none;
  margin-left: 0px;
}

#stack-book-10 {
  background-color: #fff;
  height: 15px;
  width: 110px;
  border: solid 5px #698cc1;
  border-left: none;
  border-right: none;
  margin-left: 0px;
  -webkit-transform: skew(10deg);
  -ms-transform: skew(10deg);
  transform: skew(10deg);
}

#stack-book-11 {
  background-color: #fcd57e;
  height: 0px;
  width: 110px;
  border: solid 4px #8e65a4;
  border-right: none;
  margin-left: 10px;
}

#stack-book-12 {
  background-color: #fcd57e;
  height: 0px;
  width: 110px;
  border: solid 4px #fae7c7;
  border-right: none;
  margin-left: 5px;
}

#stack-book:hover #stack-book-1 {
  -webkit-animation: wiggle-1 0.2s infinite linear;
  animation: wiggle-1 0.2s infinite linear;
}

#stack-book:hover .stack-book-2 {
  -webkit-animation: wiggle-2 0.4s infinite linear;
  animation: wiggle-2 0.4s infinite linear;
}

#stack-book:hover .stack-book-3 {
  -webkit-animation: wiggle-3 0.5s infinite linear;
  animation: wiggle-3 0.5s infinite linear;
}

@-webkit-keyframes wiggle-1 {
  0% {
    -webkit-transform: rotate(5deg) translateY(-15px);
    transform: rotate(5deg) translateY(-15px);
  }
  50% {
    -webkit-transform: rotate(-5deg) translateY(-15px);
    transform: rotate(-5deg) translateY(-15px);
  }
  100% {
    -webkit-transform: rotate(5deg) translateY(-15px);
    transform: rotate(5deg) translateY(-15px);
  }
}

@keyframes wiggle-1 {
  0% {
    -webkit-transform: rotate(5deg) translateY(-15px);
    transform: rotate(5deg) translateY(-15px);
  }
  50% {
    -webkit-transform: rotate(-5deg) translateY(-15px);
    transform: rotate(-5deg) translateY(-15px);
  }
  100% {
    -webkit-transform: rotate(5deg) translateY(-15px);
    transform: rotate(5deg) translateY(-15px);
  }
}

@-webkit-keyframes wiggle-2 {
  0% {
    -webkit-transform: rotate(5deg) translateY(-10px);
    transform: rotate(5deg) translateY(-10px);
  }
  50% {
    -webkit-transform: rotate(-5deg) translateY(-10px);
    transform: rotate(-5deg) translateY(-10px);
  }
  100% {
    -webkit-transform: rotate(5deg) translateY(-10px);
    transform: rotate(5deg) translateY(-10px);
  }
}

@keyframes wiggle-2 {
  0% {
    -webkit-transform: rotate(5deg) translateY(-10px);
    transform: rotate(5deg) translateY(-10px);
  }
  50% {
    -webkit-transform: rotate(-5deg) translateY(-10px);
    transform: rotate(-5deg) translateY(-10px);
  }
  100% {
    -webkit-transform: rotate(5deg) translateY(-10px);
    transform: rotate(5deg) translateY(-10px);
  }
}

@-webkit-keyframes wiggle-3 {
  0% {
    -webkit-transform: rotate(5deg) translateY(-5px);
    transform: rotate(5deg) translateY(-5px);
  }
  50% {
    -webkit-transform: rotate(-5deg) translateY(-5px);
    transform: rotate(-5deg) translateY(-5px);
  }
  100% {
    -webkit-transform: rotate(5deg) translateY(-5px);
    transform: rotate(5deg) translateY(-5px);
  }
}

@keyframes wiggle-3 {
  0% {
    -webkit-transform: rotate(5deg) translateY(-5px);
    transform: rotate(5deg) translateY(-5px);
  }
  50% {
    -webkit-transform: rotate(-5deg) translateY(-5px);
    transform: rotate(-5deg) translateY(-5px);
  }
  100% {
    -webkit-transform: rotate(5deg) translateY(-5px);
    transform: rotate(5deg) translateY(-5px);
  }
}

/*END OF BOOK STACK*/

/*BINDER STACK*/

#stack-binder {
  width: auto;
  height: auto;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  margin-top: 305px;
  margin-left: 290px;
  z-index: 100;
}

.stack-binder-right-bind {
  width: 120px;
  height: 35px;
  display: inline-block;
  position: relative;
}

.stack-binder-right-top,
.stack-binder-right-bottom {
  width: 125px;
  height: 5px;
  position: absolute;
  -webkit-transform-origin: 100%;
  -ms-transform-origin: 100%;
  transform-origin: 100%;
}

.stack-binder-right-top {
  top: 0;
  -webkit-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}

.stack-binder-right-bottom {
  bottom: 0;
  -webkit-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
}

.stack-binder-left-paper,
.stack-binder-right-paper {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(50%, rgba(165, 216, 219, 1)),
    color-stop(50%, rgba(255, 255, 255, 1))
  );
  background-image: -webkit-linear-gradient(
    rgba(165, 216, 219, 1) 50%,
    rgba(255, 255, 255, 1) 50%
  );
  background-image: -o-linear-gradient(
    rgba(165, 216, 219, 1) 50%,
    rgba(255, 255, 255, 1) 50%
  );
  background-image: linear-gradient(
    rgba(165, 216, 219, 1) 50%,
    rgba(255, 255, 255, 1) 50%
  );
  background-size: 1px 2px;
  display: inline-block;
  margin-top: 4px;
  position: relative;
  height: 12px;
}

.stack-binder-left-paper {
  width: 100px;
  margin-left: 15px;
}

.stack-binder-right-paper {
  width: 95px;
  margin-left: -90px;
}

.stack-binder-left-ring,
.stack-binder-right-ring {
  background-color: transparent;
  margin-top: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: solid 2px #fff;
  display: inline-block;
  position: absolute;
}

.stack-binder-right-ring {
  margin-left: 95px;
}

.stack-binder-left-ring {
}

.stack-binder-left-bind {
  width: 120px;
  height: 35px;
  display: inline-block;
  position: relative;
}

.stack-binder-left-top,
.stack-binder-left-bottom {
  position: absolute;
  width: 125px;
  height: 5px;
  -webkit-transform-origin: 0%;
  -ms-transform-origin: 0%;
  transform-origin: 0%;
}

.stack-binder-left-top {
  top: 0;
  -webkit-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
}

.stack-binder-left-bottom {
  bottom: 0;
  -webkit-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}

#stack-binder-1 {
  display: block;
  -webkit-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  -webkit-transform-origin: 0%;
  -ms-transform-origin: 0%;
  transform-origin: 0%;
  position: relative;
  margin-bottom: -10px;
  margin-left: 20px;
  position: relative;
}

#stack-binder-1-bind {
  border-right: 8px solid #84a840;
}

.stack-binder-1-tb {
  background-color: #84a840;
}

#stack-binder-2,
#stack-binder-3,
#stack-binder-4 {
  display: block;
  -webkit-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  -webkit-transform-origin: 0%;
  -ms-transform-origin: 0%;
  transform-origin: 0%;
  position: relative;
  margin-bottom: -10px;
}

#stack-binder-2 {
  margin-left: 15px;
}

#stack-binder-3 {
  margin-left: 10px;
}

#stack-binder-3-bind {
  border-right: 10px solid #eabbae;
}

.stack-binder-3-tb {
  background-color: #eabbae;
}

.stack-binder-4-bind {
  border-left: 15px solid #6787c0;
}

.stack-binder-4-tb {
  background-color: #6787c0;
}

#stack-binder:hover .stack-binder-right-top {
  -webkit-animation: flip-right 1s ease forwards;
  animation: flip-right 1s ease forwards;
}

#stack-binder:hover .stack-binder-left-top {
  -webkit-animation: flip-left 1s ease forwards;
  animation: flip-left 1s ease forwards;
}

#stack-binder:hover #stack-binder-1 {
  -webkit-animation: raise-1 0.5s ease forwards;
  animation: raise-1 0.5s ease forwards;
  -webkit-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  transform: rotate(-10deg);
}

#stack-binder:hover #stack-binder-2 {
  -webkit-animation: raise-2 0.5s ease forwards;
  animation: raise-2 0.5s ease forwards;
  -webkit-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

#stack-binder:hover #stack-binder-3 {
  -webkit-animation: raise-3 0.5s ease forwards;
  animation: raise-3 0.5s ease forwards;
  -webkit-transform: rotate(-2deg);
  -ms-transform: rotate(-2deg);
  transform: rotate(-2deg);
}

@-webkit-keyframes raise-1 {
  100% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}

@keyframes raise-1 {
  100% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}

@-webkit-keyframes raise-2 {
  100% {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }
}

@keyframes raise-2 {
  100% {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }
}

@-webkit-keyframes raise-3 {
  100% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

@keyframes raise-3 {
  100% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

@-webkit-keyframes flip-right {
  100% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
}

@keyframes flip-right {
  100% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
}

@-webkit-keyframes flip-left {
  100% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
}

@keyframes flip-left {
  100% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
}

/*END OF BINDER STACK*/

/*PENCIL HOLDER*/

#pencil-holder {
  width: auto;
  height: auto;
  display: inline-block;
  position: absolute;
  margin-top: 400px;
  margin-left: 550px;
  z-index: 100;
}

#pencil-cup {
  display: inline-block;
  background-color: #fcd57e;
  width: 35px;
  height: 45px;
  position: absolute;
  z-index: 100;
  margin-top: 385px;
  margin-left: 530px;
  cursor: pointer;
  z-index: 110;
}

#pencil-cup:after {
  content: "";
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 50%;
  border: 5px solid #fcd57e;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  display: inline-block;
  position: relative;
  -webkit-transform: translate(40px, 5px);
  -ms-transform: translate(40px, 5px);
  transform: translate(40px, 5px);
}

#heart {
  display: inline-block;
  position: relative;
  width: 12px;
  height: 20px;
  margin-left: -33px;
  margin-top: 12px;
  z-index: 100;
}

#heart:before,
#heart:after {
  position: absolute;
  content: "";
  left: 50px;
  top: 0;
  width: 12px;
  height: 18px;
  background: #f47164;
  border-radius: 50px 50px 0 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  transform-origin: 0 100%;
  z-index: 100;
}

#heart:after {
  margin-left: 38px;
  left: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

#ruler {
  display: inline-block;
  width: 80px;
  height: 13px;
  margin-left: 7px;
  background: -webkit-repeating-linear-gradient(
    left,
    #3e3f4b,
    #3e3f4b 1px,
    #fae7c7 1px,
    #fae7c7 5px
  );
  background: -o-repeating-linear-gradient(
    left,
    #3e3f4b,
    #3e3f4b 1px,
    #fae7c7 1px,
    #fae7c7 5px
  );
  background: repeating-linear-gradient(
    to right,
    #3e3f4b,
    #3e3f4b 1px,
    #fae7c7 1px,
    #fae7c7 5px
  );
  -webkit-transform: rotate(-80deg);
  -ms-transform: rotate(-80deg);
  transform: rotate(-80deg);
  -webkit-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

#ruler-inner {
  width: 80px;
  height: 7px;
  background-color: #fae7c7;
}

#ruler:hover {
  -webkit-animation: ruler-raise 0.5s ease forwards;
  animation: ruler-raise 0.5s ease forwards;
  cursor: pointer;
}

@-webkit-keyframes ruler-raise {
  100% {
    -webkit-transform: translate(10px, -40px) rotate(10deg) rotate(-80deg);
    transform: translate(10px, -40px) rotate(10deg) rotate(-80deg);
  }
}

@keyframes ruler-raise {
  100% {
    -webkit-transform: translate(10px, -40px) rotate(10deg) rotate(-80deg);
    transform: translate(10px, -40px) rotate(10deg) rotate(-80deg);
  }
}

#pencil-1,
#pencil-2 {
  -webkit-transform-origin: 0%;
  -ms-transform-origin: 0%;
  transform-origin: 0%;
  margin-top: -60px;
  border-bottom: solid 8px #eabbae;
  position: absolute;
  width: 8px;
  height: 60px;
}

#pencil-1 {
  background: -webkit-repeating-linear-gradient(
    left,
    #f6ba52,
    #f6ba52 2px,
    #f47164 2px,
    #f47164 4px
  );
  background: -o-repeating-linear-gradient(
    left,
    #f6ba52,
    #f6ba52 2px,
    #f47164 2px,
    #f47164 4px
  );
  background: repeating-linear-gradient(
    to right,
    #f6ba52,
    #f6ba52 2px,
    #f47164 2px,
    #f47164 4px
  );
  margin-left: -15px;
  -webkit-transform: rotate(-8deg);
  -ms-transform: rotate(-8deg);
  transform: rotate(-8deg);
}

#pencil-2 {
  background: -webkit-repeating-linear-gradient(
    left,
    #84a840,
    #84a840 2px,
    #6787c0 2px,
    #6787c0 4px
  );
  background: -o-repeating-linear-gradient(
    left,
    #84a840,
    #84a840 2px,
    #6787c0 2px,
    #6787c0 4px
  );
  background: repeating-linear-gradient(
    to right,
    #84a840,
    #84a840 2px,
    #6787c0 2px,
    #6787c0 4px
  );
  margin-left: -5px;
  -webkit-transform: rotate(-2deg);
  -ms-transform: rotate(-2deg);
  transform: rotate(-2deg);
}

.pencil-tip,
.pencil-tip-top {
  position: relative;
  margin-top: -10px;
  width: 0;
  height: 0;
}

.pencil-tip {
  border-bottom: 10px solid #fae7c7;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.pencil-tip-top {
  margin-left: -1.5px;
  border-bottom: 4px solid #3e3f4b;
  border-left: 1.5px solid transparent;
  border-right: 1.5px solid transparent;
}

#pencil-1:hover {
  -webkit-animation: pencil-raise 0.8s ease forwards;
  animation: pencil-raise 0.8s ease forwards;
  cursor: pointer;
  -webkit-transform-origin: 0%;
  -ms-transform-origin: 0%;
  transform-origin: 0%;
}

#pencil-2:hover {
  -webkit-animation: pencil-raise-2 0.8s ease forwards;
  animation: pencil-raise-2 0.8s ease forwards;
  cursor: pointer;
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}

@-webkit-keyframes pencil-raise {
  100% {
    -webkit-transform: translate(-10px, -50px) rotate(-10deg);
    transform: translate(-10px, -50px) rotate(-10deg);
  }
}

@keyframes pencil-raise {
  100% {
    -webkit-transform: translate(-10px, -50px) rotate(-10deg);
    transform: translate(-10px, -50px) rotate(-10deg);
  }
}

@-webkit-keyframes pencil-raise-2 {
  100% {
    -webkit-transform: translate(-2px, -60px);
    transform: translate(-2px, -60px);
  }
}

@keyframes pencil-raise-2 {
  100% {
    -webkit-transform: translate(-2px, -60px);
    transform: translate(-2px, -60px);
  }
}

#pencil-cup:hover #heart {
  -webkit-animation: heartbeat 0.5s infinite ease;
  animation: heartbeat 0.5s infinite ease;
  cursor: pointer;
}

@-webkit-keyframes heartbeat {
  100% {
    -webkit-transform: scale(1.2) translate(-7px, 0px);
    transform: scale(1.2) translate(-7px, 0px);
  }
}

@keyframes heartbeat {
  100% {
    -webkit-transform: scale(1.2) translate(-7px, 0px);
    transform: scale(1.2) translate(-7px, 0px);
  }
}

/*END OF PENCIL HOLDER*/

/*COFFEE CUP*/

#coffee-cup {
  width: auto;
  height: auto;
  display: inline-block;
  position: absolute;
  margin-top: 375px;
  margin-left: 460px;
  z-index: 100;
}

#coffee-cup-bottom {
  border-top: 55px solid #fff;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  height: 0;
  width: 25px;
  position: relative;
}

#coffee-cup-holder {
  border-top: 20px solid #eabbae;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  height: 0;
  width: 31px;
  position: relative;
  z-index: 100;
  margin-top: -40px;
  margin-left: -5px;
}

#coffee-cup-holder:before {
  content: "";
  position: absolute;
  left: 1px;
  bottom: -1px;
  height: 1px;
  width: 29px;
  border-bottom: 1px solid #3cd1c2;
}

#coffee-cup-logo {
  background-color: #84a840;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: relative;
  margin-top: -17px;
  margin-left: 8px;
}

#coffee-cup-top {
  border-bottom: 10px solid #fff;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  height: 0;
  width: 25px;
  margin-top: -10px;
  position: relative;
}

#coffee-cup-rim {
  background-color: #fff;
  width: 41px;
  height: 3px;
  margin-left: -8px;
  margin-top: 10px;
  position: absolute;
  z-index: 100;
  border-bottom: 1px solid #3cd1c2;
  border-top: 0.5px solid #3cd1c2;
}

#coffee-cup:hover #coffee-cup-top {
  cursor: pointer;
  -webkit-animation: lid 2s ease infinite;
  animation: lid 2s ease infinite;
  -webkit-transform-origin: 100%;
  -ms-transform-origin: 100%;
  transform-origin: 100%;
}

#coffee-cup:hover {
  cursor: pointer;
  -webkit-animation: shake 1s ease infinite;
  animation: shake 1s ease infinite;
  -webkit-transform-origin: 100%;
  -ms-transform-origin: 100%;
  transform-origin: 100%;
}

@-webkit-keyframes lid {
  0% {
    -webkit-transform: rotate(0deg) translate(0px, -0px);
    transform: rotate(0deg) translate(0px, -0px);
  }
  50% {
    -webkit-transform: rotate(30deg) translate(5px, -8px);
    transform: rotate(30deg) translate(5px, -8px);
  }
  100% {
    -webkit-transform: rotate(0deg) translate(0px, -0px);
    transform: rotate(0deg) translate(0px, -0px);
  }
}

@keyframes lid {
  0% {
    -webkit-transform: rotate(0deg) translate(0px, -0px);
    transform: rotate(0deg) translate(0px, -0px);
  }
  50% {
    -webkit-transform: rotate(30deg) translate(5px, -8px);
    transform: rotate(30deg) translate(5px, -8px);
  }
  100% {
    -webkit-transform: rotate(0deg) translate(0px, -0px);
    transform: rotate(0deg) translate(0px, -0px);
  }
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate(2px, -5px) rotate(0deg);
    transform: translate(2px, -5px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -3px) rotate(-1deg);
    transform: translate(-1px, -3px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px, -4px) rotate(1deg);
    transform: translate(-3px, -4px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(0px, -5px) rotate(0deg);
    transform: translate(0px, -5px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -3px) rotate(1deg);
    transform: translate(1px, -3px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, -4px) rotate(-1deg);
    transform: translate(-1px, -4px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, -5px) rotate(0deg);
    transform: translate(-3px, -5px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(2px, -2px) rotate(-1deg);
    transform: translate(2px, -2px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, -3px) rotate(1deg);
    transform: translate(-1px, -3px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(2px, -5px) rotate(0deg);
    transform: translate(2px, -5px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: translate(2px, -5px) rotate(0deg);
    transform: translate(2px, -5px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -3px) rotate(-1deg);
    transform: translate(-1px, -3px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px, -4px) rotate(1deg);
    transform: translate(-3px, -4px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(0px, -5px) rotate(0deg);
    transform: translate(0px, -5px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -3px) rotate(1deg);
    transform: translate(1px, -3px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, -4px) rotate(-1deg);
    transform: translate(-1px, -4px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, -5px) rotate(0deg);
    transform: translate(-3px, -5px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(2px, -2px) rotate(-1deg);
    transform: translate(2px, -2px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, -3px) rotate(1deg);
    transform: translate(-1px, -3px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(2px, -5px) rotate(0deg);
    transform: translate(2px, -5px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

p.cup {
  position: absolute;
  display: inline-block;
  font-family: "Pacifico", cursive;
  font-size: 8px;
  color: #3e3f4b;
  margin-left: 2px;
  margin-top: -28px;
  -webkit-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  transform: rotate(-15deg);
}

/*END OF COFFEE CUP*/

/*TAKE-OUT*/

#take-out {
  width: auto;
  height: auto;
  display: inline-block;
  position: absolute;
  margin-top: 313px;
  margin-left: 800px;
  cursor: pointer;
}

#take-out-box {
  position: absolute;
  z-index: 100;
}

#take-out-box-top {
  border-bottom: 20px solid #c0e0e3;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  height: 0;
  width: 32px;
  margin-top: -20px;
  position: relative;
}

#take-out-box-bottom {
  border-top: 50px solid #fff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  height: 0;
  width: 35px;
  position: relative;
}

#take-out-box-bottom-back {
  background-color: #c0e0e3;
  width: 15px;
  height: 50px;
  position: relative;
  margin-left: 47px;
  margin-top: -50px;
  -webkit-transform: skew(-9deg, 0deg);
  -ms-transform: skew(-9deg, 0deg);
  transform: skew(-9deg, 0deg);
}

#chopsticks {
  width: auto;
  height: auto;
  display: inline-block;
  position: relative;
  margin-top: -40px;
  margin-left: 40px;
  z-index: 50;
}

#chopsticks-1,
#chopsticks-2 {
  position: relative;
  background-color: #f47164;
  height: 70px;
  width: 3px;
  display: inline-block;
}

#chopsticks-1 {
  -webkit-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  transform: rotate(15deg);
}

#chopsticks-2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
  margin-left: 10px;
}

#take-out:hover #chopsticks-1 {
  -webkit-animation: chopstick-raise 1s ease forwards;
  animation: chopstick-raise 1s ease forwards;
}

#take-out:hover #chopsticks-2 {
  -webkit-animation: chopstick-raise-2 1s ease forwards;
  animation: chopstick-raise-2 1s ease forwards;
}

@-webkit-keyframes chopstick-raise {
  20% {
    -webkit-transform: translate(20px, -55px) rotate(50deg);
    transform: translate(20px, -55px) rotate(50deg);
  }
  50% {
    -webkit-transform: translate(20px, -55px) rotate(30deg);
    transform: translate(20px, -55px) rotate(30deg);
  }
  65% {
    -webkit-transform: translate(20px, -55px) rotate(50deg);
    transform: translate(20px, -55px) rotate(50deg);
  }
  80% {
    -webkit-transform: translate(20px, -55px) rotate(30deg);
    transform: translate(20px, -55px) rotate(30deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(15deg);
    transform: translate(0px, 0px) rotate(15deg);
  }
}

@keyframes chopstick-raise {
  20% {
    -webkit-transform: translate(20px, -55px) rotate(50deg);
    transform: translate(20px, -55px) rotate(50deg);
  }
  50% {
    -webkit-transform: translate(20px, -55px) rotate(30deg);
    transform: translate(20px, -55px) rotate(30deg);
  }
  65% {
    -webkit-transform: translate(20px, -55px) rotate(50deg);
    transform: translate(20px, -55px) rotate(50deg);
  }
  80% {
    -webkit-transform: translate(20px, -55px) rotate(30deg);
    transform: translate(20px, -55px) rotate(30deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(15deg);
    transform: translate(0px, 0px) rotate(15deg);
  }
}

@-webkit-keyframes chopstick-raise-2 {
  20% {
    -webkit-transform: translate(10px, -50px) rotate(35deg);
    transform: translate(10px, -50px) rotate(35deg);
  }
  50% {
    -webkit-transform: translate(10px, -50px) rotate(40deg);
    transform: translate(10px, -50px) rotate(40deg);
  }
  65% {
    -webkit-transform: translate(10px, -50px) rotate(35deg);
    transform: translate(10px, -50px) rotate(35deg);
  }
  80% {
    -webkit-transform: translate(10px, -50px) rotate(40deg);
    transform: translate(10px, -50px) rotate(40deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(30deg);
    transform: translate(0px, 0px) rotate(30deg);
  }
}

@keyframes chopstick-raise-2 {
  20% {
    -webkit-transform: translate(10px, -50px) rotate(35deg);
    transform: translate(10px, -50px) rotate(35deg);
  }
  50% {
    -webkit-transform: translate(10px, -50px) rotate(40deg);
    transform: translate(10px, -50px) rotate(40deg);
  }
  65% {
    -webkit-transform: translate(10px, -50px) rotate(35deg);
    transform: translate(10px, -50px) rotate(35deg);
  }
  80% {
    -webkit-transform: translate(10px, -50px) rotate(40deg);
    transform: translate(10px, -50px) rotate(40deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(30deg);
    transform: translate(0px, 0px) rotate(30deg);
  }
}

#take-out:hover #take-out-box {
  -webkit-animation: bounce 1s ease forwards;
  animation: bounce 1s ease forwards;
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  20% {
    -webkit-transform: rotateX(-30deg) translate(0px, 3px);
    transform: rotateX(-30deg) translate(0px, 3px);
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  20% {
    -webkit-transform: rotateX(-30deg) translate(0px, 3px);
    transform: rotateX(-30deg) translate(0px, 3px);
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
}

#take-out-box-handle,
#take-out-box-handle-dot {
  background-color: #3e3f4b;
  position: absolute;
  z-index: 100;
}

#take-out-box-handle {
  height: 35px;
  margin-top: -45px;
  width: 3px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  display: inline-block;
  margin-left: 3px;
}

#take-out-box-handle-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-top: -2px;
  margin-left: -1px;
}

p.box {
  position: relative;
  display: inline-block;
  font-family: "Permanent Marker", cursive;
  font-size: 9px;
  text-align: center;
  line-height: 10px;
  color: #f47164;
  margin-left: -40px;
  margin-top: 20px;
  -webkit-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
  opacity: 0.8;
}

/*END OF TAKE-OUT*/

/*COMPUTER*/

#computer {
  width: auto;
  height: auto;
  display: inline-block;
  position: absolute;
  margin-top: 180px;
  margin-left: 520px;
  z-index: 10;
}

#computer-back {
  background-color: #d9edee;
  width: 250px;
  height: 160px;
  border-radius: 20px;
  position: relative;
  margin-left: 20px;
}

#computer-side {
  background-color: #fff;
  width: 50px;
  height: 160px;
  border-radius: 20px 0px 0px 20px;
  position: relative;
  margin-top: 35px;
}

#computer-logo {
  position: relative;
  background-color: #bfdfe4;
  width: 30px;
  height: 26px;
  border-radius: 50%;
  margin: auto;
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
}

#computer-logo-bite {
  position: absolute;
  background-color: #d9edee;
  width: 15px;
  height: 13px;
  border-radius: 50%;
  -webkit-transform: translate(105px, 30px);
  -ms-transform: translate(105px, 30px);
  transform: translate(105px, 30px);
}

#computer-logo-stem {
  position: absolute;
  background-color: #bfdfe4;
  width: 4px;
  height: 10px;
  -webkit-transform: translate(120px, 15px) rotate(-15deg);
  -ms-transform: translate(120px, 15px) rotate(-15deg);
  transform: translate(120px, 15px) rotate(-15deg);
}

#computer-logo-leaf {
  position: absolute;
  background-color: #bfdfe4;
  width: 7px;
  height: 15px;
  border-radius: 50%;
  -webkit-transform: translate(126px, 15px) rotate(30deg);
  -ms-transform: translate(126px, 15px) rotate(30deg);
  transform: translate(126px, 15px) rotate(30deg);
}

#computer-stand {
  position: relative;
  margin-top: -65px;
  margin-left: 125px;
  border-bottom: 120px solid #6686bf;
  border-right: 20px solid transparent;
  border-radius: 0px 0px 20px 0px;
  height: 0;
  width: 50px;
  -webkit-transform: skewX(5deg);
  -ms-transform: skewX(5deg);
  transform: skewX(5deg);
  cursor: pointer;
}

#computer-stand-hole {
  position: relative;
  background-color: #bfdfe4;
  width: 25px;
  height: 25px;
  margin-left: 15px;
  margin-bottom: -15px;
  display: inline-block;
  border-radius: 50%;
}

#computer-stand-hole:after {
  content: "";
  position: absolute;
  background-color: #3cd1c2;
  height: 15px;
  width: 25px;
  border-radius: 0 0 90px 90px;
  bottom: 0;
}

#computer-stand-shadow {
  position: relative;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 65px solid #bfdfe4;
  margin-left: -16px;
  display: inline-block;
}

#computer-stand-bottom {
  position: relative;
  margin-top: -10px;
  margin-left: 80px;
  background-color: #6686bf;
  width: 55px;
  height: 10px;
  cursor: pointer;
}

#computer-stand-curve {
  position: relative;
  margin-top: -15px;
  margin-left: 75px;
  background-color: #3cd1c2;
  border-radius: 0px 0px 15px 0px;
  width: 55px;
  height: 10px;
  -webkit-transform: skewX(5deg);
  -ms-transform: skewX(5deg);
  transform: skewX(5deg);
}

#computer-cable {
  display: inline-block;
  position: relative;
  height: 55px;
  width: 60px;
  background: transparent;
  border-radius: 0px 0px 0px 370px/280px;
  border: solid 3px #d9edee;
  border-top: none;
  border-right: none;
  margin: 20px;
  margin-top: -5px;
  margin-left: 30px;
}

#computer:hover #computer-side {
  -webkit-animation: computer-adjust 2s ease infinite;
  animation: computer-adjust 2s ease infinite;
  cursor: pointer;
}

@-webkit-keyframes computer-adjust {
  50% {
    -webkit-transform: skewX(8deg);
    transform: skewX(8deg);
  }
  100% {
    -webkit-transform: skewX(0deg);
    transform: skewX(0deg);
  }
}

@keyframes computer-adjust {
  50% {
    -webkit-transform: skewX(8deg);
    transform: skewX(8deg);
  }
  100% {
    -webkit-transform: skewX(0deg);
    transform: skewX(0deg);
  }
}

/*END OF COMPUTER*/

/*BOOK STACK SMALL*/

#stack-book-small {
  width: 135px;
  height: auto;
  display: inline-block;
  margin-top: 355px;
  margin-left: 750px;
  position: absolute;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  z-index: 100;
}

#stack-book-small:hover {
  -webkit-animation: book-stack-shift 0.5s ease forwards;
  animation: book-stack-shift 0.5s ease forwards;
  cursor: pointer;
}

@-webkit-keyframes book-stack-shift {
  40% {
    -webkit-transform: skewX(3deg) scale(0.8) translate(-3px);
    transform: skewX(3deg) scale(0.8) translate(-3px);
  }
  60% {
    -webkit-transform: skewX(-3deg) scale(0.8) translate(3px);
    transform: skewX(-3deg) scale(0.8) translate(3px);
  }
  100% {
    -webkit-transform: skewX(0deg) scale(0.8);
    transform: skewX(0deg) scale(0.8);
  }
}

@keyframes book-stack-shift {
  40% {
    -webkit-transform: skewX(3deg) scale(0.8) translate(-3px);
    transform: skewX(3deg) scale(0.8) translate(-3px);
  }
  60% {
    -webkit-transform: skewX(-3deg) scale(0.8) translate(3px);
    transform: skewX(-3deg) scale(0.8) translate(3px);
  }
  100% {
    -webkit-transform: skewX(0deg) scale(0.8);
    transform: skewX(0deg) scale(0.8);
  }
}

/*END OF BOOK STACK SMALL*/

/*CHAIR*/

#chair {
  width: auto;
  height: auto;
  display: inline-block;
  margin-top: 540px;
  margin-left: 410px;
  position: absolute;
  z-index: 5;
}

#chair-seat,
#chair-arm-left,
#chair-arm-right {
  background-color: #8e65a4;
}

#chair-seat {
  position: relative;
  width: 210px;
  height: 30px;
  border-radius: 25px;
}

#chair-arm-left,
#chair-arm-right {
  position: absolute;
  width: 50px;
  height: 90px;
  bottom: 0;
  border-radius: 20px;
  z-index: 10;
}

#chair-arm-left {
  left: 0;
}

#chair-arm-right {
  right: 0;
}

#chair-back,
#chair-spine,
#chair-pole {
  position: absolute;
  background-color: #4f395c;
}

#chair-back {
  width: 150px;
  height: 200px;
  bottom: 0;
  margin-left: 30px;
  border-radius: 20px;
  z-index: 1;
  margin-bottom: 50px;
  pointer-events: none;
  z-index: 0;
}

#chair-spine {
  width: 100px;
  height: 20px;
  bottom: 0;
  margin-left: 55px;
  margin-bottom: 30px;
}

#chair-pole {
  width: 30px;
  height: 50px;
  top: 0;
  margin-left: 90px;
  margin-top: 30px;
}

#chair-rod {
  position: relative;
  background-color: #4f395c;
  width: 15px;
  height: 40px;
  margin-left: 8px;
  margin-top: 50px;
}

#chair-legs {
  position: relative;
  margin-top: 90px;
  display: inline-block;
}

#chair-leg-middle {
  border-top: 35px solid #4f395c;
  margin-left: 20px;
  margin-bottom: 23px;
}

#chair-leg-middle,
#chair-leg-left,
#chair-leg-right {
  position: relative;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  display: inline-block;
  height: 0;
  width: 5px;
}

#chair-leg-left,
#chair-leg-right {
  -webkit-transform-origin: 100%;
  -ms-transform-origin: 100%;
  transform-origin: 100%;
  border-top: 75px solid #4f395c;
}

#chair-leg-left {
  margin-left: 60px;
  -webkit-transform: rotate(70deg);
  -ms-transform: rotate(70deg);
  transform: rotate(70deg);
}

#chair-leg-right {
  -webkit-transform: rotate(-70deg);
  -ms-transform: rotate(-70deg);
  transform: rotate(-70deg);
  margin-left: 10px;
  margin-bottom: 14px;
}

#left-wheel,
#middle-wheel,
#right-wheel {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #4f395c;
}

#left-wheel {
  -webkit-transform: translate(0px, -5px);
  -ms-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
}

#middle-wheel {
  -webkit-transform: translate(-7px, -8px);
  -ms-transform: translate(-7px, -8px);
  transform: translate(-7px, -8px);
}

#right-wheel {
  -webkit-transform: translate(-15px, -5px);
  -ms-transform: translate(-15px, -5px);
  transform: translate(-15px, -5px);
}

#chair-seat.animated {
  -webkit-animation: chair 2s ease-out;
  animation: chair 2s ease-out;
  cursor: pointer;
}

@-webkit-keyframes chair {
  60% {
    -webkit-transform: translateX(-180px) rotate(5deg);
    transform: translateX(-180px) rotate(5deg);
  }
  70% {
    -webkit-transform: translateX(-190px);
    transform: translateX(-190px);
  }
  80% {
    -webkit-transform: translateX(-200px) rotate(-3deg);
    transform: translateX(-200px) rotate(-3deg);
  }
  90% {
    -webkit-transform: translateX(0px) rotate(-8deg);
    transform: translateX(0px) rotate(-8deg);
  }
  100% {
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}

@keyframes chair {
  60% {
    -webkit-transform: translateX(-180px) rotate(5deg);
    transform: translateX(-180px) rotate(5deg);
  }
  70% {
    -webkit-transform: translateX(-190px);
    transform: translateX(-190px);
  }
  80% {
    -webkit-transform: translateX(-200px) rotate(-3deg);
    transform: translateX(-200px) rotate(-3deg);
  }
  90% {
    -webkit-transform: translateX(0px) rotate(-8deg);
    transform: translateX(0px) rotate(-8deg);
  }
  100% {
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}

/*END OF CHAIR*/

/*SHELF 1*/

#shelf-area {
  width: auto;
  height: auto;
  display: inline-block;
  margin-top: 90px;
  margin-left: 550px;
  position: absolute;
  z-index: 1;
}

#shelf-1,
#shelf-2 {
  position: relative;
  display: inline-block;
  background-color: #f47164;
  width: 170px;
  height: 10px;
}

#shelf-2 {
  margin-bottom: -80px;
}

#shelf-1-book-1,
#shelf-1-book-2 {
  position: relative;
  width: 18px;
  height: 70px;
  margin-top: -70px;
}

#shelf-1-book-1 {
  background-color: #84a840;
  margin-left: 15px;
}

#shelf-1-book-2 {
  background-color: #6686bf;
  margin-left: 35px;
}

#shelf-1-book-1-line-1,
#shelf-1-book-2-line-1 {
  position: absolute;
  width: 18px;
  height: 3px;
  margin-top: 5px;
}

#shelf-1-book-1-line-1 {
  background-color: #fcd57e;
}

#shelf-1-book-2-line-1 {
  background-color: #eabbae;
}

#shelf-1-book-1-line-2,
#shelf-1-book-2-line-2 {
  position: absolute;
  background-color: #fff;
  width: 10px;
  height: 25px;
  margin-top: 12px;
  margin-left: 4px;
}

#shelf-1-book-1-line-3,
#shelf-1-book-2-line-3 {
  position: absolute;
  width: 18px;
  height: 4px;
  margin-top: 45px;
}

#shelf-1-book-1-line-3 {
  background-color: #fcd57e;
}

#shelf-1-book-2-line-3 {
  background-color: #eabbae;
}

#shelf-1-book-3,
#shelf-1-book-4 {
  position: relative;
  width: 10px;
  height: 60px;
  margin-top: -61px;
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom;
}

#shelf-1-book-3 {
  background-color: #eabbae;
  margin-left: 69px;
  -webkit-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  transform: rotate(-15deg);
}

#shelf-1-book-4 {
  background-color: #fcd57e;
  margin-left: 96px;
  -webkit-transform: rotate(-30deg);
  -ms-transform: rotate(-30deg);
  transform: rotate(-30deg);
}

#shelf-1-book-1:hover,
#shelf-1-book-2:hover {
  -webkit-animation: shelf-book-raise 0.8s ease-in forwards;
  animation: shelf-book-raise 0.8s ease-in forwards;
  cursor: pointer;
}

#shelf-1-book-3:hover {
  -webkit-animation: shelf-book-shift-1 0.4s alternate infinite;
  animation: shelf-book-shift-1 0.4s alternate infinite;
  cursor: pointer;
}

#shelf-1-book-4:hover {
  -webkit-animation: shelf-book-shift-2 0.3s ease-in infinite;
  animation: shelf-book-shift-2 0.3s ease-in infinite;
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom;
  cursor: pointer;
}

@-webkit-keyframes shelf-book-shift-2 {
  20% {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
  50% {
    -webkit-transform: rotate(-25deg);
    transform: rotate(-25deg);
  }
  70% {
    -webkit-transform: rotate(-23deg);
    transform: rotate(-23deg);
  }
  100% {
    -webkit-transform: rotate(-25deg);
    transform: rotate(-25deg);
  }
}

@keyframes shelf-book-shift-2 {
  20% {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
  50% {
    -webkit-transform: rotate(-25deg);
    transform: rotate(-25deg);
  }
  70% {
    -webkit-transform: rotate(-23deg);
    transform: rotate(-23deg);
  }
  100% {
    -webkit-transform: rotate(-25deg);
    transform: rotate(-25deg);
  }
}

@-webkit-keyframes shelf-book-shift-1 {
  100% {
    -webkit-transform: translate(-14px);
    transform: translate(-14px);
  }
}

@keyframes shelf-book-shift-1 {
  100% {
    -webkit-transform: translate(-14px);
    transform: translate(-14px);
  }
}

@-webkit-keyframes shelf-book-raise {
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes shelf-book-raise {
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

/*END OF SHELF 1*/

/*PLANT*/

#plant {
  position: relative;
  width: 18px;
  height: 70px;
  margin-top: -70px;
  margin-left: 130px;
}

#cactus-body,
#cactus-arm-right,
#cactus-arm-right-up,
#cactus-arm-left,
#cactus-arm-left-up {
  background-color: #84a840;
  border-radius: 25px;
}

#cactus-body {
  position: relative;
  height: 60px;
  width: 10px;
}

#cactus-arm-right,
#cactus-arm-left {
  position: absolute;
  height: 10px;
  width: 25px;
}

#cactus-arm-right-up,
#cactus-arm-left-up {
  position: absolute;
  height: 20px;
  width: 10px;
  bottom: 0;
}

#cactus-arm-left,
#cactus-arm-right-up {
  right: 0;
}

#cactus-arm-right,
#cactus-arm-left-up {
  left: 0;
}

#cactus-arm-left {
  margin-top: 30px;
}

#cactus-arm-right {
  margin-top: 18px;
}

#plant-pot {
  position: relative;
  border-top: 18px solid #8e65a4;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  height: 0;
  width: 20px;
  margin-left: -10px;
  margin-top: -5px;
  cursor: pointer;
}

#plant-pot:before {
  content: "";
  position: absolute;
  left: -5px;
  top: -28px;
  height: 5px;
  width: 30px;
  border-bottom: 5px solid #4f395c;
}

#plant:hover #cactus-body {
  -webkit-animation: cactus 0.7s linear infinite;
  animation: cactus 0.7s linear infinite;
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom;
  cursor: pointer;
}

@-webkit-keyframes cactus {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  40% {
    -webkit-transform: rotate(-5deg) translate(0px, 2px);
    transform: rotate(-5deg) translate(0px, 2px);
  }
  60% {
    -webkit-transform: rotate(3deg) translate(0px, 2px);
    transform: rotate(3deg) translate(0px, 2px);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes cactus {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  40% {
    -webkit-transform: rotate(-5deg) translate(0px, 2px);
    transform: rotate(-5deg) translate(0px, 2px);
  }
  60% {
    -webkit-transform: rotate(3deg) translate(0px, 2px);
    transform: rotate(3deg) translate(0px, 2px);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

/*END OF PLANT*/

/*SHELF 2*/

#shelf-area-2 {
  width: auto;
  height: auto;
  display: inline-block;
  margin-top: 90px;
  margin-left: 670px;
  position: absolute;
  z-index: 1;
}

#shelf-2 {
  position: relative;
  display: inline-block;
  background-color: #f47164;
  width: 200px;
  height: 10px;
}

#shelf-2-book-1 {
  position: relative;
  background-color: #84a840;
  width: 18px;
  height: 70px;
  margin-top: -70px;
  margin-left: 80px;
}

#shelf-2-book-1-line-1 {
  position: absolute;
  background-color: #fae7c7;
  width: 18px;
  height: 3px;
  margin-top: 5px;
}

#shelf-2-book-1-line-2 {
  position: absolute;
  background-color: #fff;
  width: 10px;
  height: 25px;
  margin-top: 15px;
  margin-left: 4px;
}

#shelf-2-book-1-line-3 {
  position: absolute;
  background-color: #fae7c7;
  width: 8px;
  height: 10px;
  margin-top: 50px;
  margin-left: 5px;
  border-radius: 50%;
}

#shelf-2-box {
  position: relative;
  background-color: #6686bf;
  width: 45px;
  height: 30px;
  margin-top: -30px;
  margin-left: 135px;
  cursor: pointer;
}

#shelf-2-box:before {
  content: "";
  position: absolute;
  background-color: #d9edee;
  width: 20px;
  height: 8px;
  margin-top: 10px;
  margin-left: 10px;
  border: 1px #3e3f4b solid;
  z-index: 100;
}

#shelf-2-box:after {
  content: "";
  position: absolute;
  background-color: #3e3f4b;
  width: 49px;
  height: 10px;
  margin-top: -5px;
  margin-left: -2px;
  z-index: 100;
}

#tape-dispenser {
  position: relative;
  margin-top: -31px;
  margin-left: 40px;
}

#tape-body {
  position: absolute;
  width: 25px;
  height: 30px;
  background-color: #3f3f4b;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 5px;
}

#tape-body:before {
  content: "";
  position: absolute;
  width: 28px;
  height: 15px;
  background-color: #3f3f4b;
  bottom: 0;
  margin-left: -25px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

#tape-body:after {
  content: "";
  position: absolute;
  background-color: #3cd1c2;
  margin-top: 15px;
  margin-left: -18px;
  height: 5px;
  width: 18px;
  border-radius: 0px 0px 15px 15px;
}

#tape-legs {
  position: absolute;
  width: 28px;
  height: 2px;
  margin-top: 30px;
  margin-left: -18px;
  border-left: 5px solid #3f3f4b;
  border-right: 5px solid #3f3f4b;
}

#tape-hole {
  z-index: 100;
  position: absolute;
  background-color: #3cd1c2;
  width: 8px;
  height: 8px;
  margin-top: 8px;
  margin-left: 9px;
  border-radius: 50%;
}

#tape-roll {
  position: absolute;
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: -5px;
  margin-left: -2.5px;
}

#tape-roll:after {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 40px;
  height: 1px;
  -webkit-transform: translate(-25px, 13px) rotate(-10deg);
  -ms-transform: translate(-25px, 13px) rotate(-10deg);
  transform: translate(-25px, 13px) rotate(-10deg);
  -webkit-transform-origin: 100%;
  -ms-transform-origin: 100%;
  transform-origin: 100%;
}

#shelf-2-book-2 {
  position: relative;
  background-color: #eabbae;
  width: 10px;
  height: 60px;
  margin-top: -61px;
  margin-left: 115px;
  -webkit-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  transform: rotate(-15deg);
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom;
}

#shelf-2-book-1:hover {
  -webkit-animation: shelf-book-raise 0.8s ease-in forwards;
  animation: shelf-book-raise 0.8s ease-in forwards;
  cursor: pointer;
}

#shelf-2-book-2:hover {
  -webkit-animation: shelf-book-shift-1 0.8s alternate infinite;
  animation: shelf-book-shift-1 0.8s alternate infinite;
  cursor: pointer;
}

#shelf-2-box:hover:after {
  -webkit-animation: box-lid 2s ease forwards;
  animation: box-lid 2s ease forwards;
  cursor: pointer;
}

@-webkit-keyframes box-lid {
  40% {
    -webkit-transform: translate(25px, -18px) rotate(70deg);
    transform: translate(25px, -18px) rotate(70deg);
  }
  60% {
    -webkit-transform: translate(25px, -18px) rotate(70deg);
    transform: translate(25px, -18px) rotate(70deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes box-lid {
  40% {
    -webkit-transform: translate(25px, -18px) rotate(70deg);
    transform: translate(25px, -18px) rotate(70deg);
  }
  60% {
    -webkit-transform: translate(25px, -18px) rotate(70deg);
    transform: translate(25px, -18px) rotate(70deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
}

#box-heart {
  display: inline-block;
  position: absolute;
  width: 12px;
  height: 20px;
  margin-left: -28px;
  z-index: -1;
}

#box-heart:before,
#box-heart:after {
  position: absolute;
  content: "";
  left: 50px;
  top: 0;
  width: 12px;
  height: 18px;
  background: #f47164;
  border-radius: 50px 50px 0 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  transform-origin: 0 100%;
  z-index: 100;
}

#box-heart:after {
  margin-left: 38px;
  left: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

#shelf-2-box:hover #box-heart {
  -webkit-animation: pop 2s ease forwards;
  animation: pop 2s ease forwards;
}

@-webkit-keyframes pop {
  100% {
    -webkit-transform: translateY(-50px) rotate(-20deg);
    transform: translateY(-50px) rotate(-20deg);
    opacity: 0;
  }
}

@keyframes pop {
  100% {
    -webkit-transform: translateY(-50px) rotate(-20deg);
    transform: translateY(-50px) rotate(-20deg);
    opacity: 0;
  }
}

#tape-dispenser:hover {
  -webkit-transform-origin: 100%;
  -ms-transform-origin: 100%;
  transform-origin: 100%;
  -webkit-animation: tape 0.5s ease forwards;
  animation: tape 0.5s ease forwards;
  cursor: pointer;
}

@-webkit-keyframes tape {
  20% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes tape {
  20% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

/*END OF SHELF 2*/

/*PICTURE 1*/

#picture {
  width: auto;
  height: auto;
  display: inline-block;
  margin-top: 20px;
  margin-left: 160px;
  position: absolute;
}

#picture-1,
#picture-2 {
  width: 80px;
  height: 100px;
}

#picture-1,
#picture-2,
#picture-3 {
  border: 5px solid #3f3f4b;
  position: relative;
}

#picture-1,
#picture-3 {
  margin-left: 110px;
}

#picture-1 {
  background: url("../assets/animationPic1.jpg") center no-repeat;
  background-color: #f7f5f7;
  background-size: 60px 80px;
}

#picture-2 {
  background: url("../assets/animationPic2.jpg") center no-repeat;
  background-size: 60px 80px;
  background-color: #d89a8f;
  margin-top: -50px;
}

#picture-3 {
  background: url("../assets/cat.jpg") center no-repeat;
  background-size: 80px 60px;
  background-color: #b0bbb4;
  width: 100px;
  height: 80px;
  margin-top: -40px;
}

#picture-1:hover {
  -webkit-animation: picture-1 1s linear forwards;
  animation: picture-1 1s linear forwards;
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  cursor: pointer;
}

@-webkit-keyframes picture-1 {
  20% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  50% {
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }
  70% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg);
  }
  80% {
    -webkit-transform: rotate(-1deg);
    transform: rotate(-1deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes picture-1 {
  20% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  50% {
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }
  70% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg);
  }
  80% {
    -webkit-transform: rotate(-1deg);
    transform: rotate(-1deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

#picture-2:hover {
  -webkit-animation: picture-2 0.5s linear forwards;
  animation: picture-2 0.5s linear forwards;
  cursor: pointer;
}

@-webkit-keyframes picture-2 {
  50% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes picture-2 {
  50% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

#picture-3:hover {
  -webkit-animation: picture-3 0.5s linear forwards;
  animation: picture-3 0.5s linear forwards;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  cursor: pointer;
}

@-webkit-keyframes picture-3 {
  50% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes picture-3 {
  50% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

/*END OF PICTURE 1*/

/*POST-ITS*/

#note-1 {
  margin-left: 490px;
  margin-top: 150px;
  -webkit-transform: rotate(8deg);
  -ms-transform: rotate(8deg);
  transform: rotate(8deg);
}

#note-2 {
  margin-left: 420px;
  margin-top: 170px;
  -webkit-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  transform: rotate(-10deg);
}

#note-3 {
  margin-left: 480px;
  margin-top: 205px;
  -webkit-transform: rotate(-20deg);
  -ms-transform: rotate(-20deg);
  transform: rotate(-20deg);
}

#note-4 {
  margin-left: 680px;
  margin-top: 110px;
  -webkit-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

#note-1,
#note-2,
#note-3,
#note-4 {
  display: inline-block;
  position: absolute;
  background-color: #f4e8c0;
  width: 35px;
  height: 30px;
  left: 0;
  top: 0;
  z-index: 4;
}

p.note {
  font-size: 9px;
  text-align: center;
  line-height: 10px;
  margin-top: 5px;
}

#note-4 p {
  font-size: 8px;
  text-align: center;
  line-height: 10px;
  margin-top: 5px;
}

p.note:hover {
  color: #bd735f;
}

/*END OF POST-ITS*/

/*PHOTOS*/

#photo-1 {
  display: inline-block;
  position: absolute;
  background-color: #f5f0ea;
  width: 50px;
  height: 55px;
  left: 0;
  top: 0;
  z-index: 4;
  margin-left: 580px;
  margin-top: 140px;
  -webkit-transform: rotate(8deg);
  -ms-transform: rotate(8deg);
  transform: rotate(8deg);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
}

#photo-1:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 40px;
  height: 35px;
  margin-left: 5px;
  margin-top: 5px;
  background: url("../assets/catgirl.jpg") center no-repeat;
  background-size: 40px 30px;
}

#photo-1:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #4f395c;
  border-radius: 50%;
  margin-top: -63px;
  margin-left: 22px;
}

#photo-1:hover {
  -webkit-animation: polaroid-1 1s ease infinite;
  animation: polaroid-1 1s ease infinite;
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
}

@-webkit-keyframes polaroid-1 {
  50% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
}

@keyframes polaroid-1 {
  50% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
}

p.photo,
p.note {
  font-family: "Permanent Marker", cursive;
  color: #4f395c;
  text-align: center;
}

p.photo {
  font-size: 8px;
  margin-top: 40px;
}

/*END OF PHOTOS*/

/*BIG BOX*/

#big-box {
  width: auto;
  height: auto;
  display: inline-block;
  bottom: 0;
  margin-left: 80px;
  position: absolute;
  z-index: 100;
  cursor: pointer;
}

#big-box-body {
  position: relative;
  background-color: #fff;
  width: 140px;
  height: 90px;
  z-index: 100;
}

#big-box-body:after {
  content: "";
  position: absolute;
  background-color: #d9edee;
  width: 50px;
  height: 90px;
  right: 0;
  margin-right: -50px;
  z-index: 100;
}

#big-box-body:before {
  content: "";
  position: absolute;
  background-color: #fae7c7;
  width: 140px;
  height: 50px;
  top: 0;
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  border-radius: 0px 0px 10px 10px;
  z-index: 160;
}

#big-box-shadow {
  position: absolute;
  z-index: 150;
  border-top: 70px solid #d9edee;
  border-left: 0px solid transparent;
  border-right: 20px solid transparent;
  height: 0;
  width: 120px;
  border-bottom-right-radius: 45px;
}

#big-box-hole {
  position: absolute;
  z-index: 150;
  background-color: #443f4b;
  width: 25px;
  height: 12px;
  margin-left: 155px;
  margin-top: 15px;
  border-radius: 5px;
}

#box-folder {
  position: absolute;
  background-color: #e4aa9c;
  width: 100px;
  height: 55px;
  z-index: 90;
  margin-top: -110px;
  margin-left: 25px;
  -webkit-transform: rotate(-2deg);
  -ms-transform: rotate(-2deg);
  transform: rotate(-2deg);
  -webkit-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
}

#folder-paper,
#folder-paper-2 {
  width: 60px;
  height: 85px;
  z-index: 50;
  position: absolute;
}

#folder-paper {
  background-color: #fff;
  margin-top: -110px;
  margin-left: 70px;
  -webkit-transform: rotate(-85deg);
  -ms-transform: rotate(-85deg);
  transform: rotate(-85deg);
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
  z-index: 81;
}

#folder-paper-2 {
  background-color: #d9edee;
  margin-top: -80px;
  margin-left: 65px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
  z-index: 80;
}

#box-folder:before {
  content: "";
  position: absolute;
  width: 40px;
  height: 7px;
  margin-top: -15px;
  left: 0;
  border-bottom: 10px solid #e4aa9c;
  border-left: 0px solid transparent;
  border-right: 5px solid transparent;
}

p.paper {
  font-size: 8px;
  text-align: left;
  margin-top: 5px;
  margin-left: 5px;
  font-family: "Permanent Marker", cursive;
  color: #4f395c;
  line-height: 8px;
}

#big-box:hover #big-box-body:before {
  -webkit-animation: flap 1s ease forwards;
  animation: flap 1s ease forwards;
}

#big-box:hover #box-folder {
  -webkit-animation: folder 1s ease forwards;
  animation: folder 1s ease forwards;
}

#big-box:hover #folder-paper {
  -webkit-animation: paper 1s ease forwards;
  animation: paper 1s ease forwards;
}

#big-box:hover #folder-paper-2 {
  -webkit-animation: paper 1.2s ease forwards;
  animation: paper 1.2s ease forwards;
}

@-webkit-keyframes flap {
  0% {
    -webkit-transform: rotateX(0deg) skewX(-25deg);
    transform: rotateX(0deg) skewX(-25deg);
  }
  20% {
    -webkit-transform: rotateX(-50deg) skewX(-25deg);
    transform: rotateX(-50deg) skewX(-25deg);
  }
  100% {
    -webkit-transform: rotateX(0deg) skewX(-25deg);
    transform: rotateX(0deg) skewX(-25deg);
  }
}

@keyframes flap {
  0% {
    -webkit-transform: rotateX(0deg) skewX(-25deg);
    transform: rotateX(0deg) skewX(-25deg);
  }
  20% {
    -webkit-transform: rotateX(-50deg) skewX(-25deg);
    transform: rotateX(-50deg) skewX(-25deg);
  }
  100% {
    -webkit-transform: rotateX(0deg) skewX(-25deg);
    transform: rotateX(0deg) skewX(-25deg);
  }
}

@-webkit-keyframes folder {
  0% {
    -webkit-transform: translateY(0px) rotate(-2deg);
    transform: translateY(0px) rotate(-2deg);
  }
  100% {
    -webkit-transform: translateY(-10px) rotate(2deg);
    transform: translateY(-10px) rotate(2deg);
  }
}

@keyframes folder {
  0% {
    -webkit-transform: translateY(0px) rotate(-2deg);
    transform: translateY(0px) rotate(-2deg);
  }
  100% {
    -webkit-transform: translateY(-10px) rotate(2deg);
    transform: translateY(-10px) rotate(2deg);
  }
}

@-webkit-keyframes paper {
  100% {
    -webkit-transform: translate(5px, -75px) rotate(-35deg);
    transform: translate(5px, -75px) rotate(-35deg);
  }
}

@keyframes paper {
  100% {
    -webkit-transform: translate(5px, -75px) rotate(-35deg);
    transform: translate(5px, -75px) rotate(-35deg);
  }
}

/*END OF BIG BOX*/

/*WALL OUTLET*/

#outlet {
  width: 25px;
  height: 40px;
  margin-left: 350px;
  margin-top: 600px;
  position: absolute;
  z-index: 1;
  background-color: #fff;
}

.outlet-face {
  width: 17px;
  height: 12px;
  margin-left: 4px;
  margin-top: 5px;
  position: relative;
  background-color: #d9edee;
  border-radius: 5px;
}

.outlet-face:before {
  content: "";
  position: absolute;
  margin-top: 3px;
  margin-left: 3px;
  width: 6px;
  height: 5px;
  border-left: #bfdfe4 2px solid;
  border-right: #bfdfe4 2px solid;
}

#outlet:hover {
  -webkit-animation: outlet 1s linear infinite;
  animation: outlet 1s linear infinite;
  cursor: pointer;
}

@-webkit-keyframes outlet {
  30% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  60% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes outlet {
  30% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  60% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

/*END OF WALL OUTLET*/

/*FLOOR*/

#floor {
  background-color: #bda382;
  width: 100%;
  height: 18px;
  bottom: 0;
  left: 0;
  z-index: 0;
  position: absolute;
}

/*END OF FLOOR*/

/*FLOOR BINDER*/

#floor-binder {
  width: auto;
  height: auto;
  display: inline-block;
  bottom: 0;
  margin-left: 690px;
  position: absolute;
  z-index: 6;
  cursor: pointer;
}

#floor-binder-1,
#floor-binder-2 {
  position: relative;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(50%, rgba(165, 216, 219, 1)),
    color-stop(50%, rgba(255, 255, 255, 1))
  );
  background-image: -webkit-linear-gradient(
    rgba(165, 216, 219, 1) 50%,
    rgba(255, 255, 255, 1) 50%
  );
  background-image: -o-linear-gradient(
    rgba(165, 216, 219, 1) 50%,
    rgba(255, 255, 255, 1) 50%
  );
  background-image: linear-gradient(
    rgba(165, 216, 219, 1) 50%,
    rgba(255, 255, 255, 1) 50%
  );
  background-size: 1px 2px;
  border-top: 5px solid #3e3f4b;
  border-bottom: 5px solid #3e3f4b;
  border-left: 8px solid #3e3f4b;
  width: 120px;
  height: 22px;
}

#floor-binder-2 {
  margin-left: 20px;
}

#floor-binder-1:before,
#floor-binder-2:before {
  content: "";
  position: absolute;
  background-color: #3e3f4b;
  width: 45px;
  height: 22px;
  margin-left: 45px;
}

#floor-binder-1:after,
#floor-binder-2:after {
  content: "";
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-left: 65px;
  margin-top: 10px;
}

#floor-binder:hover #floor-binder-2 {
  -webkit-animation: bag-binder-2 0.8s linear;
  animation: bag-binder-2 0.8s linear;
}

#floor-binder:hover #floor-binder-1 {
  -webkit-animation: bag-binder-1 1s linear;
  animation: bag-binder-1 1s linear;
}

@-webkit-keyframes bag-binder-1 {
  50% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes bag-binder-1 {
  50% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-webkit-keyframes bag-binder-2 {
  50% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes bag-binder-2 {
  50% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

/*END OF FLOOR BINDER*/

/*BAG*/

#bag {
  width: auto;
  height: auto;
  display: inline-block;
  margin-top: 570px;
  margin-left: 710px;
  position: absolute;
  z-index: 6;
  cursor: pointer;
}

#bag-body,
#bag-side {
  position: relative;
  height: 80px;
}

#bag-body {
  background-color: #e4aa9c;
  width: 60px;
  height: 80px;
}

#bag-side {
  background-color: #c49286;
  width: 20px;
  margin-left: 60px;
}

#bag-body:after,
#bag-side:after {
  content: " ";
  display: block;
  position: absolute;
  margin-top: -10px;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 10px;
}

#bag-body:after {
  background: -webkit-linear-gradient(135deg, #e4aa9c 5px, transparent 0),
    -webkit-linear-gradient(45deg, #e4aa9c 5px, transparent 0);
  background: -o-linear-gradient(135deg, #e4aa9c 5px, transparent 0),
    -o-linear-gradient(45deg, #e4aa9c 5px, transparent 0);
  background: linear-gradient(-45deg, #e4aa9c 5px, transparent 0),
    linear-gradient(45deg, #e4aa9c 5px, transparent 0);
  background-position: left bottom;
  background-repeat: repeat-x;
  background-size: 10px 10px;
}

#bag-side:after {
  background: -webkit-linear-gradient(135deg, #c49286 5px, transparent 0),
    -webkit-linear-gradient(45deg, #c49286 5px, transparent 0);
  background: -o-linear-gradient(135deg, #c49286 5px, transparent 0),
    -o-linear-gradient(45deg, #c49286 5px, transparent 0);
  background: linear-gradient(-45deg, #c49286 5px, transparent 0),
    linear-gradient(45deg, #c49286 5px, transparent 0);
  background-position: left bottom;
  background-repeat: repeat-x;
  background-size: 10px 10px;
}

#bag-logo {
  position: absolute;
  background-color: #d9edee;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-top: 20px;
  margin-left: 18px;
  z-index: 6;
}

#bag-logo:before {
  content: "";
  position: absolute;
  border-top: 1px solid #d9edee;
  border-bottom: 1px solid #d9edee;
  width: 60px;
  height: 5px;
  margin-left: -18px;
  margin-top: 7px;
}

#bag-handle-front,
#bag-handle-back {
  border-radius: 40%;
  position: absolute;
  border: #e4aa9c 2px solid;
  height: 50px;
}

#bag-handle-front {
  width: 30px;
  margin-top: -30px;
  margin-left: 15px;
}

#bag-handle-back {
  width: 28px;
  margin-top: -28px;
  margin-left: 28px;
}

#bag.animated {
  -webkit-animation: bag 1s linear;
  animation: bag 1s linear;
}

@-webkit-keyframes bag {
  20% {
    -webkit-transform: translateY(-20px) rotate(10deg);
    transform: translateY(-20px) rotate(10deg);
  }
  40% {
    -webkit-transform: translateY(-25px) rotate(0deg);
    transform: translateY(-25px) rotate(0deg);
  }
  60% {
    -webkit-transform: translateY(-20px) rotate(-5deg);
    transform: translateY(-20px) rotate(-5deg);
  }
}

@keyframes bag {
  20% {
    -webkit-transform: translateY(-20px) rotate(10deg);
    transform: translateY(-20px) rotate(10deg);
  }
  40% {
    -webkit-transform: translateY(-25px) rotate(0deg);
    transform: translateY(-25px) rotate(0deg);
  }
  60% {
    -webkit-transform: translateY(-20px) rotate(-5deg);
    transform: translateY(-20px) rotate(-5deg);
  }
}

p.bag {
  position: absolute;
  display: inline-block;
  font-family: "Pacifico", cursive;
  font-size: 14px;
  color: #d9edee;
  margin-left: 2px;
  margin-top: 40px;
  -webkit-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  transform: rotate(-10deg);
}

/*END OF BAG*/
/* */
</style>
