<template>
  <v-container>
    <!-- alerts -->
    <v-snackbar v-model="snackbar" :color="color" multi-line :timeout="3000">
      {{ ResponseMessage }}
      <v-btn dark flat @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <!-- heading -->
    <v-layout justify-center nowrap>
      <p class="mt-4 mb-4 title">Documents Information</p>
    </v-layout>
    <!-- document form -->
    <v-form
      v-for="(addexp, index) in docAdd"
      :key="index"
      :ref="'docform' + index"
    >
      <v-container grid-list-md text-xs-center>
        <v-layout row wrap>
          <v-flex xs12 md10>
            <v-card>
              <v-card-text>
                <v-layout row wrap>
                  <v-flex md12>
                    <v-btn
                      v-show="showDocAdd[index]"
                      :id="'DeleteDocumentDetails'"
                      small
                      fab
                      dark
                      color="red"
                      class="right"
                      @click.native.prevent="
                        removeDoc(index, docAdd, showDocAdd)
                      "
                    >
                      <v-icon dark>delete</v-icon>
                    </v-btn>
                    <v-btn
                      v-show="showDocAdd[index]"
                      :id="'EdiDocumentDetails'"
                      small
                      fab
                      dark
                      color="warning"
                      class="right"
                      @click="editDoc(index)"
                    >
                      <v-icon dark>edit</v-icon>
                    </v-btn>
                  </v-flex>
                  <!-- line 1 -->
                  <v-flex xs12 md6>
                    <!-- document name -->
                    <v-text-field
                      :id="'DocumentName'"
                      v-model="DocumentName[index]"
                      background-color="#e0e0e0"
                      color="#3cd1c2"
                      outline
                      :counter="50"
                      :disabled="showDocAdd[index]"
                      label="Document Name*"
                      prepend-icon="folder_shared"
                      :rules="$store.state.docNameRules"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <!-- document categroy -->
                    <v-select
                      :id="'DocumentCategory'"
                      v-model="DocumentCategory[index]"
                      background-color="#e0e0e0"
                      outline
                      :disabled="showDocAdd[index]"
                      prepend-icon="file_copy"
                      :items="DocumentcategoryList"
                      item-text="Category_Name"
                      item-value="Category_Id"
                      color="#3cd1c2"
                      label="Document Category*"
                      :rules="[(v) => !!v || 'This field is required']"
                      required
                      @change="ChooseDocType(DocumentCategory[index], index)"
                    ></v-select>
                  </v-flex>
                  <!-- line 2 -->
                  <v-flex xs12 md6>
                    <!-- document type -->
                    <v-select
                      :id="'DocType'"
                      v-model="DocType[index]"
                      background-color="#e0e0e0"
                      outline
                      :disabled="showDocAdd[index]"
                      prepend-icon="library_books"
                      :items="DocumentTypeList[index]"
                      item-text="Document_Type"
                      item-value="Document_Type_Id"
                      color="#3cd1c2"
                      label="Document Type*"
                      :rules="[(v) => !!v || 'This field is required']"
                      required
                      @change="ChooseDocSubType(DocType[index], index)"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 md6>
                    <!-- sub document type -->
                    <v-select
                      :id="'SubDocumentType'"
                      v-model="SubDocumentType[index]"
                      background-color="#e0e0e0"
                      outline
                      :disabled="showDocAdd[index]"
                      prepend-icon="list_alt"
                      :items="SubDocumentTypeList[index]"
                      item-text="Document_Sub_Type"
                      item-value="Document_Sub_Type_Id"
                      color="#3cd1c2"
                      label="Sub Document Type*"
                      :rules="[(v) => !!v || 'This field is required']"
                      required
                    ></v-select>
                  </v-flex>
                  <!-- line 3 -->
                  <!-- file upload -->
                  <v-flex xs12 md6>
                    <v-list v-show="fileName[index]">
                      <v-list-tile>
                        <v-list-tile-action>
                          <v-icon :disabled="showDocAdd[index]"
                            >cloud_upload</v-icon
                          >
                        </v-list-tile-action>
                        <v-list-tile-content>
                          <v-list-tile-title class="grey--text">{{
                            fileName[index]
                          }}</v-list-tile-title>
                        </v-list-tile-content>
                        <v-list-tile-action>
                          <v-btn
                            :id="'EditDocDocuments'"
                            :disabled="showDocAdd[index]"
                            fab
                            small
                            class="ml-4"
                            color="warning"
                            @click="pickFile(index)"
                          >
                            <v-icon class="right">edit</v-icon>
                          </v-btn>
                        </v-list-tile-action>
                        <v-btn
                          :id="'DeleteDocDocuments'"
                          :disabled="showDocAdd[index]"
                          fab
                          small
                          color="red"
                        >
                          <v-icon
                            class="right white--text"
                            dark
                            @click="deleteFile()"
                            >delete</v-icon
                          >
                        </v-btn>
                      </v-list-tile>
                    </v-list>
                    <v-list v-show="!fileName[index]">
                      <v-list-tile>
                        <v-list-tile-action>
                          <v-icon :disabled="showDocAdd[index]"
                            >cloud_upload</v-icon
                          >
                        </v-list-tile-action>
                        <v-list-tile-content>
                          <v-list-tile-title class="grey--text"
                            >No File Chosen</v-list-tile-title
                          >
                        </v-list-tile-content>
                        <v-list-tile-action>
                          <v-btn
                            :id="'AddDocDocuments'"
                            :disabled="showDocAdd[index]"
                            fab
                            small
                            class="success ml-4"
                            @click="pickFile(index)"
                          >
                            <v-icon class="right">add</v-icon>
                          </v-btn>
                          <input
                            ref="image"
                            type="file"
                            style="display: none"
                            accept=".png,.jpg,.jpeg,.pdf"
                            @change="onFilePicked"
                          />
                        </v-list-tile-action>
                      </v-list-tile>
                    </v-list>
                  </v-flex>
                  <!-- file upload end -->
                </v-layout>
                <v-layout>
                  <v-flex xs12 md12>
                    <!-- save document details -->
                    <v-btn
                      v-show="!showDocAdd[index]"
                      :id="'SaveOtherDocuents'"
                      class="success"
                      style="margin: auto"
                      :disabled="isUploadingDocument"
                      @click="saveDoc(index)"
                      >Save</v-btn
                    >
                    <!-- cancel document details -->
                    <v-btn
                      v-show="!showDocAdd[index]"
                      :id="'CancelOtherDocuments'"
                      class="ml-2"
                      style="margin: auto"
                      @click="cancelDoc(showDocAdd, index, docAdd, showDocAdd)"
                      >Cancel</v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 md2>
            <!-- add more other documents -->
            <v-btn
              v-show="docAdd.length === index + 1 || docAdd.length === 0"
              :id="'AddOtherDocuments'"
              fab
              small
              class="success"
              dark
              @click="addExp(index, docAdd, showDocAdd)"
            >
              <v-icon class="taxt-xs-center">add</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import {
  UPLOAD_DOCUMENT,
  EDIT_DOCUMENT,
  DELETE_DOCUMENT,
  LIST_SUB_DOC_TYPE,
  LIST_DOC_TYPE,
  LIST_DOC_CATEGORY,
  DELETE_DOC_DOCUMENT,
  ADD_DOCUMENT,
  UPDATE_DOC_DOCUMENT,
  GET_ALL_DOCUMENTS,
} from "../graphql.js";

export default {
  data() {
    return {
      // form details
      DocumentName: [],
      FileFormatDocument: [],
      CurrentIndex: "",
      fileName: [],
      fileContent: [],
      DocumentCategory: [],
      DocType: [],
      SubDocumentType: [],
      DocumentTypeList: [],
      SubDocumentTypeList: [],
      //others
      docAdd: [0],
      fileUploaded: [false],
      showDocAdd: [false],
      editEnabled: [false],
      image: "",
      imgContent: [""],
      listDocumentCategory: [],
      documentCardId: [],
      avoidAddDependent: false,
      isUploadingDocument: false,

      // alerts
      snackbar: false,
      ResponseMessage: "",
      color: "success",
    };
  },
  computed: {
    DocumentcategoryList() {
      return this.listDocumentCategory.category
        ? this.listDocumentCategory.category
        : [];
    },
  },

  created: function () {
    // retrieve document details
    this.$apollo
      .query({
        query: GET_ALL_DOCUMENTS,
        variables: {
          Org_Code: localStorage.getItem("org_code"),
          Url_Hash: localStorage.getItem("url_hash"),
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        var doc_res = res.data.getAllDocuments.documents;
        this.docAdd = [];
        if (!doc_res) {
          this.docAdd = [0];
        } else {
          this.$emit("docProcessChanged", 100);
          for (var i = 0; i < doc_res.length; i++) {
            this.avoidAddDependent = true;
            this.$set(this.docAdd, i, i);
            this.$set(this.showDocAdd, i, true);
            this.$set(this.documentCardId, i, doc_res[i].Document_Id);
            this.$set(
              this.fileName,
              i,
              !doc_res[i].File_Name ? "" : doc_res[i].File_Name.split("?")[3]
            );
            this.$set(this.FileFormatDocument, i, doc_res[i].File_Name);
            this.$set(this.fileUploaded, i, true);
            this.$set(this.DocumentName, i, doc_res[i].Document_Name);
            this.$set(this.DocumentCategory, i, doc_res[i].Category_Id);
            this.ChooseDocType(
              doc_res[i].Document_Type_Name,
              i,
              doc_res[i].Category_Id
            );
            this.ChooseDocSubType(
              doc_res[i].Document_Sub_Type_Name,
              i,
              doc_res[i].Document_Type_Id
            );
            this.$set(this.SubDocumentType, i, doc_res[i].Document_Sub_Type_Id);
            this.$set(this.DocType, i, doc_res[i].Document_Type_Id);
          }
        }
      })
      .catch((err) => {
        this.snackbar = true;
        this.ResponseMessage =
          err.graphQLErrors.length !== 0
            ? err.graphQLErrors[0].message
            : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
        this.color = "error";
      });
  },
  apollo: {
    listDocumentCategory: {
      query: LIST_DOC_CATEGORY,
      variables() {
        return {
          Org_Code: localStorage.getItem("org_code"),
          Url_Hash: localStorage.getItem("url_hash"),
        };
      },
      loading: true,
    },
  },
  methods: {
    // find document type through document categroy
    ChooseDocType(value, index, id) {
      this.$set(this.DocumentTypeList, index, []);
      this.$set(this.SubDocumentTypeList, index, []);
      this.$set(this.DocType, index, "");
      this.$set(this.SubDocumentType, index, "");
      this.$apollo
        .query({
          query: LIST_DOC_TYPE,
          variables: {
            Category_Id: id ? id : this.DocumentCategory[index],
            Org_Code: localStorage.getItem("org_code"),
            Url_Hash: localStorage.getItem("url_hash"),
          },
        })
        .then((res) => {
          this.$set(
            this.DocumentTypeList,
            index,
            res.data.listDocumentType.documentType
              ? res.data.listDocumentType.documentType
              : []
          );
        })
        .catch((err) => {
          this.snackbar = true;
          this.ResponseMessage =
            err.graphQLErrors.length !== 0
              ? err.graphQLErrors[0].message
              : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
          this.color = "error";
        });
    },
    //  find document sub type through document type
    ChooseDocSubType(value, index, id) {
      this.$set(this.SubDocumentTypeList, index, []);
      this.$set(this.SubDocumentType, index, "");
      this.$apollo
        .query({
          query: LIST_SUB_DOC_TYPE,
          variables: {
            type_id: id ? id : this.DocType[index],
            Org_Code: localStorage.getItem("org_code"),
            Url_Hash: localStorage.getItem("url_hash"),
          },
        })
        .then((res) => {
          this.$set(
            this.SubDocumentTypeList,
            index,
            res.data.listDocumentSubType.documentSubType
              ? res.data.listDocumentSubType.documentSubType
              : []
          );
        })
        .catch((err) => {
          this.snackbar = true;
          this.ResponseMessage =
            err.graphQLErrors.length !== 0
              ? err.graphQLErrors[0].message
              : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
          this.color = "error";
        });
    },
    // submit document details
    saveDoc(index) {
      if (this.$refs["docform" + index][0].validate()) {
        if (this.fileUploaded[index]) {
          if (!this.editEnabled[index]) {
            this.$apollo
              .mutate({
                mutation: ADD_DOCUMENT,
                variables: {
                  Org_Code: localStorage.getItem("org_code"),
                  Url_Hash: localStorage.getItem("url_hash"),
                  File_Name: this.FileFormatDocument[index],
                  Document_Name: this.DocumentName[index],
                  Category_Id: this.DocumentCategory[index],
                  Document_Type_Id: this.DocType[index],
                  Sub_Document_Type_Id: this.SubDocumentType[index],
                },
              })
              .then((res) => {
                if (this.docAdd.length === 1) {
                  this.$emit("docProcessChanged", 100);
                }
                this.$set(this.showDocAdd, index, true);
                this.documentCardId = [];
                for (
                  var i = 0;
                  i < res.data.addDocument.documents.length;
                  i++
                ) {
                  this.$set(
                    this.documentCardId,
                    i,
                    res.data.addDocument.documents[i].Document_Id
                  );
                }
                this.avoidAddDependent = true;
                this.snackbar = true;
                this.ResponseMessage = "Added Successfully";
                this.color = "success";
              })
              .catch((err) => {
                this.snackbar = true;
                this.ResponseMessage =
                  err.graphQLErrors.length !== 0
                    ? err.graphQLErrors[0].message
                    : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
                this.color = "error";
              });
          } else {
            this.$apollo
              .mutate({
                mutation: UPDATE_DOC_DOCUMENT,
                variables: {
                  Org_Code: localStorage.getItem("org_code"),
                  Url_Hash: localStorage.getItem("url_hash"),
                  File_Name: this.FileFormatDocument[index],
                  Document_Id: this.documentCardId[index],
                  Document_Name: this.DocumentName[index],
                  Category_Id: this.DocumentCategory[index],
                  Document_Type_Id: this.DocType[index],
                  Sub_Document_Type_Id: this.SubDocumentType[index],
                },
              })
              .then(() => {
                this.$set(this.showDocAdd, index, true);
                this.$set(this.editEnabled, index, false);
                this.avoidAddDependent = true;
                this.snackbar = true;
                this.ResponseMessage = "Updated Successfully";
                this.color = "success";
              })
              .catch((err) => {
                this.snackbar = true;
                this.ResponseMessage =
                  err.graphQLErrors.length !== 0
                    ? err.graphQLErrors[0].message
                    : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
                this.color = "error";
              });
          }
        } else {
          this.snackbar = true;
          this.ResponseMessage = "File upload should not be empty";
          this.color = "error";
        }
      }
    },
    // cancel document details
    cancelDoc(val, index, remove_el, remove_val) {
      if (this.editEnabled[index]) {
        this.avoidAddDependent = true;
        this.$set(this.showDocAdd, index, true);
      } else {
        if (this.docAdd.length !== 1) {
          this.avoidAddDependent = true;
          remove_el.splice(index, 1);
          remove_val.splice(index, 1);
          this.$set(this.DocumentName, index, this.DocumentName[index + 1]);
          this.$set(
            this.DocumentCategory,
            index,
            this.DocumentCategory[index + 1]
          );
          this.$set(this.DocType, index, this.DocType[index + 1]);
          this.$set(
            this.SubDocumentType,
            index,
            this.SubDocumentType[index + 1]
          );

          this.$set(this.fileName, index, this.fileName[index + 1]);
        } else {
          this.$set(this.DocumentName, index, "");
          this.$set(this.DocumentCategory, index, "");
          this.$set(this.DocType, index, "");
          this.$set(this.SubDocumentType, index, "");
          this.$set(this.fileName, index, "");
          this.$refs["docform" + index][0].resetValidation();
        }
      }
    },
    // enable editing of document details
    editDoc(index) {
      this.$set(this.showDocAdd, index, false);
      this.avoidAddDependent = false;
      this.$set(this.editEnabled, index, true);
    },
    // add more documents
    addExp(index, remove_el, remove_val) {
      var index_val = remove_val.length;
      if (
        this.$refs["docform" + index][0].validate() &&
        this.avoidAddDependent
      ) {
        this.avoidAddDependent = false;
        this.$set(this.DocumentName, index_val, "");
        remove_el.push(index_val);
        remove_val[index_val] = false;
      } else {
        this.snackbar = true;
        this.ResponseMessage = "First save your details";
        this.color = "error";
      }
    },
    // enabling file select
    pickFile(index) {
      this.CurrentIndex = index;
      this.$refs.image[index].click();
    },
    //  fetch file details
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        var fileContent;
        if (files[0].name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          fileContent = fr.result.split(",");
          if (!this.FileFormatDocument[this.CurrentIndex]) {
            this.isUploadingDocument = true;
            this.$apollo
              .query({
                query: UPLOAD_DOCUMENT,
                variables: {
                  File_Name: files[0].name,
                  Category: "document",
                  File_Content: fileContent[1],
                  Org_Code: localStorage.getItem("org_code"),
                  Url_Hash: localStorage.getItem("url_hash"),
                },
              })
              .then((res) => {
                this.snackbar = true;
                this.ResponseMessage = "File Uploaded";
                this.color = "success";
                this.$set(this.fileUploaded, this.CurrentIndex, true);
                this.$set(this.fileName, this.CurrentIndex, files[0].name);
                this.$set(
                  this.FileFormatDocument,
                  this.CurrentIndex,
                  res.data.fileUpload.fileName
                );
                this.isUploadingDocument = false;
              })
              .catch((err) => {
                this.snackbar = true;
                this.ResponseMessage =
                  err.graphQLErrors.length !== 0
                    ? err.graphQLErrors[0].message
                    : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
                this.color = "error";
                this.isUploadingDocument = false;
              });
          } else {
            this.isUploadingDocument = true;
            this.$apollo
              .query({
                query: EDIT_DOCUMENT,
                variables: {
                  New_File_Name: files[0].name,
                  Category: "document",
                  File_Name: this.FileFormatDocument[this.CurrentIndex],
                  New_File_Content: fileContent[1],
                  Org_Code: localStorage.getItem("org_code"),
                  Url_Hash: localStorage.getItem("url_hash"),
                },
              })
              .then((res) => {
                this.snackbar = true;
                this.ResponseMessage = "File Uploaded";
                this.color = "success";
                this.$set(this.fileUploaded, this.CurrentIndex, true);
                this.$set(this.fileName, this.CurrentIndex, files[0].name);
                this.$set(
                  this.FileFormatDocument,
                  this.CurrentIndex,
                  res.data.fileReplace.fileName
                );
                this.isUploadingDocument = false;
              })
              .catch((err) => {
                this.snackbar = true;
                this.ResponseMessage =
                  err.graphQLErrors.length !== 0
                    ? err.graphQLErrors[0].message
                    : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
                this.color = "error";
                this.isUploadingDocument = false;
              });
          }
        });
      } else {
        this.fileName[this.CurrentIndex] = "";
      }
    },
    // delete dependent dtails
    removeDoc(index, remove_el, remove_val) {
      this.$apollo
        .mutate({
          mutation: DELETE_DOC_DOCUMENT,
          variables: {
            Document_Id: this.documentCardId[index],
            Org_Code: localStorage.getItem("org_code"),
            Url_Hash: localStorage.getItem("url_hash"),
          },
        })
        .then(() => {
          this.$set(this.fileUploaded, index, false);
          if (this.docAdd.length !== 1) {
            remove_el.splice(index, 1);
            remove_val.splice(index, 1);
            this.$set(this.DocumentName, index, this.DocumentName[index + 1]);
            this.$set(
              this.DocumentCategory,
              index,
              this.DocumentCategory[index + 1]
            );
            this.$set(this.DocType, index, this.DocType[index + 1]);
            this.$set(
              this.SubDocumentType,
              index,
              this.SubDocumentType[index + 1]
            );

            this.$set(this.fileName, index, this.fileName[index + 1]);
          } else {
            this.$set(this.DocumentName, index, "");
            this.$set(this.DocumentCategory, index, "");
            this.$set(this.DocType, index, "");
            this.$set(this.SubDocumentType, index, "");
            this.$set(this.fileName, index, "");
            this.$set(this.showDocAdd, index, false);
            this.$refs["docform" + index][0].resetValidation();
            this.$emit("docProcessChanged", 0);
            remove_val[index] = false;
          }
          this.snackbar = true;
          this.ResponseMessage = "Deleted";
          this.color = "success";
        })
        .catch((err) => {
          this.snackbar = true;
          this.ResponseMessage =
            err.graphQLErrors.length !== 0
              ? err.graphQLErrors[0].message
              : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
          this.color = "error";
        });
    },
    // delete uploaded documents
    deleteFile() {
      this.isUploadingDocument = true;
      this.$apollo
        .query({
          query: DELETE_DOCUMENT,
          variables: {
            Org_Code: localStorage.getItem("org_code"),
            Url_Hash: localStorage.getItem("url_hash"),
            File_Name: this.FileFormatDocument[this.CurrentIndex],
            Category: "document",
          },
        })
        .then(() => {
          this.snackbar = true;
          this.ResponseMessage = "File Deleted";
          this.color = "success";
          this.$set(this.fileName, this.CurrentIndex, null);
          this.isUploadingDocument = false;
        })
        .catch((err) => {
          this.snackbar = true;
          this.ResponseMessage =
            err.graphQLErrors.length !== 0
              ? err.graphQLErrors[0].message
              : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
          this.color = "error";
          this.isUploadingDocument = false;
        });
    },
  },
};
</script>

<style scoped>
.ps {
  height: 90ex;
}
body {
  background: #444;
}
.avatarClass {
  width: 80% !important;
  height: 50% !important;
}
.imgClass {
  width: 71px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid #fff;
}
</style>
