<template>
  <v-container>
    <!-- alerts -->
    <v-snackbar v-model="snackbar" :color="color" multi-line :timeout="3000">
      {{ ResponseMessage }}
      <v-btn dark flat @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <!-- heading -->
    <p class="subheading grey--text">Dependent Details</p>
    <!-- dependent details form -->
    <v-form
      v-for="(addexp, index) in addDependent"
      :key="index"
      :ref="'dependentForm' + index"
    >
      <v-container grid-list-md text-xs-center>
        <v-layout row wrap>
          <v-flex xs12 md10>
            <v-card>
              <v-card-text>
                <v-layout row wrap>
                  <v-flex md12>
                    <v-btn
                      v-show="!showDependent[index]"
                      :id="'DeleteDependentDetails'"
                      small
                      fab
                      dark
                      color="red"
                      class="right"
                      @click.native.prevent="
                        removeDependent(index, addDependent, showDependent)
                      "
                    >
                      <v-icon dark>delete</v-icon>
                    </v-btn>
                    <v-btn
                      v-show="!showDependent[index]"
                      :id="'EditDependentDetails'"
                      small
                      fab
                      dark
                      color="warning"
                      class="right"
                      @click="editDependent(index)"
                    >
                      <v-icon dark>edit</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 md6>
                    <!-- dependent first name -->
                    <v-text-field
                      :id="'DependentFirstName'"
                      v-model="FirstName[index]"
                      :maxlength="50"
                      background-color="#e0e0e0"
                      color="#3cd1c2"
                      outline
                      :counter="50"
                      :disabled="!showDependent[index]"
                      label="First Name*"
                      prepend-icon="perm_identity"
                      :rules="$store.state.onlyLetterSpaceRules"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <!-- dependent last name -->
                    <v-text-field
                      :id="'DependentLastName'"
                      v-model="LastName[index]"
                      :maxlength="50"
                      background-color="#e0e0e0"
                      color="#3cd1c2"
                      outline
                      :counter="50"
                      :disabled="!showDependent[index]"
                      label="Last Name*"
                      prepend-icon="person"
                      :rules="$store.state.onlyLetterSpaceRules"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <!-- relationship -->
                    <v-select
                      :id="'RelationShip'"
                      v-model="RelationShip[index]"
                      :disabled="!showDependent[index]"
                      background-color="#e0e0e0"
                      prepend-icon="supervisor_account"
                      outline
                      color="#3cd1c2"
                      label="Relationship"
                      :items="RelationOptions"
                      item-text="Dependent_Relationship"
                      item-value="Dependent_Relationship"
                      :rules="[(v) => !!v || 'This field is required']"
                      required
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 md6>
                    <!-- dependent dateofbirth -->
                    <v-dialog
                      v-model="modalDependent"
                      persistent
                      width="290"
                      lazy
                      full-width
                    >
                      <template #activator="{ on }">
                        <v-text-field
                          :id="'DependentDob'"
                          v-model="Dependent_DateOfBirth[index]"
                          :disabled="!showDependent[index]"
                          background-color="#e0e0e0"
                          color="#3cd1c2"
                          outline
                          readonly
                          label="DOB*"
                          prepend-icon="date_range"
                          :rules="[(v) => !!v || 'This field is required']"
                          required
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="Dependent_DateOfBirth[index]"
                        :max="maxYearDependent"
                        color="#3cd1c2"
                        scrollable
                        actions
                      >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            flat
                            color="primary"
                            @click="modalDependent = false"
                            >OK</v-btn
                          >
                        </v-card-actions>
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs12 md12>
                    <!-- submit details -->
                    <v-btn
                      v-show="showDependent[index]"
                      :id="'SaveDependentDetails'"
                      class="success"
                      style="margin: auto"
                      @click="saveDependent(index)"
                      >Save</v-btn
                    >
                    <!-- cancel details -->
                    <v-btn
                      v-show="showDependent[index]"
                      :id="'CancelDependentDetails'"
                      class="ml-2"
                      style="margin: auto"
                      @click="
                        cancelDependent(index, addDependent, showDependent)
                      "
                      >Cancel</v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 md2>
            <!-- add more dependent details -->
            <v-btn
              v-show="
                addDependent.length === index + 1 || addDependent.length === 0
              "
              :id="'AddDependentDetails'"
              fab
              small
              class="success"
              dark
              @click="AddDependent(index, addDependent, showDependent)"
            >
              <v-icon class="taxt-xs-center">add</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import {
  ADD_DEPENDENT,
  UPDATE_DEPENDENT,
  DELETE_DEPENDENT,
  GET_DEPENDENT,
} from "../graphql.js";

export default {
  data() {
    return {
      // form details
      FirstName: [],
      LastName: [],
      Dependent_DateOfBirth: [],
      RelationShip: [],
      DependentId: [],
      FirstNameCopy: [],
      LastNameCopy: [],
      DOBCopy: [],
      RelationCopy: [],
      //others
      addDependent: [0],
      showDependent: [true],
      avoidAddDependent: false,
      enableDependentEdit: [false],
      modalDependent: false,
      RelationOptions: [],
      maxYearDependent: "",
      // alerts
      snackbar: false,
      ResponseMessage: "",
      color: "success",
    };
  },

  created: function () {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    this.maxYearDependent = yyyy + "-" + mm + "-" + dd;
    // retrrieve dependent details
    this.$apollo
      .query({
        query: GET_DEPENDENT,
        variables: {
          Org_Code: localStorage.getItem("org_code"),
          Url_Hash: localStorage.getItem("url_hash"),
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        var depent = res.data.getDependent.dependent;
        this.addDependent = [];
        if (!depent) {
          this.addDependent = [0];
        } else {
          this.$emit("dependentProgress", 20);
          for (var i = 0; i < depent.length; i++) {
            this.avoidAddDependent = true;
            this.addDependent.push(i);
            this.$set(this.showDependent, i, false);
            this.$set(this.FirstName, i, depent[i].Dependent_First_Name);
            this.$set(this.LastName, i, depent[i].Dependent_Last_Name);
            this.$set(this.RelationShip, i, depent[i].Relationship);
            this.$set(this.Dependent_DateOfBirth, i, depent[i].Dependent_DOB);

            this.$set(this.DependentId, i, depent[i].Dependent_Id);
          }
        }
      })
      .catch((err) => {
        this.snackbar = true;
        this.ResponseMessage =
          err.graphQLErrors.length !== 0
            ? err.graphQLErrors[0].message
            : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
        this.color = "error";
      });
  },

  methods: {
    //  find reletions id for sending backend
    setRelation: function (value) {
      this.RelationOptions = value;
    },

    // cancel dependent details
    cancelDependent(index, remove_el, remove_val) {
      if (this.enableDependentEdit[index]) {
        this.avoidAddDependent = true;
        this.$set(this.showDependent, index, false);
        this.$set(this.FirstName, index, this.FirstNameCopy[index]);
        this.$set(this.LastName, index, this.LastNameCopy[index]);
        this.$set(this.Dependent_DateOfBirth, index, this.DOBCopy[index]);
        this.$set(this.RelationShip, index, this.RelationCopy[index]);
      } else {
        if (this.addDependent.length !== 1) {
          this.avoidAddDependent = true;
          remove_el.splice(index, 1);
          remove_val.splice(index, 1);
          this.$set(this.FirstName, index, this.FirstName[index + 1]);
          this.$set(this.LastName, index, this.LastName[index + 1]);
          this.$set(this.RelationShip, index, this.RelationShip[index + 1]);
          this.$set(
            this.Dependent_DateOfBirth,
            index,
            this.Dependent_DateOfBirth[index + 1]
          );
        } else {
          this.$set(this.FirstName, index, "");
          this.$set(this.LastName, index, "");
          this.$set(this.RelationShip, index, "");
          this.$set(this.Dependent_DateOfBirth, index, "");
          this.$set(this.showDependent, index, true);
          this.$refs["dependentForm" + index][0].reset();
          remove_val[index] = true;
        }
      }
    },
    // submit dependent details
    saveDependent(index) {
      if (this.$refs["dependentForm" + index][0].validate()) {
        if (!this.enableDependentEdit[index]) {
          this.$apollo
            .mutate({
              mutation: ADD_DEPENDENT,
              variables: {
                Org_Code: localStorage.getItem("org_code"),
                Url_Hash: localStorage.getItem("url_hash"),
                Dependent_First_Name: this.FirstName[index],
                Dependent_Last_Name: this.LastName[index],
                Gender: " ",
                Relationship: this.RelationShip[index],
                Dependent_DOB: this.Dependent_DateOfBirth[index],
              },
            })
            .then((res) => {
              this.$emit("dependentProgress", 20);
              this.DependentId = [];

              for (var i = 0; i < res.data.addDependent.dependent.length; i++) {
                this.$set(
                  this.DependentId,
                  i,
                  res.data.addDependent.dependent[i].Dependent_Id
                );
              }
              this.avoidAddDependent = true;
              this.$set(this.showDependent, index, false);
              this.snackbar = true;
              this.ResponseMessage = "Added Successfully";
              this.color = "success";
            })
            .catch((err) => {
              this.snackbar = true;
              this.ResponseMessage =
                err.graphQLErrors.length !== 0
                  ? err.graphQLErrors[0].message
                  : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
              this.color = "error";
            });
        } else {
          this.$apollo
            .mutate({
              mutation: UPDATE_DEPENDENT,
              variables: {
                Org_Code: localStorage.getItem("org_code"),
                Url_Hash: localStorage.getItem("url_hash"),
                Dependent_Id: this.DependentId[index],
                Dependent_First_Name: this.FirstName[index],
                Dependent_Last_Name: this.LastName[index],
                Gender: " ",
                Relationship: this.RelationShip[index],
                Dependent_DOB: this.Dependent_DateOfBirth[index],
              },
            })
            .then(() => {
              this.snackbar = true;
              this.$set(this.enableDependentEdit, index, false);
              this.ResponseMessage = "Updated Successfully";
              this.color = "success";
              this.avoidAddDependent = true;
              this.$set(this.showDependent, index, false);
            })
            .catch((err) => {
              this.snackbar = true;
              this.ResponseMessage =
                err.graphQLErrors.length !== 0
                  ? err.graphQLErrors[0].message
                  : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
              this.color = "error";
            });
        }
      }
    },
    // enable editinf dor user
    editDependent(index) {
      this.avoidAddDependent = false;
      this.$set(this.enableDependentEdit, index, true);
      this.$set(this.showDependent, index, true);
      this.$set(this.FirstNameCopy, index, this.FirstName[index]);
      this.$set(this.LastNameCopy, index, this.LastName[index]);
      this.$set(this.DOBCopy, index, this.Dependent_DateOfBirth[index]);
      this.$set(this.RelationCopy, index, this.RelationShip[index]);
    },
    //  add more dependent details
    AddDependent(index, remove_el, remove_val) {
      var index_val = remove_val.length;
      if (
        this.$refs["dependentForm" + index][0].validate() &&
        this.avoidAddDependent
      ) {
        this.avoidAddDependent = false;
        this.$set(this.showDependent, index_val, true);
        this.$set(this.FirstName, index_val, "");
        this.$set(this.LastName, index_val, "");
        this.$set(this.RelationShip, index_val, "");
        this.$set(this.Dependent_DateOfBirth, index_val, "");
        remove_el.push(index_val);
        remove_val[index_val] = true;
      } else {
        this.snackbar = true;
        this.ResponseMessage = "First save your details";
        this.color = "error";
      }
    },
    // delete dependent deytails
    removeDependent(index, remove_el, remove_val) {
      this.$apollo
        .mutate({
          mutation: DELETE_DEPENDENT,
          variables: {
            Org_Code: localStorage.getItem("org_code"),
            Url_Hash: localStorage.getItem("url_hash"),
            Dependent_Id: this.DependentId[index],
          },
        })
        .then(() => {
          this.snackbar = true;
          this.ResponseMessage = "Deleted Successfully";
          this.color = "success";
          this.DependentId.splice(index, 1);
          if (this.addDependent.length !== 1) {
            remove_el.splice(index, 1);
            remove_val.splice(index, 1);
            this.$set(this.FirstName, index, this.FirstName[index + 1]);
            this.$set(this.LastName, index, this.LastName[index + 1]);
            this.$set(this.RelationShip, index, this.RelationShip[index + 1]);
            this.$set(
              this.Dependent_DateOfBirth,
              index,
              this.Dependent_DateOfBirth[index + 1]
            );
          } else {
            this.$emit("dependentProgress", -20);
            this.$set(this.FirstName, index, "");
            this.$set(this.LastName, index, "");
            this.$set(this.RelationShip, index, "");
            this.$set(this.Dependent_DateOfBirth, index, "");
            this.$set(this.showDependent, index, true);
            this.$refs["dependentForm" + index][0].reset();
            remove_val[index] = true;
          }
        })
        .catch((err) => {
          this.snackbar = true;
          this.ResponseMessage =
            err.graphQLErrors.length !== 0
              ? err.graphQLErrors[0].message
              : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
          this.color = "error";
        });
    },
  },
};
</script>

<style scoped>
.ps {
  height: 70vh;
}
</style>
