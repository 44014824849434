<template>
  <v-container>
    <!-- alerts -->
    <v-snackbar v-model="snackbar" :color="color" multi-line :timeout="3000">
      {{ ResponseMessage }}
      <v-btn dark flat @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <!-- heading -->
    <v-layout justify-center nowrap>
      <p class="mt-4 mb-4 title">Experience Information</p>
    </v-layout>
    <p class="text-xs-center text-sm-center text-md-center text-lg-center">
      *Required,if you have an experience.
    </p>
    <!-- toggle for to select the user is not experienced -->
    <v-switch
      v-show="showSwitch"
      v-model="expAvailable"
      label="Not Experienced ?"
      @change="notExperienced(expAvailable)"
    ></v-switch>
    <!-- total experience -->
    <v-layout v-if="!expAvailable" row wrap style="margin-bottom: 12px">
      <v-flex xs12 md10>
        <v-form ref="totalExperienceForm">
          <v-layout row wrap>
            <v-flex xs12>
              <v-label>Total Experience</v-label>
            </v-flex>
            <v-flex xs12 sm3>
              <div class="d-flex align-center">
                <v-text-field
                  id="TotalExperience"
                  v-model="totalYear"
                  color="#3cd1c2"
                  type="number"
                  :disabled="isTotalExperienceEdit"
                  :rules="$store.state.totalExpYearRules"
                ></v-text-field>
                <span style="padding: 10px">Years</span>
              </div>
            </v-flex>
            <v-flex xs12 sm3>
              <div class="d-flex align-center">
                <v-text-field
                  id="TotalExperience"
                  v-model="totalMonth"
                  color="#3cd1c2"
                  type="number"
                  :disabled="isTotalExperienceEdit"
                  :rules="$store.state.totalExpMonthRules"
                ></v-text-field>
                <span style="padding: 10px">Months</span>
              </div>
            </v-flex>
            <v-flex v-show="!isTotalExperienceEdit" xs12 sm4>
              <v-btn
                id="SaveTotalExperience"
                class="success"
                @click="saveTotalExperience('update')"
                >Save</v-btn
              >
            </v-flex>
            <v-flex v-show="isTotalExperienceEdit" xs12 sm3>
              <v-btn
                fab
                dark
                color="warning"
                small
                @click="updateTotalExperience()"
              >
                <v-icon>create</v-icon>
              </v-btn>
              <v-btn
                fab
                dark
                color="red"
                small
                @click="deleteTotalExperience()"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <v-label v-if="!expAvailable">Experience Details:</v-label>
    <!-- experience form -->
    <v-form
      v-for="(addexp, index) in addExperience"
      v-show="showexpCard"
      :key="index"
      :ref="'expForm' + index"
    >
      <v-container grid-list-md text-xs-center>
        <v-layout row wrap>
          <v-flex xs12 md10>
            <v-card>
              <v-card-text>
                <v-layout row wrap>
                  <v-flex md12>
                    <v-btn
                      v-show="!showAdd[index]"
                      :id="'DeleteExperienceDetails'"
                      small
                      fab
                      dark
                      color="red"
                      class="right"
                      @click.native.prevent="
                        removeExp(index, addExperience, showAdd)
                      "
                    >
                      <v-icon dark>delete</v-icon>
                    </v-btn>
                    <v-btn
                      v-show="!showAdd[index]"
                      :id="'EditExperienceDetails'"
                      small
                      fab
                      dark
                      color="warning"
                      class="right"
                      @click="EditExp(index)"
                    >
                      <v-icon dark>edit</v-icon>
                    </v-btn>
                  </v-flex>
                  <!-- line 1 -->
                  <v-flex xs12 md6>
                    <!-- company name -->
                    <v-text-field
                      :id="'CompanyName'"
                      v-model="CompanyName[index]"
                      background-color="#e0e0e0"
                      color="#3cd1c2"
                      outline
                      :counter="50"
                      :disabled="!showAdd[index]"
                      label="Company Name*"
                      prepend-icon="domain"
                      :rules="$store.state.companyNameRules"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <!-- locations -->
                    <v-text-field
                      :id="'Location'"
                      v-model="LocationAddress[index]"
                      :maxlength="50"
                      background-color="#e0e0e0"
                      color="#3cd1c2"
                      outline
                      :counter="50"
                      :disabled="!showAdd[index]"
                      label="Location*"
                      prepend-icon="place"
                      :rules="$store.state.onlyLetterSpaceRules"
                      required
                    ></v-text-field>
                  </v-flex>
                  <!-- line 2 -->
                  <v-flex xs12 md6>
                    <!-- designation -->
                    <v-text-field
                      :id="'Designation'"
                      v-model="Designation[index]"
                      background-color="#e0e0e0"
                      color="#3cd1c2"
                      outline
                      :counter="50"
                      :disabled="!showAdd[index]"
                      label="Designation*"
                      prepend-icon="laptop_chromebook"
                      :rules="$store.state.designationRules"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <!-- join date -->
                    <v-dialog
                      v-model="fromModal"
                      persistent
                      width="290"
                      lazy
                      full-width
                    >
                      <template #activator="{ on }">
                        <v-text-field
                          :id="'ExperienceFrom'"
                          v-model="From[index]"
                          :disabled="!showAdd[index]"
                          background-color="#e0e0e0"
                          color="#3cd1c2"
                          outline
                          readonly
                          label="From*"
                          prepend-icon="date_range"
                          :rules="[(v) => !!v || 'This field is required']"
                          required
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="From[index]"
                        :min="minJoinDate"
                        :max="maxYear"
                        color="#3cd1c2"
                        scrollable
                        actions
                        @change="emptyTO(index)"
                      >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn flat color="primary" @click="fromModal = false"
                            >OK</v-btn
                          >
                        </v-card-actions>
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                  <!-- line 3 -->
                  <v-flex xs12 md6>
                    <!-- end date -->
                    <v-dialog
                      v-model="toModal"
                      persistent
                      width="290"
                      lazy
                      full-width
                    >
                      <template #activator="{ on }">
                        <v-text-field
                          :id="'ExperienceTo'"
                          v-model="To[index]"
                          :disabled="!showAdd[index]"
                          readonly
                          background-color="#e0e0e0"
                          color="#3cd1c2"
                          outline
                          label="To*"
                          prepend-icon="date_range"
                          :value="To[index]"
                          :rules="[(v) => !!v || 'This field is required']"
                          required
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="To[index]"
                        :min="From[index]"
                        :max="maxYear"
                        color="#3cd1c2"
                        scrollable
                        actions
                      >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn flat color="primary" @click="toModal = false"
                            >OK</v-btn
                          >
                        </v-card-actions>
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                  <!-- file upload -->
                  <v-flex xs12 md6>
                    <v-layout justify-center>
                      <v-flex xs12 sm12>
                        <v-list v-show="fileName[index]">
                          <v-list-tile>
                            <v-list-tile-action>
                              <v-icon :disabled="!showAdd[index]"
                                >cloud_upload</v-icon
                              >
                            </v-list-tile-action>
                            <v-list-tile-content>
                              <v-list-tile-title class="grey--text">{{
                                fileName[index]
                              }}</v-list-tile-title>
                            </v-list-tile-content>
                            <v-list-tile-action>
                              <v-btn
                                :id="'EditExpDoc'"
                                fab
                                small
                                :disabled="!showAdd[index]"
                                class="ml-4"
                                color="warning"
                                @click="pickFile(index)"
                              >
                                <v-icon class="right">edit</v-icon>
                              </v-btn>
                            </v-list-tile-action>
                            <v-btn
                              :id="'DeleteExpDoc'"
                              fab
                              small
                              :disabled="!showAdd[index]"
                              color="red"
                            >
                              <v-icon
                                class="right white--text"
                                dark
                                @click="deleteFile(index)"
                                >delete</v-icon
                              >
                            </v-btn>
                          </v-list-tile>
                        </v-list>
                        <v-list v-show="!fileName[index]">
                          <v-list-tile>
                            <v-list-tile-action>
                              <v-icon :disabled="!showAdd[index]"
                                >cloud_upload</v-icon
                              >
                            </v-list-tile-action>
                            <v-list-tile-content>
                              <v-list-tile-title class="grey--text"
                                >No File Chosen</v-list-tile-title
                              >
                            </v-list-tile-content>
                            <v-list-tile-action>
                              <v-btn
                                :id="'AddExpDoc'"
                                fab
                                small
                                :disabled="!showAdd[index]"
                                class="success ml-4"
                                @click="pickFile(index)"
                              >
                                <v-icon
                                  class="right"
                                  :disabled="!showAdd[index]"
                                  >add</v-icon
                                >
                              </v-btn>
                            </v-list-tile-action>
                          </v-list-tile>
                        </v-list>
                      </v-flex>
                    </v-layout>
                    <input
                      ref="image"
                      type="file"
                      style="display: none"
                      accept=".png,.jpg,.jpeg,.pdf"
                      @change="onFilePicked"
                    />
                  </v-flex>
                  <!-- file upload end -->
                </v-layout>
                <v-layout>
                  <v-flex xs12 md12>
                    <!-- submit exp -->
                    <v-btn
                      v-show="showAdd[index]"
                      :id="'SaveExpDetails'"
                      class="success"
                      style="margin: auto"
                      :disabled="isUploadingDocument"
                      @click="saveExp(index)"
                      >Save</v-btn
                    >
                    <!-- cancel exp -->
                    <v-btn
                      v-show="showAdd[index]"
                      :id="'CancelExpDetails'"
                      class="ml-2"
                      style="margin: auto"
                      @click="cancelExp(index, addExperience, showAdd)"
                      >Cancel</v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 md2>
            <!-- add more exp -->
            <v-btn
              v-show="
                addExperience.length === index + 1 || addExperience.length === 0
              "
              :id="'AddExperienceDetails'"
              fab
              small
              class="success"
              dark
              @click="addExp(index, addExperience, showAdd)"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import {
  ADD_EXPERIENCE,
  UPDATE_EXPERIENCE,
  GET_EXPERIENCE,
  DELETE_EXPERIENCE,
  UPLOAD_DOCUMENT,
  EDIT_DOCUMENT,
  DELETE_DOCUMENT,
  UPDATE_TOTAL_EXPERIENCE,
} from "../graphql.js";

export default {
  data() {
    return {
      // total experience
      totalYear: null,
      totalMonth: null,
      isTotalExperienceEdit: false,
      // form details
      CompanyName: [],
      LocationAddress: [],
      Designation: [],
      From: [],
      To: [],
      Id: [],
      CurrentIndex: "",
      fileName: [],
      fileNameFormat: [],
      date: "",
      CompNameCopy: [],
      LocCopy: [],
      esiCopy: [],
      FromCopy: [],
      ToCopy: [],
      fileNameCopy: [],
      //others
      addExperience: [0],
      showAdd: [true],
      showexpCard: true,
      image: "",
      defineAddEdit: [true],
      minJoinDate: new Date(localStorage.getItem("DOB"))
        .toISOString()
        .slice(0, 10),
      avoidAdd: false,
      enableEdit: [false],
      expProgress: "100% Completed",
      imgContent: [""],
      expLength: 0,
      fromModal: false,
      toModal: false,
      expAvailable: false,
      showSwitch: true,
      isUploadingDocument: false,
      // alerts
      snackbar: false,

      color: "success",
      ResponseMessage: "",
      maxYear: "",
    };
  },

  created: function () {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    this.maxYear = yyyy + "-" + mm + "-" + dd;
    // retrieve exp details
    this.$apollo
      .query({
        query: GET_EXPERIENCE,
        variables: {
          Org_Code: localStorage.getItem("org_code"),
          Url_Hash: localStorage.getItem("url_hash"),
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        this.addExperience = [];
        const { experience, previousExperienceMonth, previousExperienceYear } =
          res.data.getExperience;
        this.totalMonth = previousExperienceMonth;
        this.totalYear = previousExperienceYear;
        // check month or year greater than zero to consider it as add/edit, if any of exist, then consider it as edit
        if (this.totalYear > 0 || this.totalMonth > 0) {
          this.isTotalExperienceEdit = true;
        } else this.isTotalExperienceEdit = false;
        if (!experience) {
          this.addExperience = [0];
          this.showSwitch = true;
        } else {
          this.showSwitch = false;
          this.$emit("expProcessChanged", 100);
          for (var i = 0; i < experience.length; i++) {
            this.expLength = res.data.getExperience.experience.length;
            this.avoidAdd = true;
            this.addExperience.push(i);
            this.$set(this.CompanyName, i, experience[i].Prev_Company_Name);
            this.$set(
              this.LocationAddress,
              i,
              experience[i].Prev_Company_Location
            );
            this.$set(this.enableEdit, i, false);
            this.$set(this.Designation, i, experience[i].Designation);
            this.$set(this.Id, i, experience[i].Experience_Id);
            this.$set(this.From, i, experience[i].Start_Date);
            this.$set(this.To, i, experience[i].End_Date);
            this.$set(this.showAdd, i, false);
            this.$set(this.fileNameFormat, i, experience[i].File_Name);
            this.$set(
              this.fileName,
              i,
              !experience[i].File_Name
                ? ""
                : experience[i].File_Name.split("?")[3]
            );
          }
        }
      })
      .catch((err) => {
        this.snackbar = true;
        this.ResponseMessage =
          err.graphQLErrors.length !== 0
            ? err.graphQLErrors[0].message
            : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
        this.color = "error";
      });
  },
  methods: {
    // saving the total experience to the backend
    saveTotalExperience(source) {
      if (this.$refs.totalExperienceForm.validate()) {
        this.$apollo
          .mutate({
            mutation: UPDATE_TOTAL_EXPERIENCE,
            variables: {
              Org_Code: localStorage.getItem("org_code"),
              Url_Hash: localStorage.getItem("url_hash"),
              Previous_Experience_Month: this.totalMonth
                ? parseInt(this.totalMonth, 10)
                : 0,
              Previous_Experience_Year: this.totalYear
                ? parseInt(this.totalYear, 10)
                : 0,
            },
          })
          .then(() => {
            this.snackbar = true;
            this.ResponseMessage =
              source === "update"
                ? "Saved Successfully"
                : "Deleted Successfully";
            this.color = "success";
            if (source === "update") this.isTotalExperienceEdit = true;
          })
          .catch((err) => {
            this.snackbar = true;
            this.ResponseMessage =
              err.graphQLErrors.length !== 0
                ? err.graphQLErrors[0].message
                : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
            this.color = "error";
          });
      }
    },

    // updating the total experience
    updateTotalExperience() {
      this.isTotalExperienceEdit = false;
    },

    // deleting the total experience
    deleteTotalExperience() {
      this.isTotalExperienceEdit = false;
      this.totalYear = 0;
      this.totalMonth = 0;
      this.saveTotalExperience("delete");
    },

    //  enable file select
    pickFile(index) {
      this.CurrentIndex = index;
      this.$refs.image[index].click();
    },

    // add more experience details
    addExp(index, remove_el, remove_val) {
      var index_val = remove_val.length;
      if (this.$refs["expForm" + index][0].validate() && this.avoidAdd) {
        this.avoidAdd = false;
        this.$set(this.showAdd, index_val, true);
        this.$set(this.defineAddEdit, index_val, true);
        this.$set(this.CompanyName, index_val, "");
        this.$set(this.LocationAddress, index_val, "");
        this.$set(this.Designation, index_val, "");
        this.$set(this.From, index_val, "");
        this.$set(this.To, index_val, "");
        this.$set(this.fileName, index_val, "");
        this.$set(this.enableEdit, index, false);
        remove_el.push(index_val);
        remove_val[index_val] = true;
      } else {
        this.snackbar = true;
        this.ResponseMessage = "First save your details";
        this.color = "error";
      }
    },
    // cancel experience details
    cancelExp(index, remove_el, remove_val) {
      if (this.enableEdit[index]) {
        this.$set(this.showAdd, index, false);
        this.avoidAdd = true;
        this.$set(this.CompanyName, index, this.CompNameCopy[index]);
        this.$set(this.LocationAddress, index, this.LocCopy[index]);
        this.$set(this.Designation, index, this.esiCopy[index]);
        this.$set(this.From, index, this.FromCopy[index]);
        this.$set(this.To, index, this.ToCopy[index]);
        this.$set(this.fileName, index, this.fileNameCopy[index]);
      } else {
        if (this.addExperience.length !== 1) {
          this.avoidAdd = true;
          remove_el.splice(index, 1);
          remove_val.splice(index, 1);
          this.Id.splice(index, 1);
          this.$set(this.CompanyName, index, this.CompanyName[index + 1]);
          this.$set(
            this.LocationAddress,
            index,
            this.LocationAddress[index + 1]
          );
          this.$set(this.Designation, index, this.Designation[index + 1]);
          this.$set(this.From, index, this.From[index + 1]);
          this.$set(this.To, index, this.To[index + 1]);
          this.$set(this.fileName, index, this.fileName[index + 1]);
        } else {
          this.$set(this.CompanyName, index, "");
          this.$set(this.LocationAddress, index, "");
          this.$set(this.Designation, index, "");
          this.$set(this.From, index, "");
          this.$set(this.To, index, "");
          this.$set(this.Id, index, "");
          this.$set(this.fileName, index, "");
          this.$set(this.showAdd, index, true);
          this.$refs["expForm" + index][0].reset();
          remove_val[index] = true;
          this.$emit("expProcessChanged", 0);
        }
      }
    },
    emptyTO(index) {
      this.$set(this.To, index, "");
    },
    // submit experience details
    saveExp(index) {
      if (this.$refs["expForm" + index][0].validate()) {
        if (!this.enableEdit[index]) {
          this.$apollo
            .mutate({
              mutation: ADD_EXPERIENCE,
              variables: {
                Org_Code: localStorage.getItem("org_code"),
                Url_Hash: localStorage.getItem("url_hash"),
                Prev_Company_Name: this.CompanyName[index],
                Prev_Company_Location: this.LocationAddress[index],
                Designation: this.Designation[index],
                Start_Date: this.From[index],
                File_Name: this.fileNameFormat[index],
                End_Date: this.To[index],
              },
            })
            .then((res) => {
              this.$emit("expProcessChanged", 100);
              this.Id = [];
              for (
                var i = 0;
                i < res.data.addExperience.experience.length;
                i++
              ) {
                this.$set(
                  this.Id,
                  i,
                  res.data.addExperience.experience[i].Experience_Id
                );
              }
              this.snackbar = true;
              this.avoidAdd = true;
              this.ResponseMessage = "Saved Successfully";
              this.color = "success";
              this.showAdd[index] = false;
            })
            .catch((err) => {
              this.snackbar = true;
              this.ResponseMessage =
                err.graphQLErrors.length !== 0
                  ? err.graphQLErrors[0].message
                  : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
              this.color = "error";
            });
        } else {
          this.$apollo
            .mutate({
              mutation: UPDATE_EXPERIENCE,
              variables: {
                Org_Code: localStorage.getItem("org_code"),
                Url_Hash: localStorage.getItem("url_hash"),
                Prev_Company_Name: this.CompanyName[index],
                Prev_Company_Location: this.LocationAddress[index],
                Designation: this.Designation[index],
                Start_Date: this.From[index],
                End_Date: this.To[index],
                File_Name: this.fileNameFormat[index],
                Experience_Id: this.Id[index],
              },
            })
            .then(() => {
              this.snackbar = true;
              this.avoidAdd = true;
              this.ResponseMessage = "Updated Successfully";
              this.color = "success";
              this.showAdd[index] = false;
            })
            .catch((err) => {
              this.snackbar = true;
              this.ResponseMessage =
                err.graphQLErrors.length !== 0
                  ? err.graphQLErrors[0].message
                  : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
              this.color = "error";
            });
        }
      }
    },
    // enable editinf of experience etails
    EditExp(index) {
      this.avoidAdd = false;
      this.$set(this.showAdd, index, true);
      this.$set(this.enableEdit, index, true);
      this.$set(this.defineAddEdit, index, false);
      this.$set(this.CompNameCopy, index, this.CompanyName[index]);
      this.$set(this.LocCopy, index, this.LocationAddress[index]);
      this.$set(this.esiCopy, index, this.Designation[index]);
      this.$set(this.FromCopy, index, this.From[index]);
      this.$set(this.ToCopy, index, this.To[index]);
      this.$set(this.fileNameCopy, index, this.fileName[index]);
    },
    // delete experience details
    removeExp(index, remove_el, remove_val) {
      this.$apollo
        .mutate({
          mutation: DELETE_EXPERIENCE,
          variables: {
            Org_Code: localStorage.getItem("org_code"),
            Url_Hash: localStorage.getItem("url_hash"),
            Experience_Id: this.Id[index],
          },
        })
        .then(() => {
          this.avoidAdd = true;
          this.snackbar = true;
          this.ResponseMessage = "Deleted Successfully";
          this.color = "success";
          if (this.addExperience.length !== 1) {
            remove_el.splice(index, 1);
            remove_val.splice(index, 1);
            this.Id.splice(index, 1);
            this.$set(this.CompanyName, index, this.CompanyName[index + 1]);
            this.$set(
              this.LocationAddress,
              index,
              this.LocationAddress[index + 1]
            );
            this.$set(this.Designation, index, this.Designation[index + 1]);
            this.$set(this.From, index, this.From[index + 1]);
            this.$set(this.To, index, this.To[index + 1]);
            this.$set(this.fileName, index, this.fileName[index + 1]);
          } else {
            this.$set(this.CompanyName, index, "");
            this.$set(this.LocationAddress, index, "");
            this.$set(this.Designation, index, "");
            this.$set(this.From, index, "");
            this.$set(this.To, index, "");
            this.$set(this.fileName, index, "");
            this.$set(this.showAdd, index, true);
            this.$refs["expForm" + index][0].reset();
            remove_val[index] = true;
            this.showSwitch = true;
            this.$emit("expProcessChanged", 0);
          }
        })
        .catch((err) => {
          this.snackbar = true;
          this.ResponseMessage =
            err.graphQLErrors.length !== 0
              ? err.graphQLErrors[0].message
              : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
          this.color = "error";
        });
    },
    //  switch to select not experienced person
    notExperienced(val) {
      if (val) {
        this.$set(this.showAdd, 0, false);
        this.showexpCard = false;
        this.$emit("expProcessChanged", 100);
      } else {
        this.$set(this.showAdd, 0, true);
        this.showexpCard = true;
        this.$emit("expProcessChanged", 0);
      }
    },
    //  fetch file details
    onFilePicked(e) {
      const files = e.target.files;
      var fileContent;
      // reader.readAsDataURL(files);
      if (files[0] !== undefined) {
        // this.fileName[this.CurrentIndex] = files[0].name;
        if (files[0].name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          fileContent = fr.result.split(",");
          if (!this.fileName[this.CurrentIndex]) {
            this.isUploadingDocument = true;
            this.$apollo
              .query({
                query: UPLOAD_DOCUMENT,
                variables: {
                  File_Name: files[0].name,
                  Category: "experience",
                  File_Content: fileContent[1],
                  Org_Code: localStorage.getItem("org_code"),
                  Url_Hash: localStorage.getItem("url_hash"),
                },
              })
              .then((res) => {
                this.snackbar = true;
                this.ResponseMessage = "File Uploaded";
                this.color = "success";
                this.$set(this.fileName, this.CurrentIndex, files[0].name);
                if (files[0].type.split("/")[1] === "pdf") {
                  this.$set(
                    this.imgContent,
                    this.CurrentIndex,
                    "/pdfImage.png"
                  );
                } else {
                  this.$set(
                    this.imgContent,
                    this.CurrentIndex,
                    fr.result.split(",")
                  );
                }
                this.$set(
                  this.fileNameFormat,
                  this.CurrentIndex,
                  res.data.fileUpload.fileName
                );
                this.isUploadingDocument = false;
              })
              .catch((err) => {
                this.snackbar = true;
                this.ResponseMessage =
                  err.graphQLErrors.length !== 0
                    ? err.graphQLErrors[0].message
                    : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
                this.color = "error";
                this.isUploadingDocument = false;
              });
          } else {
            this.isUploadingDocument = true;
            this.$apollo
              .query({
                query: EDIT_DOCUMENT,
                variables: {
                  File_Name: this.fileNameFormat[this.CurrentIndex],
                  New_File_Name: files[0].name,
                  Category: "experience",
                  New_File_Content: fileContent[1],
                  Org_Code: localStorage.getItem("org_code"),
                  Url_Hash: localStorage.getItem("url_hash"),
                },
              })
              .then((res) => {
                this.snackbar = true;
                this.ResponseMessage = "File Uploaded";
                this.color = "success";
                this.$set(this.fileName, this.CurrentIndex, files[0].name);
                if (files[0].type.split("/")[1] === "pdf") {
                  this.$set(
                    this.imgContent,
                    this.CurrentIndex,
                    "/pdfimage.png"
                  );
                } else {
                  this.$set(
                    this.imgContent,
                    this.CurrentIndex,
                    fr.result.split(",")
                  );
                }
                this.$set(
                  this.fileNameFormat,
                  this.CurrentIndex,
                  res.data.fileReplace.fileName
                );
                this.isUploadingDocument = false;
              })
              .catch((err) => {
                this.snackbar = true;
                this.ResponseMessage =
                  err.graphQLErrors.length !== 0
                    ? err.graphQLErrors[0].message
                    : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
                this.color = "error";
                this.isUploadingDocument = false;
              });
          }
        });
      } else {
        this.fileName[this.CurrentIndex] = "";
      }
    },
    // delete upload document
    deleteFile(index) {
      this.isUploadingDocument = true;
      this.$apollo
        .query({
          query: DELETE_DOCUMENT,
          variables: {
            Org_Code: localStorage.getItem("org_code"),
            Url_Hash: localStorage.getItem("url_hash"),
            Category: "experience",
            File_Name: this.fileNameFormat[index],
          },
        })
        .then(() => {
          this.snackbar = true;
          this.ResponseMessage = "File Deleted";
          this.color = "success";
          this.$set(this.fileName, index, "");
          this.isUploadingDocument = false;
        })
        .catch((err) => {
          this.snackbar = true;
          this.ResponseMessage =
            err.graphQLErrors.length !== 0
              ? err.graphQLErrors[0].message
              : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
          this.color = "error";
          this.isUploadingDocument = false;
        });
    },
  },
};
</script>

<style scoped>
.ps {
  height: 85ex;
}
.imgClass {
  width: 71px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid #fff;
}
</style>
