<template>
  <v-container>
    <!-- alerts -->
    <v-snackbar v-model="snackbar" :color="color" multi-line :timeout="3000">
      {{ ResponseMessage }}
      <v-btn dark flat @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <!-- certification -->
    <v-form
      v-for="(addexp, index) in addCertificate"
      :key="index + 'form2'"
      :ref="'certiform' + index"
    >
      <v-container grid-list-md text-xs-center>
        <v-layout row wrap>
          <v-flex xs12 md10>
            <v-card>
              <v-card-text>
                <v-layout row wrap>
                  <v-flex md12>
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          v-show="!showCerti[index]"
                          :id="'DeleteCertifications'"
                          small
                          fab
                          dark
                          color="red"
                          class="right"
                          v-on="on"
                          @click.native.prevent="
                            deleteCertification(
                              index,
                              addCertificate,
                              showCerti
                            )
                          "
                        >
                          <v-icon dark>delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete Certificate Details</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          v-show="!showCerti[index]"
                          :id="'EditCertifications'"
                          small
                          fab
                          dark
                          color="warning"
                          class="right"
                          v-on="on"
                          @click="editCertification(index)"
                        >
                          <v-icon dark>edit</v-icon>
                        </v-btn>
                      </template>
                      <span>Change Certificate Deatils</span>
                    </v-tooltip>
                  </v-flex>
                  <!-- line 1 -->
                  <v-flex xs12 md6>
                    <!-- certification name -->
                    <v-text-field
                      :id="'CertificateName'"
                      v-model="CertificateName[index]"
                      background-color="#e0e0e0"
                      color="#3cd1c2"
                      outline
                      :counter="30"
                      :maxlength="30"
                      :disabled="!showCerti[index]"
                      label="Certificate Name*"
                      prepend-icon="card_membership"
                      :rules="$store.state.certificationRules"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <!-- received from -->
                    <v-text-field
                      :id="'CertificateReceivedFrom'"
                      v-model="ReceivedFrom[index]"
                      background-color="#e0e0e0"
                      color="#3cd1c2"
                      outline
                      :counter="30"
                      :maxlength="30"
                      :disabled="!showCerti[index]"
                      label="Received From*"
                      prepend-icon="person"
                      :rules="$store.state.alSpDotRules"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-dialog
                      v-model="modal_certi"
                      persistent
                      width="290"
                      lazy
                      full-width
                    >
                      <template #activator="{ on }">
                        <!-- received date -->
                        <v-text-field
                          :id="'CertificateReceivedOn'"
                          v-model="ReceivedOn[index]"
                          :disabled="!showCerti[index]"
                          background-color="#e0e0e0"
                          color="#3cd1c2"
                          outline
                          readonly
                          label="Received On*"
                          prepend-icon="date_range"
                          :rules="[(v) => !!v || 'This field is required']"
                          required
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="ReceivedOn[index]"
                        :min="minJoinDate"
                        :max="maxYear"
                        color="#3cd1c2"
                        scrollable
                        actions
                      >
                        <v-btn
                          flat
                          color="primary"
                          class="right"
                          @click="modal_certi = false"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                  <!-- line 2 -->
                  <v-flex xs12 md6>
                    <!-- file upload -->
                    <v-list v-show="fileNameCertify[index]">
                      <v-list-tile>
                        <v-list-tile-action>
                          <v-icon :disabled="!showCerti[index]"
                            >cloud_upload</v-icon
                          >
                        </v-list-tile-action>
                        <v-list-tile-content>
                          <v-list-tile-title class="grey--text">{{
                            fileNameCertify[index]
                          }}</v-list-tile-title>
                        </v-list-tile-content>
                        <v-list-tile-action>
                          <v-tooltip bottom>
                            <template #activator="{ on }">
                              <v-btn
                                :id="'EditDocumentsCertification'"
                                fab
                                small
                                :disabled="!showCerti[index]"
                                class="ml-4"
                                color="warning"
                                v-on="on"
                                @click="pickFile(index, 'certi')"
                              >
                                <v-icon
                                  class="right"
                                  :disabled="!showCerti[index]"
                                  >edit</v-icon
                                >
                              </v-btn>
                              <input
                                ref="image"
                                type="file"
                                style="display: none"
                                accept=".png,.jpg,.jpeg,.pdf"
                                @change="onFilePicked"
                              />
                            </template>
                            <span>Change File</span>
                          </v-tooltip>
                        </v-list-tile-action>
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <v-btn
                              :id="'DeleteDocumentsCertification'"
                              fab
                              small
                              :disabled="!showCerti[index]"
                              color="red"
                              v-on="on"
                            >
                              <v-icon
                                class="right white--text"
                                dark
                                :disabled="!showCerti[index]"
                                @click="deleteFile(index, 'certi')"
                                >delete</v-icon
                              >
                            </v-btn>
                          </template>
                          <span>Delete File</span>
                        </v-tooltip>
                      </v-list-tile>
                    </v-list>
                    <v-list v-show="!fileNameCertify[index]">
                      <v-list-tile>
                        <v-list-tile-action>
                          <v-icon :disabled="!showCerti[index]"
                            >cloud_upload</v-icon
                          >
                        </v-list-tile-action>
                        <v-list-tile-content>
                          <v-list-tile-title class="grey--text"
                            >No File Chosen</v-list-tile-title
                          >
                        </v-list-tile-content>
                        <v-list-tile-action>
                          <v-tooltip bottom>
                            <template #activator="{ on }">
                              <v-btn
                                :id="'AddDocumentsCertification'"
                                fab
                                small
                                :disabled="!showCerti[index]"
                                class="success ml-4"
                                v-on="on"
                                @click="pickFile(index, 'certi')"
                              >
                                <v-icon
                                  class="right"
                                  :disabled="!showCerti[index]"
                                  >add</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Add File</span>
                          </v-tooltip>
                        </v-list-tile-action>
                      </v-list-tile>
                    </v-list>
                    <!-- file upload end -->
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-select
                      v-show="fileNameCertify[index]"
                      :id="'Certificate_Sub_Document_Type'"
                      v-model="CertificationSubType[index]"
                      background-color="#e0e0e0"
                      outline
                      :disabled="!showCerti[index]"
                      prepend-icon="list_alt"
                      :items="CertificationSubTypeList"
                      item-text="Document_Sub_Type"
                      item-value="Document_Sub_Type_Id"
                      color="#3cd1c2"
                      label="Certificate Sub Type*"
                      :rules="
                        fileNameCertify[index]
                          ? [(v) => !!v || 'This field is required']
                          : [true]
                      "
                      :required="fileNameCertify[index] ? true : false"
                    ></v-select>
                  </v-flex>
                  <!-- document name-->
                  <v-flex xs12 md6>
                    <v-text-field
                      v-show="fileNameCertify[index]"
                      :id="'Certi_document_name'"
                      v-model="CertiDocName[index]"
                      background-color="#e0e0e0"
                      color="#3cd1c2"
                      outline
                      :counter="50"
                      :maxlength="50"
                      :disabled="!showCerti[index]"
                      label="Document Name"
                      prepend-icon="folder_shared"
                      :rules="
                        fileNameCertify[index]
                          ? $store.state.docNameRules
                          : [true]
                      "
                      :required="fileNameCertify[index] ? true : false"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs12 md12>
                    <!-- submit education details -->
                    <v-btn
                      v-show="showCerti[index]"
                      :id="'SaveCertifications'"
                      class="success"
                      style="margin: auto"
                      :disabled="isUploadingDocument"
                      @click="saveCertification(index)"
                      >Save</v-btn
                    >
                    <!-- cancel  -->
                    <v-btn
                      v-show="showCerti[index]"
                      :id="'CancelCertifications'"
                      class="ml-2"
                      style="margin: auto"
                      @click="
                        cancelCertification(
                          showCerti,
                          index,
                          addCertificate,
                          showCerti
                        )
                      "
                      >Cancel</v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 md2>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <!-- add more certification details -->
                <v-btn
                  v-show="
                    addCertificate.length === index + 1 ||
                    addCertificate.length === 0
                  "
                  :id="'AddCertificationDetails'"
                  fab
                  small
                  class="success"
                  dark
                  v-on="on"
                  @click="AddCertificate(index, addCertificate, showCerti)"
                >
                  <v-icon>add</v-icon>
                </v-btn>
              </template>
              <span>Add Certificate Details</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import {
  ADD_CERTIFICATE,
  UPDATE_CERTIFICATE,
  GET_CERTIFICATE,
  DELETE_CERTIFICATE,
  UPLOAD_DOCUMENT,
  EDIT_DOCUMENT,
  DELETE_DOCUMENT,
  LIST_SUB_DOC_TYPE,
} from "../graphql.js";
import moment from "moment";

export default {
  data() {
    return {
      //Certificate details
      CertificateName: [],
      CertificationSubType: [],
      CertificationSubTypeList: [],
      ReceivedOn: [],
      ReceivedFrom: [],
      CertificateId: [],
      CertiDocName: [],
      // copy
      CertiNameCopy: [],
      ReciFromCopy: [],
      ReciOnCopy: [],
      fileNameCopy: [],
      SubTypeCopy: [],
      DocNameCopy: [],
      // File Details
      FileNameFormatCertify: [],
      FileType: "",
      CurrentIndex: "",
      fileName: [],
      fileNameCertify: [],
      isUploadingDocument: false,
      // form count
      addCertificate: [0],
      // display add edit
      showCerti: [true],
      // alert properties
      snackbar: false,
      ResponseMessage: "",
      color: "success",
      // enable edit and avoid add
      enableCertiEdit: [false],
      avoidAddCerti: false,
      // minimum date
      minJoinDate: localStorage.getItem("DOB"),
      maxYear: "",
      // list of courses
      modal_certi: false,
    };
  },

  created: function () {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    this.maxYear = yyyy + "-" + mm + "-" + dd;

    // retrieve certification sub types
    this.$apollo
      .query({
        query: LIST_SUB_DOC_TYPE,
        variables: {
          Org_Code: localStorage.getItem("org_code"),
          Url_Hash: localStorage.getItem("url_hash"),
          type_id: 4,
        },
      })
      .then((res) => {
        if (res.data.listDocumentSubType.documentSubType) {
          this.CertificationSubTypeList =
            res.data.listDocumentSubType.documentSubType;
        }
      })
      .catch((err) => {
        this.snackbar = true;
        this.ResponseMessage =
          err.graphQLErrors.length !== 0
            ? err.graphQLErrors[0].message
            : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
        this.color = "error";
      });

    // retrieve certification details
    this.$apollo
      .query({
        query: GET_CERTIFICATE,
        variables: {
          Org_Code: localStorage.getItem("org_code"),
          Url_Hash: localStorage.getItem("url_hash"),
        },
        fetchPolicy: "no-cache",
      })
      .then((res1) => {
        this.addCertificate = [];
        var certi = res1.data.getCertification.certificates;
        if (!certi) {
          this.addCertificate = [0];
        } else {
          this.$emit("certificateProgress", 32);
          for (var i = 0; i < certi.length; i++) {
            this.avoidAddCerti = true;
            this.addCertificate.push(i);
            this.$set(this.showCerti, i, false);
            this.$set(this.CertificateName, i, certi[i].Certification_Name);
            this.$set(this.ReceivedOn, i, certi[i].Received_Date);
            this.$set(this.CertificateId, i, certi[i].Certification_Id);
            this.$set(this.ReceivedFrom, i, certi[i].Certificate_Received_From);
            this.$set(
              this.fileNameCertify,
              i,
              !certi[i].File_Name ? "" : certi[i].File_Name.split("?")[3]
            );
            this.$set(this.FileNameFormatCertify, i, certi[i].File_Name);
            this.$set(this.CertificationSubType, i, certi[i].Sub_Type_Id);
            this.$set(this.CertiDocName, i, certi[i].Document_Name);
          }
        }
      })
      .catch((err) => {
        this.snackbar = true;
        this.ResponseMessage =
          err.graphQLErrors.length !== 0
            ? err.graphQLErrors[0].message
            : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
        this.color = "error";
      });
  },

  methods: {
    //  conver date as yyyy-mm-dd format
    calculateDate(value) {
      var dateObj = moment(parseInt(value, 10)).format("YYYY-MM-DD");
      return dateObj;
    },
    // submit certification details
    saveCertification(index) {
      if (this.$refs["certiform" + index][0].validate()) {
        if (!this.enableCertiEdit[index]) {
          this.$apollo
            .mutate({
              mutation: ADD_CERTIFICATE,
              variables: {
                Org_Code: localStorage.getItem("org_code"),
                Url_Hash: localStorage.getItem("url_hash"),
                Certification_Name: this.CertificateName[index],
                Received_Date: this.ReceivedOn[index],
                Certificate_Received_From: this.ReceivedFrom[index],
                File_Name: this.FileNameFormatCertify[index],
                Sub_Type_Id: this.CertificationSubType[index],
                Document_Name: this.CertiDocName[index],
              },
            })
            .then((res) => {
              if (this.addCertificate.length === 1) {
                this.$emit("certificateProgress", 32);
              }
              for (
                var i = 0;
                i < res.data.addCertification.certificates.length;
                i++
              ) {
                this.$set(
                  this.CertificateId,
                  i,
                  res.data.addCertification.certificates[i].Certification_Id
                );
              }
              this.avoidAddCerti = true;
              this.$set(this.showCerti, index, false);
              this.snackbar = true;
              this.ResponseMessage = "Added Successfully";
              this.color = "success";
            })
            .catch((err) => {
              this.snackbar = true;
              this.ResponseMessage =
                err.graphQLErrors.length !== 0
                  ? err.graphQLErrors[0].message
                  : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
              this.color = "error";
            });
        } else {
          this.$apollo
            .mutate({
              mutation: UPDATE_CERTIFICATE,
              variables: {
                Org_Code: localStorage.getItem("org_code"),
                Url_Hash: localStorage.getItem("url_hash"),
                Certification_Id: this.CertificateId[index],
                Certification_Name: this.CertificateName[index],
                Received_Date: this.ReceivedOn[index],
                Certificate_Received_From: this.ReceivedFrom[index],
                File_Name: this.FileNameFormatCertify[index],
                Sub_Type_Id: this.CertificationSubType[index],
                Document_Name: this.CertiDocName[index],
              },
            })
            .then(() => {
              this.snackbar = true;
              this.$set(this.enableCertiEdit, index, false);
              this.ResponseMessage = "Updated Successfully";
              this.color = "success";
              this.avoidAddCerti = true;
              this.$set(this.showCerti, index, false);
            })
            .catch((err) => {
              this.snackbar = true;
              this.ResponseMessage =
                err.graphQLErrors.length !== 0
                  ? err.graphQLErrors[0].message
                  : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
              this.color = "error";
            });
        }
      }
    },
    // cancel certification details
    cancelCertification(val, index, remove_el, remove_val) {
      if (this.enableCertiEdit[index]) {
        this.avoidAddCerti = true;
        this.$set(val, index, false);
        this.$set(this.CertificateName, index, this.CertiNameCopy[index]);
        this.$set(this.ReceivedOn, index, this.ReciOnCopy[index]);
        this.$set(this.ReceivedFrom, index, this.ReciFromCopy[index]);
        this.$set(this.fileNameCertify, index, this.fileNameCopy[index]);
        this.$set(this.CertificationSubType, index, this.SubTypeCopy[index]);
        this.$set(this.CertiDocName, index, this.DocNameCopy[index]);
      } else {
        if (this.addCertificate.length !== 1) {
          this.avoidAddCerti = true;
          remove_el.splice(index, 1);
          remove_val.splice(index, 1);
          this.$set(
            this.CertificateName,
            index,
            this.CertificateName[index + 1]
          );
          this.$set(this.ReceivedOn, index, this.ReceivedOn[index + 1]);
          this.$set(this.ReceivedFrom, index, this.ReceivedFrom[index + 1]);
          this.$set(
            this.fileNameCertify,
            index,
            this.fileNameCertify[index + 1]
          );
        } else {
          this.$set(this.CertificateName, index, "");
          this.$set(this.ReceivedOn, index, "");
          this.$set(this.ReceivedFrom, index, "");
          this.$set(this.showCerti, index, true);
          this.$set(this.fileNameCertify, index, "");
          this.$refs["certiform" + index][0].reset();
          remove_val[index] = true;
        }
      }
    },
    // enable certification details to edit
    editCertification(index) {
      this.avoidAddCerti = false;
      this.$set(this.enableCertiEdit, index, true);
      this.$set(this.showCerti, index, true);
      this.$set(this.CertiNameCopy, index, this.CertificateName[index]);
      this.$set(this.ReciFromCopy, index, this.ReceivedFrom[index]);
      this.$set(this.ReciOnCopy, index, this.ReceivedOn[index]);
      this.$set(this.fileNameCopy, index, this.fileNameCertify[index]);
      this.$set(this.SubTypeCopy, index, this.CertificationSubType[index]);
      this.$set(this.DocNameCopy, index, this.CertiDocName[index]);
    },
    // add more certi details
    AddCertificate(index, remove_el, remove_val) {
      var index_val = remove_val.length;
      if (this.$refs["certiform" + index][0].validate() && this.avoidAddCerti) {
        this.avoidAddCerti = false;
        this.$set(this.showCerti, index_val, true);
        this.$set(this.CertificateName, index_val, "");
        this.$set(this.ReceivedOn, index_val, "");
        this.$set(this.ReceivedFrom, index_val, "");
        remove_el.push(index_val);
        remove_val[index_val] = true;
      } else {
        this.snackbar = true;
        this.ResponseMessage = "First save your details";
        this.color = "error";
      }
    },
    // delete certification details
    deleteCertification(index, remove_el, remove_val) {
      this.$apollo
        .mutate({
          mutation: DELETE_CERTIFICATE,
          variables: {
            Org_Code: localStorage.getItem("org_code"),
            Url_Hash: localStorage.getItem("url_hash"),
            Certification_Id: this.CertificateId[index],
          },
        })
        .then(() => {
          this.snackbar = true;
          this.ResponseMessage = "Deleted Successfully";
          this.color = "success";
          this.CertificateId.splice(index, 1);

          if (this.addCertificate.length !== 1) {
            remove_el.splice(index, 1);
            remove_val.splice(index, 1);
            this.$set(
              this.CertificateName,
              index,
              this.CertificateName[index + 1]
            );
            this.$set(this.ReceivedOn, index, this.ReceivedOn[index + 1]);
            this.$set(this.ReceivedFrom, index, this.ReceivedFrom[index + 1]);
            this.$set(this.CertiDocName, index, this.CertiDocName[index + 1]);
            this.$set(
              this.CertificationSubType,
              index,
              this.CertificationSubType[index + 1]
            );
            this.$set(
              this.fileNameCertify,
              index,
              this.fileNameCertify[index + 1]
            );
          } else {
            this.$emit("certificateProgress", -32);
            this.$set(this.CertificateName, index, "");
            this.$set(this.ReceivedOn, index, "");
            this.$set(this.ReceivedFrom, index, "");
            this.$set(this.showCerti, index, true);
            this.$set(this.CertiDocName, index, "");
            this.$set(this.fileNameCertify, index, "");
            this.$set(this.CertificationSubType, index, "");
            this.$refs["certiform" + index][0].reset();
            remove_val[index] = true;
          }
        })
        .catch((err) => {
          this.snackbar = true;
          this.ResponseMessage =
            err.graphQLErrors.length !== 0
              ? err.graphQLErrors[0].message
              : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
          this.color = "error";
        });
    },

    //  enable file picker
    pickFile(index, type) {
      this.CurrentIndex = index;
      this.FileType = type;
      this.$refs.image[index].click();
    },
    // to fetch file details
    onFilePicked(e) {
      const files = e.target.files;
      var fileContent;
      // reader.readAsDataURL(files);
      if (files[0] !== undefined) {
        // this.fileName[this.CurrentIndex] = files[0].name;
        if (files[0].name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          fileContent = fr.result.split(",");
          if (this.FileType === "certi") {
            this.updateCertify(files[0].name, fileContent);
          }
        });
      } else {
        this.fileNameCertify[this.CurrentIndex] = "";
      }
    },
    // upload document for certification
    updateCertify(filename, fileContent) {
      if (!this.fileNameCertify[this.CurrentIndex]) {
        this.isUploadingDocument = true;
        this.$apollo
          .query({
            query: UPLOAD_DOCUMENT,
            variables: {
              File_Name: filename,
              Category: "document",
              File_Content: fileContent[1],
              Org_Code: localStorage.getItem("org_code"),
              Url_Hash: localStorage.getItem("url_hash"),
            },
          })
          .then((response) => {
            this.snackbar = true;
            this.ResponseMessage = "File Uploaded";
            this.color = "success";
            this.$set(this.fileNameCertify, this.CurrentIndex, filename);
            this.FileNameFormatCertify[this.CurrentIndex] =
              response.data.fileUpload.fileName;
            this.isUploadingDocument = false;
          })
          .catch((err) => {
            this.snackbar = true;
            this.ResponseMessage =
              err.graphQLErrors.length !== 0
                ? err.graphQLErrors[0].message
                : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
            this.color = "error";
            this.isUploadingDocument = false;
          });
      } else {
        this.isUploadingDocument = true;
        this.$apollo
          .query({
            query: EDIT_DOCUMENT,
            variables: {
              New_File_Name: filename,
              Category: "document",
              File_Name: this.FileNameFormatCertify[this.CurrentIndex],
              New_File_Content: fileContent[1],
              Org_Code: localStorage.getItem("org_code"),
              Url_Hash: localStorage.getItem("url_hash"),
            },
          })
          .then((response) => {
            this.snackbar = true;
            this.ResponseMessage = "File Uploaded";
            this.color = "success";
            this.$set(this.fileNameCertify, this.CurrentIndex, filename);
            this.FileNameFormatCertify[this.CurrentIndex] =
              response.data.fileReplace.fileName;
            this.isUploadingDocument = false;
          })
          .catch((err) => {
            this.snackbar = true;
            this.ResponseMessage =
              err.graphQLErrors.length !== 0
                ? err.graphQLErrors[0].message
                : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
            this.color = "error";
            this.isUploadingDocument = false;
          });
      }
    },
    // delete documents for all details
    deleteFile(index, FileType) {
      if (FileType === "certi") {
        this.isUploadingDocument = true;
        this.$apollo
          .query({
            query: DELETE_DOCUMENT,
            variables: {
              Org_Code: localStorage.getItem("org_code"),
              Url_Hash: localStorage.getItem("url_hash"),
              File_Name: this.FileNameFormatCertify[index],
              Category: "document",
            },
          })
          .then(() => {
            this.snackbar = true;
            this.ResponseMessage = "File Deleted";
            this.color = "success";
            this.$set(this.fileNameCertify, index, "");
            this.$set(this.CertiDocName, index, "");
            this.$set(this.CertificationSubType, index, "");
            this.isUploadingDocument = false;
          })
          .catch((err) => {
            this.snackbar = true;
            this.ResponseMessage =
              err.graphQLErrors.length !== 0
                ? err.graphQLErrors[0].message
                : "Something went wrong.It could be a posibility due to Network Connectivity Error..";
            this.color = "error";
            this.isUploadingDocument = false;
          });
      }
    },
  },
};
</script>
