import Vue from "vue";
import Router from "vue-router";
import Project from "./views/Onboard.vue";
import FAQ from "./views/FAQ.vue";
import Help from "./views/Help.vue";
import Signin from "./components/Signin.vue";
import NotFound from "./views/NotFound.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/signin",
      name: "Signin",
      component: Signin,
    },
    {
      path: "/",
      component: Signin,
    },
    {
      path: "/onboarding",
      name: "Project",
      component: Project,
    },
    {
      path: "/help",
      name: "Help",
      component: Help,
    },
    {
      path: "/faq",
      name: "FAQ",
      component: FAQ,
    },
    {
      path: "*",
      component: NotFound,
    },
  ],
});
